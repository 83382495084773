import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import Page from 'components/Page'
import Section from 'components/Section'
import SearchBar from 'components/SearchBar'
import {
  fetchGetDespachos
} from 'store/actions/clasificados.actions'
import Carrousel from '../components/carrouselphotos'
import EmptyLoader from 'components/PageLoader'
import MaterialTable from 'material-table'

import { PageWrapper, Wrapper, SearchWrapper, ButtonPublicaction, ContainerSearchBar, ContainerSelects, SelectStyled, Container,IconBack,ContainerIcon } from "./styled"
import mapDespachados from '../../../selectors/mapDespachados'

class Despachados extends React.Component {
  state = {
    filter: '',
    verPubliModal: false,
    addProduct: false,
    editProducto: false
  }

  componentDidMount = () => {
    this.props.fetchGetDespachos()
  }

  renderPageContent = () => {
    return (
      <SearchWrapper>
        <ContainerSearchBar>
          <SearchBar
            placeholder={'Buscar por nombre o categoria'}
            style={'width: \'70%\' '}
            value={this.state.filter}
            onChange={(e) => this.handleInputOnChange(e)}
            onClear={this.handleInputClear}
            onKeyDown={this.handleKeyPress}
            noBorder={true}
            autofocus
          />
        </ContainerSearchBar>
        <Container>
          <MaterialTable
            columns={[
              {
                title: 'Fecha', field: 'creationdate',
              },
               {
                 title: 'Fotos', field: 'images', render: rowData =>
                   <Carrousel images={rowData.images} />
               },
              { title: 'Producto', field: 'title'},
              { title: 'Cantidad', field: 'quantity'},
              { title: 'Id producto', field: 'idProduct'},
              {title: 'Estado', field: 'orderstatename'},
              { title: 'Cod. Venta', field: 'orderid'},
              { title: 'Comprador', field: 'buyerfullname'}
            ]}
            data={this.props.filteredDespachos}
            //onRowClick={(event, rowData, togglePanel) => this.onMovementClick(rowData)}
            // onSelectionChange={(rows) => this.onMovementClick( rows )}

            localization={{
              header: {
                actions: 'Acciones',
              },
              body: { emptyDataSourceMessage: 'No hay nada' }
            }}
            options={{
              toolbar: false,
              paging: false,
              height: '300px',
              width: "70vw",
              selection: false,
              actionsColumnIndex: -1,
              headerStyle: {
                zIndex: 0
              }
            }} //Esconde titulo y search y pagination 
          />
        </Container>
      </SearchWrapper>
    )
  }

  isFilterEnabled = () => this.state.filterEnabled

  handleInputOnChange = value => {
    this.setState({ filter: value, filterEnabled: false })
    this.props.fetchGetProducts(value)
  }

  handleDetailProduct = idProduct => {
    this.props.fetchDetailProduct(idProduct)
    this.toggleVerPubliModal()
  }

  toggleVerPubliModal = () => {
    this.setState({ verPubliModal: !this.state.verPubliModal })
  }

  handleInputClear = value => {
    this.setState({ filter: value, filterEnabled: false })
    this.props.fetchGetProducts(value)
  }

  handleKeyPress = e => {
    if (e.keyCode === 13 && e.target.value !== '') {
      this.props.fetchGetProducts(e.target.value)
      this.setState({ filterEnabled: true })
    }
  }

  isContentAvaiable = () => this.props.filteredProducts.length > 0 || this.props.filteredProducts.length > 0

  isLoadingContent = () => this.props.loading

  render() {
    return (
      <>
        {!this.state.addProduct && !this.state.editProducto ?
          <Page>
            <PageWrapper>
              <Section 
                title={'Despachado'}
                onBack={() => this.props.onClose()}
              >
                <Wrapper>
                  {this.isLoadingContent() ? <EmptyLoader /> : this.renderPageContent()}
                </Wrapper>
              </Section>
            </PageWrapper>
          </Page>
          :
          null
        }

        {/* 
        
        {this.state.editProducto ?
          <EditModal
           loading={this.props.getDetailsSuccess} 
           onClose={() => this.toggleEditModal()} 
           success={this.props.getDetailsFalse} 
           producto={this.props.detailProductShow.productDetail} />
          :
          null
        }       
      {this.props.fetchAddProductSuccess || this.props.fetchAddProductFail  ?
            <ModalMessage success={this.props.fetchAddProductSuccess} onClose={() => this.props.fetchAddProductReset()} >
                {
                    this.props.fetchAddProductFail ? <h3>Hubo un error, intentar nuevamente.</h3> : <>Producto creado correctamente</>
                }
            </ModalMessage>
            :
            <></>
        }
        {this.props.fetchEditProductSuccess || this.props.fetchEditProductFail  ?
            <ModalMessage success={this.props.fetchEditProductSuccess} fail onClose={() => this.props.fetchEditProductReset()} >
                {
                    this.props.fetchAddProductFail ? <h3>Hubo un error, al editar.</h3> : <>Producto editado correctamente</>
                }
            </ModalMessage>
            :
            <></>
        } */}
      </>
    )
  }
}

const mapStateToProps = ({
  clasificados: { despachos, loadingDespachos, fetchDeleteProductSuccess, loadingDeleteProduct, detailProduct, fetchDetailProductSuccess, fetchDetailProductFail, fetchAddProductSuccess, fetchAddProductFail, fetchAddProductReset, fetchEditProductSuccess, fetchEditProductFail, fetchEditProductReset, loadingAddProduct, loadingEditProduct },
  selectedGuest,
}) => ({
  filteredDespachos: despachos.length > 0 ? mapDespachados(despachos) : [],
  loading: loadingDespachos,
  succcessDelete: fetchDeleteProductSuccess,
  loadingDelete: loadingDeleteProduct,
  selectedGuest,
  detailProductShow: detailProduct,
  getDetailsSuccess: fetchDetailProductSuccess,
  getDetailsFail: fetchDetailProductFail,

  fetchAddProductSuccess: fetchAddProductSuccess,
  fetchAddProductFail: fetchAddProductFail,

  fetchEditProductSuccess: fetchEditProductSuccess,
  fetchEditProductFail: fetchEditProductFail,

  loadingAddProduct: loadingAddProduct,
  loadingEditProduct: loadingEditProduct,

})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchGetDespachos }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(Despachados)
)
