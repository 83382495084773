import React from 'react'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross-blue.png'
import MaterialTable from 'material-table'
import PageLoader from 'components/PageLoader'
import { Paper } from '@material-ui/core';

import {
  TableContainer,
  Title,
  CrossImage,
} from './styled'

class ResultUploadExpensas extends React.Component {
  state={
    errorFile: false
  }

  ModalContent=()=>{
    const {uploadFileRequest} = this.props.expensas;
      return(
                <MaterialTable
                title={(<Title>Resultado de rendicion de expensas</Title>)}
                columns={[
                    { title: 'Propietario', field: 'client_name'},
                    { title: 'CUIT', field: 'client_cuit'},
                    { title: 'Amount', field: 'amount', type:'currency'},
                    { title: 'Estado', field: 'status_result', cellStyle: { width: '45%'} }
                ]}
                data={uploadFileRequest.result_list}
                options={{
                    search: true,
                    paging: true,
                }} 
                components={{
                  Container: props => <Paper {...props} elevation={0}/>
                }}
                />
      )
  }
  render() {
    return (
        <Modal>
            <TableContainer>
                <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
                {this.props.expensas.loadingUploadFileExpense ? <PageLoader/> : this.ModalContent()}
            </TableContainer>
        </Modal>
    )
  }
}

export default ResultUploadExpensas