import React from 'react'
import { Container, MovementContainer, HeaderMovementsWrapper,LabelMovements, AuthWrapper,Arrow, TitleWrapper,Refresh,  Row, Title, Date, InfiniteLoaderWrapper,ErrorLabel, Charge, Pay, IconBack} from './styled'
import classNames from 'classnames'
import { Avatar, Grid} from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import CircularProgress from '@material-ui/core/CircularProgress'
import ticket from 'assets/icons/noun-signature-967158.svg'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {} from 'store/actions/billetera.actions'
import Spinner from 'react-spinner-material'
import {getFormattedDate} from 'utils/dateParser'
import ImagesGroup from '../ImagesGroup/index';
import concepto from 'constants/conceptoBilletera';
import refresh from 'assets/icons/refresh.svg';

const isSelected = (movement, selected) => selected && movement.id === selected.id

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ARS',
  });
  
// props movements, onMovementClick, selected, fetchMoreContent, HasMore 
class MovementsList extends React.Component {
  state = {
    electedOption: null,
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  }

  render() {
    const props = this.props;
    if(props.movements === []) {
      return (<ErrorLabel>No Hay movimientos</ErrorLabel>)
    } else {
      return (
        <InfiniteScroll
          scrollableTarget={'scrollContainer'}
          dataLength={props.movements.length}
          next={props.fetchMoreContent}
          hasMore={false}
          style={{ padding: '0px 34px 0px 34px' }}
          loader={<InfiniteLoaderWrapper><CircularProgress size={30}/></InfiniteLoaderWrapper>}
          endMessage={props.TransactionsList.length === 0 ? (<></>) : (<LabelMovements onClick={() => props.onOpenAllMovements()}>Ver más movimientos</LabelMovements>)}
        >
          <HeaderMovementsWrapper> 
            {this.props.NumberOfTransferRequest ? (
              <AuthWrapper 
                onClick={props.onOpenNewAuthMovements} 
                onClose={() => props.onCloseNewAuthMovements()}
              >
                <img src={ticket} alt={'ticket'} style={{marginRight: '20px'}}/>
                <label>
                    Tenés 
                    <label style={{color: 'red', fontWeight: '800'}}>
                        {' '+this.props.NumberOfTransferRequest+' '}
                    </label> 
                    autorizaciones de transferencia pendientes
                </label>
                <Arrow><IconBack/></Arrow>  
              </AuthWrapper>
              ) : ( 
                <AuthWrapper>
                  {this.props.NumberOfTransferRequest === 0 ? (
                    <label>No tenés autorizaciones de transferencia pendientes </label>
                    ) : (
                    <Spinner />
                    )
                  }
                </AuthWrapper>
              )}
              <Refresh src={refresh} onClick={this.props.fetchAll}/>
          </HeaderMovementsWrapper> 
          <ImagesGroup cuentas={concepto} onChange={this.props.filterMovements}/>
          <HeaderMovementsWrapper> 
            <TitleWrapper> 
              Últimos movimientos
            </TitleWrapper>
          </HeaderMovementsWrapper>
          <Container className={classNames('portal-hide-scrollbars')}>
            {props.TransactionsList.length === 0 && props.errorTransactionsList === false ? (
              <ErrorLabel>No Hay movimientos</ErrorLabel> 
            ) : ( <></>)}
            {props.loadingTransactionsList === true && props.errorTransactionsList === false ? (
              <Spinner />
            ) : ( <></>)}
            {props.errorTransactionsList ? (
              <ErrorLabel>{props.errorTransactionsList}</ErrorLabel> 
              ) : ( 
              props.movements.map((movement, i) => (
                <MovementContainer
                  key={i}
                  //onClick={() => props.onMovementClick(movement)} //callback
                  selected={isSelected(movement, props.selected)}
                >
                  {movement.amount > 0 ? (
                    <Avatar
                      style={{
                        width: '25px',
                        height: '25px',
                        padding: '10px',
                        background: '#119748'
                      }}
                    >
                      {movement.picture_url ? (
                        <img src={movement.picture_url} style={{width: "250%"}} />
                      ) : (
                        movement.description.charAt(0).toUpperCase()
                      )}                                          
                    </Avatar>
                  ) : (
                    <Avatar
                      style={{
                        width: '25px',
                        height: '24px',
                        padding: '10px',
                        background: '#ff565a',
                      }}
                    >
                      {movement.picture_url ? (
                        <img src={movement.picture_url} style={{width: "250%"}} />
                      ) : (
                        movement.description.charAt(0).toUpperCase()
                      )}  
                    </Avatar>
                  )}
                  <Grid style={{marginLeft: '10px'}} xs={10}>
                    <Row justify={'space-between'}>
                      <Row>
                        <Date selected={isSelected(movement, props.selected)} >{getFormattedDate(movement.date)}</Date>
                      </Row>
                      {
                        movement.amount > 0 ? 
                        (<Charge >{formatter.format(movement.amount)}</Charge>) 
                        : 
                        (<Pay>{formatter.format(movement.amount)}</Pay> )
                      }
                    </Row>
                    <Title>{movement.description}</Title> 
                  </Grid>
                </MovementContainer>
              ))
            )}
          </Container>
        </InfiniteScroll>
      )
    }
  }
}

const mapStateToProps  = state => {
  return {
    NumberOfTransferRequest: state.billetera.NumberOfTransferRequest,
    TransactionsList: state.billetera.TransactionsList,
    errorTransactionsList: state.billetera.errorTransactionsList,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MovementsList)
