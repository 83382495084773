
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import Page from 'components/Page'
import Section from 'components/Section'
import ResourceList from 'components/ResourceList'
import SearchBar from 'components/SearchBar'
import DestinatarioDetail from './components/DestinatarioDetail'
import NewDestinatario from './components/NewDestinatario'
import mapGuests from 'selectors/mapGuests'
import mapFilteredGuests from 'selectors/mapFilteredGuests'
import classNames from 'classnames'
import EmptyWrapper from 'components/PageEmpty'
import EmptyLoader from 'components/PageLoader'
import {SearchWrapper, ContentWrapper, ListWrapper, PageWrapper, Wrapper} from './styled'


class Invitados extends React.Component {
    state = {
      filter: '',
      NewDestinatario: false,

    }
  
    componentDidMount = () => {
      this.props.fetchInvitaciones()
    }
  
    renderPageContent = () => (
        
      <SearchWrapper>
        <SearchBar
          placeholder={'Ingresa el Nombre, alias, categoría o subcategoría'}
          value={this.state.filter}
          onChange={this.handleInputOnChange}
          onClear={this.handleInputClear}
          onKeyDown={this.handleKeyPress}
          borderBottom
        />
        <ContentWrapper>
          <ListWrapper id={'scrollContainer'} className={classNames('portal-hide-scrollbars')}>
            {this.isContentAvaiable() ? (
              <ResourceList
                elements={this.isFilterEnabled() ? this.props.filteredGuests : this.props.guests}
                onClick={this.handleGuestClick}
                selectedPos={this.props.selectedGuest ? this.props.selectedGuest.pos : null}
                fetchMoreContent={() => this.props.fetchInvitaciones()}
                noMoreContentMsg={'No hay más destinatarios'}
                hasMore={this.isFilterEnabled() ? this.props.loading : this.props.hasMore }
              />
            ) : (
              <EmptyWrapper message={'No hay destinatarios disponibles'} />
            )}
          </ListWrapper>
            <DestinatarioDetail guest={this.props.selectedGuest} />
        </ContentWrapper>
      </SearchWrapper>
    )
  
    isFilterEnabled = () => this.state.filterEnabled
  
    handleInputOnChange = value => this.setState({ filter: value })
  
    handleInputClear = value => {
      this.props.setSelectedGuest(null)
      this.setState({ filter: value, filterEnabled: false })
    }
  
    handleKeyPress = e => {
      if (e.keyCode === 13 && e.target.value !== '') {
        this.props.setSelectedGuest(null)
        this.props.fetchFilteredGuests(e.target.value)
        this.setState({ filterEnabled: true })
      }
    }
  
    handleGuestClick = guest => this.props.setSelectedGuest(guest)
  
    isContentAvaiable = () => this.props.guests.length > 0 || this.props.filteredGuests.length > 0
  
    isLoadingContent = () => this.props.loading && this.props.guests.length === 0
  
    toggleNewDestinatario = () => this.setState({ NewDestinatario: !this.state.NewDestinatario })
    
    render() {
      return (
        <Page>
          <PageWrapper> 
            <Section
              title={'Agenda de destinatarios'}
              onBack={() => {this.props.onClose()}} 
              btnContent={'Añadir Destinatario'}
              onBtnClick={this.toggleNewDestinatario} 
            >
              <Wrapper>
                {this.state.NewDestinatario && <NewDestinatario onClose={this.toggleNewDestinatario} />}
                {this.isLoadingContent() ? <EmptyLoader /> : this.renderPageContent()}
              </Wrapper>
            </Section>
          </PageWrapper>
        </Page>
      )
    }
  }
  
  const mapStateToProps = ({
    invitados: { invitadosList, filteredGuests, loading, hasMore },
    selectedGuest
  }) => ({
    guests: mapGuests(invitadosList),
    filteredGuests: mapFilteredGuests(filteredGuests),
    loading,
    hasMore,
    selectedGuest
  })
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators({  }, dispatch)
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    compose(
    )(Invitados)
  )