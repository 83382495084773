import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Page from 'components/Page'
import Section from 'components/Section'
import themeStyles from './personas.theme.style'
import compose from 'recompose/compose'
import withWidth from '@material-ui/core/withWidth'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CreateUser from './components/CreateUser'
import ProprietaryDetail from './components/ProprietaryDetail/index'
import { bindActionCreators } from 'redux'
import { fetchUsers,fetchEditUserReset } from 'store/actions/users.actions'
import PageLoader from 'components/PageLoader'
import mapAdminUsers from 'selectors/mapAdminUsers'
import ResourceList from 'components/ResourceList'
import { setSelectedResource, resetSelectedResource } from 'store/actions/selectedResource'
import EmptyWrapper from 'components/PageEmpty'
import EditUser from "./components/EditUser"
import SearchBar from 'components/SearchBar'
import mapRoles from "selectors/mapRoles"
import { SearchWrapper, ListWrapper, PageWrapper, Wrapper, ContentWrapper } from "./styled.js"
import ModalMessage from 'components/ModalMessage'
import RemoveUser from './components/RemoveUser'

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewUserOpen: false,
      mensajeErrorNewUser: false,
      isNewUserEditOpen: false,
      isUserDetailOpen: false,
      itemInDetail: '',
      uploadFile: false,
      filter: '',
      modalUserRemove: false,
      succesModal: false,
      failModal: false,
      modalContent: null
    }
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.props.fetchUsers()
    this.props.resetSelectedResource()
  }

  toggleUserDetail = () => {
    this.setState({ isUserDetailOpen: !this.state.isUserDetailOpen })
  }

  toggleNewUser = (reload) => {
    if (reload) {
      this.props.fetchUsers(true)
      this.props.resetSelectedResource()
    }
    this.setState({ isNewUserOpen: !this.state.isNewUserOpen })
  }

  onOpenNewUserEdit = (reload) => {
    this.props.fetchUsers()
    this.setState({ isNewUserEditOpen: !this.state.isNewUserEditOpen })
  }

  onOpenRemoveUserModal = (reload) => {
    if (reload) {
      this.props.fetchUsers(true)
    }
    this.setState({ modalUserRemove: !this.state.modalUserRemove })
  }

  isLoadingContent = () => this.props.loading && this.props.users.length === 0

  createMainView = () => (
    <Section
      title={'Usuarios y Roles'}
      btnContent={'Añadir Usuario'}
      onBtnClick={this.toggleNewUser}
    >
      <Wrapper>
        {this.isLoadingContent() ? <PageLoader /> : this.renderPageContent()}
      </Wrapper>
    </Section>
  )

  isContentAvaiable = () => !this.props.loading

  renderPageContent = () =>
    this.isContentAvaiable() ? (
      <>
        <SearchWrapper>
          <SearchBar
            placeholder={'Buscá usuario'}
            value={this.state.filter}
            onChange={this.handleInputOnChange}
            onClear={this.handleInputClear}
            onKeyUp={(e) => this.handleKeyPress(e)}
            borderBottom
          />
        </SearchWrapper>
        <ContentWrapper>
          <ListWrapper id={'scrollContainer'} className={classNames('portal-hide-scrollbars')}>
            <ResourceList
              elements={this.props.mappedAdminUsers}
              onClick={this.handleResourceClick}
              selectedPos={this.props.selectedResource ? this.props.selectedResource.pos : null}
              noMoreContentMsg={'No hay usuarios'}
              hasMore={this.props.hasMore}
              resourcePeople={true}
            />
          </ListWrapper>
          <ProprietaryDetail
            onHandleClose={this.onCloseNewUserEdit}
            item={this.props.selectedResource}
            onEdit={this.onOpenNewUserEdit}
            onRemove={this.onOpenRemoveUserModal}
          />
        </ContentWrapper>
      </>
    ) : (
        <EmptyWrapper message={'No hay usuarios disponibles'} />
      )

  handleInputOnChange = value => this.setState({ filter: value })

  handleInputClear = value => {
    this.setState({ filter: value, filterEnabled: false }, () => this.props.fetchUsers())
  }

  handleKeyPress(e) {
    if (e.keyCode === 13) {
      this.props.fetchUsers(e.target.value)
    }
  }

  handleResourceClick = resource => {
    console.log('recurso seleccionado', resource)
    this.props.setSelectedResource(resource)
  }

  closeAllModals = () => {
    this.setState({
      failModal: false,
      modalContent: "",
      succesModal: false,
    })
  }

  toggleSuccessModal = (modalContent) => {
    this.setState({
      succesModal: !this.state.succesModal,
      modalContent,
      failModal: false,
    })
  }

  isLoadingContent = () => this.props.loading

  render() {
    return (
      <Page className={classNames('portal-hide-scrollbars')}>
        <PageWrapper>
          {this.createMainView()}
          {this.state.isNewUserOpen == true &&
            (<CreateUser onClose={this.toggleNewUser} fetchUsers={this.props.fetchUsers} />)
            || this.state.isNewUserEditOpen == true &&
            (<EditUser onClose={this.onOpenNewUserEdit} roles={this.props.roles} userToEdit={this.props.selectedResource} fetchUsers={this.props.fetchUsers} />)
          }
          {this.state.modalUserRemove === true ?
            <RemoveUser onClose={this.onOpenRemoveUserModal} userToRemove={this.props.selectedResource} fetchUsers={this.props.fetchUsers} />
            :
            <></>
          }
        </PageWrapper>
        {this.state.succesModal || this.state.failModal ?
          <ModalMessage
            success={this.state.succesModal ? true : false}
            onClose={this.closeAllModals}
            toggleFailModal={this.toggleFailModal}
            toggleSuccessModal={this.toggleSuccessModal}
          >{this.state.modalContent}</ModalMessage>
          :
          <></>
        }
        
        {this.props.fetchEditUserSuccess || this.props.fetchEditUserFail  ?
            <ModalMessage success={this.props.fetchEditUserSuccess} fail onClose={() => this.props.fetchEditUserReset()} >
                {
                    this.props.fetchEditUserFail ? <h3>Hubo un error, al editar.</h3> : <> Usuario editado con exito !</>
                }
            </ModalMessage>
            :
            <></>
        }
      </Page>
    )
  }
}

const mapStateToProps = ({ profile, selectedResource, users }) => {
  return {
    mappedAdminUsers: mapAdminUsers(users.proprietaryList),
    itemSearched: users.proprietaryList,
    loading: users.loading,
    roles: mapRoles(profile.roles),
    hasMore: users.hasMore,
    selectedResource,
    fetchEditUserSuccess: users.fetchEditUserSuccess,
    fetchNewUserFail: users.fetchNewUserFail,
    fetchNewUserSuccess: users.fetchNewUserSuccess,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUsers,
      fetchEditUserReset,
      setSelectedResource,
      resetSelectedResource
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth(),
    withStyles(themeStyles, { withTheme: true })
  )(Users)
)