import * as actionTypes from './actionTypes'
import {
  activitiesAPI,
  getSpacesAPI,
  postNewSpaceAPI,
  postNewExceptionsAPI,
  getBookingRequestAPI,
  deleteReservationAPI,
  generateBookingAPI,
  uploadTyCAPI,
  deleteTyCAPI
} from 'apiConstants'
import getApi from 'shared/api'


//-----------------GenerateBooking-----------------------------------------------

export const generateBookingSuccess = generatedBooking => {
  return {
    type: actionTypes.GENERATE_BOOKING_SUCCESS,
    registeredBooking: generatedBooking,
  }
}

export const generateBookingFail = error => {
  return {
    type: actionTypes.GENERATE_BOOKING_FAIL,
    error: error
  }
}

export const generateBookingStart = () => {
  return {
    type: actionTypes.GENERATE_BOOKING_START
  }
}

export const generateBooking = bookingData => {
  return async (dispatch, getState) => {
    dispatch(generateBookingStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    return api
      .post(generateBookingAPI, bookingData)
      .then(res => {
        dispatch(generateBookingSuccess(res.data))
      })
      .catch(err => {
        dispatch(generateBookingFail(err))
      })
  }
}

// Delete Reservas 

export const deleteReservationSuccess = () => {
  return { type: actionTypes.FETCH_DELETE_RESERVA_SUCCESS }
}

export const deleteReservationFail = error => {
  return { type: actionTypes.FETCH_DELETE_RESERVA_FAIL, error: error }
}

export const deleteReservationStart = () => {
  return { type: actionTypes.FETCH_DELETE_RESERVA_START }
}

export const deleteReservationById = id => {
  return async (dispatch, getState) => {
    dispatch(deleteReservationStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    return api
      .delete(deleteReservationAPI + `${id}`)
      .then(res => {
        dispatch(deleteReservationSuccess(res.data))
      })
      .catch(err => {
        dispatch(deleteReservationFail(err))
      })
  }
}


export const fetchActividadesSuccess = fetchedActivities => {
  return {
    type: actionTypes.FETCH_ACTIVIDADES_SUCCESS,
    actividades: fetchedActivities
  }
}

export const fetchActividadesFail = error => {
  return {
    type: actionTypes.FETCH_ACTIVIDADES_FAIL,
    error: error
  }
}

export const fetchActividadesStart = () => {
  return {
    type: actionTypes.FETCH_ACTIVIDADES_START
  }
}

export const fetchActividades = () => {
  return async (dispatch, getState) => {
    dispatch(fetchActividadesStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    try {
      const response = await api.get(activitiesAPI)

      dispatch(fetchActividadesSuccess(response.data))
    } catch (error) {
      dispatch(fetchActividadesFail(error))
    }
  }
}

//----------------------------------------------------------------

export const createActivitySuccess = newActivity => {
  return {
    type: actionTypes.CREATE_ACTIVIDAD_SUCCESS,
    newActivity: newActivity
  }
}

export const createActivityFail = error => {
  return {
    type: actionTypes.CREATE_ACTIVIDAD_FAIL,
    error: error
  }
}

export const createActivityStart = () => {
  return {
    type: actionTypes.CREATE_ACTIVIDAD_START
  }
}

export const createActivity = newActividad => {
  return async (dispatch, getState) => {
    dispatch(createActivityStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    return api
      .post(activitiesAPI, newActividad)
      .then(res => {
        dispatch(createActivitySuccess(res.data))
      })
      .catch(err => {
        dispatch(createActivityFail(err))
      })
  }
}
//-----------------------------------------------------------------------

export const fetchSpacesSuccess = fetchedSpaces => {
  return {
    type: actionTypes.FETCH_SPACES_SUCCESS,
    spacesList: fetchedSpaces
  }
}

export const fetchSpacesFail = error => {
  return {
    type: actionTypes.FETCH_SPACES_FAIL,
    error: error
  }
}

export const fetchSpacesStart = () => {
  return {
    type: actionTypes.FETCH_SPACES_START
  }
}

export const fetchSpaces = id => {
  return async dispatch => {
    const api = await getApi()
    dispatch(fetchSpacesStart())
    api
      .get(`${getSpacesAPI}/${id}`)
      .then(res => {
        dispatch(fetchSpacesSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchSpacesFail(err))
      })
  }
}

//-----------------------------------------------------------------

export const createSpaceSuccess = () => {
  return {
    type: actionTypes.CREATE_SPACE_SUCCESS
  }
}

export const createSpaceFail = error => {
  return {
    type: actionTypes.CREATE_SPACE_FAIL,
    error: error
  }
}

export const createSpaceStart = () => {
  return {
    type: actionTypes.CREATE_SPACE_START
  }
}

export const createSpace = newSpace => {
  return async (dispatch, getState) => {
    dispatch(createSpaceStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    return api
      .post(postNewSpaceAPI, newSpace)
      .then(() => {
        dispatch(createSpaceSuccess())
      })
      .catch(err => {
        dispatch(createSpaceFail(err))
      })
  }
}

//-----------------------------------------------------------------

export const createExceptionsSuccess = () => {
  return {
    type: actionTypes.CREATE_EXCEPTIONS_SUCCESS
  }
}

export const createExceptionsFail = error => {
  return {
    type: actionTypes.CREATE_EXCEPTIONS_FAIL,
    error: error
  }
}

export const createExceptionsStart = () => {
  return {
    type: actionTypes.CREATE_EXCEPTIONS_START
  }
}

export const createExceptions = newExceptionData => {
  return async (dispatch, getState) => {
    dispatch(createExceptionsStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    return api
      .post(postNewExceptionsAPI, newExceptionData)
      .then(() => {
        dispatch(createExceptionsSuccess())
      })
      .catch(err => {
        dispatch(createExceptionsFail(err))
      })
  }
}

//------------------------------------------------------------------------
export const fetchBookingsRequestSuccess = fetchedBookings => {
  return {
    type: actionTypes.FETCH_BOOKING_REQUESTS_SUCCESS,
    fetchedBookings: fetchedBookings
  }
}

export const fetchBookingsRequestFail = error => {
  return {
    type: actionTypes.FETCH_BOOKING_REQUESTS_FAIL,
    error: error
  }
}

export const fetchBookingsRequestStart = () => {
  return {
    type: actionTypes.FETCH_BOOKING_REQUESTS_START
  }
}

export const fetchBookingsRequest = reset => {
  return async (dispatch, getState) => {
    dispatch(fetchBookingsRequestStart())
    let {
      reservas,
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    let bookingsTake = reset ? 10 : reservas.bookingsTake
    const page = reservas.page

    try {
      const response = await api.get(`${getBookingRequestAPI}/${page}/${bookingsTake}`)
      let hasMoreUpdated = response.data.length === bookingsTake
      let takeUpdated = hasMoreUpdated ? bookingsTake + 10 : bookingsTake

      dispatch(
        fetchBookingsRequestSuccess({
          values: response.data,
          bookingsTake: takeUpdated,
          hasMoreBookings: hasMoreUpdated
        })
      )
    } catch (error) {
      dispatch(fetchBookingsRequestFail(error))
    }
  }
}

//------------------UPLOAD TyC-----------------------
export const uploadTyCSuccess = data => {
  return {
    type: actionTypes.UPLOAD_TYC_SUCCESS,
    data: data
  }
}

export const uploadTyCFail = error => {
  return {
    type: actionTypes.UPLOAD_TYC_FAIL,
    error: error
  }
}

export const uploadTyCStart = () => {
  return {
    type: actionTypes.UPLOAD_TYC_START
  }
}

export const uploadTyC = (formattedData) => {
  return async (dispatch, getState) => {
    dispatch(uploadTyCStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    const body = {
      ActivityId: formattedData.ActivityId,
      FilePair: {
      ImageBase64 : formattedData.FileString,
      Extension : formattedData.Extension
      }
    }
    try {
      const response = await api.post(uploadTyCAPI, body)

      dispatch(
        uploadTyCSuccess(response.data)
      )
    } catch (error) {
      dispatch(uploadTyCFail(error.response.data.message))
    }
  }
}


//------------------DELETE TyC-----------------------
export const deleteTyCSuccess = data => {
  return {
    type: actionTypes.DELETE_TYC_SUCCESS,
    data: data
  }
}

export const deleteTyCFail = error => {
  return {
    type: actionTypes.DELETE_TYC_FAIL,
    error: error
  }
}

export const deleteTyCStart = () => {
  return {
    type: actionTypes.DELETE_TYC_START
  }
}

export const deleteTyC = (activityId) => {
  return async (dispatch, getState) => {
    dispatch(deleteTyCStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    const body = {
      ActivityId: activityId
      }
    try {
      const response = await api.post(deleteTyCAPI, body)

      dispatch(

        deleteTyCSuccess(response.data)
      )
    } catch (error) {
      dispatch(deleteTyCFail(error.response.data.message))
    }
  }
}
