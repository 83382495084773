import React from 'react'
import LeftArrow from 'assets/icons/arrow-left.png'
import {TyTitle, PaperStyled, ContainerProgressBar, GridButtonBack,StyledImg, ButtonBack} from './styled'
import ProgressBar from 'components/ProgressBar'

class PaperLayout extends React.Component {
    
    state = {
        isIncomplete: false,
        onHandleClose: this.props.onClose,
    }
    
    
  onHandleClose = () => {
      this.props.onClose()
  }
    
    render() {
        
        if (this.props.created) {
            this.onHandleClose()
        }

      return (
        <div>
            <GridButtonBack item >
                <ButtonBack onClick={this.onHandleClose} variant="fab">
                  <StyledImg src={LeftArrow} />
                </ButtonBack>
                <TyTitle >{this.props.title}</TyTitle>
            </GridButtonBack>
            <PaperStyled >
                <ContainerProgressBar>
                  <ProgressBar Step={this.props.Step} />
                </ContainerProgressBar>

                {this.props.children}
                
            </PaperStyled>
        </div>
      )
    }  
  }
  
  
  export default PaperLayout;
  
  