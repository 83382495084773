import React from 'react'
import AnimationWrapper from 'components/AnimationWrapper'
import Page from 'components/Page'
import { Container } from './styled'

class Modal extends React.Component {
  render() {
    const { children, withOutPage, width, height, top, left,display } = this.props
    return (
      <Container 
      width={width ? width : '100%'}
      height={height ? height : '100%'}
      top={top ? top : '0%'}
      left={left ? left : '0%'}
      display={display ? display : 'flex'}
      >
        {!withOutPage && (
          <Page overflow={'auto'} narrow  width={'100%'}>
            <AnimationWrapper>{children}</AnimationWrapper>
          </Page>
        )}
        {withOutPage && children}
      </Container>
    )
  }
}

export default Modal
