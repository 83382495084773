import * as actionTypes from '../actions/actionTypes'
import { updateObject } from 'shared/utility'

const initialState = {
  users: [],
  loadingFundacionUsers: false,
  errorFundacionUsers: null,

  movies: [],
  loadingMovies: false,
  errorFetchMovies: null,
  fetchMoviesSuccess: false,

  loadingCreateNewMovie: false,
  createNewMovieStart: false,
  createNewMovieSuccess: false,
  createNewMovieFail: false,
  uploadMovieProgress: 0,
  newMovie: [],

  loadingEditMovie: false,
  editMovieStart: false,
  editMovieSuccess: false,
  editMovieFail: false,

  loadingDetailMovie: false,
  fetchDetailMovieStart: false,
  fetchDetailMovieSuccess: false,
  fetchDetailMovieFail: false,
  errorFetchDetailMovie:null,
  detailMovie: null,

  loadingDeleteMovie: false,
  fetchDeleteMovieStart: false,
  fetchDeleteMovieSuccess: false,
  fetchDeleteMovieFail: false,

  stories: [],
  loadingStories: false,
  errorFetchStories: null,
  fetchStoriesSuccess: false,

  loadingDetailStory: false,
  fetchDetailStoryStart: false,
  fetchDetailStorySuccess: false,
  fetchDetailStoryFail: false,
  detailStory: null,

  loadingDeleteStory: false,
  fetchDeleteStoryStart: false,
  fetchDeleteStorySuccess: false,
  fetchDeleteStoryFail: false,


  loadingCreateNewStory: false,
  createNewStorySuccess: false,
  createNewStoryFail: false,
  uploadStoryProgress: 0,
  newStory: [],

  loadingEditStory: false,
  editStorySuccess: false,
  editStoryFail: false,
  newStory: [],

  donaciones: [],
  loadingDonaciones: false,
  errorDonaciones: null,

  news: [],
  loadingNews: false,
  errorFetchNews: null,
  fetchNewsSuccess: false,

  loadingDetailNews: false,
  fetchDetailNewsStart: false,
  fetchDetailNewsSuccess: false,
  fetchDetailNewsFail: false,
  detailNews: null,

  loadingDeleteNews: false,
  fetchDeleteNewsStart: false,
  fetchDeleteNewsSuccess: false,
  fetchDeleteNewsFail: false,


  loadingCreateNewNews: false,
  createNewNewsSuccess: false,
  createNewNewsFail: false,
  newNews: [],

  loadingEditNews: false,
  editNewsSuccess: false,
  editNewsFail: false,
  newNews: [],
}

//////////////////USERS//////////////////
const fetchFundacionUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users,
    loadingFundacionUsers: false
  })
}

const fetchFundacionUsersFail = (state, action) => {
  return updateObject(state, {
    loadingFundacionUsers: false,
    errorFundacionUsers: action.errorFundacionUsers
  })
}

const fetchFundacionUsersStart = (state) => {
  return updateObject(state, {
    loadingFundacionUsers: true,
    errorFundacionUsers: null
  })
}
///////////////////MOVIES//////////////////////////
const fetchMoviesSuccess = (state, action) => {
  return updateObject(state, {
    movies: action.data,
    fetchMoviesSuccess: true,
    loadingMovies: false
  })
}

const fetchMoviesFail = (state, action) => {
  return updateObject(state, {
    loadingMovies: false,
    errorFetchMovies: action.error
  })
}

const fetchMoviesStart = (state) => {
  return updateObject(state, {
    loadingMovies: true,
    fetchMoviesSuccess: false,
  })
}

//////////////// ADD MOVIE////////////////
const createNewMovieStart = state => {
  return updateObject(state, {
    loadingCreateNewMovie: true,
    createNewMovieSuccess: false,
    createNewMovieFail: false,
    newMovie: [],
    uploadMovieProgress: 0
    // detailProduct: [],
  })
}

const createNewMovieSuccess = (state, action) =>
  updateObject(state, {
    loadingCreateNewMovie: false,
    createNewMovieSuccess: true,
    createNewMovieFail: false,
    newMovie: action.movie
  })


const createNewMovieReset = (state, action) =>
  updateObject(state, {
    loadingCreateNewMovie: false,
    createNewMovieSuccess: false,
    createNewMovieFail: false,
    uploadMovieProgress: 0
  })
  

const createNewMovieFail = (state, action) => {
  return updateObject(state, {
    loadingCreateNewMovie: false,
    createNewMovieSuccess: false,
    createNewMovieFail: true,
    error: action.error
  })
}

const createNewMovieUpdateProgress = (state, action) => {
  return updateObject(state, {
    uploadMovieProgress: action.progress
  })
}


//////////////////EDIT MOVIE//////////////////////////////

const editMovieStart = state => {
  return updateObject(state, {
    loadingEditMovie: true,
    editMovieSuccess: false,
    editMovieFail: false,
    uploadMovieProgress: 0
  })
}

const editMovieSuccess = (state, action) =>
  updateObject(state, {
    loadingEditMovie: false, 
    editMovieSuccess: true,
    editMovieFail: false
  })

  const editMovieReset = state => {
    return updateObject(state, {
      loadingEditMovie: false,
      editMovieSuccess: false,
      editMovieFail: false,
      uploadMovieProgress: 0
    })
  }

const editMovieFail = (state, action) => {
  return updateObject(state, {
    loadingEditMovie: false,
    EditMovieSuccess: false,
    EditMovieFail: true,
    errorEditMovie: action.error
  })
}

const editMovieUpdateProgress = (state, action) => {
  return updateObject(state, {
    uploadMovieProgress: action.progress
  })
}



/////////////////DETAIL MOVIE///////////////////


const fetchDetailMovieStart = state => {
  return updateObject(state, {
    loadingDetailMovie: true,
    fetchDetailMovieSuccess: false,
    fetchDetailMovieFail: false
  })
}

const fetchDetailMovieSuccess = (state, action) =>
  updateObject(state, {
    loadingDetailMovie: false,
    fetchDetailMovieSuccess: true,
    fetchDetailMovieFail: false,
    detailMovie: action.detailMovie[0]
  })

const fetchDetailMovieFail = (state, action) => {
  return updateObject(state, {
    loadingDetailMovie: false,
    fetchDetailMovieSuccess: false,
    fetchDetailMovieFail: true,
    errorFetchDetailMovie:action.error
  })
}

const fetchDetailMovieReset = state => {
  return updateObject(state, {
    loadingDetailMovie: false,
    fetchDetailMovieSuccess: false,
    fetchDetailMovieFail: false
  })
}
//////////////////DELETE MOVIE//////////////////


const fetchDeleteMovieStart = state => {
  return updateObject(state, {
    loadingDeleteMovie: true,
    fetchDeleteMovieSuccess: false,
    fetchDeleteMovieFail: false,
    deletedMovie: [],
  })
}

const fetchDeleteMovieSuccess = (state, action) =>
  updateObject(state, {
    loadingDeleteMovie: false,
    fetchDeleteMovieSuccess: true,
    fetchDeleteMovieFail: false,
    deletedMovie: action.deletedMovie
  })

const fetchDeleteMovieFail = (state, action) => {
  return updateObject(state, {
    loadingDeleteMovie: false,
    fetchDeleteMovieSuccess: false,
    fetchDeleteMovieFail: action.error
  })
}


const fetchDeleteMovieReset = (state, action) =>
  updateObject(state, {
    loadingDeteleMovie: false,
    fetchDeleteMovieSuccess: false,
    fetchDeleteMovieFail: false,
  })

///////////////////STORIES/////////////////////////
const fetchStoriesSuccess = (state, action) => {
  return updateObject(state, {
    stories: action.data,
    loadingStories: false,
    fetchStoriesSuccess: true
  })
}

const fetchStoriesFail = (state, action) => {
  return updateObject(state, {
    loadingStories: false,
    errorFetchStories: action.error
  })
}

const fetchStoriesStart = (state) => {
  return updateObject(state, {
    loadingStories: true,
    fetchStoriesSuccess: false
  })
}

/////////////////DETAIL STORY///////////////////

const fetchDetailStoryStart = state => {
  return updateObject(state, {
    loadingDetailStory: true,
    fetchDetailStorySuccess: false,
    fetchDetailStoryFail: false
  })
}

const fetchDetailStorySuccess = (state, action) =>
  updateObject(state, {
    loadingDetailStory: false,
    fetchDetailStorySuccess: true,
    fetchDetailStoryFail: false,
    detailStory: action.data[0]
  })

const fetchDetailStoryFail = (state, action) => {
  return updateObject(state, {
    loadingDetailStory: false,
    fetchDetailStorySuccess: false,
    fetchDetailStoryFail: action.error
  })
}

const fetchDetailStoryReset = state => {
  return updateObject(state, {
    loadingDetailStory: false,
    fetchDetailStorySuccess: false,
    fetchDetailStoryFail: false
  })
}


//////////////////DELETE STORY//////////////////
const fetchDeleteStoryStart = state => {
  return updateObject(state, {
    loadingDeleteStory: true,
    fetchDeleteStorySuccess: false,
    fetchDeleteStoryFail: false,
    deletedStory: [],
    // detailMovie: [],
  })
}

const fetchDeleteStorySuccess = (state, action) =>
  updateObject(state, {
    loadingDeleteStory: false,
    fetchDeleteStorySuccess: true,
    fetchDeleteStoryFail: false,
    deletedStory: action.deletedStory
  })

const fetchDeleteStoryFail = (state, action) => {
  return updateObject(state, {
    loadingDeleteStory: false,
    fetchDeleteStorySuccess: false,
    fetchDeleteStoryFail: action.error
  })
}


const fetchDeleteStoryReset = (state, action) =>
  updateObject(state, {
    loadingDeteleStory: false,
    fetchDeleteStorySuccess: false,
    fetchDeleteStoryFail: false,
  })

/////////////CREATE STORY///////////////////
const createNewStoryStart = state => {
  let data = {
    loadingCreateNewStory: true,
    createNewStorySuccess: false,
    createNewStoryFail: false,
    uploadStoryProgress: 0
  };
  return updateObject(state, data)
}

const createNewStorySuccess = state => {
  let data = {
    loadingCreateNewStory: false,
    createNewStorySuccess: true,
    createNewStoryFail: false
  };
  return updateObject(state, data)
}

const createNewStoryFail = (state, action) => {
  return updateObject(state, {
    loadingCreateNewStory: false,
    createNewStorySuccess: false,
    createNewStoryFail: true,
    error: action.error
  })
}

const createNewStoryReset = state => {
  return updateObject(state, {
    loadingCreateNewStory: false,
    createNewStorySuccess: false,
    createNewStoryFail: false,
    uploadStoryProgress: 0
  })
}

const createNewStoryUpdateProgress = (state, action) => {
  return updateObject(state, {
    uploadStoryProgress: action.progress
  })
}

/////////////EDIT STORY///////////////////
const editStoryStart = state => {
  let data = {
    loadingEditStory: true,
    editStorySuccess: false,
    editStoryFail: false,
    uploadStoryProgress: 0
  };
  return updateObject(state, data)
}

const editStorySuccess = (state, action) =>
  updateObject(state, {
    loadingEditStory: false,
    editStorySuccess: true,
    editStoryFail: false
  })

const editStoryFail = (state, action) => {
  return updateObject(state, {
    loadingEditStory: false,
    editStorySuccess: false,
    editStoryFail: true,
    error: action.error
  })
}

const editStoryReset = state => {
  return updateObject(state, {
    loadingEditStory: false,
    editStorySuccess: false,
    editStoryFail: false,
    uploadStoryProgress: 0
  })
}


const editStoryUpdateProgress = (state, action) => {
  return updateObject(state, {
    uploadStoryProgress: action.progress
  })
}
////////////////////////////////////////////////////
///////////////////////////////////////////////////

////////////////// DONACIONES //////////////////
const fetchDonacionesSuccess = (state, action) => {
  return updateObject(state, {
    donaciones: action.donaciones,
    loadingDonaciones: false
  })
}

const fetchDonacionesFail = (state, action) => {
  return updateObject(state, {
    loadingDonaciones: false,
    errorDonaciones: action.errorDonaciones
  })
}

const fetchDonacionesStart = (state) => {
  return updateObject(state, {
    loadingDonaciones: true,
    errorDonaciones: null
  })
}

///////////////////NEWS/////////////////////////
const fetchNewsSuccess = (state, action) => {
  return updateObject(state, {
    news: action.data,
    loadingNews: false,
    fetchNewsSuccess: true
  })
}

const fetchNewsFail = (state, action) => {
  return updateObject(state, {
    loadingNews: false,
    errorFetchNews: action.error
  })
}

const fetchNewsStart = (state) => {
  return updateObject(state, {
    loadingNews: true,
    fetchNewsSuccess: false
  })
}

/////////////////DETAIL NEWS///////////////////

const fetchDetailNewsStart = state => {
  return updateObject(state, {
    loadingDetailNews: true,
    fetchDetailNewsSuccess: false,
    fetchDetailNewsFail: false
  })
}

const fetchDetailNewsSuccess = (state, action) =>{
console.log('fetchDetailNewsSuccess', action.data[0]);
  return updateObject(state, {
    loadingDetailNews: false,
    fetchDetailNewsSuccess: true,
    fetchDetailNewsFail: false,
    detailNews: action.data[0]
  })}

const fetchDetailNewsFail = (state, action) => {
  return updateObject(state, {
    loadingDetailNews: false,
    fetchDetailNewsSuccess: false,
    fetchDetailNewsFail: action.error
  })
}

const fetchDetailNewsReset = state => {
  return updateObject(state, {
    loadingDetailNews: false,
    fetchDetailNewsSuccess: false,
    fetchDetailNewsFail: false
  })
}


//////////////////DELETE NEWS//////////////////
const fetchDeleteNewsStart = state => {
  return updateObject(state, {
    loadingDeleteNews: true,
    fetchDeleteNewsSuccess: false,
    fetchDeleteNewsFail: false,
    deletedNews: [],
    // detailMovie: [],
  })
}

const fetchDeleteNewsSuccess = (state, action) =>
  updateObject(state, {
    loadingDeleteNews: false,
    fetchDeleteNewsSuccess: true,
    fetchDeleteNewsFail: false,
    deletedNews: action.deletedNews
  })

const fetchDeleteNewsFail = (state, action) => {
  return updateObject(state, {
    loadingDeleteNews: false,
    fetchDeleteNewsSuccess: false,
    fetchDeleteNewsFail: action.error
  })
}


const fetchDeleteNewsReset = (state, action) =>
  updateObject(state, {
    loadingDeteleNews: false,
    fetchDeleteNewsSuccess: false,
    fetchDeleteNewsFail: false,
  })

/////////////CREATE NEWS///////////////////
const createNewNewsStart = state => {
  let data = {
    loadingCreateNewNews: true,
    createNewNewsSuccess: false,
    createNewNewsFail: false
  };
  return updateObject(state, data)
}

const createNewNewsSuccess = state => {
  let data = {
    loadingCreateNewNews: false,
    createNewNewsSuccess: true,
    createNewNewsFail: false
  };
  return updateObject(state, data)
}

const createNewNewsFail = (state, action) => {
  return updateObject(state, {
    loadingCreateNewNews: false,
    createNewNewsSuccess: false,
    createNewNewsFail: true,
    error: action.error
  })
}

const createNewNewsReset = state => {
  return updateObject(state, {
    loadingCreateNewNews: false,
    createNewNewsSuccess: false,
    createNewNewsFail: false,
  })
}

/////////////EDIT NEWS///////////////////
const editNewsStart = state => {
  let data = {
    loadingEditNews: true,
    editNewsSuccess: false,
    editNewsFail: false
  };
  return updateObject(state, data)
}

const editNewsSuccess = (state, action) =>
  updateObject(state, {
    loadingEditNews: false,
    editNewsSuccess: true,
    editNewsFail: false
  })

const editNewsFail = (state, action) => {
  return updateObject(state, {
    loadingEditNews: false,
    editNewsSuccess: false,
    editNewsFail: true,
    error: action.error
  })
}

const editNewsReset = state => {
  return updateObject(state, {
    loadingEditNews: false,
    editNewsSuccess: false,
    editNewsFail: false,
  })
}
////////////////////////////////////////////////////

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FUNDACION_USERS_SUCCESS:
      return fetchFundacionUsersSuccess(state, action)
    case actionTypes.FETCH_FUNDACION_USERS_FAIL:
      return fetchFundacionUsersFail(state, action)
    case actionTypes.FETCH_FUNDACION_USERS_START:
      return fetchFundacionUsersStart(state, action)
    case actionTypes.FETCH_MOVIES_SUCCESS:
      return fetchMoviesSuccess(state, action)
    case actionTypes.FETCH_MOVIES_FAIL:
      return fetchMoviesFail(state, action)
    case actionTypes.FETCH_MOVIES_START:
      return fetchMoviesStart(state, action)
    case actionTypes.CREATE_NEW_MOVIE_SUCCESS:
      return createNewMovieSuccess(state, action)
    case actionTypes.CREATE_NEW_MOVIE_FAIL:
      return createNewMovieFail(state, action)
    case actionTypes.CREATE_NEW_MOVIE_START:
        return createNewMovieStart(state, action)
    case actionTypes.CREATE_NEW_MOVIE_RESET:
          return createNewMovieReset(state)
    case actionTypes.CREATE_NEW_MOVIE_UPDATE_PROGRESS:
        return createNewMovieUpdateProgress(state, action)
    case actionTypes.EDIT_MOVIE_SUCCESS:
        return editMovieSuccess(state, action)
    case actionTypes.EDIT_MOVIE_FAIL:
        return editMovieFail(state, action)
    case actionTypes.EDIT_MOVIE_START:
        return editMovieStart(state, action)
    case actionTypes.EDIT_MOVIE_RESET:
          return editMovieReset(state, action)
    case actionTypes.EDIT_MOVIE_UPDATE_PROGRESS:
      return editMovieUpdateProgress(state, action)
    case actionTypes.FETCH_DELETE_MOVIE_RESET:
      return fetchDeleteMovieReset(state, action)
    case actionTypes.FETCH_DELETE_MOVIE_SUCCESS:
      return fetchDeleteMovieSuccess(state, action)
    case actionTypes.FETCH_DELETE_MOVIE_FAIL:
      return fetchDeleteMovieFail(state, action)
    case actionTypes.FETCH_DELETE_MOVIE_START:
      return fetchDeleteMovieStart(state, action)
    case actionTypes.FETCH_DETAIL_MOVIE_SUCCESS:
      return fetchDetailMovieSuccess(state, action)
    case actionTypes.FETCH_DETAIL_MOVIE_FAIL:
      return fetchDetailMovieFail(state, action)
    case actionTypes.FETCH_DETAIL_MOVIE_START:
      return fetchDetailMovieStart(state, action)
      case actionTypes.FETCH_DETAIL_MOVIE_RESET:
        return fetchDetailMovieReset(state, action)
    case actionTypes.FETCH_STORIES_SUCCESS:
      return fetchStoriesSuccess(state, action)
    case actionTypes.FETCH_STORIES_FAIL:
      return fetchStoriesFail(state, action)
    case actionTypes.FETCH_STORIES_START:
      return fetchStoriesStart(state, action)
    case actionTypes.FETCH_DELETE_STORY_RESET:
      return fetchDeleteStoryReset(state, action)
    case actionTypes.FETCH_DELETE_STORY_SUCCESS:
      return fetchDeleteStorySuccess(state, action)
    case actionTypes.FETCH_DELETE_STORY_FAIL:
      return fetchDeleteStoryFail(state, action)
    case actionTypes.FETCH_DELETE_STORY_START:
      return fetchDeleteStoryStart(state, action)
    case actionTypes.FETCH_DETAIL_STORY_SUCCESS:
      return fetchDetailStorySuccess(state, action)
    case actionTypes.FETCH_DETAIL_STORY_FAIL:
      return fetchDetailStoryFail(state, action)
    case actionTypes.FETCH_DETAIL_STORY_START:
      return fetchDetailStoryStart(state, action)
    case actionTypes.FETCH_DETAIL_STORY_RESET:
      return fetchDetailStoryReset(state, action)
    case actionTypes.CREATE_NEW_STORY_SUCCESS:
      return createNewStorySuccess(state)
    case actionTypes.CREATE_NEW_STORY_FAIL:
      return createNewStoryFail(state, action)
    case actionTypes.CREATE_NEW_STORY_START:
      return createNewStoryStart(state)
    case actionTypes.CREATE_NEW_STORY_RESET:
      return createNewStoryReset(state)
    case actionTypes.CREATE_NEW_STORY_UPDATE_PROGRESS:
      return createNewStoryUpdateProgress(state, action)
    case actionTypes.EDIT_STORY_SUCCESS:
      return editStorySuccess(state, action)
    case actionTypes.EDIT_STORY_FAIL:
      return editStoryFail(state, action)
    case actionTypes.EDIT_STORY_RESET:
      return editStoryReset(state, action)
    case actionTypes.EDIT_STORY_START:
      return editStoryStart(state, action)
    case actionTypes.EDIT_STORY_UPDATE_PROGRESS:
      return editStoryUpdateProgress(state, action)
    case actionTypes.FETCH_FUNDACION_DONACIONES_SUCCESS:
      return fetchDonacionesSuccess(state, action)
    case actionTypes.FETCH_FUNDACION_DONACIONES_FAIL:
      return fetchDonacionesFail(state, action)
    case actionTypes.FETCH_FUNDACION_DONACIONES_START:
      return fetchDonacionesStart(state, action)
    case actionTypes.FETCH_FUNDACION_NEWS_SUCCESS:
      return fetchNewsSuccess(state, action)
    case actionTypes.FETCH_FUNDACION_NEWS_FAIL:
      return fetchNewsFail(state, action)
    case actionTypes.FETCH_FUNDACION_NEWS_START:
      return fetchNewsStart(state, action)

      case actionTypes.FETCH_NEWS_SUCCESS:
        return fetchNewsSuccess(state, action)
      case actionTypes.FETCH_NEWS_FAIL:
        return fetchNewsFail(state, action)
      case actionTypes.FETCH_NEWS_START:
        return fetchNewsStart(state, action)
      case actionTypes.FETCH_DELETE_NEWS_RESET:
        return fetchDeleteNewsReset(state, action)
      case actionTypes.FETCH_DELETE_NEWS_SUCCESS:
        return fetchDeleteNewsSuccess(state, action)
      case actionTypes.FETCH_DELETE_NEWS_FAIL:
        return fetchDeleteNewsFail(state, action)
      case actionTypes.FETCH_DELETE_NEWS_START:
        return fetchDeleteNewsStart(state, action)
      case actionTypes.FETCH_DETAIL_NEWS_SUCCESS:
        return fetchDetailNewsSuccess(state, action)
      case actionTypes.FETCH_DETAIL_NEWS_FAIL:
        return fetchDetailNewsFail(state, action)
      case actionTypes.FETCH_DETAIL_NEWS_START:
        return fetchDetailNewsStart(state, action)
      case actionTypes.FETCH_DETAIL_NEWS_RESET:
        return fetchDetailNewsReset(state, action)
      case actionTypes.CREATE_NEW_NEWS_SUCCESS:
        return createNewNewsSuccess(state)
      case actionTypes.CREATE_NEW_NEWS_FAIL:
        return createNewNewsFail(state, action)
      case actionTypes.CREATE_NEW_NEWS_START:
        return createNewNewsStart(state)
      case actionTypes.CREATE_NEW_NEWS_RESET:
        return createNewNewsReset(state)
      case actionTypes.EDIT_NEWS_SUCCESS:
        return editNewsSuccess(state, action)
      case actionTypes.EDIT_NEWS_FAIL:
        return editNewsFail(state, action)
      case actionTypes.EDIT_NEWS_RESET:
        return editNewsReset(state, action)
      case actionTypes.EDIT_NEWS_START:
        return editNewsStart(state, action)

    default:
      return state
  }
}


export default reducer