import React from 'react'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross-blue.png'

import {
  Title,
  CrossImage,
  Container
} from './styled'

class ResultReporteExpensas extends React.Component {
  state={
    
  }

  render() {
    return (
        <Modal>
          <Container>
            <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
            <Title>Se ha iniciado correctamente el proceso de reporte de expensas. Puede tardar varios minutos en finalizar.</Title>
          </Container>
        </Modal>
    )
  }
}

export default ResultReporteExpensas