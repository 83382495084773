import React from 'react';
import Padron from './containers/padron/'
import Invitados from './containers/invitados/invitados.component'
import Reservations from './containers/reservations'
import Expensas from './containers/expensas/'
import Proveedores from './containers/proveedores/proveedores.component'
import Feed from './containers/feed'
import Seguridad from './containers/seguridad/seguridad.component'
import Neighbourhood from './containers/neighbourhood/neighbourhood.component'
import Billetera from './containers/billetera/'
import Users from './containers/users/index.js'
import Accesos from './containers/acceso-entradas/'
import Shops from './containers/clasificados/index.js'
import Fundacion from './containers/fundacion/index.js'
import Tickets from './containers/tickets/tickets.component'
import Reportes from './containers/reportes/index.js'
import Gestor from './containers/gestor-consultas'

const routeList = [
  {
    path: '/invitados',
    name: 'Invitados',
    icon: null,
    component: Invitados,
    layout: null
  },
  {
    path: '/fundacion',
    name: 'Fundación',
    icon: null,
    component: Fundacion,
    layout: null
  },
  {
    path: '/users',
    name: 'Perfiles',
    icon: null,
    component: Users,
    layout: null
  },
  {
    path: '/feed',
    name: 'Feed',
    icon: null,
    component: Feed,
    layout: null
  },
  {
    path: '/padron',
    name: 'Padrón',
    icon: null,
    component: Padron,
    layout: null
  },
  {
    path: '/reservas',
    name: 'Reservas',
    icon: null,
    component: Reservations,
    layout: null
  },
  {
    path: '/seguridad',
    name: 'Seguridad',
    icon: null,
    component: Seguridad,
    layout: null
  },
  {
    path: '/billetera',
    name: 'Billetera',
    icon: null,
    component: Billetera,
    layout: null
  },
  {
    path: '/expensas',
    name: 'Expensas',
    icon: null,
    component: Expensas,
    layout: null
  },
  {
    path: '/accesos',
    name: 'Accesos',
    icon: null,
    component: Accesos,
    layout: null
  },
  {
    path: '/tickets',
    name: 'Tickets',
    icon: null,
    component: Tickets,
    layout: null
  },
  {
    path: '/shops',
    name: 'Shops',
    icon: null,
    component: Shops,
    layout: null
  },
  {
    path: '/Reportes',
    name: 'Reportes',
    icon: null,
    component: Reportes,
    layout: null
  },
  {
    path: '/proveedores',
    name: 'Proveedores',
    icon: null,
    component: Proveedores,
    layout: null
  },
  {
    path: '/nuevo_barrio',
    name: 'Neighbourhood',
    icon: null,
    component: Neighbourhood,
    layout: null
  },
  {
    path: '/gestor',
    name: 'Consultas Emprendimientos',
    icon: null,
    component: Gestor,
    layout: null
  }
];

export default routeList;
