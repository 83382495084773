import styled from 'styled-components'

export const Container = styled.div`
  width: 80%;
  height: 85%;
  max-height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 50px 25px 50px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
  box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
`

export const Row = styled.div`
  width: ${({ width }) => width || '100%'};
  height: auto;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  margin: ${({ margin }) => margin || 0};
`

export const Column = styled.div`
  width: ${({ width }) => width ? width : '35%'};
  max-width: ${({ width }) => width ? width : '35%'};
  height: 100px;
  margin: ${({ margin }) => margin ? margin : '0% 2%'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: ${({ flexGrow }) => flexGrow ? flexGrow : '0'};
`

export const SelectContainer = styled.div`
  width: 100%;
`

export const StyledLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  font-size: 16px;
  height: 35px;
  text-align: center;
`

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
`

export const ActionLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme, error }) => (error ? theme.colors.red : theme.colors.blueRibbon)};
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`

export const TableWrapper = styled.div`
  padding-top: 20px;
  width: 100%;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  font-weight: 500;
`
