
const elements = [
    {
      title: 'Radamel Falcao',
      withAvatar: true,
      cuit: '30692317021',
      lote: '39',
      moneyTotal: '527.633',
      moneySubtotal: '527.633',
      avatar: 'https://s3.us-east-2.amazonaws.com/miiiisa-app/masterdata/usrimg/636887715321205150-JesaOv.png',
    },{
      title: 'Marcelo Gallardo',
      withAvatar: true,
      avatarWithPadding: true,
      moneyTotal: '527.633',
      moneySubtotal: '527.633',
      extraInfo: 'Extra info',
      lote: '34',
      avatar: 'https://s3.us-east-2.amazonaws.com/miiiisa-app/masterdata/usrimg/636887715321205150-JesaOv.png',
      cuit: '20320470189'
    },{
      title: 'Franco Armani',
      withAvatar: true,
      avatarWithPadding: true,
      extraInfo: 'Extra info',
      moneyTotal: '527.633',
      moneySubtotal: '527.633',
      lote: '99',
      avatar: 'https://s3.us-east-2.amazonaws.com/miiiisa-app/masterdata/usrimg/636887715321205150-JesaOv.png',
      cuit: '20320470189'
    },{
      title: 'Adriana Solis',
      withAvatar: true,
      avatarWithPadding: true,
      extraInfo: 'Extra info',
      moneyTotal: '527.633',
      moneySubtotal: '527.633',
      lote: '34',
      avatar: 'https://s3.us-east-2.amazonaws.com/miiiisa-app/masterdata/usrimg/636887715321205150-JesaOv.png',
      cuit: '20320470189'
    },{
      title: 'Adriana Solis',
      withAvatar: true,
      avatarWithPadding: true,
      extraInfo: 'Extra info',
      moneyTotal: '527.633',
      moneySubtotal: '527.633',
      lote: '34',
      avatar: 'https://s3.us-east-2.amazonaws.com/miiiisa-app/masterdata/usrimg/636887715321205150-JesaOv.png',
      cuit: '20320470189'
    },{
      title: 'Adriana Solis',
      withAvatar: true,
      avatarWithPadding: true,
      extraInfo: 'Extra info',
      moneyTotal: '527.633',
      moneySubtotal: '527.633',
      lote: '30',
      avatar: 'https://s3.us-east-2.amazonaws.com/miiiisa-app/masterdata/usrimg/636887715321205150-JesaOv.png',
      cuit: '20320470189'
    },{
      title: 'Adriana Solis',
      withAvatar: true,
      avatarWithPadding: true,
      extraInfo: 'Extra info',
      moneyTotal: '527.633',
      moneySubtotal: '527.633',
      lote: '31',
      avatar: 'https://s3.us-east-2.amazonaws.com/miiiisa-app/masterdata/usrimg/636887715321205150-JesaOv.png',
      cuit: '20320470189'
    },
    ];

    export default elements;