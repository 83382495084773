import { createSelector } from 'reselect'
var moment = require('moment');
const mapDonations = createSelector(
  donations => donations,
  donations =>
  donations.map(donation => ({
      id:donation.id,
      amount: donation.amount,
      userId: donation.user_id,
      fullName:donation.full_name,
      dni:donation.dni,
      email:donation.email,
      donationTypeId: donation.donation_type_id,
      donationTypeName: donation.donation_type_name,
      date: moment(donation.date).format('DD-MM-YYYY HH:mm'),
      donationId:donation.donation_id,
    }))
)

export default mapDonations