import React from 'react'
import {ProgressBarContainer, FillerStyled} from './styled'

class ProgressBarComponent extends React.Component {
  state = {
    percentage: 30,
  }

    render() {
      return (
        <div>
          <ProgressBar percentage={this.props.percentage} />
        </div>
      )
    }  
  }
  
  const ProgressBar = (props) => {
    
    return (
        <ProgressBarContainer>
          <Filler percentage={props.percentage} />
        </ProgressBarContainer>
      )
  }
  
  const Filler = (props) => {
    return <FillerStyled style={{ width: `${props.percentage}%` }} />
  }
  
  export default ProgressBarComponent;
  
  