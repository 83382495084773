import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Page from 'components/Page'
import Section from 'components/Section'
import themeStyles from './personas.theme.style'
import compose from 'recompose/compose'
import withWidth from '@material-ui/core/withWidth'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ProprietaryCreator from './components/ProprietaryCreator/index'
import ProprietaryDetail from './components/ProprietaryDetail/index'
import { bindActionCreators } from 'redux'
import { fetchPadron } from 'store/actions/padron.actions'
import PageLoader from 'components/PageLoader'
import mapProprietary from 'selectors/mapProprietary'
import ResourceList from 'components/ResourceList'
import { setSelectedResource, resetSelectedResource } from 'store/actions/selectedResource'
import EmptyWrapper from 'components/PageEmpty'
import ProprietaryEdit from "./components/ProprietaryEdit"
import UploadFile from "./components/UploadFile"
import SearchBar from 'components/SearchBar'
import {SearchWrapper, ListWrapper, PageWrapper, Wrapper, ContentWrapper } from "./styled.js"


class Personas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewPropietarioOpen: false,
      isNewProprietaryEditOpen: false,
      isPropietarioDetalleOpen: false,
      itemInDetail: '',
      uploadFile: false,
      filter: "",
    }
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.props.fetchPadron()
    this.props.resetSelectedResource()
  }

  openDeleteConfirmation = item => {
    this.setState({ openDeleteConfirmation: true, propietarioToDelete: item })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  onOpenPropietarioDetalle = item => {
    this.setState({ isPropietarioDetalleOpen: true, itemInDetail: item })
  }

  onClosePropietarioDetalle = () => {
    this.setState({ isPropietarioDetalleOpen: false })
  }

  onOpenNewPropietario = () => {
    this.setState({ isNewPropietarioOpen: true })
  }

  onCloseNewProprietary = reload => {
    if (reload) {
      this.props.fetchPadron(true)
      this.props.resetSelectedResource()
    }
    this.setState({ isNewPropietarioOpen: false })
  }

  onOpenNewProprietaryEdit = () => {
    this.setState({ isNewProprietaryEditOpen: true })
  }

  onCloseNewProprietaryEdit = reload => {
    if (reload) {
      this.props.fetchPadron(true)
      this.props.resetSelectedResource()
    }
    this.setState({ isNewProprietaryEditOpen: false })
  }

  isLoadingContent = () => this.props.loading && this.props.guests.length === 0

  toggleUpload = () => this.setState({ uploadFile: !this.state.uploadFile })

  createMainView = (isAuth) => 
    {
      if(isAuth === true){
        return(
          <Section
            title={'Administrar Padron'}
            btnContent={'Añadir Propietario'}
            onBtnClick={this.onOpenNewPropietario}
            link={'Subida masiva'} 
            onLinkClick={this.toggleUpload} 
            secondlink={'Template carga masiva'} 
            secondlinkContent={"http://mi-barrio-admin-web-production.s3-website.us-east-2.amazonaws.com/template"}
          >
            <Wrapper>
              {this.isLoadingContent() ? <PageLoader /> : this.renderPageContent(isAuth)}  
            </Wrapper>
          </Section>
      )}
      else{
        return(
          <Section
            title={'Administrar Padron'}
           
          >
            <Wrapper>
              {this.isLoadingContent() ? <PageLoader /> : this.renderPageContent(isAuth)}  
            </Wrapper>
          </Section>
        )
    }}

  isContentAvaiable = () => !this.props.loading 

  renderPageContent = (isAuth) =>
    this.isContentAvaiable() ? (
    <>
      <SearchWrapper>
        <SearchBar
          placeholder={'Buscá por: nombre, DNI o número de lote'}
          value={this.state.filter}
          onChange={this.handleInputOnChange}
          onClear={this.handleInputClear}
          onKeyUp={(e) => this.handleKeyPress(e)}
          borderBottom
        />
      </SearchWrapper>
        <ContentWrapper>
          <ListWrapper id={'scrollContainer'} className={classNames('portal-hide-scrollbars')}>
            <ResourceList
              elements={this.props.mappedPropietaries }
              onClick={this.handleResourceClick}
              selectedPos={this.props.selectedResource ? this.props.selectedResource.pos : null}
              noMoreContentMsg={'No hay propietarios'}
              hasMore={this.props.hasMore}
              resourcePeople={true}
            />
          </ListWrapper>
          <ProprietaryDetail
            onHandleClose={this.onCloseNewProprietaryEdit}
            item={this.props.selectedResource}
            onEdit={this.onOpenNewProprietaryEdit}
            isAuth={isAuth}
          />
        </ContentWrapper>
      </>
    ) : (
      <EmptyWrapper message={'No hay propietarios disponibles'} />
    )

  handleInputOnChange = value => {
    this.setState({ filter: value })
    this.props.setSelectedResource("")
  }

  handleInputClear = value => {
    this.setState({ filter: value, filterEnabled: false },() => this.props.fetchPadron())
  }  
  
  handleKeyPress(e){
    if(e.keyCode === 13){
      this.props.fetchPadron(e.target.value)
    }
  }  

  handleResourceClick = resource => this.props.setSelectedResource(resource)

  isLoadingContent = () => this.props.loading

  render() {
    var isAuth = this.props.roles.some(el => el.code === "PEOPLE_ADMIN_BO")
    return (
      <Page className={classNames('portal-hide-scrollbars')}>
        <PageWrapper>
          {this.createMainView(isAuth)}
          { this.state.uploadFile == true &&
          (<UploadFile onClose={this.toggleUpload} />)
          || this.state.isNewPropietarioOpen == true &&
           ( <ProprietaryCreator onClose={this.onCloseNewProprietary} /> )
           || this.state.isNewProprietaryEditOpen == true &&
           ( <ProprietaryEdit onClose={this.onCloseNewProprietaryEdit} propietary={this.props.selectedResource} />)
          }
        </PageWrapper>
      </Page>
    )
  }
}

const mapStateToProps = ({ padron,userInfo, selectedResource }) => {
  return {
    mappedPropietaries: mapProprietary(padron.proprietaryList),
    itemSearched: padron.proprietaryList,
    loading: padron.loading,
    roles: userInfo.menusAuth,
    hasMore: padron.hasMore,
    selectedResource
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPadron,
      setSelectedResource,
      resetSelectedResource
    },
    dispatch
  )

Personas.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  width: PropTypes.string.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth(),
    withStyles(themeStyles, { withTheme: true })
  )(Personas)
)
