import React, { Component } from 'react'
import { connect } from 'react-redux'
import closeIcon from "assets/icons/cross-border-round.png"
import check from 'assets/icons/authorizedCheck.png'
import PageLoader from 'components/PageLoader';
import Modal from 'components/Modal'
import {
  Container,
  Header,
  RowItem,
  Body,
  Title,
  StyledButton,
  Subtitle,
  CheckIcon,
  CloseIcon,
  ErrorLabel,
  ButtonWrapper
} from './styled'

class modalDelete extends Component {

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction = (event) => {
        if(event.keyCode === 27) {
            this.props.onClose()
        }
    }
  
  render() {
    if(this.props.success === false ){
        return (
            <Modal> 
                <Container>
                    <Body>
                        <RowItem justify="center">
                            <Title>Eliminar publicación</Title>
                        </RowItem>
                        <RowItem justify="start">
                            <Subtitle>¿Estás seguro de que querés eliminar la publicación?</Subtitle>
                        </RowItem>
                        <ButtonWrapper>
                            <StyledButton invert={true} onClick={()=> this.props.onClose()}>
                                Cancelar
                            </StyledButton>
                            <StyledButton  onClick={()=> this.props.delete()}>
                                Eliminar Publicacion
                            </StyledButton>
                        </ButtonWrapper>
                    </Body>
                </Container>
            </Modal> 
        )}else{
        return(
            <Modal> 
                <Container blue={true}>
                    <Body>
                        <CloseIcon src={closeIcon} onClick={()=> this.props.onClose()} />
                        <CheckIcon src={check} />
                        <RowItem justify="center">
                            <Title blue={true}>¡La publicación fue eliminada!</Title>
                        </RowItem>
                        <RowItem justify="center">
                            <Subtitle blue={true}>El usuario recibirá un correo electrónico notificandole el motivo de eliminación.</Subtitle>
                        </RowItem>
                    </Body>
                </Container>
            </Modal> 
        )
    }
  }
}

export default modalDelete