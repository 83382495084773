import styled from 'styled-components'

const PageBlock = styled.div`
  height: 100%;
  width: 100%;
  max-width: ${({ narrow }) => (narrow ? "1300px" : 90)}vw;
  margin: 0 auto;
  overflow-y: auto;
  display: ${(props) => props.display ? props.display : 'flex'};  
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: visible;
`

export default PageBlock
