import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Accordion from 'components/Accordion';
import DatePickerStyled from 'components/DatePickerStyled';
import registro from 'assets/images/registro.png';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import ImageUploadPlaceholder from 'components/ImageUploadPlaceholder';
import WebcamModal from 'components/WebcamModal';
import Input from 'components/Input';
import { fetchGetVehicleBrands, fetchGetVehicleModels } from 'store/actions/acceso.actions';
import mapVehicleBrands from 'selectors/mapVehicleBrands';

import {
  Text,
  FormSection,
  ErrorLabel,
  InputWrapper,
  Row,
  ButtonWrapper,
  StyledButton
} from '../styled'

class NewGuestFourthStep extends Component {
  componentDidMount() {
    this.props.fetchGetVehicleBrands()
  }
  
  handleSubmit = (values) => {
    const { index, getFormData } = this.props;
    getFormData(values, index);
  }

  getInitialValues = () => ({
    seguro_img: this.props.editMode && this.props.editInitialValues.insurance ? this.props.editInitialValues.insurance.insurance_url : (''),
    seguro_vencimiento: this.props.editMode && this.props.editInitialValues.insurance ? (new Date(this.props.editInitialValues.insurance.insurance_expire_date)) : (''),
    seguro_numero: '',
    auto_modelo: this.props.editMode && this.props.editInitialValues.car ? {value: this.props.editInitialValues.car.vehicle_model, label: this.props.editInitialValues.car.vehicle_model_name} : (''),
    auto_marca: this.props.editMode && this.props.editInitialValues.car ? {value: this.props.editInitialValues.car.vehicle_brand, label: this.props.editInitialValues.car.vehicle_brand_name}  : (''),
    auto_patente: this.props.editMode && this.props.editInitialValues.car ? this.props.editInitialValues.car.vehicle_license_plate : (''),
    auto_color: this.props.editMode && this.props.editInitialValues.car ? this.props.editInitialValues.car.vehicle_colour : ('')
  })

  getSignUpSchema = () =>
  Yup.object().shape({
    seguro_img: Yup.string().nullable().required('La imagen del seguro del vehiculo es requerida'),
    seguro_vencimiento: Yup.string().required('El campo vencimiento del seguro del vehiculo es requerido'),
    seguro_numero: Yup.string().required('El campo numero del seguro del vehiculo es requerido'),
    auto_modelo: Yup.string().required('El campo modelo del vehiculo es requerido'),
    auto_marca: Yup.string().required('El campo marca del vehiculo es requerido'),
    auto_patente: Yup.string().required('El campo patente del vehiculo es requerido'),
    auto_color: Yup.string().required('El campo color del vehiculo es requerido')
  })

  render() {
    const args = {...this.props};
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validationSchema={this.getSignUpSchema()}
        isInitialValid={this.props.editMode ? true : false}
        onSubmit={this.handleSubmit}
        render={props => <FormikForm {...props} {...args} />}
      />
    )
  }
}

class FormikForm extends Component {
  state = { cameraModalOpen: false };

  componentDidMount() {
    if(this.props.editMode && this.props.editInitialValues.insurance) {
      this.setState({ dniImage: this.props.editInitialValues.insurance.insurance_url });
    }
  }

  openWebcamModal = () => {
    this.setState({
      cameraModalOpen: !this.state.cameraModalOpen,
    });
  }

  retrieveImage = (imagen) => {
    this.setState({ dniImage: imagen, cameraModalOpen: false })
  }

  clearImage = () => {
    this.setState({ dniImage: null, cameraModalOpen: true })
  }
  
  render() {
    const { handleSubmit, values, setFieldValue, errors, isValid, loadingVehicleBrands, vehicleBrands, editMode, loadingVehicleModels, vehicleModels } = this.props;
    return(
      <Accordion 
        title={this.props.title} 
        active={this.props.active} 
        index={this.props.index}
        changeIndex={this.props.changeIndex}
        formDataValidation={this.props.formDataValidation}
      >
        <form onSubmit={(values) => handleSubmit(values)}>
          <Row>
            <FormSection flex={1}>
              <InputWrapper>
                <ImageUploadPlaceholder 
                  label={'Foto del seguro'} 
                  icon={registro} 
                  onChange={file => setFieldValue('seguro_img', file)} 
                  dashed={true} 
                  value={this.state.dniImage ? this.state.dniImage : null}     
                  openWebcamModal={this.openWebcamModal}
                  clearImage={this.clearImage}
                />
                { this.state.cameraModalOpen ? <WebcamModal onClose={this.openWebcamModal} retrieveImage={this.retrieveImage}></WebcamModal> : null }
                {errors.seguro_img && <ErrorLabel>{errors.seguro_img}</ErrorLabel>}
              </InputWrapper>
            </FormSection>
            <FormSection flex={2}>
              <Row>
                <InputWrapper style={{'marginRight':'3%'}}>
                  <Text>{'Número del seguro'}</Text>
                  <Input
                    name={'seguro_numero'}
                    label={'Número del seguro'}
                    placeholder={'Número del seguro'}
                    value={values.seguro_numero}
                    error={errors.seguro_numero}
                    onChange={({target: value}) => setFieldValue('seguro_numero', value.value)}
                    />
                  {errors.seguro_numero && <ErrorLabel>{errors.seguro_numero}</ErrorLabel>}
                </InputWrapper>
                <InputWrapper display='flex' flexdirection='column'>
                  <Text margin={'0px'}>{'Fecha vencimiento'}</Text>
                  <DatePickerStyled
                    onChange={values => setFieldValue('seguro_vencimiento', values)}
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    selected={values.seguro_vencimiento}
                    error={errors.seguro_vencimiento}
                    placeholderText="dd/MM/yyyy 📅"
                    bold={true}
                    padding={'8px 10px'}
                  />
                {errors.seguro_vencimiento && <ErrorLabel>{errors.seguro_vencimiento}</ErrorLabel>}
                </InputWrapper>
              </Row>
              <Row>
                <InputWrapper style={{'marginRight':'3%'}}>
                  <Text>{'Marca'}</Text>{/*vehicleLoading  vehicleBrands*/}
                  <Select
                    options={!loadingVehicleBrands ? vehicleBrands : [{value: null, label: 'Loading...'}]}
                    placeholder={editMode && this.props.editInitialValues.car ? this.props.editInitialValues.car.vehicle_brand_name : 'Marca' }
                    name={'auto_marca'}
                    value={values.auto_marca}
                    error={errors.auto_marca}
                    onChange={values => { setFieldValue('auto_marca', values); setFieldValue('auto_modelo', ''); this.props.fetchGetVehicleModels(values.value)}}
                  >
                  </Select>
                  {errors.auto_marca && <ErrorLabel>{errors.auto_marca}</ErrorLabel>}
                </InputWrapper>
                <InputWrapper>
                  <Text>{'Modelo'}</Text>
                  <Select
                    options={!loadingVehicleModels ? vehicleModels : [{value: null, label: 'Loading...'}]}
                    placeholder={editMode && this.props.editInitialValues.car ? this.props.editInitialValues.car.vehicle_model_name : 'Model' }
                    name={'auto_modelo'}
                    value={values.auto_modelo}
                    error={errors.auto_modelo}
                    onChange={values => { setFieldValue('auto_modelo', values)}}
                    />
                  {errors.auto_modelo && <ErrorLabel>{errors.auto_modelo}</ErrorLabel>}
                </InputWrapper>
              </Row>
              <Row>
               <InputWrapper style={{'marginRight':'3%'}}>
                  <Text>{'Patente'}</Text>
                  <Input
                    name={'auto_patente'}
                    label={'Patente'}
                    placeholder={'Patente'}
                    value={values.auto_patente}
                    error={errors.auto_patente}
                    onChange={({target: value}) => setFieldValue('auto_patente', value.value)}
                    />
                  {errors.auto_patente && <ErrorLabel>{errors.auto_patente}</ErrorLabel>}
                </InputWrapper>
                <InputWrapper>
                  <Text>{'Color'}</Text>
                  <Input
                    name={'auto_color'}
                    label={'Color'}
                    placeholder={'Color'}
                    value={values.auto_color}
                    error={errors.auto_color}
                    onChange={({target: value}) => setFieldValue('auto_color', value.value)}
                    />
                  {errors.auto_color && <ErrorLabel>{errors.auto_color}</ErrorLabel>}
                </InputWrapper>
              </Row>
            </FormSection>
          </Row>
          <ButtonWrapper>
            {isValid ? <StyledButton type={'submit'}>{'Aceptar'}</StyledButton> : null}
          </ButtonWrapper>
        </form>
      </Accordion>
    );
  }
}

const mapStateToProps = ({
  accesos
}) => ({
  loading: accesos.loading,
  loadingVehicleBrands: accesos.loadingVehicleBrands,
  vehicleBrands: mapVehicleBrands(accesos.vehicleBrands),
  loadingVehicleModels: accesos.loadingVehicleModels,
  vehicleModels: mapVehicleBrands(accesos.vehicleModels),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGetVehicleBrands,
      fetchGetVehicleModels
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewGuestFourthStep)

