import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import CrossIcon from 'assets/icons/cross-blue.png'
import FileIcon from 'assets/icons/file.jpg'
import FileInput from 'components/FileInput'
import PageLoader from 'components/PageLoader'
import DeleteIcon from 'components/DeleteIcon'
import ModalMessage from 'components/ModalMessage'
import DeleteConfirmation from 'components/DeleteConfirmation'
import closeIcon from "assets/icons/cross-border-round.png"
import check from 'assets/icons/authorizedCheck.png'
import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  LinkText,
  LinkTextWrapper,
  ModalTitle,
  Container,
  RowItem,
  Title,
  Body,
  CheckIcon,
  CloseIcon
} from './styled'
import Modal from 'components/Modal'

class DetailModalTyC extends React.Component {
  state = {
    errorFile: false,
    errorDeleteTyC: false,
    loadingFile: false,
    idDeleteTyC: false,
    submitedTyC: false,
    isDeletedTyC: false
  }

  getInitialValues = () => ({
    file: ""
  })

  getSignUpSchema = () =>
    Yup.object().shape({
      file: Yup.string().required('Campo requerido')
    })


  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <ModalTitle>
        Términos y condiciones
      </ModalTitle>
      {this.props.activityToEdit.TyCUrl != null ? (
        <LinkTextWrapper>
          <LinkText href={this.props.activityToEdit.TyCUrl} download={"Términos y condiciones" + this.props.activityToEdit.id + ".pdf"} target="_blank" >Ver términos y condiciones</LinkText>
          <DeleteIcon
            width="25%"
            height="30%"
            translucent="0.3"
            onClick={() => this.toggleDeleteConfirmationClose()}>Eliminar</DeleteIcon>

          {this.state.deleteConfirmation && (
            <Modal>
              <DeleteConfirmation
                title={'¿Eliminar términos y condiciones?'}
                onBtnClose={this.toggleDeleteConfirmationClose}
                btnContent={'Eliminar'}
                onBtnClick={this.handleDeleteTyC}
                onError={this.props.errorDeleteFile}
              />
            </Modal>
          )}

        </LinkTextWrapper>


      ) :
        <> </>}
      {this.props.activityToEdit.TyCUrl ? (
        <Row>
          <InputLabel >{'Cambiar términos y condiciones(.pdf)'}</InputLabel>
        </Row>
      ) :
        <Row>
          <InputLabel margin-top={"18px"}>{'Subir términos y condiciones(.pdf)'}</InputLabel>
        </Row>
      }
      <ButtonWrapper>
        <Row>
          <InputWrapper width={30}>
            <FileInput image={FileIcon} name="file" value={values.file} onChange={file => setFieldValue('file', file)} isTyC={true} />
            {errors.file && <ErrorLabel>{errors.file}</ErrorLabel>}
          </InputWrapper>
        </Row>
      </ButtonWrapper>
      {this.state.submitedTyC || this.props.error ? (
        <Modal>
          <Container blue={this.props.error ? false : true}>
            <Body>
              <CloseIcon src={closeIcon} onClick={() => this.props.onClose()} />
              <CheckIcon src={check} />
              <RowItem justify="center">
                <Title blue={this.props.error ? false : true}>  
                {!this.props.error ? this.props.successMessage :
                 'Error al cargar el archivo, verifique si es el correcto'} </Title>
              </RowItem>
            </Body>
          </Container>
        </Modal>
      ) : (<> </>)
      }
      {this.props.errorDeleteTyC || this.state.errorDeleteFile || this.state.isDeletedTyC ? (

        <Modal>
          <Container blue={this.props.errorDeleteTyC ? false : true}>
            <Body>
              <CloseIcon src={closeIcon} onClick={() => this.props.onClose()} />
              <CheckIcon src={check} />
              <RowItem justify="center">
                <Title blue={this.props.errorDeleteTyC ? false : true}> 
                 {!this.props.errorDeleteTyC || !this.state.errorDeleteFile ?
                  this.props.successDelete : 'Error al eliminar los términos y condiciones'} </Title>
              </RowItem>
            </Body>
          </Container>
        </Modal>
      ) : (<> </>)
      }
      {this.props.loading ?
        (
          <ButtonWrapper>
            <PageLoader customHeight={"50"} />
          </ButtonWrapper>
        ) :
        (
          <ButtonWrapper>
            <StyledButton type={'submit'}>SUBIR</StyledButton>
          </ButtonWrapper>

        )

      }

    </FormContainer>
  )

  toggleDeleteConfirmationClose = () => {
    this.setState({ deleteConfirmation: !this.state.deleteConfirmation })
  }

  handleSubmit = async data => {
    this.setState({ loadingFile: true })
    try {
      const body = {
        FileString: data.file.base64Value,
        Extension: data.file.extension,
        ActivityId: this.props.activityToEdit.id
      }
      await this.props.onChange(body)
      if (!this.props.error) this.setState({ submitedTyC: true })
      this.setState({error: true })
      if (!this.props.error) this.props.fetchActivities()

    } catch (e) {
      this.setState({ errorFile: true })
    }
  }

  handleDeleteTyC = async data => {
    try {
      await this.props.onDeleteTyC(this.props.activityToEdit.id)
      if (!this.props.errorDeleteTyC) this.setState({ isDeletedTyC: true })
      if (!this.props.errorDeleteTyC) this.props.fetchActivities()
    } catch (e) {
      this.setState({ errorDeleteFile: true })
    }
  }

  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

export default DetailModalTyC
