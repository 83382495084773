import { createSelector } from 'reselect'

const mapConsultasEstadosVendor = createSelector(
  consultasListEstados => consultasListEstados,
  consultasListEstados =>
    consultasListEstados.map((val, i) => ({
      value: val.id,
      label: val.name
      
    }))
)
export default mapConsultasEstadosVendor
