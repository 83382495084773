import styled from 'styled-components'

export const Container = styled.div`
    width: 90%;
    min-height: 20px;
    max-height: 40px;
    border: 0.5px solid
    ${({ theme, selected }) => (selected ? theme.colors.mercury : theme.colors.border)};
    margin: 10px 0px 0px 30px;
    padding: 10px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    &:hover{
        cursor: pointer;
    }    
`
export const Title = styled.div`
    min-height: 20px;
    max-height: 80px;
    font-size: 15px;
    margin: 5px;  
    font-family: ${({ theme }) => theme.fonts.roboto };
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;

    ${Container}:hover & {
        color: ${({theme})=> theme.colors.blueRibbon};
        transition:0.3s ease;
    }
    
    &:hover{
        cursor: pointer;
    }    
`
export const Images = styled.div`
    width: 15%;
    margin: 5px;
`