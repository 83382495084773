import styled from 'styled-components'


export const ProgressBarContainer = styled.div`
    position: relative;
    height: 10px;
    width: 100%;
    border-radius: 50px;
    background: ${({ theme }) => theme.colors.alto };
`
export const FillerStyled = styled.div`
    background:   ${({ theme }) => theme.colors.royalBlue };
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
`