import * as actionTypes from '../actions/actionTypes'
import { updateObject } from 'shared/utility'

const initialState = {
  mascotas: [],
  loadingReporteMascotas: false,
  errorReporteMascotas: null,

  tickets: [],
  loadingReporteTickets: false,
  errorReporteTickets: null,

  reporteReservas :[],
  loadingReporteReserva: false,
  errorReporteReserva:false
}

//////////////////MASCOTAS//////////////////
const fetchReporteMascotasSuccess = (state, action) => {
  return updateObject(state, {
    mascotas: action.mascotas,
    loadingReporteMascotas: false
  })
}

const fetchReporteMascotasFail = (state, action) => {
  return updateObject(state, {
    loadingReporteMascotas: false,
    errorReporteMascotas: action.errorReporteMascotas
  })
}

const fetchReporteMascotasStart = (state) => {
  return updateObject(state, {
    loadingReporteMascotas: true,
    errorReporteMascotas: null
  })
}

//////////////////TICKETS//////////////////
const fetchReporteTicketsSuccess = (state, action) => {
  return updateObject(state, {
    tickets: action.tickets,
    loadingReporteTickets: false
  })
}

const fetchReporteTicketsFail = (state, action) => {
  return updateObject(state, {
    loadingReporteTickets: false,
    errorReporteTickets: action.errorReporteMascotas
  })
}

const fetchReporteTicketsStart = (state) => {
  return updateObject(state, {
    loadingReporteTickets: true,
    errorReporteTickets: null
  })
}

///////////////REPORTE RESERVA//////////////
const fetchReporteReservasSuccess = (state, action) => {
  return updateObject(state, {
    reporteReservas: action.reporteReservas,
    loadingReporteReserva: false
  })
}

const fetchReporteReservasFail = (state, action) => {
  return updateObject(state, {
    loadingReporteReserva: false,
    errorReporteReserva: action.errorReporteReserva
  })
}

const fetchReporteReservasStart = (state) => {
  return updateObject(state, {
    loadingReporteReserva: true,
    errorReporteReserva: null
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REPORTE_MASCOTAS_SUCCESS:
      return fetchReporteMascotasSuccess(state, action)
    case actionTypes.FETCH_REPORTE_MASCOTAS_FAIL:
      return fetchReporteMascotasFail(state, action)
    case actionTypes.FETCH_REPORTE_MASCOTAS_START:
      return fetchReporteMascotasStart(state, action)

    case actionTypes.FETCH_REPORTE_TICKETS_SUCCESS:
      return fetchReporteTicketsSuccess(state, action)
    case actionTypes.FETCH_REPORTE_TICKETS_FAIL:
      return fetchReporteTicketsFail(state, action)
    case actionTypes.FETCH_REPORTE_TICKETS_START:
      return fetchReporteTicketsStart(state, action)

    case actionTypes.FETCH_BOOKINGREPORT_REQUEST_SUCCESS:
      return fetchReporteReservasSuccess(state, action)
    case actionTypes.FETCH_BOOKINGREPORT_REQUEST_FAIL:
        return fetchReporteReservasFail(state, action)
    case actionTypes.FETCH_BOOKINGREPORT_REQUEST_START:
        return fetchReporteReservasStart(state, action)

    default:
      return state
  }
}

export default reducer