import React from 'react'
import Spinner from 'react-spinner-material'
import styled from 'styled-components'
import gif from "../../assets/images/loader.gif"

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`

const LoaderWrapper = styled(EmptyWrapper)`
  min-height: ${({ customHeight }) => customHeight || 560}px;
  border-radius: 10px;
`

const StyledImg = styled.img`
  width: 50%;
`

const PageLoader = ({ customHeight }) => (
  <LoaderWrapper customHeight={customHeight}>
    <StyledImg src={gif} />
  </LoaderWrapper>
)

export default PageLoader
