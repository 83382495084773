import React from 'react'
import Icon, { IconNames } from 'components/Icons'
import theme from 'config/theme'
import {Container,SearchWrapper,CloseWrapper,InputStyled} from './styled'


class SearchBar extends React.Component { 
  

  componentDidUpdate = prevProps => {
    if(prevProps.value){
      if (prevProps.value.length === 1 && this.props.value.length === 0) {
        this.props.onClear('')
      }
    }
   
  }

  render() {
    const { placeholder, value, onChange, onClear, onKeyDown,onKeyUp,style,noBorder } = this.props
    return (
      <Container style={{style}} noBorder={noBorder}>
          <SearchWrapper>
            <Icon size={45} name={IconNames['Search']} color={theme.colors.blueRibbon} />
          </SearchWrapper>
          <InputStyled
            placeholder={placeholder}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            size={20}
            withoutBorder
            autoFocus={true}
          />
          {value !== '' && (
            <CloseWrapper onClick={() => value !== '' && onClear('')}>
              <Icon
                onClick={onClear}
                size={30}
                name={IconNames['Close']}
                color={theme.colors.blueRibbon}
              />
            </CloseWrapper>
          )}
      </Container>
      
    )
  }
}

export default SearchBar
