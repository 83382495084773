import React, { Component } from 'react'
import { connect } from 'react-redux'
import closeIcon from "assets/icons/cross-border-round.png"
import check from 'assets/icons/authorizedCheck.png'
import PageLoader from 'components/PageLoader';
import Modal from 'components/Modal'
import {
    Container,
    Header,
    RowItem,
    Body,
    Title,
    StyledButton,
    Subtitle,
    CheckIcon,
    CloseIcon,
    ErrorLabel,
    ButtonWrapper
} from './styled'

class modalDelete extends Component {

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.props.onClose()
        }
    }

    render() {
        if (this.props.success === false) {
            return (
                <Modal>
                    <Container>
                        <Body>
                            <RowItem justify="center">
                                <Title>
                                    {this.props.isMovie ? (
                                        'Eliminar pelicula'
                                    ):this.props.isNews ?('Eliminar novedad') :(
                                            'Eliminar cuento'
                                        )}
                                </Title>
                            </RowItem>
                            <RowItem justify="start">
                                <Subtitle>
                                    {this.props.isMovie ? (
                                        ' ¿Estás seguro de que querés eliminar la pelicula?'
                                    ) :this.props.isNews ?('¿Estás seguro de que querés eliminar la novedad?') : (
                                            ' ¿Estás seguro de que querés eliminar el cuento?'
                                        )}
                                </Subtitle>
                            </RowItem>
                            <ButtonWrapper>
                                <StyledButton invert={true} onClick={() => this.props.onClose()}>
                                    Cancelar
                            </StyledButton>
                                <StyledButton onClick={() => this.props.delete()}>
                                    {this.props.isMovie ? (
                                        'Eliminar pelicula'
                                    ) :this.props.isNews ?('Eliminar novedad') : (
                                            'Eliminar cuento'
                                        )}
                                </StyledButton>
                            </ButtonWrapper>
                        </Body>
                    </Container>
                </Modal>
            )
        } else {
            return (
                <Modal>
                    <Container blue={true}>
                        <Body>
                            <CloseIcon src={closeIcon} onClick={() => this.props.onClose()} />
                            <CheckIcon src={check} />
                            <RowItem justify="center">
                                <Title blue={true}>
                                    {this.props.isMovie ? (
                                        ' ¡La Pelicula fue eliminada!'
                                    ) :this.props.isNews ?(' ¡La novedad fue eliminada!') : (
                                            ' ¡El cuento fue eliminado!'
                                        )}
                                </Title>
                            </RowItem>
                        </Body>
                    </Container>
                </Modal>
            )
        }
    }
}

export default modalDelete