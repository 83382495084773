import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik,Field } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross.png'
import TrashIcon from 'assets/icons/trash-blue.png'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import DatePickerStyled from 'components/DatePickerStyled'
import Select from 'react-select'
import { addMonths } from 'date-fns'
import FileImageInput from 'components/FileImageInput'
import FileInputGroup from 'components/FileInputGroup'
import { httpsOnly } from 'constants/regularExpresions.js'
import CheckBox from 'components/CheckboxFormik'
import PopUp from '../PopUp'
import { createNewEvent, createNewArticle, editEvent, editArticle } from 'store/actions/feed.actions'
import {
  FormContainer,
  Row,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  LabelChecked,
  CrossImage,
  ResetIcon
} from './styled'
import { parseToISO } from 'utils/iso8601Parser'

class NewActivity extends React.Component {
  state={   
    confirmNotification: false
  }

  getInitialValues = () => this.props.isEdit && this.props.publicationToEdit ? ({
    title: this.props.publicationToEdit.title,
    startDate: new Date(this.props.publicationToEdit.publicationdate),
    endDate: this.props.publicationToEdit.publicationendshowingdate ? new Date(this.props.publicationToEdit.publicationendshowingdate) : new Date(),
    type: !this.props.isEvent ? this.getTypeOptions()[0] : this.getTypeOptions()[1],
    boldparagraph: '',
    subtitle: this.props.publicationToEdit.subtitle,
    body: this.props.publicationToEdit.body,
    externalurl: '',
    headerImage: null,
    images: [],
    neighbourhood: this.getSelectedNeighbourhoods(this.props.publicationToEdit.neighbourhoodlist),
    isEdit: true,
    isEvent: this.props.isEvent,
    editId: this.props.publicationToEdit.id
  }) : ({
    title: '',
    startDate: new Date(),
    endDate: new Date(),
    type: this.getTypeOptions()[0],
    boldparagraph: '',
    subtitle: '',
    body: '',
    externalurl: '',
    headerImage: null,
    images: [],
    neighbourhood: '',
    isEdit: false,
    isEvent: this.props.isEvent,
    editId: null
  })

  getSignUpSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        title: Yup.string().required('Ingrese un titulo'),
        startDate: Yup.string().required('Ingrese una fecha'),
        endDate: Yup.string().required('Ingrese una fecha'),
        type: Yup.object(),
        subtitle: Yup.string().required('Ingrese un subtitulo'),
        body: Yup.string().required('Ingrese un mensaje'),
        neighbourhood: Yup.string().required('Ingrese al menos un barrio'),
        //  .required('Ingrese un link')
        //  .matches(httpsOnly, {
        //    message: 'El link no es valido',
        //    excludeEmptyString: true
        //  }),
        headerImage: Yup.object()
          .nullable()
          .when('isEdit', {
            is: false,
            then: Yup.object().required('Seleccione una portada'),
          }),
        images: Yup.array()
      })
    )
    

  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (

    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row>
        <InputWrapper width={38} margin={'0px 5px'}>
          <InputLabel>{'Titulo'}</InputLabel>
          <Input
            name={'title'}
            label={'Nombre'}
            placeholder={'Ingrese un titulo...'}
            value={values.title}
            error={errors.title}
            onChange={({ target: value }) => setFieldValue('title', value.value)}
            margin="normal"
          />
          {errors.title && <ErrorLabel>{errors.title}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={28} margin={'0px 5px'}>
          <InputLabel>{'Inicio de publicación'}</InputLabel>
          <DatePickerStyled
            selected={values.startDate}
            startDate={values.startDate}
            endDate={values.endDate}
            selectsStart
            showTimeSelect
            dateFormat="HH:mm dd/MM/yyy"
            onChange={startDate => setFieldValue('startDate', startDate)}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 12)}
            placeholderText="Inicio de publicación"
          />
          {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={28} margin={'0px 5px'}>
          <InputLabel>{'Fin de publicación'}</InputLabel>
          <DatePickerStyled
            selected={values.endDate}
            startDate={values.startDate}
            endDate={values.endDate}
            onChange={endDate => setFieldValue('endDate', endDate)}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 12)}
            showTimeSelect
            dateFormat="HH:mm dd/MM/yyy"
            placeholderText="Fin de publicación"
            {...(!this.isEventForm(values) && { disabled: true })}
          />
          {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={28} margin={'0px 5px'}>
          <InputLabel>{'Tipo de publicación'}</InputLabel>
          <Select
            name={'type'}
            value={values.type}
            isSearchable={false}
            onChange={values => setFieldValue('type', values)}
            error={errors.type}
            options={this.getTypeOptions()}
            placeholder="Seleccione un tipo..."
            isDisabled={values.isEdit}
          />
          {errors.type && <ErrorLabel>{errors.type}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={50} margin={'0px 5px'}>
          <InputLabel>{'Barrios asignados'}</InputLabel>
          <Select
            value={values.neighbourhood}
            onChange={value => setFieldValue('neighbourhood', value)}
            options={this.getNeighbourhoodOptions()}
            placeholder="Buscar..."
            error={errors.neighbourhood ? true : false}
            isMulti
          />
          {errors.neighbourhood && <ErrorLabel>{errors.neighbourhood}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <InputLabel>{'Subtitulo'}</InputLabel>
          <Input
            name={'subtitle'}
            label={'Subtitulo'}
            placeholder={'Ingrese un subtitulo...'}
            value={values.subtitle}
            error={errors.subtitle}
            onChange={({ target: value }) => setFieldValue('subtitle', value.value)}
            margin="normal"
          />
          {errors.subtitle && <ErrorLabel>{errors.subtitle}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <InputLabel>{'Mensaje'}</InputLabel>
          <TextArea
            name={'body'}
            value={values.body}
            placeholder={'Ingrese un mensaje...'}
            onChange={({ target: { value } }) => setFieldValue('body', value)}
            error={errors.body}
            minHeight={150}
            maxHeight={150}
            type={'text'}
            rows={3}
          />
          {errors.body && <ErrorLabel>{errors.body}</ErrorLabel>}
        </InputWrapper>
      </Row>
     {/*  <Row>
        <InputWrapper>
          <InputLabel>{'Link a compartir'}</InputLabel>
          <Input
            name={'externalurl'}
            label={'externalurl'}
            value={values.externalurl}
            placeholder={'https://...'}
            error={errors.externalurl}
            onChange={({ target: value }) => setFieldValue('externalurl', value.value)}
            margin="normal"
          />
          {errors.externalurl && <ErrorLabel>{errors.externalurl}</ErrorLabel>}
        </InputWrapper>
      </Row> */}  
      {this.props.isEdit ? null :
      <Row>
        <InputWrapper width={30}>
          <InputLabel>{'Portada'}</InputLabel>
          <FileImageInput onChange={file => setFieldValue('headerImage', file)} />
          {errors.headerImage && <ErrorLabel>{errors.headerImage}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper>
          <FileInputGroup
            imagesAmount={4}
            label={'Adjuntar imagenes'}
            error={errors.images}
            onChange={images => setFieldValue('images', images)}
          />
        </InputWrapper>
      </Row>}
      <Row>
        <ErrorLabel>{this.props.errorNew ? this.props.errorNew : null}</ErrorLabel>
        <ButtonWrapper>
          <ResetIcon onClick={() => resetForm()} src={TrashIcon} />
          <StyledButton type={'submit'}>
            {this.props.isSubmittingEvent || this.props.isSubmittingArticle ? (
              <CircularProgress size={18} />
            ) : (
              'Publicar'
            )}
          </StyledButton>
        </ButtonWrapper>
      </Row>
    </FormContainer>
  )

  isEventForm = values => {
    if(values.type){
      if(values.type.value === 'Event'){
        return true
      }else{
        return false
      }
    }
  }

  isSeguridadForm = values => {
    if(values.type){
      if(values.type.value === 'seguridad'){
        return true
      }else{
        return false
      }
    }
  }

  getTypeOptions = () => [
    {
      label: 'Articulo',
      value: 'Article'
    },
    {
      label: 'Evento',
      value: 'Event'
    },
    {
      label: 'Seguridad',
      value: 'seguridad'
    },
  ]

  preHandleSubmit = data => {

    if(this.isSeguridadForm(data)){
      this.setState({ modalConfirmNotification: true,data: data})
    }else{
      this.handleSubmit(data)
    }
  }

toggleModal(){
  this.setState({ modalConfirmNotification: !this.state.modalConfirmNotification})
}

  handleSubmit = data => {
    let formattedData = {
      title: data.title,
      subtitle: data.subtitle,
      body: data.body,
      sendsnotification: this.isSeguridadForm(data),
      externalurl: data.externalurl,
      headerimagebase64: data.headerImage ? data.headerImage.base64Value : null,
      publicationdate: parseToISO(data.startDate),
      publicationstartshowingdate : parseToISO(data.startDate),
      publicationendshowingdate : parseToISO(data.endDate),
      neighbourhoodList : data.neighbourhood.map(n => n.value),
    }
    console.log("data",data)
    console.log("formatted",formattedData)

    if (!data.isEdit){
      if (data.type.value === 'Event') {
        this.submitEvent(formattedData, data)
      } else {
        this.submitArticle(formattedData, data)
      }
    }
    else{
      if (data.type.value === 'Event') {
        this.editEvent(formattedData, data.editId)
      } else {
        this.editArticle(formattedData, data.editId)
      }
    }
  }

  submitEvent = async (formattedData, data) => {
    console.log("submite evento",formattedData)
    formattedData.imageextension = data.headerImage.extension
    formattedData.images = data.images.map(image => ({
      imagebase64: image.base64Value,
      extension: image.extension
    }))
    await this.props.createNewEvent(formattedData)

    if (!this.props.submitErrorEvent){
      this.props.onClose(true)
    } 
  }

  submitArticle = async (formattedData, data) => {
    console.log("submite articulo",formattedData)
    formattedData.headerimageextension = data.headerImage.extension
    formattedData.boldparagraph = data.boldparagraph
    formattedData.images = data.images.map(image => ({
      imagebase64: image.base64Value,
      extension: image.extension
    }))
    await this.props.createNewArticle(formattedData)
    if (!this.props.submitErrorArticle) this.props.onClose(true)
  }

  editEvent = async (formattedData, id) => {
    formattedData.id = id;
    await this.props.editEvent(formattedData)

    if (!this.props.submitErrorEvent) this.props.onClose(true)
  }

  editArticle = async (formattedData, id) => {
    formattedData.id = id;
    await this.props.editArticle(formattedData)
    if (!this.props.submitErrorArticle) this.props.onClose(true)
  }

  render() {
    console.log(this.state)
    console.log(this.props)
    if(this.state.modalConfirmNotification){
      return (
        <PopUp 
          onHandleSubmit={()=> this.handleSubmit(this.state.data)} 
          onClose={()=> this.toggleModal()}
          neighbourhoods={this.state.data.neighbourhood} 
        />

      )
    }else{
      return (
        <Modal>
          <Formik
            initialValues={this.getInitialValues()}
            validateOnChange={false}
            validationSchema={this.getSignUpSchema()}
            onSubmit={this.preHandleSubmit}
            render={e => this.getFormContent(e)}
          />
        </Modal>
      )
    }
  }

  getNeighbourhoodOptions = () =>
    this.props.neighbourhoods.map(n => ({ label: n.name, value: n.guid }));

  getSelectedNeighbourhoods = (selectedGuids) =>{
    return this.props.neighbourhoods.filter(
      element => selectedGuids.includes(element.guid)
    ).map(n => ({ label: n.name, value: n.guid }));
  }

}

const mapStateToProps = ({ userInfo: { neighbourhoods, selectedNeighbourhood }, feed }) => ({
  isSubmittingEvent: feed.loadingNewEvent,
  submitErrorEvent: feed.errorNewEvent,
  isSubmittingArticle: feed.loadingNewArticle,
  submitErrorArticle: feed.errorNewArticle,
  errorNew: feed.errorNewEventMessage || feed.errorNewArticleMessage,
  neighbourhoods,
  selectedNeighbourhood,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createNewEvent, createNewArticle, editEvent, editArticle }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewActivity)
