import React from 'react'
import styled from 'styled-components'
import Resource from 'components/Resource'
import ResourcePeople from 'components/ResourcePeople'
import InfiniteScroll from 'react-infinite-scroll-component'
import CircularProgress from '@material-ui/core/CircularProgress'

export const ScrollWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.white};

  &::-webkit-scrollbar {
    display: none;
  }
`

export const InfiniteLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 0;
`

export const NoContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 20px;
  padding: 20px 0;
`

const ResourcesList = ({
  elements,
  onClick,
  selectedPos,
  fetchMoreContent,
  hasMore,
  noMoreContentMsg
}) => (
    <div>
      <InfiniteScroll
        scrollableTarget={'scrollContainer'}
        dataLength={elements.length}
        next={fetchMoreContent}
        hasMore={hasMore}
        loader={
          <InfiniteLoaderWrapper>
            <CircularProgress size={30} />
          </InfiniteLoaderWrapper>
        }
        endMessage={
          <NoContentContainer style={{ textAlign: 'center' }}>{noMoreContentMsg}</NoContentContainer>
        }
      >
        <ScrollWrapper>
          {elements.map((e, i) => (
            <Resource
              title={e.name}
              subtitle={e.title}
              extraInfo={e.extraInfo}
              detail={e.detail}
              key={i}
              onClick={() => onClick(e)}
              selected={e === selectedPos}
              withAvatar={e.withAvatar}
              avatarWithPadding={e.avatarWithPadding}
              avatarCentered={e.avatarCentered}
            />
          ))}
        </ScrollWrapper>
      </InfiniteScroll>
    </div>
  )

export default ResourcesList

