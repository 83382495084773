import React, { Component } from 'react'
import Modal from 'components/Modal'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import PageLoader from '../../../components/PageLoader'

import {
  fetchVendorConsultas,
} from '../../../store/actions/gestorConsultas.actions'

import Input from 'components/Input'

import ModalMessage from 'components/ModalMessage'


import {
  FormContainer,
  Row,
  RowLeft,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ErrorMessage,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  InputWrapperMargin
} from './styled'

import { connect } from 'react-redux'
import CrossIcon from 'assets/icons/cross.png'





class EditTicket extends Component {
  state = {}

  componentDidMount() {
    //this.props.fetchRoles()
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row error={errors.user || errors.type}>
        <InputWrapper width={48}>
          <InputLabel>{'Nombre Contacto (*)'}</InputLabel>
          <Input
            value={values.name}
            onChange={({ target: { value } }) => setFieldValue('name', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.name ? true : false}
          />
          {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Apellido Contacto (*)'}</InputLabel>
          <Input
            value={values.surname}
            onChange={({ target: { value } }) => setFieldValue('surname', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.surname ? true : false}
          />
          {errors.surname && <ErrorLabel>{errors.surname}</ErrorLabel>}
        </InputWrapper>
      </Row>

      <Row error={errors.email || errors.tel}>
        <InputWrapper width={48}>
          <InputLabel>{'Email de Contacto (*)'}</InputLabel>
          <Input
            value={values.email}
            onChange={({ target: { value } }) => setFieldValue('email', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.email ? true : false}
          />
          {errors.email && <ErrorLabel>{errors.email}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Teléfono de Contacto (*)'}</InputLabel>
          <Input
            value={values.tel}
            onChange={({ target: { value } }) => setFieldValue('tel', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.tel ? true : false}
          />
          {errors.tel && <ErrorLabel>{errors.tel}</ErrorLabel>}
        </InputWrapper>
      </Row>

      <Row error={errors.entrepreneurshipid}>
        <InputWrapper width={48}>
          <InputLabel>{'Emprendimiento (*)'}</InputLabel>
          <Select
            value={values.entrepreneurshipid}
            onChange={value => setFieldValue('entrepreneurshipid', value)}
            options={this.props.entrepeneurList ? this.props.entrepeneurList : null}
            placeholder="Buscar..."
            error={errors.entrepreneurshipid ? true : false}

          />
          {errors.entrepreneurshipid && <ErrorLabel>{errors.entrepreneurshipid}</ErrorLabel>}
        </InputWrapper>


        <InputWrapper width={48}>
          <InputLabel>{'Tipo Producto'}</InputLabel>
          <Select
            value={values.productType}
            onChange={value => setFieldValue('productType', value)}
            options={this.props.productTypesList ? this.props.productTypesList : null}
            placeholder="Buscar..."
            error={errors.productType ? true : false}

          />
          {errors.productType && <ErrorLabel>{errors.productType}</ErrorLabel>}
        </InputWrapper>

      </Row>


      <Row error={errors.subject || errors.message}>
        <InputWrapper width={48}>
          <InputLabel>{'Asunto (*)'}</InputLabel>
          <Input
            value={values.subject}
            onChange={({ target: { value } }) => setFieldValue('subject', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.subject ? true : false}
          />
          {errors.subject && <ErrorLabel>{errors.subject}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Mensaje'}</InputLabel>
          <Input
            value={values.message}
            onChange={({ target: { value } }) => setFieldValue('message', value)}
            placeholder={''}
            type={'text'}
            rows={4}
            error={errors.message ? true : false}
          />
          {errors.message && <ErrorLabel>{errors.message}</ErrorLabel>}
        </InputWrapper>
      </Row>
      {/*
      <Row error={errors.userAssigned}>
        <InputWrapper width={48}>
          <InputLabel>{'Asignado a'}</InputLabel>
          <Select
            value={values.userAssigned}
            onChange={value => setFieldValue('userAssigned', value)}
            options={this.props.usersList ? this.props.usersList : null}
            placeholder="Buscar..."
            error={errors.userAssigned ? true : false}

          />
          {errors.userAssigned && <ErrorLabel>{errors.userAssigned}</ErrorLabel>}
        </InputWrapper>

        <InputWrapper width={48}>
          <InputLabel>{'Estado'}</InputLabel>
          <Select
            value={values.status}
            onChange={value => setFieldValue('status', value)}
            options={this.props.estadosList ? this.props.estadosList : null}
            placeholder="Buscar..."
            error={errors.status ? true : false}

          />
          {errors.status && <ErrorLabel>{errors.status}</ErrorLabel>}
        </InputWrapper>

      </Row>

      <Row>
        <InputWrapper width={100}>
          <InputLabel>{'Historial'}</InputLabel>
          <Input
            value={values.history}
            onChange={({ target: { value } }) => setFieldValue('history', value)}
            placeholder={''}
            type={'text'}
            rows={4}
            error={errors.history ? true : false}
          />
          {errors.history && <ErrorLabel>{errors.history}</ErrorLabel>}
        </InputWrapper>
      </Row>
*/}

      {this.props.editTicketSuccess ?
        <ModalMessage success={true} onClose={() => this.closeAndReset()}> Ticket editado correctamente.</ModalMessage>
        :
        null
      }

      {this.props.editTicketFail ?
        <ModalMessage success={false} onClose={() => this.props.editTicketReset()}> Error modificando el ticket.</ModalMessage>
        :
        null
      }

      <ButtonWrapper>
        {this.props.loadingNewTicket ?
          <PageLoader />
          :
          <StyledButton type="Submit">Editar Ticket</StyledButton>
        }

      </ButtonWrapper>
    </FormContainer>
  )

  closeAndReset() {
    this.props.editTicketReset()
    this.props.onClose()
  }

  handleSubmit = async data => {

    let body = {
      'id': this.props.ticketSelected.id,
      'subject': data.subject,
      'message': data.message,
      'entrepreneurshipid': data.entrepreneurshipid.value,
      'producttypeid': data.productType.value,


      'userid': data.userAssigned ? data.userAssigned.value : null,
      'stateid': data.status ? data.status.value : null,
      'historydescription': data.history,
      'vendor': {
        'name': data.name,
        'surname': data.surname,
        'phonenumber': data.tel,
        'email': data.email
      }
    }

    await this.props.editTicket(body)
    await this.props.fetchVendorConsultas(this.props.filtrosConsultas)
  }

  getSignUpSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, 'El nombre debe tener un mínimo de 3(tres) caracteres')
        .required()
      ,
      surname: Yup.string()
        .min(2, 'El apellido debe tener un mínimo de 2(dos) caracteres')
        .required(),
      email: Yup.string()
        .email('El Email es inválido')
        .required(),
      tel: Yup.string().required(),


    })


  getInitialValues = () => ({
    name: this.props.ticketSelected.vendor.name,
    surname: this.props.ticketSelected.vendor.surname,
    tel: this.props.ticketSelected.vendor.phonenumber,
    email: this.props.ticketSelected.vendor.email,
    status: { value: this.props.ticketSelected.state.id, label: this.props.ticketSelected.state.name },
    subject: this.props.ticketSelected.subject,
    message: this.props.ticketSelected.message,
    userAssigned: this.props.ticketSelected.user ? { value: this.props.ticketSelected.user.id, label: this.props.ticketSelected.user.fullname } : null,
    productType: this.props.ticketSelected.producttype ? { value: this.props.ticketSelected.producttype.id, label: this.props.ticketSelected.producttype.name } : null,
    entrepreneurshipid: { value: this.props.ticketSelected.entrepreneurship.id, label: this.props.ticketSelected.entrepreneurship.name },
  })


  render() {

    return (
      <Modal>
        {this.props.ticketSelected.length == 0 || this.props.loadingEditTicket ?
          <PageLoader />
          :
          <Formik
            initialValues={this.getInitialValues()}
            validateOnChange={false}
            validationSchema={this.getSignUpSchema()}
            onSubmit={this.handleSubmit}
            render={e => this.getFormContent(e)}
          />
        }
      </Modal>
    )
  }
}

const mapStateToProps = ({
  userInfo, gestorConsultas
}) => ({
  userInfo,
  loadingEditTicket: gestorConsultas.loadingEditTicket,
  editTicketSuccess: gestorConsultas.editTicketSuccess,
  editTicketFail: gestorConsultas.editTicketFail,
  editTicketStart: gestorConsultas.editTicketStart,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchVendorConsultas
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTicket)
