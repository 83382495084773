import styled from 'styled-components'
import ArrowBack from '@material-ui/icons/ArrowBackIos'


export const Refresh = styled.img`
  width: 30px;
  order: 3;
  margin: 0% 4%;
  
:hover {
  cursor: pointer;
}
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 430px;
  overflow: auto;
`
export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  font-weight: 500;
`

export const MovementContainer = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 15px 0px 15px 30px;
  margin-right: 2.5%;
  margin-left: 2.5%;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.alabaster : theme.colors.transparent};
  border-top: 1px solid
    ${({ theme, selected }) => (selected ? theme.colors.mercury : theme.colors.ghost)};
  border-bottom: 1px solid
    ${({ theme, selected }) => (selected ? theme.colors.mercury : theme.colors.ghost)};
  transition: background 0.15s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.alabaster};
    border-top: 1px solid ${({ theme }) => theme.colors.mercury};
    border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  }
`

export const InfiniteLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 0;
`

export const NoContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 20px;
  padding: 20px 0;
`

export const Charge = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.oceanGreen};
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
`
export const Pay = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.pomegranate};
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
`

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
    text-align: left;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'flex-start'};
`

export const Date = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.midGray};
  font-size: 14px; 
  font-weight: 400;
  padding-bottom: 5px;
  cursor: pointer;
  margin-right: 15px;
`

export const HeaderMovementsWrapper = styled.div`
  min-height: 20px; 
  max-height: 100px;
  padding-right: 13px;
  padding-left: 13px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: nowrap;
`

export const AuthWrapper = styled.div`
  min-height: 20px;
  max-height: 60px;
  padding: 15px;
  margin-top: 15px;
  border-color: ${({ theme }) => theme.colors.border};
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

export const Arrow = styled.div`
  color: ${({ theme }) => theme.colors.blueRibbon};
  margin-left: 70px;
  transform: rotate(180deg);
  align-self: flex-start;
`

export const TitleWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  text-align: left;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  margin: 5% 0% 2% 1%;
`

export const LabelMovements = styled.div`
  text-align: center;
  text-decoration: underline;
  margin: 10px 0px;
  &:hover {
    cursor: pointer;
  }
`

export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.dark };
    background-color: ${({ theme }) => theme.colors.white };
    
    

    ${Arrow}:hover & {
        color: ${({theme})=> theme.colors.blueRibbon};
        transition:0.3s ease;  
        cursor: pointer;
    }
`