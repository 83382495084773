const theme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    red: '#FF565A',
    redOpaced: 'rgba(208, 2, 27, 0.25)',
    persimmon: '#FF565A',
    alabaster: '#F8F8F8',
    blueRibbon: '#1873f3',
    blueRibbonTransparent1: 'rgba(24,115,243,0.1)',
    blueRibbonTransparent2: 'rgba(24,115,243,0.2)',
    newGreen: '#30735F',
    transparent: 'transparent',
    dustyGray: '#9B9B9B',
    dustyGrayTransparent: 'rgba(31, 31, 31, 0.04)',
    tundora: '#4A4A4A',
    ghost: 'rgba(205, 205, 211, 0.45)',
    midGray: '#6B6B76',
    transparentBlack: 'rgba(0, 0, 0, 0.25)',
    transparentWhite: 'rgba(255, 255, 255, 0.65)',
    athensGray: '#F2F2F4',
    selago: '#E5F1FB',
    royalBlue: '#3771EB',
    disabledGray: 'rgba(120, 132, 158, 0.09)',
    mineShaft: '#2D2D2D',
    alto: '#d2d2d2',
    zumthor: '#EFF6FF',
    steelGray: '#222231',
    wildSand: '#FBFCFD',
    pomegranate: '#F44336',
    hawkesBlue: '#E3F2FD',
    waterloo: '#787993',
    mercury: '#CDCACA',
    greyBorder: 'rgba(205, 205, 211, 0.45)',
    mystic: '#DADFEA',
    warmGrey: '#808080',
    border: '#e5e5e5',
    oceanGreen: '#15994C',
    greenTransparent: 'rgba(45,199,109,0.25)',
    mariGold: '#F5A827',
    springGreen:'rgb(91, 245, 168)',
    gainsboro:'rgb(220,220,220)'
  },
  fonts: {
    roboto: `'Roboto', sans-serif`
  },
  shadows: {
    black13: 'rgba(0, 0, 0, 0.13)',
    portGore01: '0 2px 12px 0 rgba(37, 38, 94, 0.1)'
    
  },
  spacing: {
    unit: '10px',
    unit2: '20px',
    unit3: '30px',
    unit4: '40px',
  }
}

export default theme
