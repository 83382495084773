import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import Publicaciones from "./publicaciones/index"
import Despachado from "./despachado/index"
import Mensajes from "./mensajes/index"
import Ventas from "./ventas/index"
import publicationimg from "assets/icons/publication.svg"
import ventasimg from "assets/icons/ventasimg.svg"
import despachadoimg from "assets/icons/despachadoimg.svg"
import mensajes from "assets/icons/mensajes.svg"

import { AccessEntry, BigButton, Text, PngImage } from './styled'

class HomeShops extends React.Component {
  state = {
    publicacionesOpen: false,
    mensajesOpen: false,

    mensajeriaForm: false,
    registrosForm: false,
  }

  renderPageContent = () => (
    <>
      <AccessEntry>
        <BigButton onClick={this.togglePublicaciones}>
          <PngImage src={publicationimg} />
          <Text>
            Publicaciones
          </Text>
        </BigButton>
        <BigButton onClick={this.toggleVentas}>
          <PngImage src={ventasimg} />
          <Text>
            Ventas
          </Text>
        </BigButton>
        <BigButton onClick={this.toggleMensajeria}>
          <PngImage src={mensajes} />
          <Text>
            Mensajes
          </Text>
        </BigButton>
      </AccessEntry>
    </>
  )

  toggleRegistros = () => {
    this.setState({
      registrosForm: !this.state.registrosForm
    })
  }

  toggleMensajeria = () => {
    this.setState({
      mensajesOpen: !this.state.mensajesOpen
    })
  }

  toggleSuccesModal = (modalContent) => {
    this.setState({
      succesModal: !this.state.succesModal,
      modalContent,
      failModal: false,
    })
  }

  closeAllModals = () => {
    this.setState({
      failModal: false,
      succesModal: false,
      showSteps: false
    })
  }

  onSuccess = () => {
    this.setState({
      scanQRModal: false,
      newGuestForm: false,
      newExit: false,
      succesModal: false,
      editMode: false,
      showSteps: false
    })
  }

  toggleFailModal = (modalContent) => {
    this.setState({
      failModal: !this.state.failModal,
      modalContent,
      succesModal: false,
    })
  }

  toggleVentas = () => {
    this.setState({ ventasOpen: !this.state.ventasOpen })
  }

  togglePublicaciones = () => {
    this.setState({ publicacionesOpen: !this.state.publicacionesOpen })
  }

  toggleDespachados = () => {
    this.setState({ despachosOpen: !this.state.despachosOpen })
  }

  togglePutsModalDetails = () => {
    this.setState({
      putsDetailsModal: !this.state.putsDetailsModal
    })
  }

  isLoadingContent = () => this.props.loading;

  componentDidUpdate(prevProps) {
    if (this.props.loadingPuts && !prevProps.loadingPuts) {
      this.togglePutsModalDetails();
    }
  }

  render() {
    const { mensajesOpen, despachosOpen, ventasOpen, publicacionesOpen } = this.state;
    if (publicacionesOpen === true) {
      return (
        <Publicaciones
          onClose={this.togglePublicaciones}
        />
      )

    } else if (mensajesOpen) {
      return (
        <Mensajes
          onClose={this.toggleMensajeria}
        />
      )
    } else if (despachosOpen) {
      return (
        <Despachado
          onClose={this.toggleDespachados}
        />
      )
    } else if (ventasOpen) {
      return (
        <Ventas
          onClose={this.toggleVentas}
          showMensajes={this.toggleMensajeria}
        />
      )
    } else {
      return (
        <>{this.renderPageContent()}</>
      )

    }
  }
}


const mapStateToProps = ({

}) => ({


})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(compose()(HomeShops))