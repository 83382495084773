import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { convertFileInBase64 } from 'utils/fileManager'
import * as Yup from 'yup'
import { Formik } from 'formik'
import CrossIcon from 'assets/icons/cross-blue.png'
import FileIcon from 'assets/icons/file.jpg'
import FileInput from 'components/FileInput'
import { importProprietary } from 'store/actions/padron.actions'
import PageLoader from 'components/PageLoader'
import { Base64 } from 'js-base64';
import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
} from './styled'
import Modal from 'components/Modal'
import {readFile} from "utils/fileManager"

class UploadTyC extends React.Component {
  state = {
    url: this.props.value,
    errorFile: false,
    loadingFile: false
  }

  getInitialValues = () => ({
  })

  getSignUpSchema = () =>
    Yup.object().shape({
      file: Yup.string().required('Campo requerido')
    })

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) this.setState({ url: this.props.value })
  }

  hasAttachedContent = () => this.state.url

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row> <InputLabel>{'Subir terminos y condiciones(.pdf)'}</InputLabel> </Row>
      <Row>
        <InputWrapper width={30}>
            <FileInput image={FileIcon} name="file" value={values.file} onChange={file => setFieldValue('file', file)} pdf={true} />
            {/* {errors.importFile && <ErrorLabel>{errors.importFile}</ErrorLabel>} */}

            {/* <FileInput onChange={file => setFieldValue('file', file)} pdf={true}/>
            {errors.headerImage && <ErrorLabel>{errors.headerImage}</ErrorLabel>} */}
        </InputWrapper>
      </Row>
      {this.props.error || this.state.errorFile ? (
        <ButtonWrapper>
          <ErrorLabel>Error al cargar el archivo, verifique si es el correcto</ErrorLabel>
        </ButtonWrapper>
      ): (<> </>)}
      {this.props.loading   ?
        (
          <ButtonWrapper>
            <PageLoader customHeight={"50"}/>
          </ButtonWrapper>
        ) :
        (
          <ButtonWrapper>
            <StyledButton type={'submit'}>SUBIR</StyledButton>
          </ButtonWrapper>
        )
      }
    </FormContainer>
    )


    handleSubmit = async data => {
        this.setState({loadingFile: true})
        try{
          console.log("DATA",data);
          console.log("EXTENSION",data.file.extension);
          const body = {
            Imagebase64:window.btoa(unescape(encodeURIComponent( data.file ))),
            // Extension:,
            ActivityId:this.props.activityId
          }
          await this.props.onChange(body)
        //   if (!this.props.error) this.props.onOpenResult(true)
          if (!this.props.error) this.props.onClose(true)
        //   if (!this.props.error) this.props.openPeriod(0)
        }catch(e){
          this.setState({errorFile: true})
        }
      }



  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

export default UploadTyC