import React from 'react'
import { Container, Row, Title, Subtitle, ContainerElement } from './styled'
import { Avatar } from '@material-ui/core'

const formatContent = content => content ? (content.length > 20 ? `${content.substring(0, 20)}...` : content) : null

const ResourcePeople = ({
    name,
    onClick,
    lote,
    selected,
    avatar    
}) => (
  <Container onClick={onClick} selected={selected}>
      <Row>
        <ContainerElement width={'20%'}>
            <Avatar  src={avatar} style={{ width: '45px', height: '45px' }} ></Avatar>
        </ContainerElement>
        <ContainerElement margin={'3%'}>
            <Row justify={'flex-start'}>
                <Title>{formatContent(name)}</Title>
            </Row>
            { lote == undefined ?
                <Row margin={'10px 0px'}> 
                    <Subtitle>{formatContent(lote)}</Subtitle>
                </Row>
            : 
                <></>
            }
        </ContainerElement>
      </Row>
  </Container>
)

export default ResourcePeople
