import * as actionTypes from './actionTypes'
import getApi from 'shared/api'

import {
  fetchGetGuestAPI,
  fetchGetVehicleBrandsAPI,
  fetchMensajeriaAPI,
  fetchGetVehicleModelsAPI,
  fetchLotsAPI, 
  fetchNotificationsAPI, 
  fetchRegistrosAPI
} 
from 'apiConstants'

export const fetchGetGuestSuccess = guest => {
  return {
    type: actionTypes.FETCH_GET_GUEST_SUCCESS,
    getGuestInvitation: guest
  }
}
  
export const fetchGetGuestFail = error => {
  return {
    type: actionTypes.FETCH_GET_GUEST_FAIL,
    error: error
  }
}
  
export const fetchGetGuestStart = () => {
  return {
    type: actionTypes.FETCH_GET_GUEST_START
  }
}

export const clearAccessStore = () => {
  return {
    type: actionTypes.CLEAR_ACCESS_STORE
  }
}

export const fetchVehicleStart = () => {
  return {
    type: actionTypes.FETCH_VEHICLES_BRAND_START
  }
}

export const fetchVehicleSuccess = vehicles => {
  return {
    type: actionTypes.FETCH_VEHICLES_BRAND_SUCCESS,
    vehicles: vehicles
  }
}

export const fetchVehicleFail = error => {
  return {
    type: actionTypes.FETCH_VEHICLES_BRAND_FAIL,
    error: error
  }
}
  
export const fetchVehicleModelStart = () => {
  return {
    type: actionTypes.FETCH_VEHICLES_MODEL_START
  }
}

export const fetchVehicleModelSuccess = models => {
  return {
    type: actionTypes.FETCH_VEHICLES_MODEL_SUCCESS,
    vehicles_model: models
  }
}

export const fetchVehicleModelFail = error => {
  return {
    type: actionTypes.FETCH_VEHICLES_MODEL_FAIL,
    error: error
  }
}

export const fetchGetGuest = id => {
  return async (dispatch, getState) => {
    dispatch(fetchGetGuestStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid,true)
    return api
    .get(`${fetchGetGuestAPI}${id}`)
    .then(res => {
      dispatch(fetchGetGuestSuccess(res.data))
    })
    .catch(err => {
      dispatch(fetchGetGuestFail(err.response.data.message))
    })
  }
}
///////////
export const fetchPostInviteSuccess = postInviteSuccess => {
  return {
    type: actionTypes.FETCH_POST_INVITE_SUCCESS,
    postInviteSuccess: postInviteSuccess,
  }
}

export const fetchPostInviteFail = error => {
  return {
    type: actionTypes.FETCH_POST_INVITE_FAIL,
    error: error
  }
}

export const fetchPostInviteStart = () => {
  return {
    type: actionTypes.FETCH_POST_INVITE_START
  }
}
  
// NUEVO INVITADO
export const fetchPostInvite = (body) => {
  return async (dispatch, getState) => {
    dispatch(fetchPostInviteStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
    .post(`${fetchGetGuestAPI}`,body) 
    .then(res => {
      dispatch(fetchPostInviteSuccess(res.data))
      dispatch(fetchCheckIn(res.data.invitation_id))
    })
    .catch(error => {
      dispatch(fetchPostInviteFail(error.response.data.message))
    })
  }
}

/////////// 

export const fetchPutInviteSuccess = (PutInviteSuccess, segment) => {
  return {
    type: actionTypes.FETCH_PUT_INVITE_SUCCESS,
    putInviteSuccess: PutInviteSuccess,
    segment
  }
}

export const fetchPutInviteFail = (error, segment) => {
  return {
    type: actionTypes.FETCH_PUT_INVITE_FAIL,
    error,
    segment
  }
}

export const fetchPutInviteStart = (segment) => {
  return {
    type: actionTypes.FETCH_PUT_INVITE_START,
    segment
  }
}

// INVITADO ACTUALIZAR DATOS
export const fetchPostInviteUpdate = (body, inviteId, segment) => {
  return async (dispatch, getState) => {
    dispatch(fetchPutInviteStart(segment))
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
    .put(`${fetchGetGuestAPI}${inviteId}/${segment}`, body) 
    .then(res => {
      dispatch(fetchPutInviteSuccess(res.data, segment))
    })
    .catch(error => {
      dispatch(fetchPutInviteFail(error.response.data.message, segment))
    })
  }
}
  
export const fetchCheckInSuccess = guest => {
  return {
    type: actionTypes.FETCH_CHECK_IN_SUCCESS,
    GuestInvitation: guest
  }
}

export const fetchCheckInFail = error => {
  return {
    type: actionTypes.FETCH_CHECK_IN_FAIL,
    error: error
  }
}

export const fetchCheckInStart = () => {
  return {
    type: actionTypes.FETCH_CHECK_IN_START
  }
}

//Copia algo asi para completar/editar
export const fetchCheckIn = (invitation_id,body) => {
  return async (dispatch, getState) => {
    dispatch(fetchCheckInStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
    .put(`${fetchGetGuestAPI}${invitation_id}/in`,body ? body : "") 
    .then(res => {
      dispatch(fetchCheckInSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchCheckInFail(error.response.data.message))
    })
  }
}


///////////

export const fetchCheckOutSuccess = guest => {
  return {
    type: actionTypes.FETCH_CHECK_OUT_SUCCESS,
    checkOut: guest
  }
}

export const fetchCheckOutFail = error => {
  return {
    type: actionTypes.FETCH_CHECK_OUT_FAIL,
    error: error
  }
}

export const fetchCheckOutStart = () => {
  return {
    type: actionTypes.FETCH_CHECK_OUT_START
  }
}

//Copia algo asi para completar/editar
export const fetchCheckOut = (invitation_id) => {
  return async (dispatch, getState) => {
    dispatch(fetchCheckOutStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
    .put(`${fetchGetGuestAPI}${invitation_id}/out`) 
    .then(res => {
      dispatch(fetchCheckOutSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchCheckOutFail(error.response.data.message))
    })
  }
}
///////////

export const fetchSearchInviteDniSuccess = searchedInvites => {
  return {
    type: actionTypes.FETCH_SEARCH_INVITES_SUCCESS,
    searchedInvites: searchedInvites
  }
}

export const fetchSearchInviteDniFail = error => {
  return {
    type: actionTypes.FETCH_SEARCH_INVITES_FAIL,
    error: error
  }
}

export const fetchSearchInviteDniStart = () => {
  return {
    type: actionTypes.FETCH_SEARCH_INVITES_START
  }
}

export const clearSearchedInvites = () => {
  return {
    type: actionTypes.CLEAR_SEARCHED_INVITES
  }
}


//Copia algo asi para completar/editar
export const fetchSearchInviteDni = (dni) => {
  return async (dispatch, getState) => {
    dispatch(fetchSearchInviteDniStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
    .get(`${fetchGetGuestAPI}search-invites-in?dni=${dni}`) 
    .then(res => {
      dispatch(fetchSearchInviteDniSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchSearchInviteDniFail(error.response.data.message))
    })
  }
}

// Get vehicle brands
export const fetchGetVehicleBrands = () => {
  return async (dispatch, getState) => {
    dispatch(fetchVehicleStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
    .get(fetchGetVehicleBrandsAPI)
    .then(response => {
      dispatch( fetchVehicleSuccess({
        values: response.data
      }))
    })
    .catch(err => {
      dispatch(fetchVehicleFail(err))
    })
  }
}

// Get vehicle models
export const fetchGetVehicleModels = (brandId) => {
  return async (dispatch, getState) => {
    dispatch(fetchVehicleModelStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
    .get(fetchGetVehicleModelsAPI+brandId)
    .then(response => {
      dispatch( fetchVehicleModelSuccess({
        values: response.data
      }))
    })
    .catch(err => {
      dispatch(fetchVehicleModelFail(err))
    })
  }
}


///////////

export const fetchLotsSuccess = lots => {
  return {
    type: actionTypes.FETCH_LOTS_SUCCESS,
    lots: lots
  }
}

export const fetchLotsFail = error => {
  return {
    type: actionTypes.FETCH_LOTS_FAIL,
    errorLots: error
  }
}

export const fetchLotsStart = () => {
  return {
    type: actionTypes.FETCH_LOTS_START
  }
}

//Copia algo asi para completar/editar
export const fetchLots = (barrio) => {
  return async (dispatch, getState) => {
    dispatch(fetchLotsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
    .get(`${fetchLotsAPI}`) 
    .then(res => {
      dispatch(fetchLotsSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchLotsFail(error.response.data.message))
    })
  }
}





/////////// Action para Notificaciones 

export const fetchNotificationsSuccess = notificacion => {
  return {
    type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
    notificacion: notificacion
  }
}

export const fetchNotificationsFail = error => {
  return {
    type: actionTypes.FETCH_NOTIFICATIONS_FAIL,
    errorNotif: error
  }
}

export const fetchNotificationsStart = () => {
  return {
    type: actionTypes.FETCH_NOTIFICATIONS_START
  }
}

export const fetchNotifications = (notificacion) => {
  return async (dispatch, getState) => {
    dispatch(fetchNotificationsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
    .post(`${fetchNotificationsAPI}`,notificacion) 
    .then(res => {
      dispatch(fetchNotificationsSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchNotificationsFail(error.response.data.message))
    })
  }
}


/////////// ACCESOS - MENSAJERIA

export const fetchMensajeriaSuccess = mensajeria => {
  return {
    type: actionTypes.FETCH_MENSAJERIA_SUCCESS,
    mensajeria: mensajeria
  }
}

export const fetchMensajeriaFail = error => {
  return {
    type: actionTypes.FETCH_MENSAJERIA_FAIL,
    errorMensajeria : error
  }
}

export const fetchMensajeriaStart = () => {
  return {
    type: actionTypes.FETCH_MENSAJERIA_START
  }
}

export const fetchMensajeria = (mensajeria) => {
  return async (dispatch, getState) => {
    dispatch(fetchMensajeriaStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
    .get(`${fetchMensajeriaAPI}`,mensajeria)
    .then(res => {
      dispatch(fetchMensajeriaSuccess(res.data))
    })
    .catch(error => {
      //dispatch(fetchMensajeriaFail(error.response.data.message))
      dispatch(fetchMensajeriaFail('ERROR TODO -- '))
    })
  }
}



/////////// ACCESOS - registros

export const fetchRegistrosSuccess = registros => {
  return {
    type: actionTypes.FETCH_REGISTROS_SUCCESS,
    registros: registros
  }
}

export const fetchRegistrosFail = error => {
  return {
    type: actionTypes.FETCH_REGISTROS_FAIL,
    errorRegistro : error
  }
}

export const fetchRegistrosStart = () => {
  return {
    type: actionTypes.FETCH_REGISTROS_START
  }
}

export const fetchRegistros = (registros) => {
  return async (dispatch, getState) => {
    dispatch(fetchRegistrosStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
    .get(`${fetchRegistrosAPI}`,registros)
    .then(res => {
      dispatch(fetchRegistrosSuccess(res.data))
    })
    .catch(error => {
      //dispatch(fetchRegistrosFail(error.response.data.message))
      dispatch(fetchRegistrosFail('ERROR TODO -- '))
    })
  }
}