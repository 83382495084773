import styled, { css } from 'styled-components'

export const DetailContainer = styled.div`
  width: 230px;
  padding: 10px 15px;
  position: absolute;
  
  display: ${(props) => props.display ? props.display : 'flex'};  


  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  outline: none;
  border: none;
  z-index: 999;
  cursor: auto;

  ${({ bottomPopUp }) =>
    bottomPopUp
      ? css`
          bottom: 15px;
        `
      : css`
          top: 15px;
        `}

  ${({ leftPopUp }) =>
    leftPopUp
      ? css`
          right: 15px;
          border-right: 5px solid ${({ theme }) => theme.colors.blueRibbon};
        `
      : css`
          left: 15px;
          border-left: 5px solid ${({ theme }) => theme.colors.blueRibbon};
        `}
`

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const Title = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0px'};  
`

export const Name = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.tundora};
  font-size: 14px;
  margin-top: ${(props) => props.margintop ? props.margintop : '10px'};
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0px'};  
`

export const CancelButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  background-color: ${({ theme }) => theme.colors.redOpaced};
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
`

export const ErrorLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 12px;
  margin-top: 5px;
`

export const SaveButton = styled.button`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  width: ${(props) => props.width ? props.width : '50%'};
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
`

export const Close = styled.img`
  width: 10px;
  cursor: pointer;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ButtonWrapperBooking = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
   margin: 5% 0% 0% 32%;
`

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  align-items: center;
`
export const StyledButtonBooking = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  align-items: center;
`

export const CrossImage = styled.img`
width: 15px;
    height: 15px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

`



export const TitleModal = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0px'};  
`

export const StyledLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  height: 35px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
`
export const StyledLabelBooking = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-weight: 400;
  height: 35px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
  width:  ${({ width }) => width ? width : '100%'};
`

export const SelectWrapper = styled.div`
  width: ${({ width }) => width ? width : '35%'};
  max-width: ${({ width }) => width ? width : '35%'};
  margin: ${({ margin }) => margin ? margin : '0% 2%'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: ${({ flexGrow }) => flexGrow ? flexGrow : '0'};
`

