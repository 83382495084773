import React from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross.png'
import CircularProgress from '@material-ui/core/CircularProgress'
import EmptyLoader from 'components/PageLoader'
import Input from 'components/Input'
import PageLoader from 'components/PageLoader';
import TextArea from 'components/TextArea'
import FileIcon from 'assets/icons/file.jpg'
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import CheckBox from 'components/CheckboxFormik'

import FileImageInput from 'components/FileImageInput'
import FileInputRaw from 'components/FileInputRaw';
import {
  FormContainer,
  Row,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  FlexWrapper,
  Title,
  ResetIcon,
  ProgressBarWrapper,
  RadioBtnContainer,
  LabelCheckbox,
  LabelChecked
} from './styled'

class MovieModal extends React.Component {
  state = {
    isPublic: this.props.detailStory ? this.props.detailStory.isPublic : false
  }

  getInitialValues = () => ({
    id: this.props.detailMovie ? this.props.detailMovie.id : null,
    title: this.props.detailMovie ? this.props.detailMovie.title : '',
    description: this.props.detailMovie ? this.props.detailMovie.description : '',
    movieImage: this.props.detailMovie ? this.props.detailMovie.imageUrl : '',
    movieUrl: this.props.detailMovie ? this.props.detailMovie.movieUrl : '',
    movieRaw: null,
    order: this.props.detailMovie ? this.props.detailMovie.order : 1,
    isEdit: this.props.detailMovie ? true : false,
    isPublic: this.props.detailMovie ? this.props.detailMovie.isPublic : false
  })

  getSignUpSchema = () =>
  this.props.detailMovie ?
  Yup.lazy(values =>
    Yup.object().shape({
      title: Yup.string().required('Ingrese un titulo'),
      description: Yup.string().required('Ingrese una descripción'),
      movieUrl: Yup.string().required('Seleccione un archivo'),
      movieImage: Yup.string().required('Seleccione una imagen'),
      order: Yup.number().moreThan(0,'El orden debe ser un número mayor a 0').required('Ingrese un orden de prioridad'),
      movieRaw: Yup.mixed().test(
        'format-validation',
        'Debe ser un archivo de formato MP4 o MKV',
        value => {
          if (value == null)
            return true;
          var re = /(?:\.([^.]+))?$/;
          var ext = re.exec(value.name)[1];
          if (ext != null){
            return (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv'); 
          }
          return false;
        }
      )
    })
  )
  : Yup.lazy(values =>
    Yup.object().shape({
      title: Yup.string().required('Ingrese un titulo'),
      description: Yup.string().required('Ingrese una descripción'),
      movieUrl: Yup.string(),
      movieImage: Yup.string().required('Seleccione una imagen'),
      order: Yup.number().moreThan(0,'El orden debe ser un número mayor a 0').required('Ingrese un orden de prioridad'),
      movieRaw: Yup.mixed().required('Seleccione un archivo').test(
        'format-validation',
        'Debe ser un archivo de formato MP4 o MKV',
        value => {
          if (value == null)
            return false;
          var re = /(?:\.([^.]+))?$/;
          var ext = re.exec(value.name)[1];
          if (ext != null){
            return (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv'); 
          }
          return false;
        }
      )
    })
  );
    

  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />

      <FlexWrapper>
        <Title>{this.props.tittle}</Title>
      </FlexWrapper>
      <FlexWrapper>
        <FlexWrapper width={70}>
          <InputWrapper width={70}>
            <InputLabel>{'Titulo'}</InputLabel>
            <Input
              name={'titleInput'}
              label={'titleInput'}
              placeholder={'Ingrese un titulo...'}
              value={values.title}
              error={errors.title}
              onChange={({ target: value }) => setFieldValue('title', value.value)}
              margin="normal"
              disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
            />
            {errors.title && <ErrorLabel>{errors.title}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper width={30}>
            <InputLabel>{'Orden'}</InputLabel>
            <Input
              type="number"
              name={'orderInput'}
              label={'orderInput'}
              placeholder={'Ingrese el orden de prioridad...'}
              value={values.order}
              error={errors.order}
              onChange={({ target: value }) => setFieldValue('order', value.value)}
              margin="normal"
              disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
            />
            {errors.order && <ErrorLabel>{errors.order}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>{'Descripción'}</InputLabel>
            <TextArea
              minWidth="0"
              maxWidth="1024"
              minHeight="110"
              maxHeight="110"
              name={'descriptionInput'}
              label={'descriptionInput'}
              placeholder={'Ingrese una descripción...'}
              value={values.description}
              error={errors.description}
              onChange={({ target: value }) => setFieldValue('description', value.value)}
              margin="normal"
              disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
            ></TextArea>
            {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
          </InputWrapper>
        </FlexWrapper>
        <FlexWrapper width={30}>
          <InputWrapper>
            <InputLabel>{'Imagen'}</InputLabel>
            <FileImageInput width="100%"
             disabled={this.props.isReadOnly ? this.props.isReadOnly : false} 
             value={values.movieImage}
             onChange={(file) => setFieldValue('movieImage', file)} />
            {errors.movieImage && <ErrorLabel textAlign="center">{errors.movieImage}</ErrorLabel>}
          </InputWrapper>
        </FlexWrapper>
        <RadioBtnContainer width={30} >
            <LabelChecked checked={this.state.isPublic}>
            <Field
              component={CheckBox}
              name="isPublic"
              id="isPublic"
            />
            <LabelCheckbox>
            Es publico

            </LabelCheckbox>
          </LabelChecked>
        </RadioBtnContainer>
      </FlexWrapper>
      <FlexWrapper align="flex-end" hidden={!this.props.detailMovie}>
        <InputWrapper>
          <InputLabel>{'Url video actual'}</InputLabel>
          <Input
            name={'movieUrlInput'}
            label={'movieUrlInput'}
            value={values.movieUrl}
            error={errors.movieUrl}
            onChange={({ target: value }) => setFieldValue('movieUrl', value.value)}
            margin="normal"
            disabled={true}
          />
        </InputWrapper>
      </FlexWrapper>
      <FlexWrapper align="flex-end" hidden={this.props.isReadOnly}>
        <InputWrapper width={30}>
          <InputLabel>{this.props.detailMovie ? 'Reemplazar video' : 'Video'}</InputLabel>
          <FileInputRaw name="movieRaw" value={values.movieRaw} image={FileIcon} onChange={file => setFieldValue('movieRaw', file)} isReplace={this.props.detailMovie}/>
          {errors.movieRaw && <ErrorLabel>{errors.movieRaw}</ErrorLabel>}
          {errors.movieUrl && <ErrorLabel>{errors.movieUrl}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={70}>
          <ProgressBarWrapper opacity={this.props.uploadProgress > 0 ? 1 : 0}>
            <Progress percent={this.props.uploadProgress} />
          </ProgressBarWrapper>
        </InputWrapper>
      </FlexWrapper>
      <FlexWrapper>
        <ButtonWrapper>
          <StyledButton type={'submit'} disabled={this.props.isSubmittingMovie} hidden={this.props.isReadOnly}>
            {this.props.isSubmittingMovie ? (
              <CircularProgress size={18} />
            ) : (
                'Publicar'
              )}
          </StyledButton>
        </ButtonWrapper>
        </FlexWrapper>
    </FormContainer>
  )


  render() {
    return (
      <Modal>
        {this.props.loading ? (
          <PageLoader />
        ) : (
            <Formik
              initialValues={this.getInitialValues()}
              validateOnChange={false}
              validationSchema={this.getSignUpSchema()}
              onSubmit={this.props.onSubmit}
              render={e => this.getFormContent(e)}
            />
          )}
      </Modal>
    )
  }
}
export default MovieModal;
