import React from 'react'
import { ContentWrapper,WrapperItems,RowItem,Description, Label, Container,CloseIcon, Row, TableContainer,Circle,ColumnItem,StyledButton,ErrorLabel } from './styled'
import MaterialTable from 'material-table'
import Section from 'components/Section'
import arrowDown from 'assets/icons/arrow-bottom.png'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {fetchGetTransferPending, fetchApproveTransaction,fetchDeniedTransaction} from 'store/actions/billetera.actions'
import Spinner from 'react-spinner-material'
import mapTransferPending from 'selectors/mapTransferPending'
import NewTokenRequest from 'components/AuthPopUp';
import {fetchReSendToken} from 'store/actions/billetera.actions'
import cross from "assets/icons/cross.png"
import Modal from 'components/Modal'
import PageLoader from 'components/PageLoader';

class AuthMovements extends React.Component {
    state = {
        transferSelected: [],
        error: "",
        newResultModal: false,
    }
    
    componentDidMount() {
        this.props.fetchGetTransferPending()
    }

    
  componentDidUpdate(prevProps) {
    if(this.props.billetera.ApproveTransaction !== prevProps.billetera.ApproveTransaction){
        this.props.fetchGetTransferPending() 
    }else if(this.props.billetera.DeniedTransaction !== prevProps.billetera.DeniedTransaction){
        this.props.fetchGetTransferPending() 
    }
  }

    handleTransferSelected = (selected) => {
        if(selected.length > 1){
            let arrayTransaction = []
            selected.map(row =>{ 
                arrayTransaction.push(row.tranfer_request_id)
            })
            this.setState({ transferSelected: arrayTransaction})
        }else{
            if(selected.length === 0){ 
                this.setState({ transferSelected: []}) 
            }else{
                let arrayFormat= [selected[0].tranfer_request_id]
                this.setState({ transferSelected:  arrayFormat}) 
            }
        }
    }
      
      confirmTransaction(token){
        let TransferBody= { 
            TransfersId: this.state.transferSelected,
            Token: token
        }
        if(this.state.action === 'approve'){
            this.props.fetchApproveTransaction(TransferBody)
            this.setState({ newResultModal: true })
            this.props.fetchGetTransferPending()
        }else{
            this.props.fetchDeniedTransaction(TransferBody)
            this.setState({ newResultModal: true })
            this.props.fetchGetTransferPending()
        }
      }

      toggleNewTokenRequest = (action) => {
        let TransferToApprove= { 
            TransfersId: this.state.transferSelected,
            Token: ''
        }
        if(this.state.transferSelected.length === 0){
            this.setState({ error: "Debe seleccionar una transferencia" })
        }else{
            this.props.fetchApproveTransaction(TransferToApprove)
            this.setState({ newTokenRequest: !this.state.newTokenRequest,action: action,error: "" })
        }
      }

      toggleNewToken = (action) => {
        this.setState({newResultModal: false, newTokenRequest: !this.state.newTokenRequest,action: action })
      }
      toggleNewResultModal = (action) => {
        this.setState({ newResultModal: !this.state.newResultModal })
      }

      newModal = (error) => {
        if(this.state.newResultModal){
            return (
                <Modal>
                    <Container>
                    <CloseIcon src={cross} onClick={this.props.onClose}/>  
                    {this.props.billetera.errorApproveTransactionMessage !== false ? (
                        <WrapperItems>
                            <RowItem>
                                <Label bold={true}> Resultado: </Label> 
                            </RowItem>
                            <RowItem>
                                <Description bold={true}>{this.props.billetera.errorApproveTransactionMessage}</Description>
                            </RowItem>
                        </WrapperItems>
                    ) : 
                    <>{this.props.ApproveTransfer.length != 0 ? (
                        <WrapperItems>
                            <RowItem>
                                <Label bold={true}> Resultado: </Label> 
                            </RowItem>
                            {this.props.ApproveTransfer.map((transfer)=> 
                                    <RowItem>
                                        <Description >ID: {transfer.transferId}</Description>
                                        <Description bold={true}>{transfer.message}</Description>
                                    </RowItem>
                            )}
                        </WrapperItems>
                    ) : (
                        <PageLoader/>
                    ) }
                    </>}
                    </Container>
                </Modal>
            )
        }else if(this.state.newTokenRequest){
            return (
                <NewTokenRequest 
                    onClose={this.toggleNewToken } 
                    chargeToken={(e) => this.confirmTransaction(e) } 
                    fetchReSendToken={this.props.fetchReSendToken}
                />
            )
        }
      }

      renderTableTransferPending = () => {
        if(this.props.TransferRequestFormatted){
            return (
                <TableContainer>
                    <MaterialTable
                        columns={[
                            { 
                                title: 'Fecha de pago', 
                                field: 'datePay', 
                                headerStyle:{margin: '0px', padding: '0px'}, 
                                cellStyle: {
                                    padding: "0%"
                                } 
                            },
                            { 
                                title: 'Alias', 
                                field: 'alias', 
                                headerStyle:{margin: '0px', padding: '0px'}, 
                                cellStyle: {
                                    padding: "0%"
                                } 
                            },
                            { 
                                title: 'Categoría', 
                                field: 'category', 
                                headerStyle:{margin: '0px', padding: '0px'}, 
                                cellStyle: {
                                    padding: "0%"
                                }   
                            },
                            { 
                                title: 'Origen', 
                                field: 'origin', 
                                headerStyle:{margin: '0px', padding: '0px'}, 
                                cellStyle: {
                                    padding: "0%"
                                } 
                            },
                            { 
                                title: 'Destinatario', 
                                field: 'destination', 
                                headerStyle:{margin: '0px',padding: '0px'}, 
                                cellStyle: {
                                    padding: "0%"
                                }  
                            },
                            { 
                                title: 'Fecha de solicitud', 
                                field: 'dateRequest', 
                                headerStyle:{margin: '0px', padding: '0px'}, 
                                cellStyle: {
                                    padding: "0%"
                                } 
                            },
                            { 
                                title: 'Importe', field: 'amount', type: 'currency',
                                headerStyle:{margin: '0px', padding: '0px' },
                                currencySetting: {currencyCode: "ARS"}, 
                                cellStyle: {
                                    padding: "0%"
                                }
                            }
                        ]}
                        data={this.props.TransferRequestFormatted}        
                        onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        detailPanel={[
                        {
                            icon: () => (<img src={arrowDown} />),
                            tooltip: 'Ver detalles',
                            render: rowData => {
                                return (
                                <Row>
                                    <Label>Creada por:</Label><Label bold={true}> {rowData.origin} </Label> 
                                    <Label>Autorizantes:</Label>
                                    {(() => {
                                       switch(rowData.status) {
                                       case 'PEND_ADMIN':
                                           return (
                                           <>
                                           <Circle status={'pendiente'}/>
                                           <Label bold={true}> Administrador </Label> 
                                           <Circle status={'pendiente'}/>
                                           <Label bold={true}> Operador </Label>
                                           <Circle status={'pendiente'}/>
                                           <Label bold={true}> Aprobador </Label> 
                                           </>
                                           )
                                       case 'PEND_OPER':
                                           return (
                                               <>
                                               <Circle status={'aprobado'}/>
                                               <Label bold={true}> Administrador </Label> 
                                               <Circle status={'pendiente'}/>
                                               <Label bold={true}> Operador </Label>
                                               <Circle status={'pendiente'}/>
                                               <Label bold={true}> Aprobador </Label> 
                                               </>
                                               )
                                       case 'PEND_APPR':
                                           return (
                                               <>
                                               <Circle status={'aprobado'}/>
                                               <Label bold={true}> Administrador </Label> 
                                               <Circle status={'aprobado'}/>
                                               <Label bold={true}> Operador </Label>
                                               <Circle status={'pendiente'}/>
                                               <Label bold={true}> Aprobador </Label> 
                                               </>
                                               )
                                       default:
                                           return null;
                                       }
                                   })()}
                                </Row>
                                )
                            },
                        }]}
                        onSelectionChange={(rows) => this.handleTransferSelected( rows )}
                        style={{padding: "0%"}}
                        options={{
                            toolbar: false,
                            paging: false,
                            height: '300px',
                            selection: true,
                            headerStyle: {
                                zIndex: 0
                            } ,
                            rowStyle: {
                                padding: '0%',
                              }
                        }} //Esconde titulo y search y pagination 
                    />
                </TableContainer> 
            )
        }else{
        return( 
            <TableContainer>
                <Spinner /> 
            </TableContainer>
        )}
      }
      

    render() {
        return (
            <Section 
                title={'Transferencias pendientes de autorizacíon'} 
                onBack={() => this.props.onClose() } 
            >
                {this.newModal()}
                <ContentWrapper  >
                   {this.renderTableTransferPending()}
                    <Row justify={'flex-end'} >
                        <StyledButton color={true} onClick={() => this.toggleNewTokenRequest('denied')}> Rechazar</StyledButton>
                        <StyledButton onClick={() => this.toggleNewTokenRequest('approve')}>Autorizar</StyledButton>
                    </Row>
                    <Row margin={'10px 0px'} justify={'flex-end'} >
                        <ErrorLabel>
                            {this.state.error.length > 0 ? (this.state.error) : (null)}
                        </ErrorLabel>

                        <ErrorLabel>{this.props.DeniedTransfer.length > 0 ? (this.props.DeniedTransfer[0].message) : (null)}</ErrorLabel>
                    </Row>
                </ContentWrapper>
            </Section>
        )
      }
}

const mapStateToProps  = state => {
    return {
        TransferRequestFormatted: mapTransferPending(state.billetera.TransferPending),
        ApproveTransfer: state.billetera.ApproveTransaction ? state.billetera.ApproveTransaction : [] ,
        DeniedTransfer: state.billetera.DeniedTransaction ? state.billetera.DeniedTransaction : [] ,
        billetera: state.billetera
    }
  }
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        fetchGetTransferPending,
        fetchApproveTransaction,
        fetchDeniedTransaction,
        fetchReSendToken
      },
      dispatch
    )
  
  export default connect(
      mapStateToProps,
      mapDispatchToProps
  )(AuthMovements)

