import React from 'react'
import styled from 'styled-components'

const DeleteIcon = props => (
  <svg
    className="prefix__MuiSvgIcon-root-1015 prefix__sc-eIHaNI prefix__bWRgyL"
    viewBox="0 0 24 24"
    aria-hidden="true"
    {...props}
  >
   <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
  </svg>
)


export const DeleteIconStyled = styled(DeleteIcon)`
  fill-opacity: ${({ translucent }) =>  translucent || '1'};
  border-style: solid;
  border-width: ${({border }) => border ? "0.5px" : "0"};
  border-radius: 8px;
  padding: 1%;
  &:hover{
    fill-opacity: 1;
    transition: all 0.3s ease-in-out, background-position 1ms;
    transform: scale(1.1,1.1);
    fill: ${({ theme }) => theme.colors.blueRibbon};
    cursor: pointer;
    border-color: ${({border, theme }) => border ? theme.colors.blueRibbon : "none"};
  }

`
export default DeleteIconStyled
