import { createSelector } from 'reselect'

const mapRelativeListPadron = createSelector(
    relativesList => relativesList,
    relativesList => relativesList ?
    relativesList.map(
      ({ id, name, surname,dni, username, phone_number, }, i) => ({
        pos: i,
        id,
        full_name: `${name ? name : ""} ${surname ? surname : ""}`,
        username: username,
        dni: dni,
        phone_number: phone_number,

      })
    ) : null
)

export default mapRelativeListPadron
