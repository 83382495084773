
import React, { Component } from 'react'
import Page from 'components/Page'
import Section from 'components/Section'
import Select from 'react-select';
import styled, { css } from 'styled-components'
import ImageUploadPlaceholder from 'components/ImageUploadPlaceholder';
import plus from 'assets/images/registro.png';
import Buttom from 'components/ButtonMaterial';
import { PageWrapper, Wrapper, ButtonContinuar, Row, Title, WrapperForm, StyledButton } from "../styled"
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import ProgressBar from 'components/ProgressBar';
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import CheckBox from 'components/CheckboxFormik';
import loader from 'assets/images/load.gif';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import {
    fetchGetProducts, fetchDeleteProductReset, fetchDetailProduct, fetchDeleteProduct,

    fetchPaymentMethods, fetchShippingMethods, fetchItemConditions, fetchGetCategories,

    fetchAddProduct, fetchEditProduct
} from 'store/actions/clasificados.actions'

const LoaderImg = styled.img`  
        width: 80px;
    `
const ErrorLabel = styled.div`  
        color: red;
    `
const GridContainer = styled.div`  
        display: grid;
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    
        
        @media (max-width: 650px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 0px;
        }
    `


const GridContainerImagenes = styled.div`  
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    
        
        @media (max-width: 650px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 0px;
        }
    `




const GridContainerDataMapped = styled.div`  
        display: grid;
        width: 100%;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    
        
        @media (max-width: 650px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 0px;
        }
    `


const talles = [{ label: 'XS', value: '0' },
{ label: 'S', value: '1' },
{ label: 'M', value: '2' },
{ label: 'L', value: '3' },
{ label: 'X', value: '4' },
{ label: 'XL', value: '5' }];


const colores = [{ label: 'Rojo', value: '0' },
{ label: 'Verde', value: '1' },
{ label: 'Amarillo', value: '2' },
{ label: 'Azul', value: '3' },
{ label: 'Violeta', value: '4' },
{ label: 'Blanco', value: '5' }];


class Paso2 extends Component {
    state = {
        step: 1,
        formData: [
            { validated: false },
            { validated: false },
            { validated: false },
        ]
    }


    getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
        <form onSubmit={handleSubmit} key='1'>
            {/* onChange={({ target: { value } }) => setFieldValue('name', value)} */}
            <Wrapper>
                <ProgressBar percentage={'66'} />
                <br />
                <GridContainer>
                    <Title>Importe</Title>
                    <Input
                        name={'importe'}
                        label={'importe'}
                        placeholder={'Importe'}
                        value={values.importe}
                        error={errors.importe}
                        onChange={({ target: { value } }) => setFieldValue('importe', value)}
                    />

                    <Title>Stock</Title>
                    <Input
                        name={'stock'}
                        label={'stock'}
                        placeholder={'Stock'}
                        value={values.stock}
                        error={errors.stock}
                        onChange={({ target: { value } }) => setFieldValue('stock', value)}
                    />

                    <Title>Condicion del producto</Title>
                    <GridContainer>
                        <Select
                            name={'itemCondition'}
                            label={'itemCondition'}
                            autosize={false}
                            value={values.itemCondition}
                            onChange={value => setFieldValue('itemCondition', value)}
                            placeholder="Condición del producto/item"
                            options={
                                this.props.clasificados.itemConditions.values.length > 0 ?
                                    this.props.clasificados.itemConditions.values.map(n =>
                                        ({ label: n.name, value: n.id })) : [{ value: null, label: "Loading..." }]}
                        />
                        {errors.itemCondition && <ErrorLabel>{errors.itemCondition}</ErrorLabel>}
                    </GridContainer>
                </GridContainer>
                <StyledButton type={'submit'} >Continuar</StyledButton>
            </Wrapper>
        </form>
    )


    openWebcamModal = () => {
        this.setState({
            cameraModalOpen: !this.state.cameraModalOpen,
        });
    }

    handleSubmit = (values) => {
        const { index, getFormData } = this.props;
        getFormData(values, index);
        this.props.handleContinuar();
    }


    getInitialValues = () => ({
        importe: this.props.producto.values.prices[0].price,
        stock: this.props.producto.values.quantity,
        itemCondition: { label: this.props.producto.values.shopitemconditionname, value: this.props.producto.values.shopitemconditionid }
    })

    getSignUpSchema = () =>
        Yup.object().shape({
            importe: Yup.number()
                .required('Importe es requerido'),
            stock: Yup.number()
                .required('Stock es requerido'),
            itemCondition: Yup.string()
                .required('Stock es requerido'),
        })


    componentDidMount() {
        this.props.fetchItemConditions();
    }

    render() {
        return (
            <Formik
                initialValues={this.getInitialValues()}
                validateOnChange={false}
                validationSchema={this.getSignUpSchema()}
                onSubmit={this.handleSubmit}
                render={e => this.getFormContent(e)}
            />
        )
    }
}

const mapStateToProps = ({
    clasificados,
    selectedGuest
}) => ({
    clasificados,
    selectedGuest
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchGetCategories, fetchItemConditions }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(Paso2)
)