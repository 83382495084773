import React from 'react'
import { Container, Images,  Title } from './styled'


class Buttons extends React.Component {
    state = {
        onOpen: this.props.onOpen
    }

    onOpen = () => {
        this.state.onOpen()
    }

render() {
    const { image,title } = this.props

    return (
        <Container onClick={this.onOpen}>
            <Images>
                <img src={image} alt="qr" />
            </Images>
            <Title> 
                {title}
            </Title>
        </Container>
    )
}}

export default Buttons
