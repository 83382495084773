import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross-blue.png'
import PageLoader from 'components/PageLoader'
import DatePickerStyled from 'components/DatePickerStyled'
import { addDays, addMonths } from 'date-fns'

import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
} from './styled'

class ReporteExpensas extends React.Component {
  state={

  }

  componentWillUnmount(){
    // this.setState({loadingFile: false,errorFile: false})
  }

  getInitialValues = () => ({
    date: addDays(new Date(), -1)
  })

  getValidationSchema = () =>
    Yup.object().shape({
        date: Yup.string().required('El campo fecha es requerido')
    })

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row> <InputLabel size="20px">{'Generar reporte de expensas'}</InputLabel> </Row>
      <Row>
        <InputWrapper width={28} margin={'0px 5px'}>
          <InputLabel size="20px">{'Fecha'}</InputLabel>
          <DatePickerStyled
            selected={values.date}
            onChange={date => setFieldValue('date', date)}
            minDate={addMonths(new Date(), -12)}
            maxDate={new Date()}
            dateFormat="dd/MM/yyy"
            placeholderText="Fecha"
          />
          {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
        </InputWrapper>
        
      </Row>
      {this.props.error ? (
        <ButtonWrapper>
          <ErrorLabel>Error al iniciar el proceso de reporte de expensas.</ErrorLabel>
        </ButtonWrapper>
      ): (<> </>)}
      {this.props.loading   ? 
        (
          <ButtonWrapper>
            <PageLoader customHeight={'50'}/>
          </ButtonWrapper>
        ) : 
        (
          <ButtonWrapper>
            <StyledButton type={'submit'}>EJECUTAR</StyledButton>
          </ButtonWrapper>
        )}
    </FormContainer>
  )

  handleSubmit = async data => {
    await this.props.onChange(data.date)
    if (!this.props.error) this.props.onOpenResult(true)
    if (!this.props.error) this.props.onClose(true)
  }

  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getValidationSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

export default ReporteExpensas