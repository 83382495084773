import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import Page from 'components/Page'
import Section from 'components/Section'
import ResourceList from 'components/ResourceList'
import SearchBar from 'components/SearchBar'
import {
  fetchGetProducts, fetchDeleteProductReset, fetchDetailProduct, fetchDeleteProduct,
  fetchEditProductReset, fetchAddProductReset, fetchAddProduct, fetchEditProduct,
  fetchUploadProductsFile, fetchUploadProductsFileReset
} from 'store/actions/clasificados.actions'
import eyeIcon from 'assets/icons/eyeIcon.svg'
import editIcon from 'assets/icons/editIcon.svg'
import mapFilteredProducts from 'selectors/mapFilteredProducts'
import EmptyLoader from 'components/PageLoader'
import MaterialTable from 'material-table'
import Delete from 'assets/icons/deletetrash.svg'
import Carrousel from '../components/carrouselphotos'
import DeleteModal from '../components/modalDelete'
import DetailModal from '../components/modalDetail'
import EditModal from '../components/editProduct'
import ModalMessage from 'components/ModalMessage'
import AddProduct from '../components/addProduct'
import UploadFile from '../components/UploadFile'

import { PageWrapper, Wrapper,Icon,ContainerIcon,IconBack, SearchWrapper, ContainerImg, ContainerSearchBar, ContainerSelects, SelectStyled, Container } from "./styled"
import ResultUploadFile from '../components/ResultUploadFile';

const years = [
  { value: '2021', label: '2021' },
  { value: '2020', label: '2020' },
  { value: '2019', label: '2019' },
];

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'ARS',
});
var Data = [
  { date: 'Mehmet', user: 'agostinasalvatore', price: 4500, category: 63, photos: 'https://avatars0.githubusercontent.com/u/7895451?s=460&v=4' },
  { date: 'Zerya Betül', user: 'agostinasalvatore', price: 4500, category: 34, photos: 'https://avatars0.githubusercontent.com/u/7895451?s=460&v=4' },
]


class Clasificados extends React.Component {
  state = {
    filter: '',
    verPubliModal: false,
    addProduct: false,
    resultUpload: false,
    editProducto: false
  }

  componentDidMount = () => {
    this.props.fetchGetProducts()
  }

  renderPageContent = () => {
    return (
      <SearchWrapper>
        <ContainerSearchBar>
          <SearchBar
            placeholder={'Buscar por usuario'}
            style={'width: \'70%\' '}
            value={this.state.filter}
            onChange={(e) => this.handleInputOnChange(e)}
            onClear={this.handleInputClear}
            onKeyDown={this.handleKeyPress}
            noBorder={true}
            autofocus
          />
          {/* <ContainerSelects>
            <SelectStyled
              value={this.state.selectedNeighbourhood}
              onChange={this.handleSelectNeighbourhood}
              options={years}
              placeholder={'Fecha'}
            />
          </ContainerSelects> */}
        </ContainerSearchBar>
        <Container>
          <MaterialTable
            columns={[
              {
                title: 'Titulo', field: 'productname', 
              },
              { title: 'Fecha', field: 'date'},
              { title: 'Usuario', field: 'user', },
              {
                title: 'Precio', field: 'amount', render: rowData =>
                  <> {formatter.format(rowData.amount)}</>
              },
              { title: 'Categoria', field: 'category', },
              {
                title: 'Fotos', field: 'images',
                cellStyle: { width: "5vw"},
                 render: rowData => <Carrousel images={rowData.images} />
              },
              {
                title: 'Acciones', field: 'publication', render: rowData =>
                  <ContainerImg>
                    <Icon src={eyeIcon}  onClick={() => this.handleDetailProduct(rowData.idProduct)} />
                    <Icon src={editIcon}  onClick={() => this.handleEditProduct(rowData.idProduct)} />
                    <Icon src={Delete} onClick={() => this.handleDeleteProduct(rowData.idProduct)} />
                  </ContainerImg>
              },
            ]}
            data={this.props.filteredProducts}
            //onRowClick={(event, rowData, togglePanel) => this.onMovementClick(rowData)}
            // onSelectionChange={(rows) => this.onMovementClick( rows )}

            localization={{
              header: {
                actions: 'Acciones',
              },
              body: { emptyDataSourceMessage: 'No hay nada' }
            }}
            options={{
              toolbar: false,
              paging: false,
              height: '300px',
              width: "70vw",
              selection: false,
              actionsColumnIndex: -1,
              headerStyle: {
                zIndex: 0
              }
            }} //Esconde titulo y search y pagination 
          />
        </Container>
      </SearchWrapper>
    )
  }

  isFilterEnabled = () => this.state.filterEnabled

  handleInputOnChange = value => {
    this.setState({ filter: value, filterEnabled: false })
    this.props.fetchGetProducts(value)
  }

  handleDetailProduct = idProduct => {
    this.props.fetchDetailProduct(idProduct)
    this.toggleVerPubliModal()
  }

  handleEditProduct = idProduct => {
    this.props.fetchDetailProduct(idProduct)
    this.toggleEditModal()
  }

  toggleEditModal = () => {
    this.setState({ editProducto: !this.state.editProducto })
  }

  handleDeleteProduct = (idProduct, reason) => {
    this.setState({ reasonDelete: "1", IdToDelete: idProduct }, this.toggleDeleteModal())
  }

  async DeleteProduct() {
    this.props.fetchDeleteProduct(this.state.IdToDelete, this.state.reasonDelete)
  }

  toggleDeleteModal = () => {
    this.props.fetchDeleteProductReset();
    this.setState({ deleteModal: !this.state.deleteModal })
  }

  toggleVerPubliModal = () => {
    this.setState({ verPubliModal: !this.state.verPubliModal })
  }

  toggleAddProd = () => {
    this.setState({ addProduct: !this.state.addProduct })
    if (!this.state.addProduct) {
      this.props.fetchGetProducts()
    }
  }

  handleInputClear = value => {
    this.setState({ filter: value, filterEnabled: false })
    this.props.fetchGetProducts(value)
  }

  handleKeyPress = e => {
    if (e.keyCode === 13 && e.target.value !== '') {
      this.props.fetchGetProducts(e.target.value)
      this.setState({ filterEnabled: true })
    }
  }

  isContentAvaiable = () => this.props.clasificados.fetchGetProductsSuccess === true && this.props.clasificados.loadingProducts === false ? true : false

  toggleResultUpload = () => {
    if (this.state.resultUpload) this.props.fetchGetProducts(this.state.filter);
    this.setState({ resultUpload: !this.state.resultUpload })
  }

  toggleUpload = () => {
    if (!this.state.uploadFile) this.props.fetchUploadProductsFileReset();
    this.setState({ uploadFile: !this.state.uploadFile })
  }

  render() {
    return (
      <>
        {!this.state.addProduct && !this.state.editProducto ?
          <Page>
            <PageWrapper>
              <Section title={'Publicaciones'}
                onBack={() => this.props.onClose()}
                btnContent={'Cargar producto'}
                link={'Subida masiva'} 
                onLinkClick={this.toggleUpload} 
                onBtnClick={() => this.toggleAddProd()}
                secondlink={'Descargar Template'} 
                secondlinkContent={"https://miiiisa-app.s3.us-east-2.amazonaws.com/shop/Productos+Miiii.xlsx"}
             >
                <Wrapper>
                  {this.isContentAvaiable() ? this.renderPageContent() : <EmptyLoader />}
                  {this.state.uploadFile ? 
                  <UploadFile 
                    onClose={this.toggleUpload} 
                    onOpenResult={this.toggleResultUpload}
                    onChange={(file) => this.props.fetchUploadProductsFile(file)} // !!!
                    error={this.props.errorUploadProductsFile} 
                    errorMessage={this.props.errorMessage} 
                    loading={this.props.loadingUploadProductsFile}
                  /> : <></>}
                  {this.state.deleteModal ? <DeleteModal success={this.props.succcessDelete} loadingDelete={this.props.loadingDelete} onClose={() => this.toggleDeleteModal()} delete={() => this.DeleteProduct()} /> : <></>}
                  {this.state.verPubliModal ? <DetailModal loading={this.props.getDetailsSuccess} onClose={() => this.toggleVerPubliModal()} success={this.props.getDetailsFalse} producto={this.props.detailProductShow} /> : <></>}
                </Wrapper>
              </Section>
            </PageWrapper>
          </Page>
          :
          null
        }
        {this.state.addProduct ?
          <AddProduct
            toggleAddProd={this.toggleAddProd}
            fetchAddProduct={this.props.fetchAddProduct}
            loading={this.props.loadingAddProduct}
          />
          :
          null
        }

        {this.state.editProducto ?
          <EditModal
           //loading={this.props.getDetailsSuccess} 
           onClose={() => this.toggleEditModal()} 
           success={this.props.getDetailsFalse} 
           producto={this.props.detailProductShow} />
          :
          null
        }
        {this.state.resultUpload && 
          <ResultUploadFile 
            onClose={this.toggleResultUpload}
            resultUploadProductsFile={this.props.resultUploadProductsFile}
            loadingUploadProductsFile={this.props.loadingUploadProductsFile} 
          />
        }
        {/* {this.state.resultUpload ?
            <ModalMessage success={this.state.resultUpload} onClose={() => this.toggleResultUpload()} >
                <h3>Importacion Exitosa</h3>
            </ModalMessage>
            :
            <></>
        } */}
        
      {this.props.fetchAddProductSuccess || this.props.fetchAddProductFail  ?
            <ModalMessage success={this.props.fetchAddProductSuccess} onClose={() => this.props.fetchAddProductReset()} >
                {
                    this.props.fetchAddProductFail ? <h3>Hubo un error, intentar nuevamente.</h3> : <>Producto creado correctamente</>
                }
            </ModalMessage>
            :
            <></>
        }
        {this.props.fetchEditProductSuccess || this.props.fetchEditProductFail  ?
            <ModalMessage success={this.props.fetchEditProductSuccess} fail onClose={() => this.props.fetchEditProductReset()} >
                {
                    this.props.fetchEditProductFail ? <h3>Hubo un error, al editar.</h3> : <>Producto editado correctamente</>
                }
            </ModalMessage>
            :
            <></>
        }
      </>
    )
  }
}

const mapStateToProps = ({
  clasificados: {
    products,
    loadingproducts,
    fetchDeleteProductSuccess,
    loadingDeleteProduct,
    detailProduct,
    fetchDetailProductSuccess,
    fetchDetailProductFail,
    fetchAddProductSuccess,
    fetchAddProductFail,
    fetchAddProductReset,
    fetchEditProductSuccess,
    fetchEditProductFail,
    fetchEditProductReset,
    loadingAddProduct,
    loadingEditProduct,
    resultUploadProductsFile,
    loadingUploadProductsFile,
    errorUploadProductsFile,
    errorMessage
  },
  selectedGuest,
  clasificados
}) => ({
  filteredProducts: products.length > 0 ? mapFilteredProducts(products) : [],
  loading: loadingproducts,
  succcessDelete: fetchDeleteProductSuccess,
  loadingDelete: loadingDeleteProduct,
  selectedGuest,
  detailProductShow: detailProduct,
  getDetailsSuccess: fetchDetailProductSuccess,
  getDetailsFail: fetchDetailProductFail,
  clasificados,

  fetchAddProductSuccess: fetchAddProductSuccess,
  fetchAddProductFail: fetchAddProductFail,

  fetchEditProductSuccess: fetchEditProductSuccess,
  fetchEditProductFail: fetchEditProductFail,

  loadingAddProduct: loadingAddProduct,
  loadingEditProduct: loadingEditProduct,

  resultUploadProductsFile,
  loadingUploadProductsFile,
  errorUploadProductsFile,
  errorMessage

})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchGetProducts,
    fetchDeleteProductReset,
    fetchDetailProduct,
    fetchDeleteProduct,
    fetchAddProduct,
    fetchEditProduct,
    fetchEditProductReset,
    fetchAddProductReset,
    fetchUploadProductsFile,
    fetchUploadProductsFileReset
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(Clasificados)
)
