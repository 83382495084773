import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

const StyledTextField = styled(TextField)`
  width: 100%;

  & label {
    color: ${({ theme }) => theme.colors.dustyGray};
  }

  & .MuiOutlinedInput-notchedOutline-31 {
    border: 2px solid ${({ theme }) => theme.colors.dustyGray} !important;
  }
`

const TextFieldMaterial = props => <StyledTextField {...props} />

export default TextFieldMaterial
