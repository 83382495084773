import React from 'react'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross-blue.png'
import MaterialTable from 'material-table'
import PageLoader from 'components/PageLoader'
import { Paper } from '@material-ui/core';

import {
  TableContainer,
  Title,
  CrossImage,
} from './styled'

class ResultUploadFile extends React.Component {
  state={
    errorFile: false
  }

  renderItemRow(width, data){
    return (<div style={{maxWidth: width}}>  {data}  </div>)
  }

  renderStateRow(width, data){
    return (<div style={{maxWidth: width}}>  { data == true ? <span style={{color:'green'}}>SI</span> : <span style={{color:'red'}}>NO</span> }  </div>)
  }

  ModalContent=()=>{
    const uploadFileResult = this.props.resultUploadProductsFile;
      return(
                <MaterialTable
                title={(<Title>Resultado de carga masiva de productos</Title>)}
                columns={[
                    { title: 'Item', field: 'item', render: rowData => this.renderItemRow('80px', rowData['item'])},
                    { title: 'Correcto', field: 'success', render: rowData => this.renderStateRow('80px', rowData['success'])},
                    { title: 'Mensaje', field: 'message'}
                ]}
                data={uploadFileResult}
                options={{
                    emptyRowsWhenPaging: false,
                    search: false,
                    paging: false,
                    maxBodyHeight: 300
                }} 
                // components={{
                //   Container: props => <Paper {...props} elevation={0}/>
                // }}
                />
      )
  }
  render() {
    return (
        <Modal>
            <TableContainer>
                <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
                {this.props.loadingUploadProductsFile ? <PageLoader/> : this.ModalContent()}
            </TableContainer>
        </Modal>
    )
  }
}

export default ResultUploadFile