import styled from 'styled-components'
import mediaQueries from 'config/media-queries'

export const HeaderMenuWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: initial;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 2px solid ${({ theme }) => theme.colors.alabaster};
  padding-left: 23px;
  &::-webkit-scrollbar-thumb {
    
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    
    background-image: -webkit-gradient(linear, 0 0, 0 100%,
                       color-stop(.5, rgba(255, 255, 255, .2)),
               color-stop(.5, transparent), to(transparent))
    }
  &::-webkit-scrollbar { 
    height: 5px;
    width: 10px;
    background-color: #F5F5F5;
  }
  
  &::-webkit-scrollbar-track{
   box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  overflow: auto;

  ${mediaQueries.tablet`
      display: none;
    `};
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0% 1%;
  padding: 0.5% 1%;
  border-radius: 20px;
  cursor: pointer;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.blueRibbon : theme.colors.transparent};
  transition: all 0.15s ease;

  &:hover > label {
    color: ${({ active, theme }) => !active && theme.colors.blueRibbon};
  }
`

export const MenuLabel = styled.label`
  font-size: 16px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.black)};

  &:hover {
    color: ${({ active, theme }) => !active && theme.colors.blueRibbon};
  }
`
