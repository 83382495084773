import { createSelector } from 'reselect'
var moment = require('moment');

const mapStories = createSelector(
  stories => stories,
  stories =>
    stories.map(story => ({
      id: story.id,
      name: story.name,
      description: story.description,
      imageUrl: story.image_url,
      storyUrl: story.story_url,
      creationDate:moment(story.creation_date).format('YYYY-MM-DD'),
      order:story.order,
      isPublic: story.is_public,
      isPublicGrid: story.is_public ? 'Si' : 'No'
    }))
)

export default mapStories

