import { createSelector } from 'reselect'



const mapAdminUsers = createSelector(
  proprietaryList => proprietaryList,
  proprietaryList =>
    proprietaryList.map(
      ({ id, name, surname, personneighbourhoods, dni, username, property_code, gender, personroles, phonenumber, email, neighbourhoods_name, picturebase64, cuil }, i) => ({
        pos: i,
        id,
        neighbourhoods: personneighbourhoods,
        title: `${name} ${surname}`,
        subtitle: `Lote: ${property_code}`,
        extraInfo: username,
        avatar: picturebase64,
        withAvatar: true,
        username,
        gender: { value: gender, label: gender === 0 ? 'mujer' : 'hombre' },
        name,
        surname,
        neighbourhoods_name,
        property_code,
        dni,
        phonenumber: phonenumber.replace('+', ''),
        email,
        personroles,
        picture_url: picturebase64,
        cuil
      })
    )
)

export default mapAdminUsers
