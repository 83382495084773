import styled, { css } from 'styled-components'
import Select from 'react-select'

export const DataContainer = styled.div`
  width: 65%;
  padding: 1% 1%;
  border-bottom-right-radius: 10px;
`

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const Row = styled.div`
  width: ${({ widthAuto }) => (widthAuto ? 'auto' : '100%')};
  display: flex;
  padding: ${({ padding }) => padding || '0px'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'center'};
  margin: ${({ margin }) => margin || '0px'};

  border: ${({ border }) => border || '0px'};
  background-color: ${({ background }) => background || ''};
`

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
`

export const Title = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 22px;
  font-weight: 700;
  margin-right: 40%;
`

export const DataLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ noMargin, theme }) => (noMargin ? theme.colors.black : theme.colors.midGray)};
  font-size: 18px;
  font-weight: 700;

  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin: 15px 0;
      margin-right: 5px;
    `}
`

export const DataContent = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.midGray};
  font-size: 18px;
  font-weight: 500;
  ${({ noMargin }) =>
    !noMargin
      ? css`
          margin: 15px 0;
        `
      : css`
          margin-top: 15px;
        `}
`

export const GuestState = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};
`

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  margin-top: ${({ noMargin }) => (noMargin ? 15 : 40)}px;
  margin-bottom: ${({ noMargin }) => (noMargin ? 15 : 10)}px;
`
export const ImgContainer = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.ghost};
  border-radius: 5px;
  padding: 4px;

  &:hover{
    cursor:pointer;
  }
`
export const SelectStyled = styled(Select)`
    width: 20%;
    margin: 0px 10px;
`
export const MoneyWrapper = styled.div`
  width: 100%;
  padding: 2% 0%;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.athensGray};
  background-color: ${({ theme }) => theme.colors.wildSand};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

`
export const MoneyContainer = styled.div`
  width: 33.3%;
  border-right: ${({ border,theme }) => border || "1px solid "+theme.colors.mercury};
  background-color: ${({ theme }) => theme.colors.wildSand};
`
export const MoneyTitle = styled.div`
  background-color: ${({ theme }) => theme.colors.wildSand};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 30px;
  font-weight: 500;
  margin-left: 2%;
`
export const MoneySubtitle = styled.div`
  background-color: ${({ theme }) => theme.colors.wildSand};
  color: ${({ theme }) => theme.colors.mineShaft};
  font-size: 10px;
  font-weight: 500;
  margin-left: 5px;
`
export const TitleList = styled.div`
  font-size: 15px;
  font-weight:  ${({ bold }) => (bold ? 600 : 0)};
  width: ${({ width }) => width || '20%'};
  margin: ${({ margin }) => margin || '0px'};
`
export const Status = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    background-color: ${({ background }) => background || 'white'};
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    font-size: 18px;
    align-self: center;
    font-weight: 500;
`



export const InfiniteLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 0;
`

export const NoContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 20px;
  padding: 20px 0;
`






