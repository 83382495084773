import React, { Component } from 'react'
import {  Container, InputStyled, RadioBtnContainer, LabelContainer,FormContainer, ListWrapper ,StyledButton, LabelChecked,ErrorLabel } from './styled';
import ResourceList from 'components/ResourceList'
import CheckBox from 'components/CheckBox'
import elements from 'constants/elementsResourceList'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { fetchGetAccountQR } from 'store/actions/billetera.actions'
import mapAccountQR from "selectors/mapAccountQR"

class FirstStep extends Component {
    state = {
        selectedOptionToSearch: 'nombre',
        valueInput: 'Buscar por: nombre',
        isIncomplete: false,
        results: elements,
        body: {
          Neighbourhood: this.props.neighbourhood.guid,
          BankAccountAlias: "",
          PersonName: "",
          PropertyCode: "",
        }
    }

    
  handleFormSubmit = formSubmitEvent => {
    formSubmitEvent.preventDefault();
  }

  handleOptionChange = changeEvent => {
    this.setState({
      selectedOptionToSearch: changeEvent.target.value,
      valueInput: "Buscar por: "+changeEvent.target.value
    })
  }
  
  inputChange(value){
    this.setState({
      valueInput: value
    })
  }
  
  isFilterEnabled = () => this.state.valueInput

  handleDestinatarioClick = destinatario => {
    this.props.setDestinatario(destinatario)
    this.setState({ isIncomplete: true, destinatario: destinatario })
  }

  search(words){
    switch(this.state.selectedOptionToSearch) {
      case 'nombre':
        this.setState(prevState => {
          let body = Object.assign({}, prevState.body); 
          body.PersonName = "";    
          body.BankAccountAlias = "";  
          body.PersonName = words;          
          return { body }; 
        },() => this.props.fetchGetAccountQR(this.state.body))
        var data = elements.filter((val) => val.title.toLowerCase().includes(words));
        this.setState({results: data})
        return data
      case 'alias':
        this.setState(prevState => {
          let body = Object.assign({}, prevState.body); 
          body.PersonName = "";    
          body.PropertyCode = "";   
          body.BankAccountAlias = words;          
          return { body }; 
        },() => this.props.fetchGetAccountQR(this.state.body))   
        var data = elements.filter((val) => val.title.toLowerCase().includes(words));
        this.setState({results: data});
        return data
      case 'lote':
        this.setState(prevState => {
          let body = Object.assign({}, prevState.body);
          body.PersonName = "";    
          body.BankAccountAlias = "";    
          body.PropertyCode = words;          
          return { body }; 
        },() => this.props.fetchGetAccountQR(this.state.body))   
        var data = elements.filter((val) => val.lote.toLowerCase().includes(words));
        this.setState({results: data})
        return data
      default:
        return null;
    } 
  }

  // isContentAvaiable = () => this.props.guests.length > 0 || this.props.filteredGuests.length > 0
  // Hay que validar contenido con las props que recibe, igual a Invitados.

    render() {
        return (
          <FormContainer onSubmit={this.handleFormSubmit}>
            <Container>
              <LabelContainer>Destinatario</LabelContainer>
                <RadioBtnContainer>
                  <LabelChecked checked={this.state.selectedOptionToSearch === 'nombre'}>
                    <CheckBox
                      type="radio"
                      name="rdBtn"
                      value="nombre"
                      checked={this.state.selectedOptionToSearch === 'nombre'}
                      onChange={this.handleOptionChange}
                      className="form-check-input"
                    />
                    Nombre
                  </LabelChecked>
                  <LabelChecked checked={this.state.selectedOptionToSearch === 'alias'}>
                    <CheckBox
                      type="radio"
                      name="rdBtn"
                      value="alias"
                      checked={this.state.selectedOptionToSearch === 'alias'}
                      onChange={this.handleOptionChange}
                      className="form-check-input"
                    />
                    Alias
                  </LabelChecked>
                  <LabelChecked checked={this.state.selectedOptionToSearch === 'lote'}>
                    <CheckBox
                      type="radio"
                      name="rdBtn"
                      value="lote"
                      checked={this.state.selectedOptionToSearch === 'lote'}
                      onChange={this.handleOptionChange}
                      className="form-check-input"
                    />
                    Nº de lote
                  </LabelChecked>
                </RadioBtnContainer>
                  
                <InputStyled placeholder={this.state.valueInput} onChange={(e) => e.target.value.length > 3 ? (this.search(e.target.value)) : (null)}
                autoFocus />
                <ListWrapper id={'scrollContainer'} className={'portal-hide-scrollbars'}>
                   {this.props.AccountQR ? (
                    <ResourceList
                    elements={this.props.AccountQR }
                    //elements={this.isFilterEnabled() ? this.props.filteredGuests : this.props.guests}
                    onClick={this.handleDestinatarioClick}
                    selectedPos={this.state.destinatario}
                    //fetchMoreContent={() => this.props.fetchInvitaciones()}
                    noMoreContentMsg={'No hay más destinatarios'}
                    hasMore={false}
                    resourcePeople={false}
                    //hasMore={this.isFilterEnabled() ? this.props.loading : this.props.hasMore}
                    />
                   ) : (
                    <>
                    </>
                   )}
                   
                </ListWrapper>
                  
                <StyledButton onClick={this.state.isIncomplete ? (() => this.props.NextStep(2)) : (null)} >
                  {this.state.isIncomplete ? (
                      'Continuar'
                    ) : (
                      <ErrorLabel >Seleccione un destinatario</ErrorLabel>
                    )}
                </StyledButton>
            </Container>
          </FormContainer>
          )
      }
  }
  
const mapStateToProps = state => {
  return {
    AccountQR: mapAccountQR(state.billetera.AccountQR)
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGetAccountQR
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(FirstStep)
)
