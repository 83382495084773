import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import Donacion from "./donaciones/index"
import News from "./novedades/index"
// import Despachado from "./despachado/index"
import Stories from "./stories/index"
import Movies from "./movies/index"
import donacionimg from "assets/icons/donacion.svg"
import moviesimg from "assets/icons/tvIcon.svg"
import storiesimg from "assets/icons/stories.svg"
import usersimg from "assets/icons/people.svg"
import newsimg from "assets/icons/news.svg"
import Page from 'components/Page'
import Section from 'components/Section'
import FundacionUsers from './users/index'

import { AccessEntry, BigButton, Text, PngImage, Wrapper, PageWrapper } from './styled'
import stories from './stories/index'

class Fundacion extends React.Component {
  state = {
    donacionesOpen: false,
    moviesOpen: false,
    storiesOpen: false,
    fundacionUsersOpen: false,
    newsOpen: false,

    // mensajeriaForm: false,
    // registrosForm: false,
  }

  renderPageContent = () => (
    <>
      <AccessEntry>
        <BigButton onClick={this.toggleDonaciones}>
          <PngImage src={donacionimg} />
          <Text>
            Donaciones
          </Text>
        </BigButton>

        <BigButton onClick={this.toggleFundacionUsers}>
          <PngImage src={usersimg} />
          <Text>
            Usuarios
          </Text>
        </BigButton>
      </AccessEntry>

      <AccessEntry>
        <BigButton onClick={this.toggleStories}>
          <PngImage src={storiesimg} />
          <Text>
            Cuentos
          </Text>
        </BigButton>

        <BigButton onClick={this.toggleMovies}>
          <PngImage src={moviesimg} />
          <Text>
            Películas
          </Text>
        </BigButton>

      </AccessEntry>
      <AccessEntry>

        <BigButton onClick={this.toggleNews}>
          <PngImage src={newsimg} />
          <Text>
            Novedades
          </Text>
        </BigButton>

      </AccessEntry>

    </>
  )

  toggleSuccesModal = (modalContent) => {
    this.setState({
      succesModal: !this.state.succesModal,
      modalContent,
      failModal: false,
    })
  }

  closeAllModals = () => {
    this.setState({
      failModal: false,
      succesModal: false,
      showSteps: false
    })
  }

  onSuccess = () => {
    this.setState({
      scanQRModal: false,
      newGuestForm: false,
      newExit: false,
      succesModal: false,
      editMode: false,
      showSteps: false
    })
  }

  toggleFailModal = (modalContent) => {
    this.setState({
      failModal: !this.state.failModal,
      modalContent,
      succesModal: false,
    })
  }

  toggleMovies = () => {
    console.log('toggle peliculas')
    this.setState({ moviesOpen: !this.state.moviesOpen })
  }

  toggleDonaciones = () => {
    console.log('toggle donaciones')
    this.setState({ donacionesOpen: !this.state.donacionesOpen })
  }

  toggleStories = () => {
    console.log('toggle cuentos')
    this.setState({ storiesOpen: !this.state.storiesOpen })
  }

  toggleFundacionUsers = () => {
    console.log('toggle usuarios')
    this.setState({ fundacionUsersOpen: !this.state.fundacionUsersOpen })
  }

  toggleNews = () => {
    console.log('toggle novedades')
    this.setState({ newsOpen: !this.state.newsOpen })
  }

  togglePutsModalDetails = () => {
    this.setState({
      putsDetailsModal: !this.state.putsDetailsModal
    })
  }

  isLoadingContent = () => this.props.loading;

  componentDidUpdate(prevProps) {
    if (this.props.loadingPuts && !prevProps.loadingPuts) {
      this.togglePutsModalDetails();
    }
  }

  render() {
    const { storiesOpen, newsOpen, moviesOpen, donacionesOpen, fundacionUsersOpen } = this.state;
    if (donacionesOpen === true) {
      return (
        <Page>
        <PageWrapper>
          <Section 
              title={'Listado de donaciones'} 
              onBack={this.toggleDonaciones} 
              > 
          </Section>
          <Donacion></Donacion>
        </PageWrapper>
        </Page>
      )

    } else if (storiesOpen) {
      return (

                <Stories
                onClose={this.toggleStories}
              />
      
      )
    } else if (fundacionUsersOpen) {
      return (
        <Page>
        <PageWrapper>
          <Section 
              title={'Listado de usuarios'} 
              onBack={this.toggleFundacionUsers} 
              > 
          </Section>
          <FundacionUsers></FundacionUsers>
        </PageWrapper>
        </Page>
      )
    } else if (moviesOpen) {
      return (
        <Movies
        onClose={this.toggleMovies}
      />
      )
    } else if (newsOpen){
      return (
        <News
        onClose={this.toggleNews}
      />
      )
    }
    else {
      return (
        <Page>
          <PageWrapper>
            <Section title={'Fundación'}>
              <Wrapper>
                {this.renderPageContent()}
              </Wrapper>
            </Section>
          </PageWrapper>
        </Page>
      )

    }
  }
}


const mapStateToProps = ({
  fundacion,
  userInfo
}) => ({
  fundacion,
  userRoles: userInfo.menusAuth
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(compose()(Fundacion))