import { createSelector } from 'reselect'

const mapProductType = createSelector(
  productTypeList => productTypeList,
  productTypeList =>
  productTypeList.map(
      ({ id, name }, i) => ({
        value: id,
        label: name
      })
    )
)

export default mapProductType
