import styled from 'styled-components'

export const Container = styled.div`
    width: ${({ width }) => width ? width : '50px'};
    justify-content: center;
`;

export const ImgStyled = styled.img`
    width: ${({ width }) => width ? width : '50px'};
`;

export const ImgContainer = styled.div`
    border-radius: 2px;
    padding: 2px;
    border: solid 0.5px #e9eff4; 
    background-color: #f8f8f8;
    width: ${({ width }) => width ? width : '50px'};
`;