import styled from 'styled-components'

export const TableContainer = styled.div`
    position: relative;
    padding: 20px 20px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: all 0.2s ease;

    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
`
export const Title = styled.div`
    font-size: 30px;
`
export const CrossImage = styled.img`
    width: 25px;
    height: 25px;
    align-self: flex-end;
    margin: 1%;
    cursor: pointer;
`