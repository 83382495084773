import React from 'react'
import AnimationWrapper from 'components/AnimationWrapper'
import { Container,Items, Header, HeaderItem } from './styled'
import check from "assets/icons/checked-border-round.png"
import cross from "assets/icons/cross-border-round.png"

class ModalDetails extends React.Component {
  closeModal() {
    this.props.onClose()
  }
  
  render() {
    const { success, errors} = this.props;
    let filteredError = []
    filteredError = errors.filter(error => error.error);

    return (
      <AnimationWrapper>
        <Container success={success}>
            <Header onClick={() => this.props.onClose()}>
              <HeaderItem></HeaderItem>
              <HeaderItem style={{'flex':'2'}}>
                <b>Actualizando detalles del invitado.</b>
              </HeaderItem>
              <HeaderItem>
                <div style={{'position':'absolute','right':'0','alignItems':'center','display':'flex'}}>
                  Cerrar<img src={success ? check : cross} style={{margin: "0px 10px"}}/>
                </div>
              </HeaderItem>
            </Header>
          <Items>
            {
              !success && filteredError.length === 0?
              <b>Guardando detalles, por favor espere. No cierre o cambie de ventana.</b> : null
            }
            {
              success && filteredError.length === 0?
                <>
                  <b>Resultado:</b>
                  <span>
                    Todos los detalles guardados exitosamente
                  </span>
                </> : null
            }
            {
              !success && filteredError.length > 0 ?
              <>
                {filteredError.map((error, i) => {
                  if(i === 0) {
                    return <><b>Resultado:</b><span><b>{error.label}:</b> {error.error}</span></>
                  } else { return(<span><b>{error.label}:</b> {error.error}</span>);}
                })}
                <span style={{'marginTop': '10px'}}>
                  La operacion tuvo errores, por favor revise cual segmento fallo y vuelva a intentarlo.
                </span>
              </> : null
            }
          </Items>
        </Container>
      </AnimationWrapper>
    ) 
  }  
}

export default ModalDetails
