//AUTH
export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'
export const FETCH_FORGOT_PASSWORD_SUCCESS = 'FETCH_FORGOT_PASSWORD_SUCCESS'
export const FETCH_FORGOT_PASSWORD_FAIL = 'FETCH_FORGOT_PASSWORD_FAIL'
export const FETCH_FORGOT_PASSWORD_START = 'FETCH_FORGOT_PASSWORD_START'

export const FETCH_TOKEN_AUTH_LOGIN_SUCCESS = 'FETCH_TOKEN_AUTH_LOGIN_SUCCESS'
export const FETCH_TOKEN_AUTH_LOGIN_FAIL = 'FETCH_TOKEN_AUTH_LOGIN_FAIL'
export const FETCH_TOKEN_AUTH_LOGIN_START = 'FETCH_TOKEN_AUTH_LOGIN_START'

export const AUTH_REQUIRE_TOKEN = 'AUTH_REQUIRE_TOKEN'


//LAYOUT
export const UPDATE_LAYOUT = '(LAYOUT) UPDATE_THEME_LAYOUT'
export const TOGGLE_SIDENAV = '(LAYOUT) TOGGLE_SIDENAV'
export const SET_SIDENAV_OPEN = '(LAYOUT) SET_SIDENAV_OPEN'
export const TOGGLE_SIDENAV_VARIANT = '(LAYOUT) TOGGLE_SIDENAV_VARIANT'
export const TOGGLE_NOTIFICATIONS = '(LAYOUT) TOGGLE_NOTIFICATIONS'

//THEMES
export const CHANGE_THEME = '(THEME) CHANGE_THEME'
export const CHANGE_SIDENAV_TOOLBAR_BACKGROUND = '(THEME) CHANGE_SIDENAV_TOOLBAR_BACKGROUND'
export const CHANGE_SIDENAV_TOOLBAR_TEXT = '(THEME) CHANGE_SIDENAV_TOOLBAR_TEXT'
export const CHANGE_SIDENAV_PALETTE_TYPE = '(THEME) CHANGE_SIDENAV_PALETTE_TYPE'
export const CHANGE_CONTENT_TOOLBAR_BACKGROUND = '(THEME) CHANGE_CONTENT_TOOLBAR_BACKGROUND'
export const CHANGE_CONTENT_TOOLBAR_TEXT = '(THEME) CHANGE_CONTENT_TOOLBAR_TEXT'
export const CHANGE_CONTENT_PALETTE_TYPE = '(THEME) CHANGE_CONTENT_PALETTE_TYPE'
export const CHANGE_PRIMARY_PALETTE_BACKGROUND = '(THEME) CHANGE_PRIMARY_PALETTE_BACKGROUND'
export const CHANGE_PRIMARY_PALETTE_TEXT = '(THEME) CHANGE_PRIMARY_PALETTE_TEXT'
export const CHANGE_SECONDARY_PALETTE_BACKGROUND = '(THEME) CHANGE_SECONDARY_PALETTE_BACKGROUND'
export const CHANGE_SECONDARY_PALETTE_TEXT = '(THEME) CHANGE_SECONDARY_PALETTE_TEXT'
export const CHANGE_THEME_DIRECTION = '(THEME) CHANGE_TEXT_DIRECTION'

//SECURITYSTAFF
export const FETCH_SECURITYSTAFF_START = 'FETCH_SECURITYSTAFF_START'
export const FETCH_SECURITYSTAFF_SUCCESS = 'FETCH_SECURITYSTAFF_SUCCESS'
export const FETCH_SECURITYSTAFF_FAIL = 'FETCH_SECURITYSTAFF_FAIL'
export const CREATE_SECURITYSTAFF_START = 'CREATE_SECURITYSTAFF_START'
export const CREATE_SECURITYSTAFF_SUCCESS = 'CREATE_SECURITYSTAFF_SUCCESS'
export const CREATE_SECURITYSTAFF_FAIL = 'CREATE_SECURITYSTAFF_FAIL'
export const FILTER_SEARCH_CHANGED = 'FILTER_SEARCH_CHANGED'

//PADRON
export const FETCH_PADRON_START = 'FETCH_PADRON_START'
export const FETCH_PADRON_SUCCESS = 'FETCH_PADRON_SUCCESS'
export const FETCH_PADRON_FAIL = 'FETCH_PADRON_FAIL'
export const CREATE_PROPRIETARY_START = 'CREATE_PROPRIETARY_START'
export const CREATE_PROPRIETARY_SUCCESS = 'CREATE_PROPRIETARY_SUCCESS'
export const CREATE_PROPRIETARY_FAIL = 'CREATE_PROPRIETARY_FAIL'
export const IMPORT_PROPRIETARY_START = 'IMPORT_PROPRIETARY_START'
export const IMPORT_PROPRIETARY_SUCCESS = 'IMPORT_PROPRIETARY_SUCCESS'
export const IMPORT_PROPRIETARY_FAIL = 'IMPORT_PROPRIETARY_FAIL'

//INVITADOS
export const FETCH_GUESTS_START = 'FETCH_GUESTS_START'
export const FETCH_GUESTS_SUCCESS = 'FETCH_GUESTS_SUCCESS'
export const FETCH_GUESTS_FAIL = 'FETCH_GUESTS_FAIL'
export const FETCH_RELATIVE_GUESTS_START = 'FETCH_RELATIVE_GUESTS_START'
export const FETCH_RELATIVE_GUESTS_SUCCESS = 'FETCH_RELATIVE_GUESTS_SUCCESS'
export const FETCH_RELATIVE_GUESTS_FAIL = 'FETCH_RELATIVE_GUESTS_FAIL'
export const FETCH_INVITACIONES_START = 'FETCH_INVITACIONES_START'
export const FETCH_INVITACIONES_SUCCESS = 'FETCH_INVITACIONES_SUCCESS'
export const FETCH_INVITACIONES_FAIL = 'FETCH_INVITACIONES_FAIL'
export const FETCH_FILTERED_GUESTS_START = 'FETCH_FILTERED_GUESTS_START'
export const FETCH_FILTERED_GUESTS_SUCCESS = 'FETCH_FILTERED_GUESTS_SUCCESS'
export const FETCH_FILTERED_GUESTS_FAIL = 'FETCH_FILTERED_GUESTS_FAIL'

//FAMILIARES
export const FETCH_RELATIVES_START = 'FETCH_RELATIVES_START'
export const FETCH_RELATIVES_SUCCESS = 'FETCH_RELATIVES_SUCCESS'
export const FETCH_RELATIVES_FAIL = 'FETCH_RELATIVES_FAIL'

//AUTOS

export const FETCH_VEHICLES_START = 'FETCH_VEHICLES_START'
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS'
export const FETCH_VEHICLES_FAIL = 'FETCH_VEHICLES_FAIL'

//LOTES
export const FETCH_LOTS_BY_NEIGHBOURHOOD_START = 'FETCH_LOTS_BY_NEIGHBOURHOOD_START'
export const FETCH_LOTS_BY_NEIGHBOURHOOD_SUCCESS = 'FETCH_LOTS_BY_NEIGHBOURHOOD_SUCCESS'
export const FETCH_LOTS_BY_NEIGHBOURHOOD_FAIL = 'FETCH_LOTS_BY_NEIGHBOURHOOD_FAIL'

export const FETCH_EDIT_PERSON_START = 'FETCH_EDIT_PERSON_START'
export const FETCH_EDIT_PERSON_SUCCESS = 'FETCH_EDIT_PERSON_SUCCESS'
export const FETCH_EDIT_PERSON_FAIL = 'FETCH_EDIT_PERSON_FAIL'

//FEED
export const FETCH_EVENTS_START = 'FETCH_EVENTS_START'
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL'
export const FETCH_ARTICLES_START = 'FETCH_ARTICLES_START'
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_FAIL = 'FETCH_ARTICLES_FAIL'
export const CREATE_NEW_EVENT_START = 'CREATE_NEW_EVENT_START'
export const CREATE_NEW_EVENT_SUCCESS = 'CREATE_NEW_EVENT_SUCCESS'
export const CREATE_NEW_EVENT_FAIL = 'CREATE_NEW_EVENT_FAIL'
export const CREATE_NEW_ARTICLE_START = 'CREATE_NEW_ARTICLE_START'
export const CREATE_NEW_ARTICLE_SUCCESS = 'CREATE_NEW_ARTICLE_SUCCESS'
export const CREATE_NEW_ARTICLE_FAIL = 'CREATE_NEW_ARTICLE_FAIL'
export const EDIT_ARTICLE_START = 'EDIT_ARTICLE_START'
export const EDIT_ARTICLE_SUCCESS = 'EDIT_ARTICLE_SUCCESS'
export const EDIT_ARTICLE_FAIL = 'EDIT_ARTICLE_FAIL'
export const DELETE_ARTICLE_START = 'DELETE_ARTICLE_START'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL'
export const DELETE_EVENT_START = 'DELETE_EVENT_START'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL'


export const EDIT_EVENT_START = 'EDIT_EVENT_START'
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS'
export const EDIT_EVENT_FAIL = 'EDIT_EVENT_FAIL'



//MENSAJES
export const FETCH_MSJ_RECIBIDO_START = 'FETCH_MSJ_RECIBIDO_START'
export const FETCH_MSJ_RECIBIDO_SUCCESS = 'FETCH_MSJ_RECIBIDO_SUCCESS'
export const FETCH_MSJ_RECIBIDO_FAIL = 'FETCH_MSJ_RECIBIDO_FAIL'
export const CREATE_NEW_PRIVATE_MSJ_START = 'CREATE_NEW_PRIVATE_MSJ_START'
export const CREATE_NEW_PRIVATE_MSJ_SUCCESS = 'CREATE_NEW_PRIVATE_MSJ_SUCCESS'
export const CREATE_NEW_PRIVATE_MSJ_FAIL = 'CREATE_NEW_PRIVATE_MSJ_FAIL'
export const FETCH_MSJ_ENVIADOS_START = 'FETCH_MSJ_ENVIADOS_START'
export const FETCH_MSJ_ENVIADOS_SUCCESS = 'FETCH_MSJ_ENVIADOS_SUCCESS'
export const FETCH_MSJ_ENVIADOS_FAIL = 'FETCH_MSJ_ENVIADOS_FAIL'
export const FETCH_MSJ_UNREAD_START = 'FETCH_MSJ_UNREAD_START'
export const FETCH_MSJ_UNREAD_SUCCESS = 'FETCH_MSJ_UNREAD_SUCCESS'
export const FETCH_MSJ_UNREAD_FAIL = 'FETCH_MSJ_UNREAD_FAIL'
export const CREATE_NEW_PUBLIC_MSJ_START = 'CREATE_NEW_PUBLIC_MSJ_START'
export const CREATE_NEW_PUBLIC_MSJ_SUCCESS = 'CREATE_NEW_PUBLIC_MSJ_SUCCESS'
export const CREATE_NEW_PUBLIC_MSJ_FAIL = 'CREATE_NEW_PUBLIC_MSJ_FAIL'
export const FETCH_USERS_START = 'FETCH_USERS_START'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL'

//PROVEEDORES
export const FETCH_CATEGORIAS_START = 'FETCH_CATEGORIAS_START'
export const FETCH_CATEGORIAS_SUCCESS = 'FETCH_CATEGORIAS_SUCCESS'
export const FETCH_CATEGORIAS_FAIL = 'FETCH_CATEGORIAS_FAIL'
export const FETCH_PROVEEDORES_START = 'FETCH_PROVEEDORES_START'
export const FETCH_PROVEEDORES_SUCCESS = 'FETCH_PROVEEDORES_SUCCESS'
export const FETCH_PROVEEDORES_FAIL = 'FETCH_PROVEEDORES_FAIL'
export const FETCH_SERVICES_START = 'FETCH_SERVICES_START'
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS'
export const FETCH_SERVICES_FAIL = 'FETCH_SERVICES_FAIL'
export const FETCH_NEIGHBOURHOOD_START = 'FETCH_NEIGHBOURHOOD_START'
export const FETCH_NEIGHBOURHOOD_SUCCESS = 'FETCH_NEIGHBOURHOOD_SUCCESS'
export const FETCH_NEIGHBOURHOOD_FAIL = 'FETCH_NEIGHBOURHOOD_FAIL'
export const CREATE_SUPPLIERS_START = 'CREATE_SUPPLIERS_START'
export const CREATE_SUPPLIERS_SUCCESS = 'CREATE_SUPPLIERS_SUCCESS'
export const CREATE_SUPPLIERS_FAIL = 'CREATE_SUPPLIERS_FAIL'
export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL'
export const CREATE_SERVICE_START = 'CREATE_SERVICE_START'
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS'
export const CREATE_SERVICE_FAIL = 'CREATE_SERVICE_FAIL'
export const FETCH_SUPPLIERS_REQUESTS_START = 'FETCH_SUPPLIERS_REQUESTS_START'
export const FETCH_SUPPLIERS_REQUESTS_SUCCESS = 'FETCH_SUPPLIERS_REQUESTS_SUCCESS'
export const FETCH_SUPPLIERS_REQUESTS_FAIL = 'FETCH_SUPPLIERS_REQUESTS_FAIL'

//RESERVAS
export const FETCH_ACTIVIDADES_START = 'FETCH_ACTIVIDADES_START'
export const FETCH_ACTIVIDADES_SUCCESS = 'FETCH_ACTIVIDADES_SUCCESS'
export const FETCH_ACTIVIDADES_FAIL = 'FETCH_ACTIVIDADES_FAIL'
export const CREATE_ACTIVIDAD_START = 'CREATE_ACTIVIDAD_START'
export const CREATE_ACTIVIDAD_SUCCESS = 'CREATE_ACTIVIDAD_SUCCESS'
export const CREATE_ACTIVIDAD_FAIL = 'CREATE_ACTIVIDAD_FAIL'
export const FETCH_SPACES_START = 'FETCH_SPACES_START'
export const FETCH_SPACES_SUCCESS = 'FETCH_SPACES_SUCCESS'
export const FETCH_SPACES_FAIL = 'FETCH_SPACES_FAIL'
export const CREATE_SPACE_START = 'CREATE_SPACE_START'
export const CREATE_SPACE_SUCCESS = 'CREATE_SPACE_SUCCESS'
export const CREATE_SPACE_FAIL = 'CREATE_SPACE_FAIL'
export const CREATE_EXCEPTIONS_START = 'CREATE_EXCEPTIONS_START'
export const CREATE_EXCEPTIONS_SUCCESS = 'CREATE_EXCEPTIONS_SUCCESS'
export const CREATE_EXCEPTIONS_FAIL = 'CREATE_EXCEPTIONS_FAIL'
export const FETCH_BOOKING_REQUESTS_START = 'FETCH_BOOKING_REQUESTS_START'
export const FETCH_BOOKING_REQUESTS_SUCCESS = 'FETCH_BOOKING_REQUESTS_SUCCESS'
export const FETCH_BOOKING_REQUESTS_FAIL = 'FETCH_BOOKING_REQUESTS_FAIL'

export const FETCH_DELETE_RESERVA_SUCCESS = 'FETCH_DELETE_RESERVA_SUCCESS'
export const FETCH_DELETE_RESERVA_FAIL = 'FETCH_DELETE_RESERVA_FAIL'
export const FETCH_DELETE_RESERVA_START = 'FETCH_DELETE_RESERVA_START'

export const GENERATE_BOOKING_SUCCESS = 'GENERATE_BOOKING_SUCCESS'
export const GENERATE_BOOKING_FAIL = 'GENERATE_BOOKING_FAIL'
export const GENERATE_BOOKING_START = 'GENERATE_BOOKING_START'

export const FETCH_NO_OWNERS_SUCCESS = 'FETCH_NO_OWNERS_SUCCESS'
export const FETCH_NO_OWNERS_FAIL = 'FETCH_NO_OWNERS_FAIL'
export const FETCH_NO_OWNERS_START = 'FETCH_NO_OWNERS_START'

export const UPLOAD_TYC_SUCCESS = 'UPLOAD_TYC_SUCCESS'
export const UPLOAD_TYC_FAIL = 'UPLOAD_TYC_FAIL'
export const UPLOAD_TYC_START = 'UPLOAD_TYC_START'

export const DELETE_TYC_SUCCESS = 'DELETE_TYC_SUCCESS'
export const DELETE_TYC_FAIL = 'DELETE_TYC_FAIL'
export const DELETE_TYC_START = 'DELETE_TYC_START'


//NEIGHBOURHOOD
export const FETCH_DEFAULT_ACTIVITIES_START = 'FETCH_DEFAULT_ACTIVITIES_START'
export const FETCH_DEFAULT_ACTIVITIES_SUCCESS = 'FETCH_DEFAULT_ACTIVITIES_SUCCESS'
export const FETCH_DEFAULT_ACTIVITIES_FAIL = 'FETCH_DEFAULT_ACTIVITIES_FAIL'
export const FETCH_DEFAULT_CATEGORIES_START = 'FETCH_DEFAULT_CATEGORIES_START'
export const FETCH_DEFAULT_CATEGORIES_SUCCESS = 'FETCH_DEFAULT_CATEGORIES_SUCCESS'
export const FETCH_DEFAULT_CATEGORIES_FAIL = 'FETCH_DEFAULT_CATEGORIES_FAIL'

//BILLETERA
export const FETCH_TRANSACTION_LIST_FAIL = ' FETCH_TRANSACTION_LIST_FAIL'
export const FETCH_TRANSACTION_LIST_START = ' FETCH_TRANSACTION_LIST_START'
export const FETCH_TRANSACTION_LIST_SUCCESS = ' FETCH_TRANSACTION_LIST_SUCCESS'
export const FETCH_TRANSACTION_QR_FAIL = 'FETCH_TRANSACTION_QR_FAIL'
export const FETCH_TRANSACTION_QR_START = 'FETCH_TRANSACTION_QR_START'
export const FETCH_TRANSACTION_QR_SUCCESS = 'FETCH_TRANSACTION_QR_SUCCESS'
export const FETCH_REQUESTED_TRANSACTION_SUCCESS = 'FETCH_REQUESTED_TRANSACTION_SUCCESS'
export const FETCH_REQUESTED_TRANSACTION_FAIL = 'FETCH_REQUESTED_TRANSACTION_FAIL'
export const FETCH_REQUESTED_TRANSACTION_START = 'FETCH_REQUESTED_TRANSACTION_START'
export const FETCH_GET_CODE_QR_IMAGE_START = 'FETCH_GET_CODE_QR_IMAGE_START'
export const FETCH_GET_CODE_QR_IMAGE_FAIL = 'FETCH_GET_CODE_QR_IMAGE_FAIL'
export const FETCH_GET_CODE_QR_IMAGE_SUCCESS = 'FETCH_GET_CODE_QR_IMAGE_SUCCESS'
export const FETCH_GET_ACCOUNT_SUCCESS = 'FETCH_GET_ACCOUNT_SUCCESS'
export const FETCH_GET_ACCOUNT_FAIL = 'FETCH_GET_ACCOUNT_FAIL'
export const FETCH_GET_ACCOUNT_START = 'FETCH_GET_ACCOUNT_START'
export const FETCH_GET_ACCOUNT_BY_LOTE_SUCCESS = 'FETCH_GET_ACCOUNT_BY_LOTE_SUCCESS'
export const FETCH_GET_ACCOUNT_BY_LOTE_FAIL = 'FETCH_GET_ACCOUNT_BY_LOTE_FAIL'
export const FETCH_GET_ACCOUNT_BY_LOTE_START = 'FETCH_GET_ACCOUNT_BY_LOTE_START'
export const FETCH_GET_ACCOUNT_DEBIT_SUCCESS = 'FETCH_GET_ACCOUNT_DEBIT_SUCCESS'
export const FETCH_GET_ACCOUNT_DEBIT_FAIL = 'FETCH_GET_ACCOUNT_DEBIT_FAIL'
export const FETCH_GET_ACCOUNT_DEBIT_START = 'FETCH_GET_ACCOUNT_DEBIT_START'
export const FETCH_CREATE_TRANSACTION_REQUEST_SUCCESS = 'FETCH_CREATE_TRANSACTION_REQUEST_SUCCESS'
export const FETCH_CREATE_TRANSACTION_REQUEST_FAIL = 'FETCH_CREATE_TRANSACTION_REQUEST_FAIL'
export const FETCH_CREATE_TRANSACTION_REQUEST_START = 'FETCH_CREATE_TRANSACTION_REQUEST_START'
export const FETCH_GET_NUMBER_OF_TRANSFER_PENDING_SUCCESS = 'FETCH_GET_NUMBER_OF_TRANSFER_PENDING_SUCCESS'
export const FETCH_GET_NUMBER_OF_TRANSFER_PENDING_FAIL = 'FETCH_GET_NUMBER_OF_TRANSFER_PENDING_FAIL'
export const FETCH_GET_NUMBER_OF_TRANSFER_PENDING_START = 'FETCH_GET_NUMBER_OF_TRANSFER_PENDING_START'
export const FETCH_GET_TRANSFER_PENDING_SUCCESS = 'FETCH_GET_TRANSFER_PENDING_SUCCESS'
export const FETCH_GET_TRANSFER_PENDING_FAIL = 'FETCH_GET_TRANSFER_PENDING_FAIL'
export const FETCH_GET_TRANSFER_PENDING_START = 'FETCH_GET_TRANSFER_PENDING_START'
export const FETCH_APPROVE_TRANSACTION_SUCCESS = 'FETCH_APPROVE_TRANSACTION_SUCCESS'
export const FETCH_APPROVE_TRANSACTION_FAIL = 'FETCH_APPROVE_TRANSACTION_FAIL'
export const FETCH_APPROVE_TRANSACTION_START = 'FETCH_APPROVE_TRANSACTION_START'
export const FETCH_DENIED_TRANSACTION_SUCCESS = 'FETCH_DENIED_TRANSACTION_SUCCESS'
export const FETCH_DENIED_TRANSACTION_FAIL = 'FETCH_DENIED_TRANSACTION_FAIL'
export const FETCH_DENIED_TRANSACTION_START = 'FETCH_DENIED_TRANSACTION_START'
export const FETCH_RESEND_TOKEN_SUCCESS = 'FETCH_RESEND_TOKEN_SUCCESS'
export const FETCH_RESEND_TOKEN_FAIL = 'FETCH_RESEND_TOKEN_FAIL'
export const FETCH_RESEND_TOKEN_START = 'FETCH_RESEND_TOKEN_START'
export const FETCH_GET_ACCOUNT_QR_SUCCESS = 'FETCH_GET_ACCOUNT_QR_SUCCESS'
export const FETCH_GET_ACCOUNT_QR_FAIL = 'FETCH_GET_ACCOUNT_QR_FAIL'
export const FETCH_GET_ACCOUNT_QR_START = 'FETCH_GET_ACCOUNT_QR_START'
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS'
export const FETCH_BALANCE_FAIL = 'FETCH_BALANCE_FAIL'
export const FETCH_BALANCE_START = 'FETCH_BALANCE_START'
export const FETCH_TRANSACTION_CHARGE_CARD_SUCCESS = 'FETCH_TRANSACTION_CHARGE_CARD_SUCCESS'
export const FETCH_TRANSACTION_CHARGE_CARD_FAIL = 'FETCH_TRANSACTION_CHARGE_CARD_FAIL'
export const FETCH_TRANSACTION_CHARGE_CARD_START = 'FETCH_TRANSACTION_CHARGE_CARD_START'


export const FETCH_TRANSACTION_DATA_SUCCESS = 'FETCH_TRANSACTION_DATA_SUCCESS'
export const FETCH_TRANSACTION_DATA_FAIL = 'FETCH_TRANSACTION_DATA_FAIL'
export const FETCH_TRANSACTION_DATA_START = 'FETCH_TRANSACTION_DATA_START'

//EXPENSAS
export const FETCH_GENERATE_EXPENSAS_SUCCESS = 'FETCH_GENERATE_EXPENSAS_SUCCESS'
export const FETCH_GENERATE_EXPENSAS_START = 'FETCH_GENERATE_EXPENSAS_START'
export const FETCH_GENERATE_EXPENSAS_FAIL = 'FETCH_GENERATE_EXPENSAS_FAIL'

export const FETCH_AUTOMATIC_GENERATE_EXPENSAS_SUCCESS = 'FETCH_AUTOMATIC_GENERATE_EXPENSAS_SUCCESS'
export const FETCH_AUTOMATIC_GENERATE_EXPENSAS_START = 'FETCH_AUTOMATIC_GENERATE_EXPENSAS_START'
export const FETCH_AUTOMATIC_GENERATE_EXPENSAS_FAIL = 'FETCH_AUTOMATIC_GENERATE_EXPENSAS_FAIL'
export const FETCH_AUTOMATIC_GENERATE_EXPENSAS_RESET = 'FETCH_AUTOMATIC_GENERATE_EXPENSAS_RESET'

export const FETCH_GET_HOME_EXPENSAS_SUCCESS = 'FETCH_GET_HOME_EXPENSAS_SUCCESS'
export const FETCH_GET_HOME_EXPENSAS_START = 'FETCH_GET_HOME_EXPENSAS_START'
export const FETCH_GET_HOME_EXPENSAS_FAIL = 'FETCH_GET_HOME_EXPENSAS_FAIL'
export const FETCH_GET_EXPENSAS_CLIENTE_SUCCESS = 'FETCH_GET_EXPENSAS_CLIENTE_SUCCESS'
export const FETCH_GET_EXPENSAS_CLIENTE_START = 'FETCH_GET_EXPENSAS_CLIENTE_START'
export const FETCH_GET_EXPENSAS_CLIENTE_FAIL = 'FETCH_GET_EXPENSAS_CLIENTE_FAIL'
export const FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS = 'FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS'
export const FETCH_GET_FILE_DETAIL_CLIENT_START = 'FETCH_GET_FILE_DETAIL_CLIENT_START'
export const FETCH_GET_FILE_DETAIL_CLIENT_FAIL = 'FETCH_GET_FILE_DETAIL_CLIENT_FAIL'
export const FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_SUCCESS = 'FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_SUCCESS'
export const FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_START = 'FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_START'
export const FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_FAIL = 'FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_FAIL'

export const FETCH_POST_UPLOAD_FILE_SUCCESS = 'FETCH_POST_UPLOAD_FILE_SUCCESS'
export const FETCH_POST_UPLOAD_FILE_START = 'FETCH_POST_UPLOAD_FILE_START'
export const FETCH_POST_UPLOAD_FILE_FAIL = 'FETCH_POST_UPLOAD_FILE_FAIL'

export const FETCH_EXPENSES_REPORT_SUCCESS = 'FETCH_EXPENSES_REPORT_SUCCESS'
export const FETCH_EXPENSES_REPORT_FAIL = 'FETCH_EXPENSES_REPORT_FAIL'
export const FETCH_EXPENSES_REPORT_START = 'FETCH_EXPENSES_REPORT_START'

//PROFILE
export const FETCH_GET_ROLES_SUCCESS = 'FETCH_GET_ROLES_SUCCESS'
export const FETCH_GET_ROLES_FAIL = 'FETCH_GET_ROLES_FAIL'
export const FETCH_GET_ROLES_START = 'FETCH_GET_ROLES_START'

export const FETCH_GET_MENUS_SUCCESS = 'FETCH_GET_MENUS_SUCCESS'
export const FETCH_GET_MENUS_FAIL = 'FETCH_GET_MENUS_FAIL'
export const FETCH_GET_MENUS_START = 'FETCH_GET_MENUS_START'

export const FETCH_GET_PERSON_SUCCESS = 'FETCH_GET_PERSON_SUCCESS'
export const FETCH_GET_PERSON_FAIL = 'FETCH_GET_PERSON_FAIL'
export const FETCH_GET_PERSON_START = 'FETCH_GET_PERSON_START'

export const FETCH_REFRESH_MENUS_FOR_ROLE_SUCCESS = 'FETCH_REFRESH_MENUS_FOR_ROLE_SUCCESS'
export const FETCH_REFRESH_MENUS_FOR_ROLE_FAIL = 'FETCH_REFRESH_MENUS_FOR_ROLE_FAIL'
export const FETCH_REFRESH_MENUS_FOR_ROLE_START = 'FETCH_REFRESH_MENUS_FOR_ROLE_START'


//ACCESOS

export const FETCH_GET_GUEST_SUCCESS = 'FETCH_GET_GUEST_SUCCESS'
export const FETCH_GET_GUEST_FAIL = 'FETCH_GET_GUEST_FAIL'
export const FETCH_GET_GUEST_START = 'FETCH_GET_GUEST_START'
export const CLEAR_ACCESS_STORE = 'CLEAR_ACCESS_STORE'
export const FETCH_CHECK_IN_SUCCESS = 'FETCH_CHECK_IN_SUCCESS'
export const FETCH_CHECK_IN_FAIL = 'FETCH_CHECK_IN_FAIL'
export const FETCH_CHECK_IN_START = 'FETCH_CHECK_IN_START'
export const FETCH_VEHICLES_BRAND_START = 'FETCH_VEHICLES_BRAND_START'
export const FETCH_VEHICLES_BRAND_SUCCESS = 'FETCH_VEHICLES_BRAND_SUCCESS'
export const FETCH_VEHICLES_BRAND_FAIL = 'FETCH_VEHICLES_BRAND_FAIL'
export const FETCH_VEHICLES_MODEL_START = 'FETCH_VEHICLES_MODEL_START'
export const FETCH_VEHICLES_MODEL_SUCCESS = 'FETCH_VEHICLES_MODEL_SUCCESS'
export const FETCH_VEHICLES_MODEL_FAIL = 'FETCH_VEHICLES_MODEL_FAIL'
export const FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_FAIL = 'FETCH_NOTIFICATIONS_FAIL'
export const FETCH_MENSAJERIA_START = 'FETCH_MENSAJERIA_START'
export const FETCH_MENSAJERIA_SUCCESS = 'FETCH_MENSAJERIA_SUCCESS'
export const FETCH_MENSAJERIA_FAIL = 'FETCH_MENSAJERIA_FAIL'
export const FETCH_REGISTROS_START = 'FETCH_REGISTROS_START'
export const FETCH_REGISTROS_SUCCESS = 'FETCH_REGISTROS_SUCCESS'
export const FETCH_REGISTROS_FAIL = 'FETCH_REGISTROS_FAIL'



//TICKETS

export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS'
export const FETCH_TICKETS_FAIL = 'FETCH_TICKETS_FAIL'
export const FETCH_TICKETS_START = 'FETCH_TICKETS_START'

export const FETCH_CATEGORIES_BY_ROLE_SUCCESS = 'FETCH_CATEGORIES_BY_ROLE_SUCCESS'
export const FETCH_CATEGORIES_BY_ROLE_FAIL = 'FETCH_CATEGORIES_BY_ROLE_FAIL'
export const FETCH_CATEGORIES_BY_ROLE_START = 'FETCH_CATEGORIES_BY_ROLE_START'

export const FETCH_TICKET_MESSAGES_SUCCESS = 'FETCH_TICKET_MESSAGES_SUCCESS'
export const FETCH_TICKET_MESSAGES_FAIL = 'FETCH_TICKET_MESSAGES_FAIL'
export const FETCH_TICKET_MESSAGES_START = 'FETCH_TICKET_MESSAGES_START'

export const SEND_TICKET_MESSAGE_SUCCESS = 'SEND_TICKET_MESSAGE_SUCCESS'
export const SEND_TICKET_MESSAGE_FAIL = 'SEND_TICKET_MESSAGE_FAIL'
export const SEND_TICKET_MESSAGE_START = 'SEND_TICKET_MESSAGE_START'

export const CLOSE_TICKET_SUCCESS = 'CLOSE_TICKET_SUCCESS'
export const CLOSE_TICKET_FAIL = 'CLOSE_TICKET_FAIL'
export const CLOSE_TICKET_START = 'CLOSE_TICKET_START'

export const PENDING_TICKET_SUCCESS = 'PENDING_TICKET_SUCCESS'
export const PENDING_TICKET_FAIL = 'PENDING_TICKET_FAIL'
export const PENDING_TICKET_START = 'PENDING_TICKET_START'

export const CHANGE_CATEGORY_TICKET_SUCCESS = 'CHANGE_CATEGORY_TICKET_SUCCESS'
export const CHANGE_CATEGORY_TICKET_FAIL = 'CHANGE_CATEGORY_TICKET_FAIL'
export const CHANGE_CATEGORY_TICKET_START = 'CHANGE_CATEGORY_TICKET_START'

export const SEARCH_TICKETS_SUCCESS = 'SEARCH_TICKETS_SUCCESS'
export const SEARCH_TICKETS_FAIL = 'SEARCH_TICKETS_FAIL'
export const SEARCH_TICKETS_START = 'SEARCH_TICKETS_START'

export const FETCH_PUT_INVITE_SUCCESS = 'FETCH_PUT_INVITE_SUCCESS'
export const FETCH_PUT_INVITE_FAIL = 'FETCH_PUT_INVITE_FAIL'
export const FETCH_PUT_INVITE_START = 'FETCH_PUT_INVITE_START'

export const FETCH_POST_INVITE_SUCCESS = 'FETCH_POST_INVITE_SUCCESS'
export const FETCH_POST_INVITE_FAIL = 'FETCH_POST_INVITE_FAIL'
export const FETCH_POST_INVITE_START = 'FETCH_POST_INVITE_START'

export const FETCH_CHECK_OUT_SUCCESS = 'FETCH_CHECK_OUT_SUCCESS'
export const FETCH_CHECK_OUT_FAIL = 'FETCH_CHECK_OUT_FAIL'
export const FETCH_CHECK_OUT_START = 'FETCH_CHECK_OUT_START'

export const FETCH_SEARCH_INVITES_SUCCESS = 'FETCH_SEARCH_INVITES_SUCCESS'
export const FETCH_SEARCH_INVITES_FAIL = 'FETCH_SEARCH_INVITES_FAIL'
export const FETCH_SEARCH_INVITES_START = 'FETCH_SEARCH_INVITES_START'
export const CLEAR_SEARCHED_INVITES = 'CLEAR_SEARCHED_INVITES'

export const FETCH_TICKET_CATEGORIES_SUCCESS = 'FETCH_TICKET_CATEGORIES_SUCCESS'
export const FETCH_TICKET_CATEGORIES_FAIL = 'FETCH_TICKET_CATEGORIES_FAIL'
export const FETCH_TICKET_CATEGORIES_START = 'FETCH_TICKET_CATEGORIES_START'

export const FETCH_TICKET_TYPES_BY_CATEGORY_SUCCESS = 'FETCH_TICKET_TYPES_BY_CATEGORY_SUCCESS'
export const FETCH_TICKET_TYPES_BY_CATEGORY_FAIL = 'FETCH_TICKET_TYPES_BY_CATEGORY_FAIL'
export const FETCH_TICKET_TYPES_BY_CATEGORY_START = 'FETCH_TICKET_TYPES_BY_CATEGORY_START'

// usuarios
export const FETCH_GETUSERS_SUCCESS = 'FETCH_GETUSERS_SUCCESS'
export const FETCH_GETUSERS_FAIL = 'FETCH_GETUSERS_FAIL'
export const FETCH_GETUSERS_START = 'FETCH_GETUSERS_START'

//crear
export const FETCH_NEW_USER_SUCCESS = 'FETCH_NEW_USER_SUCCESS'
export const FETCH_NEW_USER_FAIL = 'FETCH_NEW_USER_FAIL'
export const FETCH_NEW_USER_START = 'FETCH_NEW_USER_START'

//editar
export const FETCH_EDIT_USER_SUCCESS = 'FETCH_EDIT_USER_SUCCESS'
export const FETCH_EDIT_USER_FAIL = 'FETCH_EDIT_USER_FAIL'
export const FETCH_EDIT_USER_START = 'FETCH_EDIT_USER_START'
export const FETCH_EDIT_USER_RESET = 'FETCH_EDIT_USER_RESET'

//eliminar
export const DELETE_USUARIO_SUCCESS = 'DELETE_USUARIO_SUCCESS'
export const DELETE_USUARIO_FAIL = 'DELETE_USUARIO_FAIL'
export const DELETE_USUARIO_START = 'DELETE_USUARIO_START'

//accesos
export const FETCH_LOTS_SUCCESS = 'FETCH_LOTS_SUCCESS'
export const FETCH_LOTS_FAIL = 'FETCH_LOTS_FAIL'
export const FETCH_LOTS_START = 'FETCH_LOTS_START'

//Clasificados
export const FETCH_GET_PRODUCTS_SUCCESS = 'FETCH_GET_PRODUCTS_SUCCESS'
export const FETCH_GET_PRODUCTS_FAIL = 'FETCH_GET_PRODUCTS_FAIL'
export const FETCH_GET_PRODUCTS_START = 'FETCH_GET_PRODUCTS_START'
export const FETCH_DETAIL_PRODUCT_SUCCESS = 'FETCH_DETAIL_PRODUCT_SUCCESS'
export const FETCH_DETAIL_PRODUCT_FAIL = 'FETCH_DETAIL_PRODUCT_FAIL'
export const FETCH_DETAIL_PRODUCT_START = 'FETCH_DETAIL_PRODUCT_START'
export const FETCH_DELETE_PRODUCT_SUCCESS = 'FETCH_DELETE_PRODUCT_SUCCESS'
export const FETCH_DELETE_PRODUCT_FAIL = 'FETCH_DELETE_PRODUCT_FAIL'
export const FETCH_DELETE_PRODUCT_START = 'FETCH_DELETE_PRODUCT_START'
export const FETCH_DELETE_PRODUCT_RESET = 'FETCH_DELETE_PRODUCT_RESET'
export const FETCH_ADD_PRODUCT_SUCCESS = 'FETCH_ADD_PRODUCT_SUCCESS'
export const FETCH_ADD_PRODUCT_FAIL = 'FETCH_ADD_PRODUCT_FAIL'
export const FETCH_ADD_PRODUCT_START = 'FETCH_ADD_PRODUCT_START'
export const FETCH_ADD_PRODUCT_RESET = 'FETCH_ADD_PRODUCT_RESET'
export const FETCH_EDIT_PRODUCT_SUCCESS = 'FETCH_EDIT_PRODUCT_SUCCESS'
export const FETCH_EDIT_PRODUCT_FAIL = 'FETCH_EDIT_PRODUCT_FAIL'
export const FETCH_EDIT_PRODUCT_START = 'FETCH_EDIT_PRODUCT_START'
export const FETCH_EDIT_PRODUCT_RESET = 'FETCH_EDIT_PRODUCT_RESET'
export const FETCH_GET_CATEGORIES_SUCCESS = 'FETCH_GET_CATEGORIES_SUCCESS'
export const FETCH_GET_CATEGORIES_START = 'FETCH_GET_CATEGORIES_START'
export const FETCH_GET_CATEGORIES_FAIL = 'FETCH_GET_CATEGORIES_FAIL'
export const FETCH_GET_CATEGORIES_RESET = 'FETCH_GET_CATEGORIES_RESET'
export const FETCH_GET_SHIPPING_SUCCESS = 'FETCH_GET_SHIPPING_SUCCESS'
export const FETCH_GET_SHIPPING_START = 'FETCH_GET_SHIPPING_START'
export const FETCH_GET_SHIPPING_FAIL = 'FETCH_GET_SHIPPING_FAIL'
export const FETCH_GET_SHIPPING_RESET = 'FETCH_GET_SHIPPING_RESET'
export const FETCH_GET_CONDITIONS_SUCCESS = 'FETCH_GET_CONDITIONS_SUCCESS'
export const FETCH_GET_CONDITIONS_START = 'FETCH_GET_CONDITIONS_START'
export const FETCH_GET_CONDITIONS_FAIL = 'FETCH_GET_CONDITIONS_FAIL'
export const FETCH_GET_CONDITIONS_RESET = 'FETCH_GET_CONDITIONS_RESET'
export const FETCH_GET_PAYMENT_SUCCESS = 'FETCH_GET_PAYMENT_SUCCESS'
export const FETCH_GET_PAYMENT_START = 'FETCH_GET_PAYMENT_START'
export const FETCH_GET_PAYMENT_FAIL = 'FETCH_GET_PAYMENT_FAIL'
export const FETCH_GET_PAYMENT_RESET = 'FETCH_GET_PAYMENT_RESET'
export const FETCH_DESPACHOS_SUCCESS = 'FETCH_DESPACHOS_SUCCESS'
export const FETCH_DESPACHOS_START = 'FETCH_DESPACHOS_START'
export const FETCH_DESPACHOS_FAIL = 'FETCH_DESPACHOS_FAIL'
export const FETCH_DESPACHOS_RESET = 'FETCH_DESPACHOS_RESET'
export const FETCH_MSG_SUCCESS = 'FETCH_MSG_SUCCESS'
export const FETCH_MSG_START = 'FETCH_MSG_START'
export const FETCH_MSG_FAIL = 'FETCH_MSG_FAIL'
export const FETCH_MSG_RESET = 'FETCH_MSG_RESET'
export const SEND_MSG_START = 'SEND_MSG_START'
export const SEND_MSG_FAIL = 'SEND_MSG_FAIL'
export const SEND_MSG_SUCCESS = 'SEND_MSG_SUCCESS'
export const SEND_MSG_RESET = 'SEND_MSG_RESET'
export const GET_MSG_SUCCESS = 'GET_MSG_SUCCESS'
export const GET_MSG_START = 'GET_MSG_START'
export const GET_MSG_FAIL = 'GET_MSG_FAIL'
export const GET_MSG_RESET = 'GET_MSG_RESET'
export const FETCH_UPLOAD_PRODUCTS_FILE_SUCCESS = 'FETCH_UPLOAD_PRODUCTS_FILE_SUCCESS'
export const FETCH_UPLOAD_PRODUCTS_FILE_START = 'FETCH_UPLOAD_PRODUCTS_FILE_START'
export const FETCH_UPLOAD_PRODUCTS_FILE_FAIL = 'FETCH_UPLOAD_PRODUCTS_FILE_FAIL'
export const FETCH_UPLOAD_PRODUCTS_FILE_RESET = 'FETCH_UPLOAD_PRODUCTS_FILE_RESET'
export const NEW_CONVER_SUCCESS = 'NEW_CONVER_SUCCESS'
export const NEW_CONVER_START = 'NEW_CONVER_START'
export const NEW_CONVER_FAIL = 'NEW_CONVER_FAIL'
export const NEW_CONVER_RESET = 'NEW_CONVER_RESET'
export const FETCH_VENTAS_SUCCESS = 'FETCH_VENTAS_SUCCESS'
export const FETCH_VENTAS_START = 'FETCH_VENTAS_START'
export const FETCH_VENTAS_FAIL = 'FETCH_VENTAS_FAIL'
export const FETCH_VENTAS_RESET = 'FETCH_VENTAS_RESET'
export const FETCH_READY_TO_DELIVER_SUCCESS = 'FETCH_READY_TO_DELIVER_SUCCESS'
export const FETCH_READY_TO_DELIVER_START = 'FETCH_READY_TO_DELIVER_START'
export const FETCH_READY_TO_DELIVER_FAIL = 'FETCH_READY_TO_DELIVER_FAIL'
export const FETCH_READY_TO_DELIVER_RESET = 'FETCH_READY_TO_DELIVER_RESET'
export const FETCH_GET_QR_ORDER_SUCCESS = 'FETCH_GET_QR_ORDER_SUCCESS'
export const FETCH_GET_QR_ORDER_START = 'FETCH_GET_QR_ORDER_START'
export const FETCH_GET_QR_ORDER_FAIL = 'FETCH_GET_QR_ORDER_FAIL'
export const FETCH_GET_QR_ORDER_RESET = 'FETCH_GET_QR_ORDER_RESET'// Reportes - Mascotas
export const FETCH_REPORTE_MASCOTAS_SUCCESS = 'FETCH_REPORTES_MASCOTAS_SUCCESS'
export const FETCH_REPORTE_MASCOTAS_FAIL = 'FETCH_REPORTES_MASCOTAS_FAIL'
export const FETCH_REPORTE_MASCOTAS_START = 'FETCH_REPORTES_MASCOTAS_START'
export const DISCLAIMER_METODO_PAGO_SUCCESS = 'DISCLAIMER_METODO__PAGO_SUCCESS'
export const DISCLAIMER_METODO_PAGO_FAIL = 'DISCLAIMER_METODO_PAGO_FAIL'
export const DISCLAIMER_METODO_PAGO_START = 'DISCLAIMER_METODO_PAGO_START'
export const DISCLAIMER_METODO_PAGO_RESET = 'DISCLAIMER_METODO_PAGO_RESET'
// Reportes - Tickets
export const FETCH_REPORTE_TICKETS_SUCCESS = 'FETCH_REPORTES_TICKETS_SUCCESS'
export const FETCH_REPORTE_TICKETS_FAIL = 'FETCH_REPORTES_TICKETS_FAIL'
export const FETCH_REPORTE_TICKETS_START = 'FETCH_REPORTES_TICKETS_START'

// Reportes - Reservas
export const FETCH_BOOKINGREPORT_REQUEST_FAIL = 'FETCH_BOOKINGREPORT_REQUEST_FAIL'
export const FETCH_BOOKINGREPORT_REQUEST_SUCCESS = 'FETCH_BOOKINGREPORT_REQUEST_SUCCESS'
export const FETCH_BOOKINGREPORT_REQUEST_START = 'FETCH_BOOKINGREPORT_REQUEST_START'


// Fundacion
export const FETCH_FUNDACION_USERS_FAIL = 'FETCH_FUNDACION_USERS_FAIL'
export const FETCH_FUNDACION_USERS_SUCCESS = 'FETCH_FUNDACION_USERS_SUCCESS'
export const FETCH_FUNDACION_USERS_START = 'FETCH_FUNDACION_USERS_START'

export const FETCH_STORIES_FAIL = 'FETCH_STORIES_FAIL'
export const FETCH_STORIES_SUCCESS = 'FETCH_STORIES_SUCCESS'
export const FETCH_STORIES_START = 'FETCH_STORIES_START'
export const FETCH_DELETE_STORY_RESET = 'FETCH_DELETE_STORY_RESET'
export const FETCH_DELETE_STORY_START = 'FETCH_DELETE_STORY_START'
export const FETCH_DELETE_STORY_FAIL = 'FETCH_DELETE_STORY_FAIL'
export const FETCH_DELETE_STORY_SUCCESS = 'FETCH_DELETE_STORY_SUCCESS'
export const FETCH_DETAIL_STORY_START = 'FETCH_DETAIL_STORY_START'
export const FETCH_DETAIL_STORY_FAIL = 'FETCH_DETAIL_STORY_FAIL'
export const FETCH_DETAIL_STORY_SUCCESS = 'FETCH_DETAIL_STORY_SUCCESS'
export const FETCH_DETAIL_STORY_RESET = 'FETCH_DETAIL_STORY_RESET'
export const CREATE_NEW_STORY_START = 'CREATE_NEW_STORY_START'
export const CREATE_NEW_STORY_FAIL = 'CREATE_NEW_STORY_FAIL'
export const CREATE_NEW_STORY_SUCCESS = 'CREATE_NEW_STORY_SUCCESS'
export const CREATE_NEW_STORY_RESET = 'CREATE_NEW_STORY_RESET'
export const CREATE_NEW_STORY_UPDATE_PROGRESS = 'CREATE_NEW_STORY_UPDATE_PROGRESS'
export const EDIT_STORY_START = 'EDIT_STORY_START'
export const EDIT_STORY_FAIL = 'EDIT_STORY_FAIL'
export const EDIT_STORY_SUCCESS = 'EDIT_STORY_SUCCESS'
export const EDIT_STORY_RESET = 'EDIT_STORY_RESET'
export const EDIT_STORY_UPDATE_PROGRESS = 'EDIT_STORY_UPDATE_PROGRESS'
export const FETCH_MOVIES_FAIL = 'FETCH_MOVIES_FAIL'
export const FETCH_MOVIES_SUCCESS = 'FETCH_MOVIES_SUCCESS'
export const FETCH_MOVIES_START = 'FETCH_MOVIES_START'
export const FETCH_DELETE_MOVIE_RESET = 'FETCH_DELETE_MOVIE_RESET'
export const FETCH_DELETE_MOVIE_START = 'FETCH_DELETE_MOVIE_START'
export const FETCH_DELETE_MOVIE_FAIL = 'FETCH_DELETE_MOVIE_FAIL'
export const FETCH_DELETE_MOVIE_SUCCESS = 'FETCH_DELETE_MOVIE_SUCCESS'

export const FETCH_DETAIL_MOVIE_START = 'FETCH_DETAIL_MOVIE_START'
export const FETCH_DETAIL_MOVIE_FAIL = 'FETCH_DETAIL_MOVIE_FAIL'
export const FETCH_DETAIL_MOVIE_SUCCESS = 'FETCH_DETAIL_MOVIE_SUCCESS'
export const FETCH_DETAIL_MOVIE_RESET = 'FETCH_DETAIL_MOVIE_RESET'
export const CREATE_NEW_MOVIE_START = 'CREATE_NEW_MOVIE_START'
export const CREATE_NEW_MOVIE_FAIL = 'CREATE_NEW_MOVIE_FAIL'
export const CREATE_NEW_MOVIE_SUCCESS = 'CREATE_NEW_MOVIE_SUCCESS'
export const CREATE_NEW_MOVIE_RESET = 'CREATE_NEW_MOVIE_RESET'
export const CREATE_NEW_MOVIE_UPDATE_PROGRESS = 'CREATE_NEW_MOVIE_UPDATE_PROGRESS'
export const EDIT_MOVIE_START = 'EDIT_MOVIE_START'
export const EDIT_MOVIE_FAIL = 'EDIT_MOVIE_FAIL'
export const EDIT_MOVIE_SUCCESS = 'EDIT_MOVIE_SUCCESS'
export const EDIT_MOVIE_RESET = 'EDIT_MOVIE_RESET'
export const EDIT_MOVIE_UPDATE_PROGRESS = 'EDIT_MOVIE_UPDATE_PROGRESS'

export const FETCH_FUNDACION_DONACIONES_FAIL = 'FETCH_FUNDACION_DONACIONES_FAIL'
export const FETCH_FUNDACION_DONACIONES_SUCCESS = 'FETCH_FUNDACION_DONACIONES_SUCCESS'
export const FETCH_FUNDACION_DONACIONES_START = 'FETCH_FUNDACION_DONACIONES_START'
export const FETCH_FUNDACION_NEWS_FAIL = 'FETCH_FUNDACION_NEWS_FAIL'
export const FETCH_FUNDACION_NEWS_SUCCESS = 'FETCH_FUNDACION_NEWS_SUCCESS'
export const FETCH_FUNDACION_NEWS_START = 'FETCH_FUNDACION_NEWS_START'


export const FETCH_NEWS_FAIL = 'FETCH_NEWS_FAIL'
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS'
export const FETCH_NEWS_START = 'FETCH_NEWS_START'
export const FETCH_DELETE_NEWS_RESET = 'FETCH_DELETE_NEWS_RESET'
export const FETCH_DELETE_NEWS_START = 'FETCH_DELETE_NEWS_START'
export const FETCH_DELETE_NEWS_FAIL = 'FETCH_DELETE_NEWS_FAIL'
export const FETCH_DELETE_NEWS_SUCCESS = 'FETCH_DELETE_NEWS_SUCCESS'
export const FETCH_DETAIL_NEWS_START = 'FETCH_DETAIL_NEWS_START'
export const FETCH_DETAIL_NEWS_FAIL = 'FETCH_DETAIL_NEWS_FAIL'
export const FETCH_DETAIL_NEWS_SUCCESS = 'FETCH_DETAIL_NEWS_SUCCESS'
export const FETCH_DETAIL_NEWS_RESET = 'FETCH_DETAIL_NEWS_RESET'
export const CREATE_NEW_NEWS_START = 'CREATE_NEW_NEWS_START'
export const CREATE_NEW_NEWS_FAIL = 'CREATE_NEW_NEWS_FAIL'
export const CREATE_NEW_NEWS_SUCCESS = 'CREATE_NEW_NEWS_SUCCESS'
export const CREATE_NEW_NEWS_RESET = 'CREATE_NEW_NEWS_RESET'
export const EDIT_NEWS_START = 'EDIT_NEWS_START'
export const EDIT_NEWS_FAIL = 'EDIT_NEWS_FAIL'
export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS'
export const EDIT_NEWS_RESET = 'EDIT_NEWS_RESET'

// gestor landing
export const FETCH_GET_VENDOR_CONSULTAS_START = 'FETCH_GET_VENDOR_CONSULTAS_START'
export const FETCH_GET_VENDOR_CONSULTAS_SUCCESS = 'FETCH_GET_VENDOR_CONSULTAS_SUCCESS'
export const FETCH_GET_VENDOR_CONSULTAS_FAIL = 'FETCH_GET_VENDOR_CONSULTAS_FAIL'

// gestor landing
export const FETCH_GET_VENDOR_CONSULTAS_ESTADOS_START = 'FETCH_GET_VENDOR_CONSULTAS_ESTADOS_START'
export const FETCH_GET_VENDOR_CONSULTAS_ESTADOS_SUCCESS = 'FETCH_GET_VENDOR_CONSULTAS_ESTADOS_SUCCESS'
export const FETCH_GET_VENDOR_CONSULTAS_ESTADOS_FAIL = 'FETCH_GET_VENDOR_CONSULTAS_ESTADOS_FAIL'

// gestor landing
export const POST_CHANGE_STATE_START = 'POST_CHANGE_STATE_START'
export const POST_CHANGE_STATE_SUCCESS = 'POST_CHANGE_STATE_SUCCESS'
export const POST_CHANGE_STATE_FAIL = 'POST_CHANGE_STATE_FAIL'
export const POST_CHANGE_STATE_RESET = 'POST_CHANGE_STATE_RESET'

// gestor landing
export const POST_ASSIGN_USER_START = 'POST_ASSIGN_USER_START'
export const POST_ASSIGN_USER_SUCCESS = 'POST_ASSIGN_USER_SUCCESS'
export const POST_ASSIGN_USER_FAIL = 'POST_ASSIGN_USER_FAIL'
export const POST_ASSIGN_USER_RESET = 'POST_ASSIGN_USER_RESET'



// gestor landing
export const NEW_TICKET_START = 'NEW_TICKET_START'
export const NEW_TICKET_SUCCESS = 'NEW_TICKET_SUCCESS'
export const NEW_TICKET_FAIL = 'NEW_TICKET_FAIL'
export const NEW_TICKET_RESET = 'NEW_TICKET_RESET'


// gestor landing
export const EDIT_TICKET_START = 'EDIT_TICKET_START'
export const EDIT_TICKET_SUCCESS = 'EDIT_TICKET_SUCCESS'
export const EDIT_TICKET_FAIL = 'EDIT_TICKET_FAIL'
export const EDIT_TICKET_RESET = 'EDIT_TICKET_RESET'



// gestor landing
export const ENTREPENEUR_START = 'ENTREPENEUR_START'
export const ENTREPENEUR_SUCCESS = 'ENTREPENEUR_SUCCESS'
export const ENTREPENEUR_FAIL = 'ENTREPENEUR_FAIL'

export const USERS_GESTOR_START = 'USERS_GESTOR_START'
export const USERS_GESTOR_SUCCESS = 'USERS_GESTOR_SUCCESS'
export const USERS_GESTOR_FAIL = 'USERS_GESTOR_FAIL'

export const PRODUCT_TYPE_START = 'PRODUCT_TYPE_START'
export const PRODUCT_TYPE_SUCCESS = 'PRODUCT_TYPE_SUCCESS'
export const PRODUCT_TYPE_FAIL = 'PRODUCT_TYPE_FAIL'


export const GET_TICKET_START = 'GET_TICKET_START'
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS'
export const GET_TICKET_FAIL = 'GET_TICKET_FAIL'

export const GET_TICKET_HIS_START = 'GET_TICKET_HIS_START'
export const GET_TICKET_HIS_SUCCESS = 'GET_TICKET_HIS_SUCCESS'
export const GET_TICKET_HIS_FAIL = 'GET_TICKET_HIS_FAIL'

export const SAVE_TICKET_HIS_START = 'SAVE_TICKET_HIS_START'
export const SAVE_TICKET_HIS_SUCCESS = 'SAVE_TICKET_HIS_SUCCESS'
export const SAVE_TICKET_HIS_FAIL = 'SAVE_TICKET_HIS_FAIL'
