import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Page from 'components/Page';
import Section from 'components/Section';
import compose from 'recompose/compose';
import withWidth from '@material-ui/core/withWidth';
import Select from 'react-select';
import axios from 'axios';
import classNames from 'classnames';
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler';
import PageLoader from 'components/PageLoader';
import MovementList from './components/MovementList';
import CardContainer from './components/Card';
import Buttons from './components/ButtonsMain/Buttons';
import qr from 'assets/icons/qr-code.svg';
import printer from 'assets/icons/printer.svg';
import transfer from 'assets/icons/transfer.svg';
import refresh from 'assets/icons/refresh.svg';
import card from 'assets/icons/credit-card.svg';
import NewTransfer from './components/NewTransfer';
import PrintQR from './components/PrintQR';
import Modal from 'components/Modal';
import AgendarDestinatario from './components/AgendarDestinatario';
import GeneratePay from './components/GeneratePay';
import GenerateQR from './components/GenerateQR';
import AllMovements from './components/MovementList/components/AllMovements';
import AuthMovements from './components/MovementList/components/AuthMovements';
import ChargeWithCard from './components/GenerateChargeWithCard';
import { setNeighbourhood } from 'store/actions/userInfo.actions';
import Spinner from 'react-spinner-material';
import { 
  fetchGetTransferPending, 
  fetchBalance, 
  fetchGetAccountDebit, 
  fetchTransactionList, 
  fetchGetNumberOfTransferPending } from 'store/actions/billetera.actions'
import { 
  SelectWrapper, 
  TitleWrapper, 
  HeaderMovementsWrapper, 
  ListMovementsWrapper, 
  MainWrapper, 
  ContentWrapper, 
  Wrapper,
  Refresh,
  PageWrapper } from './styled';
import { setYear } from 'date-fns';

 class Billetera extends Component {
  state = {
    selectedOption: null,
    isNewTransferOpen: false,
    isNewDestinatarioOpen: false,
    isNewGeneratePayOpen: false,
    isNewGenerateQROpen: false,
    isNewAllMovementsOpen: false,
    isNewAuthMovementsOpen: false,
    isPrintQR: false,
    isChargeWithCard: false,
    movementsFiltered: [],
    neighbourhoodSelected: {
      value:  this.props.selectedNeighbourhood.value || this.props.selectedNeighbourhood.name,
      label: this.props.selectedNeighbourhood.label || this.props.selectedNeighbourhood.name,
      guid:this.props.selectedNeighbourhood.guid
    }
  }

  componentDidMount() {
    this.props.fetchBalance()
    this.props.fetchGetAccountDebit()
    this.props.fetchTransactionList()
    this.props.fetchGetNumberOfTransferPending()
    this.props.fetchGetTransferPending()

    let formattedNeighbourhoods = this.props.neighbourhoods.map(item => {
      let neighbourhood = {
        value:  item.name,
        label: item.name,
        guid: item.guid
      }
      return neighbourhood
    })
    this.setState({
      neighbourhoods: formattedNeighbourhoods,
    })
  }

  componentWillReceiveProps(nextProps){
    if(this.state.movementsFiltered !== nextProps.billetera.TransactionsList){
      this.setState({
        movementsFiltered: nextProps.billetera.TransactionsList,
      })
    }  
   }

  onOpenNewTransfer = () => {
    this.setState({ isNewTransferOpen: true })
  }
  onCloseNewTransfer = () => {
    this.setState({ isNewTransferOpen: false })
  }
  onOpenNewDestinatario = () => {
    this.setState({ isNewDestinatarioOpen: true })
  }
  onCloseNewDestinatario = () => {
    this.setState({ isNewDestinatarioOpen: false })
  }
  onOpenNewGeneratePay = () => {
    this.setState({ isNewGeneratePayOpen: true })
  }
  onCloseNewGeneratePay = () => {
    this.setState({ isNewGeneratePayOpen: false })
  }
  onOpenNewGenerateQR = () => {
    this.setState({ isNewGenerateQROpen: true })
  }
  onCloseNewGenerateQR = () => {
    this.setState({ isNewGenerateQROpen: false })
  }
  onOpenNewAllMovements = () => {
    this.setState({ isNewAllMovementsOpen: true })
  }
  onCloseNewAllMovements = () => {
    this.setState({ isNewAllMovementsOpen: false })
  }
  onOpenNewAuthMovements = () => {
    this.setState({ isNewAuthMovementsOpen: true })
  }
  onCloseNewAuthMovements = () => {
    this.setState({ isNewAuthMovementsOpen: false })
    this.props.fetchBalance()
    this.props.fetchGetNumberOfTransferPending()
  }
  onOpenPrintQR = () => {
    this.setState({ isPrintQR: true })
  }
  onClosePrintQR = () => {
    this.setState({ isPrintQR: false })
  }
  toggleChargeWithCard = () => {
    this.setState({ isChargeWithCard: !this.state.isChargeWithCard })
  }

  handleChange = (neighbourhoodSelected) => {
    this.props.setNeighbourhood(neighbourhoodSelected)
    this.setState({ neighbourhoodSelected: neighbourhoodSelected });
    
    this.props.fetchBalance()
    this.props.fetchGetAccountDebit()
    this.props.fetchTransactionList()
    this.props.fetchGetNumberOfTransferPending()
    this.props.fetchGetTransferPending() 
  }
  fetchAll = () => {
    this.props.fetchBalance()
    this.props.fetchGetAccountDebit()
    this.props.fetchTransactionList()
    this.props.fetchGetNumberOfTransferPending()
    this.props.fetchGetTransferPending()  
  }

  isLoadingContent = () => this.props.billetera.loading

  createMainView = () => (
    <Section title={'Billetera'}>
      <Wrapper>{this.props.loading ? <PageLoader /> : this.renderPageContent()}</Wrapper>
    </Section>
  )

  createNew = () => {
    if(this.state.isNewTransferOpen){
      return (
        <NewTransfer onClose={this.onCloseNewTransfer} neighbourhood={this.state.neighbourhoodSelected} username={this.props.username} />
      )
    } else if (this.state.isNewDestinatarioOpen){
      return(
        <AgendarDestinatario onClose={this.onCloseNewDestinatario} />
      )
    }else if (this.state.isNewGeneratePayOpen){
      return(
        <GeneratePay onClose={this.onCloseNewGeneratePay} neighbourhood={this.state.neighbourhoodSelected}/>
      )
    }else if (this.state.isNewGenerateQROpen){
      return(
        <GenerateQR onClose={this.onCloseNewGenerateQR} neighbourhood={this.state.neighbourhoodSelected}/>
      )
    }else if (this.state.isNewAllMovementsOpen){
      return(
        <AllMovements movements={this.props.billetera.TransactionsList} onClose={this.onCloseNewAllMovements} />
      )
    }else if (this.state.isNewAuthMovementsOpen){
      return(
        <AuthMovements onClose={this.onCloseNewAuthMovements} refresh={() => this.props.fetchGetTransferPending()} />
      )
    }else if (this.state.isChargeWithCard){
      return(
        <ChargeWithCard onClose={this.toggleChargeWithCard} neighbourhood={this.state.neighbourhoodSelected}/>
      )
    }
  }

  renderPageContent = () => (
      <ContentWrapper>
        <MainWrapper id={'scrollContainer'} className={classNames('portal-hide-scrollbars')} >
          <HeaderMovementsWrapper>
            <TitleWrapper>
                <h5>Selecciona una Billetera: </h5>
            </TitleWrapper>
              <SelectWrapper>
                  <Select
                    value={this.state.neighbourhoodSelected}
                    onChange={this.handleChange}
                    options={this.state.neighbourhoods}
                  />
              </SelectWrapper>
          </HeaderMovementsWrapper>
          {this.props.billetera.AccountDebitInfo ? (
            <CardContainer 
              amount={this.formatNumber(this.props.billetera.balance)} 
              cvu={this.props.billetera.AccountDebitInfo.Cvbu}
              error={this.props.billetera.errorTransactionsList} 
              neighbourhoodSelected={this.state.neighbourhoodSelected}
            />
          ) : (
            <Spinner/>
          )}          
          <h4 style={{margin: '20px 0px 20px 30px', textAlign: 'left'}}>¿Qué te gustaría hacer?</h4>
          <Buttons title={'Generar botón de pago'} image={qr} onOpen={this.onOpenNewGeneratePay}/>
          <Buttons title={'Generar nuevo QR'} image={qr} onOpen={this.onOpenNewGenerateQR}/>
          <Buttons title={'Transferencia'} image={transfer} onOpen={this.onOpenNewTransfer}/>
          <Buttons title={'Imprimir QR'} image={printer} onOpen={this.onOpenPrintQR}/>
          <Buttons title={'Cobro con tarjeta de crédito/débito'} image={card} onOpen={this.toggleChargeWithCard}/>
         {/* AGENDA DE DETINATARIO NO ENTRA EN MVP
         <Buttons title={'Agenda destinatarios'} image={agendar} onOpen={this.onOpenNewDestinatario}/>
         */}
        </MainWrapper>  

        <ListMovementsWrapper id={'scrollContainer'} className={classNames('portal-hide-scrollbars')} >
          <MovementList
            movements={this.state.movementsFiltered}
            filterMovements={this.filterMovements}
            onOpenAllMovements={this.onOpenNewAllMovements}  
            onCloseAllMovements={this.onCloseNewAllMovements}
            onOpenNewAuthMovements={this.onOpenNewAuthMovements}
            onCloseNewAuthMovements={this.onCloseNewAuthMovements}
            fetchAll={this.fetchAll}
          />
        </ListMovementsWrapper>
        { this.state.isPrintQR ? <Modal><PrintQR onClose={this.onClosePrintQR}/></Modal> : null }
      </ContentWrapper>
    ) 

  handleResourceClick = resource => this.props.setSelectedResource(resource)

  filterMovements = (filtercharge) => {
    const { billetera } = this.props;
    if (filtercharge.value === 'todo') {
      this.setState({movementsFiltered: billetera.TransactionsList })
    } else {
      let movementsFiltered = billetera.TransactionsList.filter( movement => {
        // if(movement.description === filtercharge.value){
        //   movementsFiltered.push(movement)
        // }
        return movement.description === filtercharge.value;
      })
      this.setState({movementsFiltered: movementsFiltered})
    }
  }

  formatNumber(num) {
    if(num){
      return 'ARS ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }else{
      return 'ARS 0'
    }
  }

  render() {
    return (
      <Page className={classNames('portal-hide-scrollbars')}>
        <PageWrapper>
          {this.state.isNewTransferOpen || this.state.isNewDestinatarioOpen || this.state.isNewGeneratePayOpen || this.state.isNewAllMovementsOpen|| this.state.isNewAuthMovementsOpen || this.state.isNewGenerateQROpen || this.state.isChargeWithCard 
          ? this.createNew()
          : this.createMainView()}
        </PageWrapper>
      </Page>
    )
  }
}

const mapStateToProps = ({
  userInfo: { neighbourhoods, selectedNeighbourhood, username },
  billetera,
}) => ({
  loading: billetera.loadingTransferRequest || billetera.loadingNumberOfTransferRequest || billetera.loadingTransferPending,
  billetera,
  neighbourhoods,
  selectedNeighbourhood,
  username
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGetTransferPending,
      fetchBalance,
      setNeighbourhood,
      fetchGetAccountDebit,
      fetchTransactionList,
      fetchGetNumberOfTransferPending,
      fetchGetTransferPending
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)((withErrorHandler(Billetera, axios)));