import React from 'react'
import { Wrapper, Container, Row, Circle, ColumnItem, StyledButton, ErrorLabel } from './styled'
import MaterialTable from 'material-table'

import StyledTabs from 'components/StyledTabs'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchRegistros } from '../../../store/actions/acceso.actions'

import Spinner from 'react-spinner-material'
import PageLoader from '../../../components/PageLoader'




class RegistrosEInformes extends React.Component {

    state = {
        transferSelected: [],
        error: '',
        newResultModal: false,
        tabEnabled: 0
    }

    componentDidMount() {
        this.props.fetchRegistros()
    }

    handleTabChange = (event, tabEnabled) => {
        this.setState({ tabEnabled })
    }

    render() {
        return (
            <>
                <Row>
                    <Wrapper>
                        <StyledTabs
                            value={this.state.tabEnabled}
                            tabs={[
                                { label: 'Todas', value: -1, id: 0 }
                            ]}
                            handleTabChange={this.handleTabChange}
                        />
                    </Wrapper>
                </Row>
                <Row>
                    <Container>
                        {this.props.loadingRegistros ? <PageLoader /> :
                            <MaterialTable
                                columns={[
                                    { title: 'Estado', field: 'state_name', },
                                    { title: 'Nombre y Apellido', field: 'guest_full_name', },
                                    { title: 'DNI', field: 'guest_dni', },
                                    { title: 'Vehículo', field: 'guest_vehicle', },
                                    { title: 'Categoría', field: 'category', },
                                    { title: 'Fecha', field: 'creation_date', },
                                    { title: 'Hora', field: 'creation_time', }
                                ]}
                                data={this.props.registros}
                                onSelectionChange={(rows) => this.onMovementClick(rows)}
                                title="Reporte e Informes"
                                options={{
                                    toolbar: true,
                                    paging: true,
                                    selection: false,
                                    exportButton: true
                                }}
                                localization={{
                                  toolbar:{
                                    exportTitle: 'Exportar',
                                    exportAriaLabel: 'Exportar',
                                    exportName: 'Exportar a CSV'
                                  }
                                }}
                            />
                        }
                    </Container>
                </Row>
            </>
        )
    }

}

const mapStateToProps = ({
    accesos, userInfo
}) => ({
    loading: accesos.loadingLots,
    loadingRegistros: accesos.loadingRegistros,
    registros: accesos.registros
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchRegistros
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrosEInformes)