import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
    loadingRoles: false,
    errorRoles: false,
    roles: [],
    persons: [],
    menus: []
  }

  
//--------------- Generate file expensas for the bank ------------------------
const fetchRolesSuccess = (state, action) => {
    return updateObject(state, {
        loadingRoles: false,
        errorRoles: false,
        roles: action.roles
    })
  }
  
  const fetchRolesFail = state => {
    return updateObject(state, { loadingRoles: false, errorRoles: true })
  }
  
  const fetchRolesStart = state => {
    return updateObject(state, { loadingRoles: true, errorRoles: false })
  }

  
//--------------- Get Menus ------------------------
const fetchgetMenusSuccess = (state, action) => {
    return updateObject(state, {
        menus: action.menus,
        loadingMenus: false,
        errorMenus: false,
    })
  }
  
  const fetchgetMenusFail = state => {
    return updateObject(state, { loadingMenus: false, errorMenus: true })
  }
  
  const fetchgetMenusStart = state => {
    return updateObject(state, { loadingMenus: true, errorMenus: false })
  }

  
//--------------- Get Menus ------------------------
const fetchgetPersonByNeighbourhoodSuccess = (state, action) => {
    return updateObject(state, {
        persons: action.persons,
        loadingPerson: false,
        erroPersons: false,
    })
  }
  
  const fetchgetPersonByNeighbourhoodFail = state => {
    return updateObject(state, { loadingPerson: false, errorPerson: true })
  }
  
  const fetchgetPersonByNeighbourhoodStart = state => {
    return updateObject(state, { loadingPerson: true, errorPerson: false })
  }
  
  
//--------------- fetch menus for roles ------------------------
const fetchRefreshMenusForRoleSuccess = (state, action) => {
    return updateObject(state, {
        successRefreshMenuForRole: action.success.success,
        loadingRefreshMenuForRole: false,
        errorRefreshMenuForRole: false,
    })
  }
  
  const fetchRefreshMenusForRoleFail = (state, action) => {
    return updateObject(state, { loadingRefreshMenuForRole: false, errorRefreshMenuForRole: action.error  })
  }
  
  const fetchRefreshMenusForRoleStart = state => {
    return updateObject(state, { loadingRefreshMenuForRole: true, errorRefreshMenuForRole: false })
  }
  
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_GET_ROLES_SUCCESS:
        return fetchRolesSuccess(state, action)
      case actionTypes.FETCH_GET_ROLES_FAIL:
        return fetchRolesFail(state, action)
      case actionTypes.FETCH_GET_ROLES_START:
        return fetchRolesStart(state, action)
        
      case actionTypes.FETCH_GET_MENUS_SUCCESS:
        return fetchgetMenusSuccess(state, action)
      case actionTypes.FETCH_GET_MENUS_FAIL:
        return fetchgetMenusFail(state, action)
      case actionTypes.FETCH_GET_MENUS_START:
        return fetchgetMenusStart(state, action)
 
      case actionTypes.FETCH_GET_PERSON_SUCCESS:
        return fetchgetPersonByNeighbourhoodSuccess(state, action)
      case actionTypes.FETCH_GET_PERSON_FAIL:
        return fetchgetPersonByNeighbourhoodFail(state, action)
      case actionTypes.FETCH_GET_PERSON_START:
        return fetchgetPersonByNeighbourhoodStart(state, action)

        
      case actionTypes.FETCH_REFRESH_MENUS_FOR_ROLE_SUCCESS:
        return fetchRefreshMenusForRoleSuccess(state, action)
      case actionTypes.FETCH_REFRESH_MENUS_FOR_ROLE_FAIL:
        return fetchRefreshMenusForRoleFail(state, action)
      case actionTypes.FETCH_REFRESH_MENUS_FOR_ROLE_START:
        return fetchRefreshMenusForRoleStart(state, action)


      default:
        return state
    }
  }
  
  export default reducer