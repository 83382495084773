import React from 'react'
import { ContentWrapper, WrapperItems, RowItem, Description, Label, Container, CloseIcon, Row, TableContainer, Circle, ColumnItem, StyledButton, ErrorLabel } from './styled'
import MaterialTable from 'material-table'

import Section from 'components/Section'
import arrowDown from 'assets/icons/arrow-bottom.png'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchMensajeria } from 'store/actions/acceso.actions'
import Spinner from 'react-spinner-material'
import mapTransferPending from 'selectors/mapTransferPending'
import NewTokenRequest from 'components/AuthPopUp';
import { fetchReSendToken } from 'store/actions/billetera.actions'
import cross from "assets/icons/cross.png"
import Modal from 'components/Modal'
import PageLoader from 'components/PageLoader';


class Mensajeria extends React.Component {

    state = {
        transferSelected: [],
        error: '',
        newResultModal: false,
    }

    componentDidMount() {
        this.props.fetchMensajeria()
    }

    render() {
        return (
            <>
                <ContentWrapper>
                    <Container>
                        <MaterialTable
                            columns={[
                                { title: 'Motivo', field: 'motivo', },
                                { title: 'Usuario', field: 'user', },
                                { title: 'Categoria', field: 'category', },
                                { title: 'Fecha', field: 'date', },
                                { title: 'Hora', field: 'time', },
                                { title: 'Acciones', field: 'actions', }
                            ]}
                            data={this.props.allMessages}
                            onSelectionChange={(rows) => this.onMovementClick(rows)}
                            title="Mensajes enviados"
                            options={{
                                toolbar: true,
                                paging: true,
                                selection: false,
                                headerStyle: {
                                    zIndex: 0
                                }
                            }} //Esconde titulo y search y pagination 
                        />
                    </Container>
                </ContentWrapper>
            </>
        )
    }

}


const mapStateToProps = ({
    accesos, userInfo
  }) => ({
    loading: accesos.loadingLots,    
  })
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        fetchMensajeria
      },
      dispatch
    )
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Mensajeria)
  