import { createSelector } from 'reselect'
import {getFormattedDate} from 'utils/dateParser'


const mapConsultasVendor = createSelector(
  consultasList => consultasList,
  consultasList =>
    consultasList.map((val, i) => ({
      pos: i,
      date: getFormattedDate(val.creationdate),
      status: val.state.code,
      statusName: val.state.name,
      nya: val.vendor.name + ' ' +  val.vendor.surname,
      subject: val.subject,
      message: val.message,
      email:val.email,
      assing: val.user,
      id: val.id,
    }))
)
export default mapConsultasVendor
