import React from 'react'
import Page from 'components/Page'
import Section from 'components/Section'
import EmptyLoader from 'components/PageLoader'
import { Wrapper, Container, Row, Circle, ColumnItem, StyledButton, ErrorLabel, ContainerImg, Icon, PageWrapper, ContainerSearchBar, SearchWrapper } from './styled'
import MaterialTable from 'material-table'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageLoader from '../../../components/PageLoader'
import { fetchMovies, fetchDeleteMovieReset, fetchDeleteMovie, fetchDetailMovie, fetchDetailMovieReset, createNewMovieReset, createNewMovie, editMovieReset, editMovie, fetchFilteredMoviesByTitle } from 'store/actions/fundacion.actions';
import DeleteModal from '../components/modalDelete'
import SearchBar from 'components/SearchBar'
import eyeIcon from 'assets/icons/eyeIcon.svg'
import editIcon from 'assets/icons/editIcon.svg'
import Delete from 'assets/icons/deletetrash.svg'
import MovieModal from '../components/movieModal'
import ModalMessage from 'components/ModalMessage'


class Movies extends React.Component {

  state = {
    filter: '',
    isFiltered: false,
    error: '',
    addMovie: false,
    deleteModal: false,
    editMovie: false,
    showDetail: null,
    idToEdit: null
  }

  componentDidMount() {
    this.props.fetchMovies()
  }

  isContentAvailable = () => this.props.fetchMoviesSuccess === true && this.props.loadingMovies === false ? true : false


  toggleAddModal = () => {
    this.setState({ addMovie: !this.state.addMovie }, () => {
      if (!this.state.addMovie) {
        this.props.createNewMovieReset();
        this.fetchMovies()
      }
    });
  }

  handleEditMovie = movieId => {
    this.props.editMovieReset();
    this.props.fetchDetailMovieReset();
    this.props.fetchDetailMovie(movieId);
    this.setState({ idToEdit: movieId }, this.toggleEditModal())
  }
  toggleEditModal = () => {
    this.setState({ editMovie: !this.state.editMovie })
    if (!this.state.editMovie) {
      this.fetchMovies()
    }
  }


  handleDetailMovie = (movieId) => {
    this.props.fetchDetailMovieReset();
    this.props.fetchDetailMovie(movieId);
    this.setState({ idToDetail: movieId }, this.toggleDetailModal(movieId))
  }

  toggleDetailModal = () => {
    this.setState({ showDetail: !this.state.showDetail }, () => {
      if (!this.state.showDetail) {
        this.fetchMovies()
      }
    });
  }
  handleDeleteMovie = (idMovie) => {
    this.setState({ idToDelete: idMovie }, this.toggleDeleteModal())
  }

  toggleDeleteModal = () => {
    this.props.fetchDeleteMovieReset();
    this.setState({ deleteModal: !this.state.deleteModal })
  }

  fetchMovies = () => {
    if (this.state.isFiltered) {
      this.props.fetchFilteredMoviesByTitle(this.state.filter);
      return;
    }

    this.props.fetchMovies();
  }
  async deleteMovie() {
    this.props.fetchDeleteMovie(this.state.idToDelete)
  }

  createMovieHandler = async (data) => {

    let formattedData = {
      title: data.title,
      description: data.description,
      movieimagebase64: data.movieImage ? data.movieImage.base64Value : null,
      movieExtension: data.movieImage ? data.movieImage.extension : null,
      movieUrl: data.movieUrl,
      movieRaw: data.movieRaw,
      order: data.order,
      isPublic: data.isPublic
    };

    await this.props.createNewMovie(formattedData);
  }

  editMovieHandler = async (data) => {
    let formattedData = {
      id: data.id,
      title: data.title,
      description: data.description,
      movieimagebase64: data.movieImage ? data.movieImage.base64Value : null,
      movieExtension: data.movieImage ? data.movieImage.extension : null,
      movieUrl: data.movieUrl,
      movieRaw: data.movieRaw,
      order: data.order,
      isPublic: data.isPublic
    };

    await this.props.editMovie(formattedData);
  }

  searchBarKeyPressHandler = e => {
    if (e.keyCode === 13) {
      if (e.target.value !== '') {
        this.setState({ isFiltered: true });
        this.props.fetchFilteredMoviesByTitle(e.target.value)
      } else {
        this.props.fetchMovies();
      }
    }
  }

  searchBarInputClearHandler = () => {
    this.setState({ filter: '', isFiltered: false });
    this.props.fetchMovies();
  }

  searchBarInputOnChange = value => {
    this.setState({ filter: value })
  }



  renderPageContent = () => {
    return (
      <SearchWrapper>
        <ContainerSearchBar>
          <SearchBar
            placeholder={'Buscar por titulo'}
            style={'width: \'70%\' '}
            value={this.state.filter}
            onChange={this.searchBarInputOnChange}
            onClear={this.searchBarInputClearHandler}
            noBorder={true}
            onKeyDown={this.searchBarKeyPressHandler}
            autofocus
          />
        </ContainerSearchBar>
        <Container>
          <MaterialTable
            columns={[
              { title: 'Titulo', field: 'title', },
              { title: 'Fecha de creación', field: 'creationDate', },
              { title: 'Orden', field: 'order', },
              { title: 'Es publico', field: 'isPublicGrid', },
              {
                title: 'Acciones', field: 'publication', render: rowData =>
                  <ContainerImg>
                    <Icon src={eyeIcon} onClick={() => this.handleDetailMovie(rowData.id)} />
                    <Icon src={editIcon} onClick={() => this.handleEditMovie(rowData.id)} />
                    <Icon src={Delete} onClick={() => this.handleDeleteMovie(rowData.id)} />
                  </ContainerImg>
              }
            ]}
            data={this.props.movies}

            localization={{
              header: {
                actions: 'Acciones',
              },
              body: { emptyDataSourceMessage: 'No hay datos para mostrar' }
            }}
            options={{
              toolbar: false,
              paging: false,
              height: '300px',
              width: '70vw',
              selection: false,
              actionsColumnIndex: -1,
              headerStyle: {
                zIndex: 0
              }
            }} //Esconde titulo y search y pagination 
          />
        </Container>
      </SearchWrapper>
    )
  }

  renderModalMessageSuccess = (message, callback) => (<ModalMessage success={true} onClose={() => callback()}>
    {message}
  </ModalMessage>);

  renderModalMessageFail = (message, callback) => (<ModalMessage success={false} onClose={() => callback()}>
    {message}
  </ModalMessage>);


  render() {


    //ADD MODAL
    let addModal = null;
    if (this.props.createNewMovieSuccess) {
      addModal = this.renderModalMessageSuccess('Se ha creado la pelicula correctamente.', this.toggleAddModal);
    }
    else if (this.props.createNewMovieFail) {
      addModal = this.renderModalMessageFail('Hubo un error al intentar crear la pelicula.',this.toggleAddModal);
    }
    else if (this.state.addMovie) {
      addModal = <MovieModal tittle="Agregar Pelicula"
        onClose={() => this.toggleAddModal()}
        onSubmit={this.createMovieHandler}
        isSubmittingMovie={this.props.loadingCreateNewMovie}
        uploadProgress={this.props.uploadMovieProgress} />;
    }

    //EDIT MODAL
    let editModal = null;
    if (this.props.editMovieSuccess) {
      editModal = this.renderModalMessageSuccess('Se ha editado la pelicula correctamente.', this.toggleEditModal);
    }
    else if (this.props.editMovieFail) {
      editModal = this.renderModalMessageFail('Hubo un error al intentar editar la pelicula.', this.toggleEditModal);
    }
    else if (this.state.editMovie) {
      editModal = <MovieModal tittle="Editar Pelicula"
        isReadOnly={false}
        onSubmit={this.editMovieHandler}
        loading={this.props.loadingDetailMovie}
        onClose={() => { this.toggleEditModal() }}
        isSubmittingMovie={this.props.loadingEditMovie}
        detailMovie={this.props.detailMovie}
        uploadProgress={this.props.uploadMovieProgress} />
    }

    //DETAIL MODAL
    let detailModal = null;
    if (this.state.showDetail) {
      detailModal = <MovieModal tittle="Detalle"
        isReadOnly={true}
        onClose={() => { this.toggleDetailModal() }}
        loading={this.props.loadingDetailMovie}
        detailMovie={this.props.detailMovie} />;
    }

    let deleteModal = null;
    if (this.state.deleteModal) {
      deleteModal = <DeleteModal success={this.props.successDeleteMovie}
        isMovie={true}
        loadingDelete={this.props.loadingDeleteMovie}
        onClose={() => this.toggleDeleteModal()}
        delete={() => this.deleteMovie()} />;
    }
    return (
      <>
        {!this.state.addStory && !this.state.editStory && !this.state.showDetail ?
          <Page>
            <PageWrapper>
              <Section title={'Listado de peliculas'}
                onBack={() => this.props.onClose()}
                btnContent={'Agregar pelicula'}
                onBtnClick={() => this.toggleAddModal()}
              >
                <Wrapper>
                  {this.isContentAvailable() ? this.renderPageContent() : <EmptyLoader />}

                </Wrapper>
              </Section>
            </PageWrapper>
          </Page>
          :
          null
        }
        {addModal}
        {editModal}
        {detailModal}
        {deleteModal}

      </>
    )
  }
}

const mapStateToProps = ({
  fundacion
}) => ({
  loadingMovies: fundacion.loadingMovies,
  movies: fundacion.movies,
  fetchMoviesSuccess: fundacion.fetchMoviesSuccess,
  successDeleteMovie: fundacion.fetchDeleteMovieSuccess,
  loadingDeleteMovie: fundacion.loadingDeleteMovie,
  loadingDetailMovie: fundacion.loadingDetailMovie,
  loadingCreateNewMovie: fundacion.loadingCreateNewMovie,
  createNewMovieSuccess: fundacion.createNewMovieSuccess,
  createNewMovieFail: fundacion.createNewMovieFail,
  fetchDetailMovieSuccess: fundacion.fetchDetailMovieSuccess,
  fetchDetailMovieFail: fundacion.fetchDetailMovieFail,
  detailMovie: fundacion.detailMovie,
  loadingEditMovie: fundacion.loadingEditMovie,
  editMovieSuccess: fundacion.editMovieSuccess,
  editMovieFail: fundacion.editMovieFail,
  uploadMovieProgress: fundacion.uploadMovieProgress,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMovies,
      fetchFilteredMoviesByTitle,
      createNewMovieReset,
      createNewMovie,
      editMovieReset,
      editMovie,
      fetchDeleteMovieReset,
      fetchDeleteMovie,
      fetchDetailMovie,
      fetchDetailMovieReset
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Movies)