import React, { Component } from 'react'
import {  Container, AvatarItem, LabelChecked,  LabelContainer,Label,DestinatarioDetailContainer,DestinatarioDetailItemContainer,DestinatarioDetailItem,StyledButton,RadioBtnContainer,ErrorLabel} from './styled';
import CheckBox from 'components/CheckBox'
import expensas from 'assets/icons/group-3.svg'
import { Avatar } from '@material-ui/core'

const formatContent = content => content ? (content.length > 20 ? `${content.substring(0, 20)}...` : content) : null
export default class ThirdStep extends Component {
    state = {
        selectedOption: false
    }

    
  handleFormSubmit = formSubmitEvent => {
    formSubmitEvent.preventDefault();
    this.props.confirmTransaction()
  };

  handleOptionChange = changeEvent => {
    this.setState({
      selectedOption:  !this.state.selectedOption
    })
  
  };  

    render() {
        return (
            <Container>
              <form onSubmit={this.handleFormSubmit}>
                <DestinatarioDetailContainer>  
                    <DestinatarioDetailItemContainer>
                      <AvatarItem>
                          <Avatar
                            style={{
                                width: '20px',
                                height: '20px',
                                padding: '20px',
                                borderColor: '#e5e5e5',
                                borderStyle: 'solid',
                                borderWidth: '0.5px',
                                backgroundColor: 'transparent'
                                }}
                          >
                            <img src={expensas} alt="todo" />
                          </Avatar>
                          <Label>{'Pago a '+this.props.transactionProgress.DestinationBankAccount.HolderName}</Label>
                      </AvatarItem>
                    </DestinatarioDetailItemContainer>
                </DestinatarioDetailContainer>
                  
                <DestinatarioDetailContainer>
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> Cuenta de origen</DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.SourceBankAccount.HolderName}</DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> Destinatario</DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} >{formatContent(this.props.transactionProgress.DestinationBankAccount.HolderName)}</DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer> 
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> Concepto</DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.Concept}</DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> Alias </DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} > {this.props.transactionProgress.DestinationBankAccount.Alias} </DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> CBU</DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.DestinationBankAccount.Cvbu}  </DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> Fecha a acreditar</DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.ExecutionDate} </DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                    <DestinatarioDetailItemContainer>
                        <DestinatarioDetailItem> Importe a transferir </DestinatarioDetailItem>
                        <DestinatarioDetailItem bold={true}>{this.props.transactionProgress.Currency+' '}${this.props.transactionProgress.Amount}</DestinatarioDetailItem>
                    </DestinatarioDetailItemContainer>
                </DestinatarioDetailContainer>
                {/* HABILITAR CUANDO SE PUEDA MANDAR MAIL EN TRANSFERENCIA
                 <RadioBtnContainer>                
                  <LabelChecked checked={this.state.selectedOption === true} onClick={this.handleOptionChange}>
                    <CheckBox
                      type="checkbox"
                      name="checkbox"
                      value="option1"
                      checked={this.state.selectedOption === true}
                      onChange={this.handleOptionChange}
                      className="form-check-input"
                    />
                      Enviar un mail de notificación al destinatario
                  </LabelChecked>
                </RadioBtnContainer>
                 */}
                 <StyledButton type="submit" value="submit">Preparar Transferencia</StyledButton>
              </form>
            </Container>
        )
    }

}
  