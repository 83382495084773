import * as actionTypes from './actionTypes'
import getApi from 'shared/api'
import mapStories from '../../selectors/mapStories'
import mapMovies from '../../selectors/mapMovies'
import mapDonations from '../../selectors/mapDonations'
import mapNews from '../../selectors/mapNews'
import {
  fetchFundacionUsersAPI,
  storiesAPI,
  moviesAPI,
  donationsAPI,
  newsAPI,
  moviesGetPresignedUrlAPI,
  storiesGetPresignedUrlAPI
}
  from 'apiConstants'

////////////////////// Users //////////////////////

export const fetchFundacionUsersSuccess = users => {
  return {
    type: actionTypes.FETCH_FUNDACION_USERS_SUCCESS,
    users: users
  }
}

export const fetchFundacionUsersFail = error => {
  return {
    type: actionTypes.FETCH_FUNDACION_USERS_FAIL,
    errorFundacionUsers: error
  }
}

export const fetchFundacionUsersStart = () => {
  return {
    type: actionTypes.FETCH_FUNDACION_USERS_START
  }
}

export const fetchFundacionUsers = () => {
  return async (dispatch, getState) => {
    dispatch(fetchFundacionUsersStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
      .get(`${fetchFundacionUsersAPI}` + "/4")
      .then(res => {
        dispatch(fetchFundacionUsersSuccess(res.data))
      })
      .catch(error => {
        dispatch(fetchFundacionUsersFail(error))
      })
  }
}

/////////////////////MOVIES////////////////////////////////

export const fetchMoviesSuccess = data => {
  return {
    type: actionTypes.FETCH_MOVIES_SUCCESS,
    data: data
  }
}

export const fetchMoviesFail = error => {
  return {
    type: actionTypes.FETCH_MOVIES_FAIL,
    error: error
  }
}

export const fetchMoviesStart = () => {
  return {
    type: actionTypes.FETCH_MOVIES_START
  }
}

export const fetchMovies = () => {
  return async (dispatch, getState) => {
    dispatch(fetchMoviesStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
      .get(`${moviesAPI}`)
      .then(res => {
        dispatch(fetchMoviesSuccess(mapMovies(res.data)))
      })
      .catch(error => {
        dispatch(fetchMoviesFail(error))
      })
  }
}

export const fetchFilteredMoviesByTitle = movieTitle => {  
  return async (dispatch, getState) => {
    dispatch(fetchMoviesStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
      .get(`${moviesAPI}?movieTitle=${movieTitle}`)
      .then(res => {
        dispatch(fetchMoviesSuccess(mapMovies(res.data)))
      })
      .catch(error => {
        dispatch(fetchMoviesFail(error))
      })
  }
}

////////////////////CREATE MOVIE///////////////////////////////

export const createNewMovieSuccess = data => {
  return {
    type: actionTypes.CREATE_NEW_MOVIE_SUCCESS,
    data: data
  }
}

export const createNewMovieFail = error => {
  return {
    type: actionTypes.CREATE_NEW_MOVIE_FAIL,
    error: error
  }
}

export const createNewMovieStart = () => {
  return {
    type: actionTypes.CREATE_NEW_MOVIE_START
  }
}
export const createNewMovieReset = () => {
  return {
    type: actionTypes.CREATE_NEW_MOVIE_RESET
  }
}

export const createNewMovieUpdateProgress = progress => {
  return {
    type: actionTypes.CREATE_NEW_MOVIE_UPDATE_PROGRESS,
    progress: progress
  }
}

export const createNewMovie = (formattedData) => {
  return async (dispatch, getState) => {
    dispatch(createNewMovieStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    const externalApi = await getApi();
    delete externalApi.defaults.headers.common.Authorization;

    console.log("formattedData", formattedData);

    let url = "";

    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(formattedData.movieRaw.name)[1];

    try {
      const response = await api.get(moviesGetPresignedUrlAPI + '/' + ext)

      url = response.data;
      
    } catch (error) {
      dispatch(createNewMovieFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar crear una nueva pelicula."))
      return;
    }

    // let fd = new FormData();
    // fd.append('file', formattedData.movieRaw);

    const config = {
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch(createNewMovieUpdateProgress(percentCompleted));
      }
    };

    try {
      const response = await externalApi.put(url, formattedData.movieRaw, config);
      
    } catch (error) {
      dispatch(createNewMovieFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar crear una nueva pelicula."))
      return;
    }

    const resourceUrl = url.split('?')[0];

    const body = {
      title: formattedData.title,
      description: formattedData.description,
      picture_base64: formattedData.movieimagebase64,
      image_extension: formattedData.movieExtension,
      movie_url: resourceUrl,
      order: formattedData.order,
      is_public: formattedData.isPublic
    };

    try {
      const response = await api.post(moviesAPI, body)

      dispatch(
        createNewMovieSuccess(response.data)
      )
      dispatch(
        fetchMovies()
      )
    } catch (error) {
      dispatch(createNewMovieFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar crear una nueva pelicula."))
    }
  }
}


////////////////DETAIL MOVIE///////////////////
export const fetchDetailMovie = movieId => {
  return async (dispatch, getState) => {
    dispatch(fetchDetailMovieStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    let url = moviesAPI + '?movieId=' + movieId;

    return api
      .get(url)
      .then(response => {
        dispatch(fetchDetailMovieSuccess(
          mapMovies(response.data)
        ))
      })
      .catch(err => {
        dispatch(fetchDetailMovieFail(err))
      })

      
  }
}

export const fetchDetailMovieSuccess = data => {
  return {
    type: actionTypes.FETCH_DETAIL_MOVIE_SUCCESS,
    detailMovie: data
  }
}

export const fetchDetailMovieFail = error => {
  return {
    type: actionTypes.FETCH_DETAIL_MOVIE_FAIL,
    error: error
  }
}

export const fetchDetailMovieStart = () => {
  return {
    type: actionTypes.FETCH_DETAIL_MOVIE_START
  }
}
export const fetchDetailMovieReset = () => {
  return {
    type: actionTypes.FETCH_DETAIL_MOVIE_RESET
  }
}


///////////////EDIT MOVIE/////////////////////

export const editMovie = (formattedData) => {
  return async (dispatch, getState) => {
    dispatch(editMovieStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    let url = '';

    if (formattedData.movieRaw != null){
      const externalApi = await getApi();
      delete externalApi.defaults.headers.common.Authorization;

      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(formattedData.movieRaw.name)[1];

      try {
        const response = await api.get(moviesGetPresignedUrlAPI + '/' + ext)

        url = response.data;
        
      } catch (error) {
        dispatch(editMovieFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar editar la pelicula."))
        return;
      }

      // let fd = new FormData();
      // fd.append('file', formattedData.movieRaw);

      const config = {
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch(editMovieUpdateProgress(percentCompleted));
        }
      };

      try {
        const response = await externalApi.put(url, formattedData.movieRaw, config);
        
      } catch (error) {
        dispatch(editMovieFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar editar la pelicula."))
        return;
      }

      url = url.split('?')[0];
    }
    else{
      url = formattedData.movieUrl;
    }

    const body = {
      title: formattedData.title,
      description: formattedData.description,
      picture_base64: formattedData.movieimagebase64,
      image_extension: formattedData.movieExtension,
      movie_url: url,
      order: formattedData.order,
      is_public: formattedData.isPublic
    }
    return api
      .put(`${moviesAPI}/${formattedData.id}`, body)
      .then(res => {
        dispatch(editMovieSuccess({ values: res.data }))
        dispatch(fetchMovies())
      })
      .catch(error => {
        dispatch(editMovieFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar editar la pelicula."));
      })
  }
}

export const editMovieSuccess = () => {
  return {
    type: actionTypes.EDIT_MOVIE_SUCCESS,
  }
}

export const editMovieFail = error => {
  return {
    type: actionTypes.EDIT_MOVIE_FAIL,
    error: error
  }
}

export const editMovieStart = () => {
  return {
    type: actionTypes.EDIT_MOVIE_START
  }
}
export const editMovieReset = () => {
  return {
    type: actionTypes.EDIT_MOVIE_RESET
  }
}

export const editMovieUpdateProgress = progress => {
  return {
    type: actionTypes.EDIT_MOVIE_UPDATE_PROGRESS,
    progress: progress
  }
}

////////////////////DELETE MOVIE//////////////////////////////

export const fetchDeleteMovie = (movieId) => {
  return async (dispatch, getState) => {
    dispatch(fetchDeleteMovieStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .delete(`${moviesAPI}/${movieId}`)
      .then(res => {
        // dispatch(fetchDeleteMovieSuccess({ values: res.data }))
        dispatch(fetchDeleteMovieSuccess())
        dispatch(fetchMovies())
      })
      .catch(err => {
        dispatch(fetchDeleteMovieFail(err))
      })
  }
}

// export const fetchDeleteMovieSuccess = detail => {
export const fetchDeleteMovieSuccess = () => {
  return {
    type: actionTypes.FETCH_DELETE_MOVIE_SUCCESS,
    // deletedMovie: detail
  }
}

export const fetchDeleteMovieFail = error => {
  return {
    type: actionTypes.FETCH_DELETE_MOVIE_FAIL,
    error: error
  }
}

export const fetchDeleteMovieStart = () => {
  return {
    type: actionTypes.FETCH_DELETE_MOVIE_START
  }
}
export const fetchDeleteMovieReset = () => {
  return {
    type: actionTypes.FETCH_DELETE_MOVIE_RESET
  }
}

//////////////////////////////////////////////////////
////////////////////// Donacion //////////////////////

export const fetchDonacionesSuccess = donaciones => {
  return {
    type: actionTypes.FETCH_FUNDACION_DONACIONES_SUCCESS,
    donaciones: donaciones
  }
}

export const fetchDonacionesFail = error => {
  return {
    type: actionTypes.FETCH_FUNDACION_DONACIONES_FAIL,
    errorDonacion: error
  }
}

export const fetchDonacionesStart = () => {
  return {
    type: actionTypes.FETCH_FUNDACION_DONACIONES_START
  }
}

export const fetchDonaciones = () => {
  return async (dispatch, getState) => {
    dispatch(fetchDonacionesStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
      .get(`${donationsAPI}`)
      .then(res => {
        dispatch(fetchDonacionesSuccess(mapDonations(res.data)))
      })
      .catch(error => {
        dispatch(fetchDonacionesFail(error))
      })
  }
}

////////////////////STORIES////////////////////////////////

export const fetchStoriesSuccess = data => {
  return {
    type: actionTypes.FETCH_STORIES_SUCCESS,
    data: data
  }
}

export const fetchStoriesFail = error => {
  return {
    type: actionTypes.FETCH_STORIES_FAIL,
    error: error
  }
}

export const fetchStoriesStart = () => {
  return {
    type: actionTypes.FETCH_STORIES_START
  }
}

export const fetchStories = () => {  
  return async (dispatch, getState) => {
    dispatch(fetchStoriesStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
      .get(`${storiesAPI}`)
      .then(res => {
        dispatch(fetchStoriesSuccess(mapStories(res.data)))
      })
      .catch(error => {
        dispatch(fetchStoriesFail(error))
      })
  }
}

export const fetchStoriesByName = storyName => {  
  return async (dispatch, getState) => {
    dispatch(fetchStoriesStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
      .get(`${storiesAPI}?storyName=${storyName}`)
      .then(res => {
        dispatch(fetchStoriesSuccess(mapStories(res.data)))
      })
      .catch(error => {
        dispatch(fetchStoriesFail(error))
      })
  }
}
////////////////////STORY DETAIL////////////////////////////////
export const fetchDetailStoryReset = () => {
  return {
    type: actionTypes.FETCH_DETAIL_STORY_RESET
  }
}

export const fetchDetailStorySuccess = data => {
  return {
    type: actionTypes.FETCH_DETAIL_STORY_SUCCESS,
    data: data
  }
}

export const fetchDetailStoryFail = error => {
  return {
    type: actionTypes.FETCH_DETAIL_STORY_FAIL,
    error: error
  }
}

export const fetchDetailStoryStart = () => {
  return {
    type: actionTypes.FETCH_DETAIL_STORY_START
  }
}

export const fetchStory = (storyId) => {
  return async (dispatch, getState) => {
    dispatch(fetchDetailStoryStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
      .get(`${storiesAPI}?storyId=${storyId}`)
      .then(res => {
        dispatch(fetchDetailStorySuccess(mapStories(res.data)))
      })
      .catch(error => {
        dispatch(fetchDetailStoryFail(error))
      })
  }
}

////////////////////CREATE STORY///////////////////////////////
export const createNewStorySuccess = () => {
  return {
    type: actionTypes.CREATE_NEW_STORY_SUCCESS
  }
}

export const createNewStoryFail = error => {
  return {
    type: actionTypes.CREATE_NEW_STORY_FAIL,
    error: error
  }
}

export const createNewStoryStart = () => {
  return {
    type: actionTypes.CREATE_NEW_STORY_START
  }
}

export const createNewStoryReset = () => {
  return {
    type: actionTypes.CREATE_NEW_STORY_RESET
  }
}

export const createNewStoryUpdateProgress = progress => {
  return {
    type: actionTypes.CREATE_NEW_STORY_UPDATE_PROGRESS,
    progress: progress
  }
}

export const createNewStory = formattedData => {
  return async (dispatch, getState) => {
    try {
      dispatch(createNewStoryStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid)

      const externalApi = await getApi();
      delete externalApi.defaults.headers.common.Authorization;

      let url = "";

      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(formattedData.storyRaw.name)[1];

      try {
        const response = await api.get(storiesGetPresignedUrlAPI + '/' + ext)

        url = response.data;
        
      } catch (error) {
        dispatch(createNewStoryFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar crear una nueva historia."))
        return;
      }

      const config = {
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch(createNewStoryUpdateProgress(percentCompleted));
        }
      };

      try {
        const response = await externalApi.put(url, formattedData.storyRaw, config);
        
      } catch (error) {
        dispatch(createNewStoryFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar crear una nueva historia."))
        return;
      }

      const resourceUrl = url.split('?')[0];


      const body = {
        name: formattedData.name,
        description: formattedData.description,
        picture_base_64: formattedData.pictureBase64,
        image_extension: formattedData.imageExtension,
        story_url: resourceUrl,
        order: formattedData.order,
        is_public: formattedData.isPublic
      }
      return api.post(storiesAPI, body)
        .then(res => {
          dispatch(createNewStorySuccess())
        })
        .catch(error => {
          dispatch(createNewStoryFail(error))
        });
    } catch (error) {
      dispatch(createNewStoryFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar crear una nueva historia."))
    }
  }
}


////////////////////EDIT STORY///////////////////////////////

export const editStorySuccess = () => {
  return {
    type: actionTypes.EDIT_STORY_SUCCESS
  }
}

export const editStoryFail = error => {
  return {
    type: actionTypes.EDIT_STORY_FAIL,
    error: error
  }
}

export const editStoryStart = () => {
  return {
    type: actionTypes.EDIT_STORY_START
  }
}

export const editStoryReset = () => {
  return {
    type: actionTypes.EDIT_STORY_RESET
  }
}

export const editStoryUpdateProgress = progress => {
  return {
    type: actionTypes.EDIT_STORY_UPDATE_PROGRESS,
    progress: progress
  }
}

export const editStory = formattedData => {
  return async (dispatch, getState) => {
    try {
      dispatch(editStoryStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid)

      let url = "";
      
      if (formattedData.storyRaw != null){
        const externalApi = await getApi();
        delete externalApi.defaults.headers.common.Authorization;

        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(formattedData.storyRaw.name)[1];

        try {
          const response = await api.get(storiesGetPresignedUrlAPI + '/' + ext)

          url = response.data;
          
        } catch (error) {
          dispatch(editStoryFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar editar la historia."))
          return;
        }

        const config = {
          onUploadProgress: (progressEvent) => {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch(editStoryUpdateProgress(percentCompleted));
          }
        };

        try {
          const response = await externalApi.put(url, formattedData.storyRaw, config);
          
        } catch (error) {
          dispatch(editStoryFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar editar la historia."))
          return;
        }

        url = url.split('?')[0];
      }
      else{
        url = formattedData.storyUrl;
      }


      const body = {
        name: formattedData.name,
        description: formattedData.description,
        picture_base_64: formattedData.pictureBase64,
        image_extension: formattedData.imageExtension,
        story_url: url,
        order: formattedData.order,
        is_public: formattedData.isPublic
      }
      return api.put(storiesAPI + `/${formattedData.id}`, body)
        .then(res => { 
          dispatch(editStorySuccess())
        })
        .catch(error => { 
          dispatch(editStoryFail(error))
        });
    } catch (error) { 
      dispatch(editStoryFail(error.response && error.response.data ? error.response.data.message : "Ha ocurrido un error al intentar editar la historia."))
    }
  }
}


////////////////////DELETE STORY//////////////////////////////

export const fetchDeleteStory = (storyId) => {
  return async (dispatch, getState) => {
    dispatch(fetchDeleteStoryStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .delete(`${storiesAPI}/${storyId}`)
      .then(res => {
        // dispatch(fetchDeleteMovieSuccess({ values: res.data }))
        dispatch(fetchDeleteStorySuccess())
        dispatch(fetchStories())
      })
      .catch(err => {
        dispatch(fetchDeleteStoryFail(err))
      })
  }
}

// export const fetchDeleteMovieSuccess = detail => {
export const fetchDeleteStorySuccess = () => {
  return {
    type: actionTypes.FETCH_DELETE_STORY_SUCCESS,
    deletedMovie: null
  }
}

export const fetchDeleteStoryFail = error => {
  return {
    type: actionTypes.FETCH_DELETE_STORY_FAIL,
    error: error
  }
}

export const fetchDeleteStoryStart = () => {
  return {
    type: actionTypes.FETCH_DELETE_STORY_START
  }
}
export const fetchDeleteStoryReset = () => {
  return {
    type: actionTypes.FETCH_DELETE_STORY_RESET
  }
}

//////////////////////////////////////////////////////

////////////////////NEWS////////////////////////////////

export const fetchNewsSuccess = data => {
  return {
    type: actionTypes.FETCH_NEWS_SUCCESS,
    data: data
  }
}

export const fetchNewsFail = error => {
  return {
    type: actionTypes.FETCH_NEWS_FAIL,
    error: error
  }
}

export const fetchNewsStart = () => {
  return {
    type: actionTypes.FETCH_NEWS_START
  }
}

export const fetchNews = () => {  
  
  return async (dispatch, getState) => {
    dispatch(fetchNewsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
      .get(`${newsAPI}`)
      .then(res => {
        dispatch(fetchNewsSuccess(mapNews(res.data)))
      })
      .catch(error => {
        dispatch(fetchNewsFail(error))
      })
  }
}

export const fetchNewsByName = newsTitle => {  
  return async (dispatch, getState) => {
    dispatch(fetchNewsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
      .get(`${newsAPI}?newsTitle=${newsTitle}`)
      .then(res => {
        dispatch(fetchNewsSuccess(mapNews(res.data)))
      })
      .catch(error => {
        dispatch(fetchNewsFail(error))
      })
  }
}
////////////////////NEWS DETAIL////////////////////////////////
export const fetchDetailNewsReset = () => {
  return {
    type: actionTypes.FETCH_DETAIL_NEWS_RESET
  }
}

export const fetchDetailNewsSuccess = data => {
  return {
    type: actionTypes.FETCH_DETAIL_NEWS_SUCCESS,
    data: data
  }
}

export const fetchDetailNewsFail = error => {
  return {
    type: actionTypes.FETCH_DETAIL_NEWS_FAIL,
    error: error
  }
}

export const fetchDetailNewsStart = () => {
  return {
    type: actionTypes.FETCH_DETAIL_NEWS_START
  }
}

export const fetchNewsById = (newsId) => {
  return async (dispatch, getState) => {
    dispatch(fetchDetailNewsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
      .get(`${newsAPI}?newsId=${newsId}`)
      .then(res => {
        dispatch(fetchDetailNewsSuccess(mapNews(res.data)))
      })
      .catch(error => {
        dispatch(fetchDetailNewsFail(error))
      })
  }
}

////////////////////CREATE NEWS///////////////////////////////
export const createNewNewsSuccess = () => {
  return {
    type: actionTypes.CREATE_NEW_NEWS_SUCCESS
  }
}

export const createNewNewsFail = error => {
  return {
    type: actionTypes.CREATE_NEW_NEWS_FAIL,
    error: error
  }
}

export const createNewNewsStart = () => {
  return {
    type: actionTypes.CREATE_NEW_NEWS_START
  }
}

export const createNewNewsReset = () => {
  return {
    type: actionTypes.CREATE_NEW_NEWS_RESET
  }
}

export const createNewNews = formattedData => {
  return async (dispatch, getState) => {
    try {
      dispatch(createNewNewsStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid) 

      const body = {
        title: formattedData.title,
        description: formattedData.description,
        short_description: formattedData.shortDescription,
        picture_base_64: formattedData.pictureBase64,
        image_extension: formattedData.imageExtension,
        tags: formattedData.tags,
        author: formattedData.author ,
        order:formattedData.order
      }

      return api.post(newsAPI, body)
        .then(res => {
          dispatch(createNewNewsSuccess())
        })
        .catch(error => {
          dispatch(createNewNewsFail(error))
        });
    } catch (error) {
      dispatch(createNewNewsFail(error.response.data.message));
    }
  }
}


////////////////////EDIT NEWS///////////////////////////////
export const editNewsSuccess = () => {
  return {
    type: actionTypes.EDIT_NEWS_SUCCESS
  }
}

export const editNewsFail = error => {
  return {
    type: actionTypes.EDIT_NEWS_FAIL,
    error: error
  }
}

export const editNewsStart = () => {
  return {
    type: actionTypes.EDIT_NEWS_START
  }
}

export const editNewsReset = () => {
  return {
    type: actionTypes.EDIT_NEWS_RESET
  }
}

export const editNews = formattedData => {
  return async (dispatch, getState) => {
    try {
      dispatch(editNewsStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid)

      const body = {
        title: formattedData.title,
        description: formattedData.description,
        short_description: formattedData.shortDescription,
        picture_base_64: formattedData.pictureBase64,
        image_extension: formattedData.imageExtension,
        tags: formattedData.tags,
        author: formattedData.author ,
        order:formattedData.order
      }
      return api.put(newsAPI + `/${formattedData.id}`, body)
        .then(res => { 
          dispatch(editNewsSuccess())
        })
        .catch(error => { 
          dispatch(editNewsFail(error))
        });
    } catch (error) { 
      dispatch(editNewsFail(error.response.data.message));
    }
  }
}


////////////////////DELETE NEWS//////////////////////////////

export const fetchDeleteNews = (newsId) => {
  return async (dispatch, getState) => {
    dispatch(fetchDeleteNewsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .delete(`${newsAPI}/${newsId}`)
      .then(res => {
        // dispatch(fetchDeleteMovieSuccess({ values: res.data }))
        dispatch(fetchDeleteNewsSuccess())
        dispatch(fetchNews())
      })
      .catch(err => {
        dispatch(fetchDeleteNewsFail(err))
      })
  }
}

// export const fetchDeleteMovieSuccess = detail => {
export const fetchDeleteNewsSuccess = () => {
  return {
    type: actionTypes.FETCH_DELETE_NEWS_SUCCESS,
    deletedMovie: null
  }
}

export const fetchDeleteNewsFail = error => {
  return {
    type: actionTypes.FETCH_DELETE_NEWS_FAIL,
    error: error
  }
}

export const fetchDeleteNewsStart = () => {
  return {
    type: actionTypes.FETCH_DELETE_NEWS_START
  }
}

export const fetchDeleteNewsReset = () => {
  return {
    type: actionTypes.FETCH_DELETE_NEWS_RESET
  }
}

//////////////////////////////////////////////////////