import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  loading: false,
  created: false,
  proprietaryList: [],
  loadingNewProprietary: false,
  createdNewProprietary: false,
  errorNewProprietary: false,
  importPropietary: false,
  loadingImportPropietary: false,
  createdimportPropietary: false,
  errorImportPropietary: false,

  loadingPerson: false,
  errorPerson: false,
  modifiedPerson: false,


  page: 0,
  take: 10,
  hasMore: true
}

const fetchPadronSuccess = (state, action) => {
  return updateObject(state, {
    proprietaryList: action.padron.values,
    take: action.padron.take,
    hasMore: action.padron.hasMore,
    loading: false
  })
}

const fetchPadronFail = state => {
  return updateObject(state, { created: false })
}

const fetchPadronStart = state => {
  return updateObject(state, { loading: true, modifiedPerson: false })
}

//----------------------------------------------------------------

const createProprietaryStart = state => {
  return updateObject(state, { loadingNewProprietary: true, errorNewProprietary: false })
}

const createProprietarySuccess = state => {
  return updateObject(state, {
    loadingNewProprietary: false,
    createdNewProprietary: true,
    errorNewProprietary: false
  })
}

const createProprietaryFail = state => {
  return updateObject(state, {
    createdNewProprietary: false,
    loadingNewProprietary: false,
    errorNewProprietary: true
  })
}


//----------------------------------------------------------------

const importProprietaryStart = state => {
  return updateObject(state, { loadingImportPropietary: true, errorImportPropietary: false })
}

const importProprietarySuccess = state => {
  return updateObject(state, {
    loadingImportPropietary: false,
    createdImportPropietary: true,
    errorImportPropietary: false
  })
}

const importProprietaryFail = state => {
  return updateObject(state, {
    createdImportPropietary: false,
    loadingImportPropietary: false,
    errorImportPropietary: true
  })
}

//---------------------Request Edit Person------------------------------

const fetchEditPersonStart = state => {
  return updateObject(state, { loadingPerson: true, modifiedPerson: false })
}

const fetchEditPersonSuccess = state => {
  return updateObject(state, {
    loadingPerson: false,
    modifiedPerson: true,
    errorPerson: false
  })
}

const fetchEditPersonFail = state => {
  return updateObject(state, {
    modifiedPerson: false,
    loadingPerson: false,
    errorPerson: true
  })
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PADRON_SUCCESS:
      return fetchPadronSuccess(state, action)
    case actionTypes.FETCH_PADRON_FAIL:
      return fetchPadronFail(state, action)
    case actionTypes.FETCH_PADRON_START:
      return fetchPadronStart(state, action)

    case actionTypes.CREATE_PROPRIETARY_SUCCESS:
      return createProprietarySuccess(state, action)
    case actionTypes.CREATE_PROPRIETARY_FAIL:
      return createProprietaryFail(state, action)
    case actionTypes.CREATE_PROPRIETARY_START:
      return createProprietaryStart(state, action)

    case actionTypes.IMPORT_PROPRIETARY_SUCCESS:
      return importProprietarySuccess(state, action)
    case actionTypes.IMPORT_PROPRIETARY_FAIL:
      return importProprietaryFail(state, action)
    case actionTypes.IMPORT_PROPRIETARY_START:
      return importProprietaryStart(state, action)

    case actionTypes.FETCH_EDIT_PERSON_SUCCESS:
      return fetchEditPersonSuccess(state, action)
    case actionTypes.FETCH_EDIT_PERSON_FAIL:
      return fetchEditPersonFail(state, action)
    case actionTypes.FETCH_EDIT_PERSON_START:
      return fetchEditPersonStart(state, action)


    default:
      return state
  }
}

export default reducer
