import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross-blue.png'
import { registerLocale } from 'react-datepicker';
import PageLoader from 'components/PageLoader';
import Select from 'react-select';
import { postAssingUser, postAssingUserReset } from 'store/actions/gestorConsultas.actions';
import es from 'date-fns/locale/es';
import ModalMessage from 'components/ModalMessage'
import {
  fetchVendorConsultas, 
 } from '../../../store/actions/gestorConsultas.actions'
 
import {
  FormContainer,
  Row, FormSection, Text,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  LabelFecha,
  ExpensasList,
  ListItem, SelectContainer, InputWrapper2Col
} from './styled'


registerLocale('es', es)

class modalUsuarios extends React.Component {

  state = {
    user: JSON.parse(localStorage.getItem('user')),
    filter: '',
    newExpensa: false,
    isDetailItemOpen: false,
    radioBtnCanal: 'email'
  }
  componentDidMount() {
    // this.props.fetchLots();
  }

  getInitialValues = () => ({
    currentDate: '',
  })

  handleSubmit = async data => {


    let arrayTmp = []

    this.props.ticketSeleccionados.map(({ id }) => {
      arrayTmp.push(id)
    })

    const postData = {
      ticketsidlist: arrayTmp,
      userid: data.user.value
    }

    await this.props.postAssingUser(postData)
    await this.props.fetchVendorConsultas(this.props.filtrosConsultas)

  }



  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row>
        <InputLabel>
          {'Asignar Usuario'}
        </InputLabel>
      </Row>
      <Row >

        <InputWrapper>
          <InputLabel>{'Usuario'}</InputLabel>
          <SelectContainer>
            <Select
              name={'user'}
              value={values.user}
              options={this.props.userList ? this.props.userList : [{ value: null, label: "Loading..." }]}
              placeholder="Sel. Usuario"
              onChange={values => setFieldValue('user', values)}
            />
          </SelectContainer>
          {errors.user && <ErrorLabel>{errors.user}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <ButtonWrapper>
        {
          this.props.gestorConsultas.loadingPostAssingUser ?
            <PageLoader customHeight={'50px'} />
            :
            <StyledButton type={'submit'}>Asignar</StyledButton>
        }

      </ButtonWrapper>
    </FormContainer>
  )

  getNuevaNotifSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        user: Yup.string().required('Seleccione valor'),

      })
    )

  closeBoth() {
    this.props.postAssingUserReset()
    this.props.onClose()
  }

  render() {
    return (
      <Modal>
        <Formik
          validateOnChange={false}
          validationSchema={this.getNuevaNotifSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
        {this.props.gestorConsultas.postAssingUserSuccess == true || this.props.gestorConsultas.postAssingUserFail ?
          <ModalMessage
            success={this.props.gestorConsultas.postAssingUserSuccess ? true : false}
            onClose={() => this.closeBoth()}>
            {this.props.gestorConsultas.postAssingUserSuccess ? 'Asignado con exito' : 'Error al asignar.'}
          </ModalMessage>
          :
          null
        }
      </Modal>
    )
  }
}

const mapStateToProps = ({
  gestorConsultas, userInfo
}) => ({
  gestorConsultas, userInfo
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postAssingUser,
      postAssingUserReset,fetchVendorConsultas
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(modalUsuarios)
