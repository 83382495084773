import { createSelector } from 'reselect'
import { getFormattedDate } from 'utils/dateParser'

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'ARS',
});

const mapVentas = createSelector(
  ventas => ventas,
  ventas => 
  ventas.map(
      ({ buyerfullname, shopitemid, id,title,price,creationdate,quantity,currency,buyerpictureurl,orderstatename,orderstatecode,buyerfulladdress,buyerneighbourhoodname,orderid,paymentmethodname,shopitemimagesurls,buyerusername }, i) => ({
        value: id,
        title: title,
        shopitemid: shopitemid,
        currency: currency,
        price: formatter.format(price),
        quantity: quantity,
        buyerusername: buyerusername,
        buyerpictureurl,
        buyerneighbourhoodname,
        buyerfulladdress,
        orderstatename: orderstatename,
        orderstatecode,
        orderid: orderid,
        creationdate: getFormattedDate(creationdate),
        images: shopitemimagesurls,
        buyerfullname: buyerfullname,
        paymentmethodname: paymentmethodname
      })
    )
)

export default mapVentas
