import * as actionTypes from './actionTypes'
import { fetchNewUserAPI, deleteUsuarioAPI, fetchEditUserAPI, fetchUsuariosByNeighbourhoodAPI } from 'apiConstants'
import getApi from 'shared/api'

// CREATE
export const fetchNewUserSuccess = userCreated => {
  return {
    type: actionTypes.FETCH_NEW_USER_SUCCESS,
    userCreated: userCreated
  }
}

export const fetchNewUserFail = error => {
  return {
    type: actionTypes.FETCH_NEW_USER_FAIL,
    error: error
  }
}

export const fetchNewUserStart = () => {
  return {
    type: actionTypes.FETCH_NEW_USER_START
  }
}

export const fetchNewUser = body => {
  return async (dispatch, getState) => {
    dispatch(fetchNewUserStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    return api
      .post(`${fetchNewUserAPI}`, body)
      .then(res => {
        dispatch(fetchNewUserSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchNewUserFail(err.response.data.message))
      })
  }
}

// GET ALL
export const fetchUsers = textoABuscar => {
  return async (dispatch, getState) => {
    dispatch(fetchGetUsersStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    const request = {
      searchValue: textoABuscar ? textoABuscar : '',
      pageSize: null,
      pageNumber: null
    }

    return api
      .get(fetchUsuariosByNeighbourhoodAPI, request)
      .then(response => {
        dispatch(fetchGetUsersSuccess({
          values: response.data
        }))
      })
      .catch(err => {
        dispatch(fetchGetUsersFail(err))
      })
  }
}

export const fetchGetUsersSuccess = users => {
  return {
    type: actionTypes.FETCH_GETUSERS_SUCCESS,
    users: users
  }
}

export const fetchGetUsersFail = error => {
  return {
    type: actionTypes.FETCH_GETUSERS_FAIL,
    error: error
  }
}

export const fetchGetUsersStart = () => {
  return {
    type: actionTypes.FETCH_GETUSERS_START
  }
}

// DELETE
export const deleteUsuarioSuccess = () => {
  return {
    type: actionTypes.DELETE_USUARIO_SUCCESS
  }
}

export const deleteUsuarioFail = error => {
  return {
    type: actionTypes.DELETE_USUARIO_FAIL,
    error: error
  }
}

export const deleteUsuarioStart = () => {
  return {
    type: actionTypes.DELETE_USUARIO_START
  }
}

export const deleteUsuarioById = id => {
  return async (dispatch, getState) => {
    dispatch(deleteUsuarioStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    return api
      .delete(deleteUsuarioAPI + `${id}`)
      .then(res => {
        dispatch(deleteUsuarioSuccess(res.data))
      })
      .catch(err => {
        dispatch(deleteUsuarioFail(err))
      })
  }
}



// EDIT USER
export const fetchEditUserSuccess = userEdition => {
  return {
    type: actionTypes.FETCH_EDIT_USER_SUCCESS,
    userEdited: userEdition
  }
}

export const fetchEditUserFail = error => {
  return {
    type: actionTypes.FETCH_EDIT_USER_FAIL,
    error: error
  }
}

export const fetchEditUserStart = () => {
  return {
    type: actionTypes.FETCH_EDIT_USER_START
  }
}

export const fetchEditUserReset = () => {
  return {
    type: actionTypes.FETCH_EDIT_USER_RESET
  }
}

export const fetchEditUser = body => {
  return async (dispatch, getState) => {
    dispatch(fetchEditUserStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)

    return api
      .post(`${fetchEditUserAPI}`, body)
      .then(res => {
        dispatch(fetchEditUserSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchEditUserFail(err.response.data.message))
      })
  }
}
