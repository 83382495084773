import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {withWidth} from '@material-ui/core'
import axios from 'axios'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import { bindActionCreators } from 'redux'
import { fetchCreateTransactionRequest,fetchGetNumberOfTransferPending,fetchBalance } from 'store/actions/billetera.actions'
import PaperLayout from 'components/PaperLayout'
import FirstStep from './FirstStep/index'
import SecondStep from './SecondStep/index'
import ThirdStep from './ThirdStep/index'
import FourStep from './FourStep/index'

class NewTransfer extends Component {
  state = {
    selectedOption: 'option1',
    isNewDestinatarioOpen: false,
    completed: false,
    step: {
      name: 'Creation',
      number: 1,
      percentage: 30,
      }
  }

  componentWillUnmount(){
    this.props.fetchGetNumberOfTransferPending()
  }

  onOpenNewDestinatario = () => {
    this.setState({ isNewDestinatarioOpen: true })
  }

  onCloseNewDestinatario= () => {
    this.setState({ isNewDestinatarioOpen: false })
  }
  
  setDestinatario = async (person) => {   
    this.setState(prevState => ({
      transaction: {
        ...prevState.transaction,
        DestinationBankAccount: {
          Id: person.Id,
          Cvbu: person.Cvbu,
          BankId: person.BankId,
          BankName: person.BankName,
          Number:  person.Number,
          IsMiiiiClient:  person.IsMiiiiClient,
          HolderName: person.HolderName,
          HolderCuit: person.HolderCuit,
          UserName:  person.ClientAppUserName,
          Alias: person.Alias,
          Currency: 'ARS',
        },
        SourceBankAccount:{
          Id: this.props.billetera.AccountDebitInfo.Id,
          Alias: this.props.billetera.AccountDebitInfo.alias,
          BankId: this.props.billetera.AccountDebitInfo.BankId,
          Cvbu: this.props.billetera.AccountDebitInfo.Cvbu,
          HolderCuit: this.props.billetera.AccountDebitInfo.HolderCuit,
          HolderName: this.props.billetera.AccountDebitInfo.HolderName,
          Number: this.props.billetera.AccountDebitInfo.Number,
          IsMiiiiClient: this.props.billetera.AccountDebitInfo.IsMiiiiClient,
          originClientUserName: this.props.userInfo.username,
          Currency: 'ARS',
        },  
        //Si las dos cuentas son MIII es Inner, si no.. outgoing
        type: person.IsMiiiiClient === this.props.billetera.AccountDebitInfo.IsMiiiiClient || person.IsMiiiiClient === true ? 'INNER_TRANSACTION' : 'OUTGOING_DEBIT',
        Description: ' ',
      }
    }))
    
  }

  updateTransaction = (transaction) => {
    this.setState(prevState => ({
      transaction: {
          ...prevState.transaction,
          Amount: transaction.amount,
          ExecutionDate: transaction.date,
          Concept: transaction.concepto,
          Currency: 'ARS',
      }
    }))
  }

  createTransactionRequest = (toggleSendMail) => { 
    this.props.fetchCreateTransactionRequest(this.state.transaction)
    this.props.fetchBalance()
    //si se confirmo la transferencia mostrar ok

    this.NextStep()
  }


  NextStep = () => {
    this.setState(prevState => ({
      step: {
          ...prevState.step,
          number:  prevState.step.number + 1,
          percentage: prevState.step.percentage + 30
      }
    }))
  }

  PreviousStep= () => {
    this.setState(prevState => ({
      step: {
          ...prevState.step,
          number:  prevState.step.number - 1,
          percentage: prevState.step.percentage - 30
      }
    }))
  }

  render() {
      return (
        <div>
          {this.state.completed === false ? ( 
            <PaperLayout title={'Nueva Transferencia'} onClose={() => {this.props.onClose()}} Step={this.state.step}>
                {(() => {
                switch(this.state.step.number) {
                  case 1:
                    return <FirstStep 
                            NextStep={() => {this.NextStep()}} 
                            neighbourhood={this.props.neighbourhood} 
                            setDestinatario={this.setDestinatario} 
                          />;
                  case 2:
                    return <SecondStep 
                              NextStep={() => {this.NextStep()}} 
                              PreviousStep={() => {this.PreviousStep()}} 
                              transactionProgress={this.state.transaction} 
                              updateTransaction={this.updateTransaction} 
                              totalBalance={this.props.totalBalance}
                            />;
                  case 3:
                    return <ThirdStep 
                            PreviousStep={() => {this.PreviousStep()}} 
                            confirmTransaction={this.createTransactionRequest} 
                            transactionProgress={this.state.transaction}
                            errorMessageCreateTransactionRequest={this.props.billetera.errorMessageCreateTransactionRequest ? this.props.billetera.errorMessageCreateTransactionRequest : false} 
                           />;
                  case 4:
                    return this.setState({completed: true})
                  default:
                    return null;
                }
              })()}
            </PaperLayout >
          ): (
          <FourStep onClose={() => {this.props.onClose()}} transactionProgress={this.state.transaction} billetera={this.props.billetera} /> 
          )}     
      </div>
    )
  
    
  }
}


const mapStateToProps = state => {
  return {
    idTransaction: state.billetera.idTransaction,
    billetera: state.billetera,
    totalBalance: state.billetera.balance,
    userInfo: state.userInfo
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCreateTransactionRequest,
      fetchGetNumberOfTransferPending,
      fetchBalance
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((withErrorHandler(NewTransfer, axios)))
