import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  loadingNewUser: false,
  fetchNewUserFail: false,
  fetchNewUserSuccess: false,


  loadingEditUser: false,
  fetchEditUserFail: false,
  fetchEditUserSuccess: false,
  
  proprietaryList: [],

  loadingUsers: false,
  fetchUsersFail: false,
  fetchUsersSuccess: false,

  loadingDeleteUsuario: false,
  errorDeleteUsuario: false,
  deletedUsuario: false,
}

const fetchEditUserStart = state => {
  return updateObject(state, { loadingEditUser: true, fetchEditUserSuccess: false, fetchEditUserFail: false })
}

const fetchEditUserSuccess = (state, action) =>
  updateObject(state, {
    loadingNewUser: false, fetchEditUserSuccess: true, fetchEditUserFail: false, userEdited: action.userEdited.values
  })

const fetchEditUserFail = (state, action) => {
  return updateObject(state, { loadingEditUser: false, fetchEditUserSuccess: false, fetchEditUserFail: action.error })
}


const fetchEditUserReset = (state, action) =>
  updateObject(state, {
    loadingEditUser: false, fetchEditUserSuccess: false, fetchEditUserFail: false,
  })

const fetchNewUserStart = state => {
  return updateObject(state, { loadingNewUser: true, fetchNewUserSuccess: false, fetchNewUserFail: false })
}

const fetchNewUserSuccess = (state, action) =>
  updateObject(state, {
    loadingNewUser: false, fetchNewUserSuccess: true, fetchNewUserFail: false, userCreated: action.userCreated.values
  })

const fetchNewUserFail = (state, action) => {
  return updateObject(state, { loadingNewUser: false, fetchNewUserSuccess: false, fetchNewUserFail: action.error })
}

const fetchGetUsersStart = state => {
  return updateObject(state, { loadingUsers: true, fetchNewUserSuccess: false, fetchNewUserFail: false })
}

const fetchGetUsersSuccess = (state, action) =>
  updateObject(state, {
    loadingUsers: false, fetchUsersSuccess: true, fetchUsersFail: false, proprietaryList: action.users.values
  })

const fetchGetUsersFail = (state, action) => {
  return updateObject(state, { loadingUsers: false, fetchNewUserSuccess: false, fetchUsersFail: action.error })
}

const deleteUsuarioStart = state => {
  return updateObject(state, { loadingDeleteUsuario: true, errorDeleteUsuario: false })
}

const deleteUsuarioSuccess = state => {
  return updateObject(state, {
    loadingDeleteUsuario: false,
    deletedUsuario: true
  })
}

const deleteUsuarioFail = state => {
  return updateObject(state, {
    loadingDeleteUsuario: false,
    deletedUsuario: false,
    errorDeleteUsuario: true
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NEW_USER_SUCCESS:
      return fetchNewUserSuccess(state, action)
    case actionTypes.FETCH_NEW_USER_FAIL:
      return fetchNewUserFail(state, action)
    case actionTypes.FETCH_NEW_USER_START:
      return fetchNewUserStart(state, action)


      case actionTypes.FETCH_EDIT_USER_SUCCESS:
        return fetchEditUserSuccess(state, action)
      case actionTypes.FETCH_EDIT_USER_FAIL:
        return fetchEditUserFail(state, action)
      case actionTypes.FETCH_EDIT_USER_START:
        return fetchEditUserStart(state, action)
        case actionTypes.FETCH_EDIT_USER_RESET:
          return fetchEditUserReset(state, action)

    case actionTypes.FETCH_GETUSERS_SUCCESS:
      return fetchGetUsersSuccess(state, action)
    case actionTypes.FETCH_GETUSERS_FAIL:
      return fetchGetUsersFail(state, action)
    case actionTypes.FETCH_GETUSERS_START:
      return fetchGetUsersStart(state, action)

    case actionTypes.DELETE_USUARIO_SUCCESS:
      return deleteUsuarioSuccess(state, action)
    case actionTypes.DELETE_USUARIO_FAIL:
      return deleteUsuarioFail(state, action)
    case actionTypes.DELETE_USUARIO_START:
      return deleteUsuarioStart(state, action)
    default:
      return state
  }
}
export default reducer
