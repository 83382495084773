import React, { Component } from 'react'
import CrossIcon from 'assets/icons/cross.png'

import PageLoader from 'components/PageLoader';
import Modal from 'components/Modal'

import Carrousel from '../carrouselphotos'
import {
    Container,
    RowItem,
    RowLeft, RowRight, TituloLeft, DescRight, RowItemNoBorder,
    Body, TituloLeftSub, DescRightSub, SubtitleText,
    Subtitle, CrossImage
} from './styled'

class DetailModal extends Component {

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.props.onClose()
        }
    }

    render() {
        console.log("props detail",this.props)
        return (
            <Modal withOutPage={true} width="auto" height="auto" left="30%" top="10%" >
                {this.props.loading && this.props.producto !== undefined ?
                    <Container>
                        <Body>
                            <CrossImage onClick={() => this.props.onClose()} src={CrossIcon} />
                            <RowItemNoBorder style={{ justifyContent: 'center' }}>
                                <Carrousel images={this.props.producto.values.shopitemimagesurls} width='200px' />
                            </RowItemNoBorder>
                            <RowItem>
                                <RowLeft>
                                    <TituloLeft>{this.props.producto.values.title}</TituloLeft>
                                </RowLeft>
                                <RowRight>
                                    <DescRight>{this.props.producto.values.prices[0].currency} {this.props.producto.values.prices[0].price}</DescRight>
                                </RowRight>
                            </RowItem>
                            <RowItem>
                                <RowLeft>
                                    <TituloLeftSub>Estado</TituloLeftSub>
                                </RowLeft>
                                <RowRight>
                                    <DescRightSub>{this.props.producto.values.shopitemconditionname}</DescRightSub>
                                </RowRight>
                            </RowItem>
                            <RowItem>
                                <RowLeft>
                                    <TituloLeftSub>Condición Uso</TituloLeftSub>
                                </RowLeft>
                                <RowRight>
                                    <DescRightSub>{this.props.producto.values.shopitemstatename}</DescRightSub>
                                </RowRight>
                            </RowItem>
                            <RowItemNoBorder>
                                <Subtitle inputColor='#1873f3'>Descripción</Subtitle>
                            </RowItemNoBorder>
                            <RowItemNoBorder>
                                <SubtitleText>{this.props.producto.values.description}</SubtitleText>
                            </RowItemNoBorder>
                        </Body>
                    </Container>
                    :
                    <PageLoader />
                }
            </Modal>
        )
    }
}


export default DetailModal;
