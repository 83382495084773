import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import eyeIcon from 'assets/icons/eye.svg'
import etiquetaIcon from 'assets/icons/etiqueta.svg'
import despacharIcon from 'assets/icons/despacharIcon.svg'
import cajaIcon from 'assets/icons/cajaIcon.svg'
import facturaIcon from 'assets/icons/facturaIcon.svg'
import transitoIcon from 'assets/icons/transitoIcon.svg'
import casaIcon from 'assets/icons/casaIcon.svg'
import entregadoIcon from 'assets/icons/entregadoIcon.svg'
import enTransitoIcon from 'assets/icons/enTransitoIcon.svg'
import aprobado from 'assets/icons/aprobado.svg'
import borrarIcon from 'assets/icons/borrarIcon.svg'
import infoIcon from 'assets/icons/infoIcon.png'
import cross from 'assets/icons/cross-border-round.png'
import compose from 'recompose/compose'
import Page from 'components/Page'
import Section from 'components/Section'
import FilterBox from '../components/filterBox'
import SearchBar from 'components/SearchBar'
import TableCell from '@material-ui/core/TableCell';
import { fetchGetVentas, fetchGetReadyToDeliver, fetchDetailProduct, fetchOrderQR, newConversation, newConversationReset } from 'store/actions/clasificados.actions'
import Carrousel from '../components/carrouselphotos'
import EmptyLoader from 'components/PageLoader'
import DetailOrderModal from '../components/modalOrderDetail'
import DetailPedido from '../components/detailPedido'
import MaterialTable from 'material-table'
import ModalMessage from 'components/ModalMessage'

import { PageWrapper, Wrapper, SearchWrapper, ContainerImg, Icon, IconBack,Title, ContainerSearchBar, StyledButton, Row, ContainerIcon, Container,CompradorLabel,DisclaimerLabel } from "./styled"
import mapVentas from '../../../selectors/mapVentas'

class Ventas extends React.Component {
  state = {
    orderToSee: undefined,
    filter: '',
    filterOn: "",
    productsOnTable: [],
    ventasSelected: [],
    disclaimerShow: true,
    verModalComprador: false,
    addProduct: false,
    editProducto: false,
    modalCambioEstado: false,
    modalStartConversation: false
  }

  componentDidMount = () => {
    this.props.fetchGetVentas()
  }

  componentDidUpdate(prevProps) {
    if(!this.props.loading) {
      if (prevProps.filteredVentas !== this.props.filteredVentas) {
        this.state.filterOn.length > 1 ? this.handleFilterChange(this.state.filterOn) :this.handleFilterChange("PENDIENTE_ENTREGA")
      }
    }
    if (prevProps.orderQr !== this.props.orderQr) {
      this.state.orderQr ? this.downloadLabel() : this.handleFilterChange(this.state.filterOn)
    }
  
  }


  renderPageContent = () => {
    return (
      <SearchWrapper>
        {/* <ContainerSearchBar>
          <SearchBar
            placeholder={'Buscar por nombre o categoria'}
            style={'width: \'70%\' '}
            value={this.state.filter}
            onChange={(e) => this.handleInputOnChange(e)}
            onClear={this.handleInputClear}
            onKeyDown={this.handleKeyPress}
            noBorder={true}
            autofocus
          />
        </ContainerSearchBar> */}
        <Container margin={true}>
          <MaterialTable
            columns={[
              {
                title: 'Fecha', field: 'creationdate',width:"80px"
              },
              { title: 'N° de Pedido', field: 'orderid',width:"80px"
              },
              { title: 'Importe', field: 'price',width:"80px"},
              { title: 'Comprador', field: 'buyerfullname',width:"200px",render: rowData =><CompradorLabel onClick={() => this.handleDetailProduct(rowData)} >{rowData.buyerfullname}</CompradorLabel>,},
              {
                title: 'Acciones', field: 'publication', render: rowData =>
                  <>
                    <ContainerImg>
                      <Icon width="45px" src={eyeIcon} onClick={() => this.handleDetailOrder(rowData)} />
                    </ContainerImg>

                    {/* <ContainerImg>
                      <Icon width="20px" src={converIcon} onClick={() => this.startConversation(rowData)} />
                    </ContainerImg> */}
                    <ContainerImg>
                      <Icon width="40px" src={borrarIcon}  />
                    </ContainerImg>
                    {(()=> {
                        switch (this.state.filterOn) {
                          case 'PENDIENTE_ENTREGA':
                            return <>
                                      <ContainerImg>
                                        <Icon width="8vw" src={etiquetaIcon} onClick={()=> this.handleDownloadLabel(rowData.orderid)}  />
                                      </ContainerImg>
                                      <ContainerImg>
                                        <Icon width="8vw" src={despacharIcon} onClick={()=> this.toggleCambioEstado(rowData.orderid)} />
                                      </ContainerImg>
                                    </>;
                          case 'LISTO_DESPACHAR':
                            return <>
                                    <ContainerImg>
                                        <Icon width="12vw" src={enTransitoIcon} onClick={()=> this.toggleCambioEstado(rowData.orderid)} />
                                      </ContainerImg>
                                  </>;
                          case 'EN_TRANSITO':
                            return <>
                                    <ContainerImg>
                                        <Icon width="12vw" src={entregadoIcon}  />
                                      </ContainerImg>
                                  </>;
                          case 'ENTREGADO':
                            return <>
                                    <ContainerImg>
                                        <Icon width="12vw" src={facturaIcon}  />
                                      </ContainerImg>
                                  </>;
                          default:
                            return null;
                        }
                      })()}

                  </>,width:"250px"
              }
            ]}
            data={this.state.productsOnTable}
            //onRowClick={(event, rowData, togglePanel) => this.onMovementClick(rowData)}
            // onSelectionChange={(rows) => this.onMovementClick( rows )}

            localization={{
              header: {
                actions: 'Acciones',
              },
              body: { emptyDataSourceMessage: 'No hay nada' }
            }}
            style={{
              backgroundColor: '#F8F8F8',
              color: '#000000'
            }}
            onSelectionChange={(rows) => this.handleVentasSelected(rows)}
            options={{
              toolbar: false,
              paging: false,
              maxBodyHeight: '600px',
              width: "40vw",
              selection: true,
              actionsColumnIndex: -1,
              tableLayout: "fixed",
              headerStyle: {
                backgroundColor: '#F8F8F8',
                color: '#9B9A9B',
                height: "2vh",
                width: "auto"
              },
              rowStyle: {
                border: "2px solid #D2D2D2",
                backgroundColor: '#FFFFFF',
                borderRadius: "6px",
                marginTop: "25px !important",
                boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2), inset -3px 0 0 0 rgba(228, 228, 228, 0.2)",
            }
            }} //Esconde titulo y search y pagination
          />
        </Container>
      </SearchWrapper>
    )
  }

  toggleRow=(value)=>{
    this.setState({ showRow: value })

  }


  handleVentasSelected = (selected) => {
    if (selected.length > 1) {
      let arrayTransaction = []
      selected.map(row => {
        arrayTransaction.push(row.orderid)
      })
      this.setState({ ventasSelected: arrayTransaction },this.toggleRow(true))
    } else {
      if (selected.length === 0) {
        this.setState({ ventasSelected: [] },this.toggleRow(false))
      } else {
        let arrayFormat = [selected[0].orderid]
        this.setState({ ventasSelected: arrayFormat },this.toggleRow(true))
      }
    }
  }

  isFilterEnabled = () => this.state.filterEnabled

  handleFilterChange = filter => {
    var productsFiltered = this.props.filteredVentas.filter(value => (value.orderstatecode === filter))
    console.log(productsFiltered)
    this.setState({ productsOnTable: productsFiltered,filterOn: filter })
  }

  async handleDownloadLabel(orderid){
    console.log("handle",orderid)

    if(  Array.isArray(orderid)){
      await orderid.map((id)=> (
         this.props.fetchOrderQR(id)
      ))
      if(this.props.orderQr){
        this.downloadLabel()
      }      
      console.log("entro al map",orderid)
    }else{
      var response = await this.props.fetchOrderQR(orderid)
      if(this.props.orderQr){
        this.downloadLabel()
      }
    }
  }



  handleInputOnChange = value => {
    this.setState({ filter: value, filterEnabled: false })
    this.props.fetchGetProducts(value)
  }

  handleDetailOrder = order => {
    this.setState({ orderToSee: order })
    this.props.fetchDetailProduct(order.orderid)
    this.toggleVerPedido()
  }

  toggleVerPedido = (id) => {
    this.setState({ verPedido: !this.state.verPedido })
  }

  handleDetailProduct = order => {
    this.setState({ orderToSee: order })
    this.toggleVerModalComprador(order.orderid)
  }

  toggleVerModalComprador = (id) => {
    this.props.fetchGetVentas()
    this.props.fetchDetailProduct(id)
    this.setState({ verModalComprador: !this.state.verModalComprador })
  }

  handleInputClear = value => {
    this.setState({ filter: value, filterEnabled: false })
    this.props.fetchGetProducts(value)
  }

  handleDisclaimer = () => {
    this.setState({ disclaimerShow:!this.state.disclaimerShow })
  }

  toggleCambioEstado = orderId => {

    this.setState({ modalCambioEstado: true })

    if (Array.isArray(orderId)) {
      orderId.map(order =>
        this.props.fetchGetReadyToDeliver(order)
      )
    } else {
      this.props.fetchGetReadyToDeliver(orderId)
    }
  }


  startConversation = (data) => {

    const dataConver = {
      receiverusername: data.buyerusername,
      auxid: data.shopitemid,
      pictureurl: data.images[0],
      title: data.title
    }

    console.log('data new conversation', dataConver)

    this.props.newConversation(dataConver)
    this.props.onClose()
    this.props.showMensajes()

  }

  closeModalUpdate = () => {
    this.props.fetchGetVentas()
    this.setState({ modalCambioEstado: false })
  }
  handleKeyPress = e => {
    if (e.keyCode === 13 && e.target.value !== '') {
      this.props.fetchGetProducts(e.target.value)
      this.setState({ filterEnabled: true })
    }
  }

  downloadLabel = () => {
    document.getElementById('download').click();
  }

  isContentAvaiable = () => this.props.filteredProducts.length > 0 || this.props.filteredProducts.length > 0

  isLoadingContent = () => this.props.loading

  render() {
    console.log("estado",this.state)
    console.log("props",this.props)
    const { filterOn, addProduct, editProducto } = this.state;
    if(this.state.verPedido){
      return(<DetailPedido onClose={()=> this.toggleVerPedido()} order={this.state.orderToSee}/>)
    }
    else{
      return (
        <>
          {!addProduct && !editProducto ?
            <Page>
              <PageWrapper>
                <Section title="Ventas" onBack={() => this.props.onClose()}>
                  <Container margin={false} >
                    <FilterBox title={"Aprobado"} subtitle={"Pedidos realizados"} img={aprobado} active={filterOn === "PENDIENTE_ENTREGA" ? true : false} onClick={()=> this.handleFilterChange("PENDIENTE_ENTREGA")} />
                    <FilterBox title={"Listos para despachar"} subtitle={"Pedidos listos para ser enviados"} img={cajaIcon} active={filterOn === "LISTO_DESPACHAR" ? true : false} onClick={()=> this.handleFilterChange("LISTO_DESPACHAR")} />
                    <FilterBox title={"En tránsito"} subtitle={"Pedidos viajando a destino"} img={transitoIcon} active={filterOn === "EN_TRANSITO" ? true : false} onClick={()=> this.handleFilterChange("EN_TRANSITO")}  />
                    <FilterBox title={"Entregado"} subtitle={"Pedidos llegados a destino"} img={casaIcon} active={filterOn === "ENTREGADO" ? true : false} onClick={()=> this.handleFilterChange("ENTREGADO")} />
                  </Container>
                  {this.state.disclaimerShow ?
                  <DisclaimerLabel out={!this.state.disclaimerShow}>
                    <Icon width="25px" height="25px" margin="0% 2%" src={infoIcon}/>
                    Antes de marcar un pedido para el despacho, descargá y pegá la etiqueta correspondiente en el paquete.
                    <Icon width="25px" height="25px" margin="0% 2% 0% 25%" src={cross} onClick={()=> this.handleDisclaimer()}/>
                  </DisclaimerLabel> : <></>}
                  <Wrapper>
                    {this.isLoadingContent() ? <EmptyLoader /> : this.renderPageContent()}
                    {this.state.verModalComprador ? <DetailOrderModal loading={this.props.getDetailsSuccess} clasificados={this.props.clasificados} fetchOrderQR={this.props.fetchOrderQR} startConversation={()=> this.startConversation(this.state.orderToSee)} onClose={() => this.toggleVerModalComprador()} success={this.props.getDetailsFalse} order={this.state.orderToSee} /> : <></>}
                  </Wrapper>
                  {
                    this.state.showRow ? (
                      <Row justify={'flex-end'}  >
                        <>
                        <StyledButton color={false} onClick={() => this.handleDownloadLabel(this.state.ventasSelected)}>
                          Imprimir etiquetas
                        </StyledButton>
                        {(()=> {
                              switch (this.state.filterOn) {
                                case 'PENDIENTE_ENTREGA':
                                  return <StyledButton color={false} onClick={() => this.toggleCambioEstado(this.state.ventasSelected)}>
                                          Listo para despachar
                                          </StyledButton>;
                                case 'LISTO_DESPACHAR':
                                  return <StyledButton color={false} onClick={() => this.toggleCambioEstado(this.state.ventasSelected)}>
                                          Cambiar a en transito
                                          </StyledButton>;
                                case 'EN_TRANSITO':
                                  return <StyledButton color={false} onClick={() => this.toggleCambioEstado(this.state.ventasSelected)}>
                                          Marcar como entregado
                                          </StyledButton>;
                                case 'ENTREGADO':
                                  return <StyledButton color={false} onClick={() => this.toggleCambioEstado(this.state.ventasSelected)}>
                                          Enviar factura
                                          </StyledButton>;
                                default:
                                  return null;
                              }
                            })()}
                            </>
                      </Row>
                    ) : (<></>)
                  }

                </Section>
              </PageWrapper>
            </Page>
            :
            null
          }
          {this.state.modalCambioEstado && !this.props.loadingGetReadyToDeliver ?
            <ModalMessage success={this.props.fetchGetReadyToDeliverSuccess} onClose={() => this.closeModalUpdate()} >
              {
                this.props.fetchGetReadyToDeliverSuccess ? "Cambio de estado exitoso" : this.props.fetchGetReadyToDeliverFail
              }
            </ModalMessage>
            :
            <></>
          }
          {  //Show Conver
            this.props.loadingNewConversation ?
              <ModalMessage success={true} onClose={() => this.props.newConversationReset()} >Iniciando Conversación</ModalMessage>
              :
                null
          }

         {/* //"order_N"+this.state.orderToSee.orderid+".pdf" */}
          <a href={'data:application/octet-stream;base64,' + this.props.orderQr} download={"DetalleDeOrden.pdf"} id="download" hidden></a>


        </>
      )
    }
  }
}

const mapStateToProps = ({
  clasificados: { ventas, loadingVentas, fetchDetailProductSuccess,loadingNewConversation, orderQr, loadingGetReadyToDeliver, fetchGetReadyToDeliverSuccess, fetchGetReadyToDeliverFail }
}) => ({
  filteredVentas: ventas.length > 0 ? mapVentas(ventas) : [],
  loading: loadingVentas,
  getDetailsSuccess: fetchDetailProductSuccess,
  loadingGetReadyToDeliver,
  loadingNewConversation,
  orderQr,
  fetchGetReadyToDeliverSuccess,
  fetchGetReadyToDeliverFail
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchGetVentas, fetchGetReadyToDeliver, fetchDetailProduct, fetchOrderQR, newConversation, newConversationReset
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(Ventas)
)
