import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import Page from 'components/Page'
import Section from 'components/Section'
import withWidth from '@material-ui/core/withWidth'
import classNames from 'classnames'
import EmptyLoader from 'components/PageLoader'
import { fetchgetHomeExpensas, fetchgetExpensasPorCliente, fetchpostUploadFile, fetchExpensesReport } from 'store/actions/expensas.actions'
import mapExpensasHome from 'selectors/mapExpensasHome'
import EmptyWrapper from 'components/PageEmpty'
import MonthList from './components/MonthLists'
import SearchBar from 'components/SearchBar'
import { SelectStyled, SearchWrapper, ContentWrapper, ListWrapper, PageWrapper,Wrapper,ContainerSelects,ContainerSearchBar } from './styled'
import Detail from './components/Detail'
import ResultUploadExpensa from './components/ResultUploadExpensa'
import GenerateAutomaticExpense from './components/GenerateAutomaticExpense'
import { setNeighbourhood } from 'store/actions/userInfo.actions'
import NewUploadExpensa from './components/UploadExpensas'
import ReporteExpensas from './components/ReporteExpensas'
import ResultReporteExpensas from './components/ResultReporteExpensas'
import DetailItem from './components/DetailItem'
import themeStyles from '../invitados/invitados.theme.style'
import { withStyles } from '@material-ui/core/styles'

const years = [
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
    { value: '2019', label: '2019' },
  ];

class Expensas extends Component {
    state = {
        filter: '',
        newGenerateExpensa: false,
        newUploadExpensa: false,
        isDetailItemOpen: false,
        newResultUploadExpensa: false,
        reporteExpensas: false,
        resultReporteExpensas: false,
        monthSelected: "",
        neighbourhoods: [],
        selectedNeighbourhood: "",
        yearFilter:  { value: '2021', label: '2021' },
        loteFilter: "",
        meses: []
      }

    componentWillMount=()=> this.handleFetch();
    componentDidMount = () => {
        this.handleFetch();
        this.props.neighbourhoods.map(neighbourhood => {
            let barrio= {value: neighbourhood.name,label: neighbourhood.name,guid: neighbourhood.guid};
            this.setState(prevState => ({ neighbourhoods: prevState.neighbourhoods.concat(barrio) }))
        })
        this.setState(prevState => {
            let selectedNeighbourhood = Object.assign({}, prevState.selectedNeighbourhood); 
            selectedNeighbourhood.guid = this.props.selectedNeighbourhood.guid     
            selectedNeighbourhood.value = this.props.selectedNeighbourhood.name ? this.props.selectedNeighbourhood.name : this.props.selectedNeighbourhood.label
            selectedNeighbourhood.label = this.props.selectedNeighbourhood.name ? this.props.selectedNeighbourhood.name : this.props.selectedNeighbourhood.label   
            return { selectedNeighbourhood };                                 
          })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.yearFilter !== this.state.yearFilter || prevState.selectedNeighbourhood !== this.state.selectedNeighbourhood || prevState.loteFilter !== this.state.loteFilter && this.state.loteFilter.length > 4  || prevState.loteFilter !== this.state.loteFilter && this.state.loteFilter.length === 0) {
            this.handleFetch();
        }
    }

    handleFetch(){
        if(this.state.selectedNeighbourhood !== ""){
            this.props.fetchgetHomeExpensas(this.state.selectedNeighbourhood.guid, this.state.yearFilter.value,this.state.loteFilter)
        }
    }
    
    componentWillReceiveProps(props){
        if(props.expensas.homeExpensas ){
            this.setState({ isContentAvaiable: true })
        }
    }
    
    renderPageContent = () => (
        <SearchWrapper>
            <ContainerSearchBar>
                <SearchBar
                    placeholder={'Busca por: lote'}
                    style={'width: \'70%\' '}
                    value={this.state.loteFilter}
                    onChange={(e)=>  this.handleInputOnChange(e) }
                    onClear={this.handleInputClear}
                    onKeyDown={this.handleKeyPress}
                    noBorder={true}
                    autofocus
                />
                <ContainerSelects>
                    <SelectStyled
                        value={this.state.selectedNeighbourhood}
                        onChange={this.handleSelectNeighbourhood}
                        options={this.state.neighbourhoods}
                    />
                    <SelectStyled
                        value={this.state.yearFilter}
                        onChange={this.handleSelectYear}
                        options={years}
                    />
                </ContainerSelects>
            </ContainerSearchBar>
            <ContentWrapper>
                <ListWrapper id={'scrollContainer'} className={classNames('portal-hide-scrollbars')}>
                    {this.state.isContentAvaiable ? (
                        <MonthList
                            elements={this.props.expensasFormatted}
                            year={this.state.yearFilter.label}
                            onClick={this.handleClickPropietario}
                        />
                    ) : (
                        <EmptyWrapper message={'No hay expensas disponibles'} />
                    )}
                </ListWrapper>
                {this.state.monthSelected !== "" && this.props.expensas.homeExpensas !== [] ? (
                         <Detail
                            month={this.props.expensas.homeExpensas[this.state.monthSelected]} 
                            id={'scrollContainer'} 
                            className={classNames('portal-hide-scrollbars')} 
                            onOpenDetailItem={this.toggleDetailItem}
                            selectedNeighbourhood={this.state.selectedNeighbourhood}
                            yearFilter={this.state.yearFilter}
                            loteFilter={this.state.loteFilter}
                          />
                    ) : (
                        <EmptyWrapper message={'No hay expensas disponibles'} />
                )}
                   
            </ContentWrapper>
        </SearchWrapper>
    )
    
    handleInputOnChange = value => this.setState({ loteFilter: value, filterEnabled: true})
     
     
    handleSelectYear = value => this.setState({ yearFilter: value })

    handleSelectNeighbourhood = value => {
        this.props.setNeighbourhood(value)
        this.setState({ selectedNeighbourhood: value })
    }

    handleInputClear = value => {this.setState({loteFilter: value, filterEnabled: false })}

    handleClickPropietario = e => this.setState({ monthSelected: e })

    isLoadingContent = () =>  this.props.expensas.loadingHomeExpensas

    togglenewGenerateAutomaticExpense = () => this.setState({ newGenerateAutomaticExpense: !this.state.newGenerateAutomaticExpense })

    closeNewGenereteExpenseAndRefresh= () => {this.setState({ newGenerateAutomaticExpense: !this.state.newGenerateAutomaticExpense }); this.handleFetch()} 

    toggleDetailItem = (propietary) => this.setState({ isDetailItemOpen: !this.state.isDetailItemOpen, propietaryOpen: propietary }) 

    togglenewUploadExpensa = () => this.setState({ newUploadExpensa: !this.state.newUploadExpensa })

    togglenewResultUploadExpensa = () => this.setState({ newResultUploadExpensa: !this.state.newResultUploadExpensa })

    toggleReporteExpensas = () => this.setState({ reporteExpensas: !this.state.reporteExpensas })

    toggleResultReporteExpensas = () => this.setState({ resultReporteExpensas: !this.state.resultReporteExpensas })

    render() {
        return (
            <Page>
                <PageWrapper>           
                {this.state.isDetailItemOpen ? 
                    <DetailItem 
                        propietary={this.state.propietaryOpen} 
                        onClose={this.toggleDetailItem} 
                    /> :
                    <Section 
                        title={'Expensas'} 
                        btnContent={'Cobrar expensas'}
                        onBtnClick={this.togglenewGenerateAutomaticExpense}
                        btn2Content={'Reporte de expensas'}
                        onBtn2Click={this.toggleReporteExpensas}
                        link={"Importar rendicion de Expensas"}
                        onLinkClick={this.togglenewUploadExpensa}
                    >
                      <Wrapper>
                        {this.state.newUploadExpensa && 
                        <NewUploadExpensa 
                            onClose={this.togglenewUploadExpensa} 
                            onOpenResult={this.togglenewResultUploadExpensa}
                            openPeriod={this.handleClickPropietario}
                            onChange={(file) => this.props.fetchpostUploadFile(file)}
                            error={this.props.expensas.errorUploadFileExpense} 
                            loading={this.props.expensas.loadingUploadFileExpense} 
                        />}
                        {this.state.newResultUploadExpensa && 
                        <ResultUploadExpensa 
                            onClose={this.togglenewResultUploadExpensa} 
                            openPeriod={this.handleClickPropietario}
                            expensas={this.props.expensas}
                            onChange={(file) => this.props.fetchpostUploadFile(file)}
                            error={this.props.expensas.errorUploadFileExpense} 
                        />}
                        {this.state.newGenerateAutomaticExpense && 
                            <GenerateAutomaticExpense 
                                onClose={this.togglenewGenerateAutomaticExpense} 
                                openPeriod={this.handleClickPropietario}
                                expensas={this.props.expensas}
                                onChange={(file) => this.props.fetchpostUploadFile(file)}
                                error={this.props.expensas.errorGenerateAutomatiExpense} 
                            />
                        }
                        {this.state.reporteExpensas && 
                        <ReporteExpensas 
                            onClose={this.toggleReporteExpensas} 
                            onOpenResult={this.toggleResultReporteExpensas}
                            error={this.props.expensas.errorExpensesReport} 
                            onChange={(date) => this.props.fetchExpensesReport(date)}
                            loading={this.props.expensas.loadingExpensesReport} 
                        />}
                        {this.state.resultReporteExpensas && 
                        <ResultReporteExpensas 
                            onClose={this.toggleResultReporteExpensas}
                        />}
                        {this.isLoadingContent() ? <EmptyLoader /> : this.renderPageContent()}
                      </Wrapper>
                    </Section>
                }
                </PageWrapper>
            </Page>
        )
    }
}
    
const mapStateToProps = ({
    userInfo: { neighbourhoods, selectedNeighbourhood,username },
    expensas,
  }) => ({
    expensasFormatted: mapExpensasHome(expensas),
    neighbourhoods,
    selectedNeighbourhood,
    username,
    expensas
  })
  

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchgetHomeExpensas, fetchgetExpensasPorCliente, fetchpostUploadFile, setNeighbourhood, fetchExpensesReport }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    withWidth(),
    withStyles(themeStyles, { withTheme: true })
    )(Expensas)
)
    