import styled from 'styled-components'
import {
    Grid,
    Button,
    Paper,
    Typography
} from '@material-ui/core'

export const Header = styled.div`
    width: 100%;
    min-height: 20px;
    max-height: 50px;    
    margin: 30px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
`
export const ContentWrapper = styled.div`
    width: 95%;
    margin: 1% 2.5%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
`

export const Label = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ black,theme }) => (black ? theme.colors.black : theme.colors.midGray)};
    font-size: ${({ bold }) => (bold ? '18px' : '15px')};
    align-self: center;
    font-weight:${({ bold }) => (bold ? 600 : 400)};
    margin-left: 5px;
`
export const LabelContainer = styled.div`
    min-height: 20px;
    max-height: 30px;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    align-self: center;
    justify-content: center;
    width: ${({ width }) => (width ? width : "15%")};
    margin-left: ${({ margin }) => (margin ? margin : "0px")};
`

export const SelectContainer = styled.div`
    min-height: 20px;
    max-height: 30px;
    margin: 0px 5px;
    width: 15%;
`
export const TableContainer = styled.div`
    width: 100%;

`

export const Status = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ status, theme }) => {
        if (status === "Abonado") {
            return theme.colors.oceanGreen;
        } else if (status === "Pendiente") {
            return theme.colors.mariGold;
        } else { 
            return theme.colors.pomegranate;
        }
        }};
    font-size: 18px;
    align-self: center;
    font-weight: 500;
`