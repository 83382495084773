import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withWidth from '@material-ui/core/withWidth'
import compose from 'recompose/compose'
import InfiniteScroll from 'react-infinite-scroll-component'
import CircularProgress from '@material-ui/core/CircularProgress'
import {  DataContainer,SelectStyled, DataWrapper, Row, Status, Title,InfiniteLoaderWrapper, MoneyContainer, MoneyTitle,MoneySubtitle, MoneyWrapper,TitleList, ImgContainer,NoContentContainer,ErrorLabel} from './styled'
import expensas from 'assets/icons/group-3.svg';
import EmptyWrapper from 'components/PageEmpty'
import { fetchgetFileDetailNeighbourhood } from 'store/actions/expensas.actions'

const options = [
    { value: 'PENDING', label: 'Pendientes' },
    { value: 'COMPLETED', label: 'Pagados' },
    { value: 'REJECTED', label: 'Rechazados' },
    { value: 'ERROR', label: 'Error' },
    { value: 'ALL', label: 'Todos' }
  ];

  
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ARS',
    });
  

class ExpensasDetail extends React.Component {
    state = {
        guest: this.props.month,
        filter:  { value: 'ALL', label: 'Todos' }
    }

     handleFilter = (e) => {
        this.setState({filter: e})
      }

    
    fetchFileDetail = async id => {
        var response = await this.props.fetchgetFileDetailNeighbourhood(this.props.selectedNeighbourhood.guid,this.props.loteFilter,this.props.yearFilter.value)

        if(this.props.expensas.fileDetailNeighbourhood){
            this.setState({dataResponse: this.props.expensas.fileDetailNeighbourhood },
            this.downloadBlob())
        }
    }

    downloadBlob(){
      document.getElementById('download').click();
    }
  
    render() {
        return (
            <DataContainer style={{overflow: 'auto'}} id={'scrollContainer'} className={'portal-hide-scrollbars'}>
                {this.props.month ? (
                    <InfiniteScroll
                        scrollableTarget={'scrollContainer'}
                        dataLength={this.props.month}
                        next={this.props.month.fetchMoreContent}
                        hasMore={this.props.month.hasMore}
                        loader={
                            <InfiniteLoaderWrapper>
                                <CircularProgress size={30}  />
                            </InfiniteLoaderWrapper>
                        } 
                    >
                        {
                            this.props.month.expenses_header.details.length === 0 ? (
                                <DataWrapper>
                                    <ErrorLabel>{'No hay expensas este mes'}</ErrorLabel>
                                </DataWrapper>
                            ) : (
                                this.RenderExpensasDetail(this.props.month)
                            )
                        }
                    </InfiniteScroll>
                ) : (
                    <EmptyWrapper message={'No hay expensas disponibles'} />
                )}
        </DataContainer> 
        )
    }


 RenderExpensasDetail = ({moneyTotal,moneySubtotal}) => {
    console.log(this.props)
    return (
        <DataWrapper id={'scrollContainer'} >
            <Row justify={'initial'}>
                <Title>{'Listado de propietarios'}</Title>
                <ImgContainer onClick={() =>this.fetchFileDetail()}>
                    <img src={expensas}/>
                </ImgContainer>
                <SelectStyled
                    value={this.state.filter}
                    onChange={this.handleFilter}
                    options={options}
                    placeholder={'Ordenar por...'}
                />
            </Row>
            <Row align={'flex-start'} margin={'10px 0px'} >
                <MoneyWrapper>
                    <MoneyContainer>
                        <MoneyTitle>{formatter.format(this.props.month.total_pendiente_cobrar)} </MoneyTitle>
                        <MoneySubtitle>{'Total de expensas a cobrar'} </MoneySubtitle>
                    </MoneyContainer>
                    <MoneyContainer>
                        <MoneyTitle>{formatter.format(this.props.month.total_cobrado)} </MoneyTitle>
                        <MoneySubtitle>{'Total de expensas cobrado'} </MoneySubtitle>
                    </MoneyContainer>
                    <MoneyContainer border={"none"}>
                        <MoneyTitle>{this.props.month.total_usuario_debito_directo} </MoneyTitle>
                        <MoneySubtitle>{'Usuarios con débito directo'} </MoneySubtitle>
                    </MoneyContainer>
                </MoneyWrapper>
            </Row>
            <Row justify={'flex-start'} margin={'10px 0px 0px 0px'}>
                <TitleList >{'Propietario'} </TitleList>
                <TitleList>{'Barrio'} </TitleList>
                <TitleList margin={'0px 10px 0px 50px'}>{'Lote'} </TitleList>
                <TitleList margin={'0px 10px 0px 5px'}>{'Importe'} </TitleList>
                <TitleList margin={'0px 5px 0px 5px'}>{'Estado'} </TitleList>
            </Row>
            {this.props.month.expenses_header.details.map((propietario, i) => (
                <>
                <a 
                    href={'data:application/octet-stream;base64,' + this.props.expensas.fileDetailNeighbourhood} 
                    download={"Detalle"+propietario.neighborhood_name+".pdf"} id="download" 
                    hidden
                ></a>
                    {this.state.filter.value === propietario.status_code ? (
                        <Row 
                        justify={'flex-start'} 
                        margin={'5px 0px 10px 0px'} 
                        widthAuto padding={'15px'} 
                        border={'1px solid #F2F2F2'} 
                        background={'#FBFCFD'}
                        onClick={() => this.props.onOpenDetailItem(propietario)}
                        key={i}
                    >
                        {console.log(propietario)}
                        <TitleList width={"30%"} bold={true}>{propietario.client_name} </TitleList>
                        <TitleList bold={true} margin={'0px 15px 0px 5px'}>{propietario.neighborhood_name} </TitleList>
                        <TitleList bold={true} margin={'0px 50px 0px 0px'}>{propietario.property_code} </TitleList>
                        <TitleList bold={true} margin={'0px 50px 0px 0px'}>{formatter.format(propietario.amount)} </TitleList>
                        <TitleList>
                            {(() => {
                            switch(propietario.status_code) {
                                case "COMPLETED":
                                return  <Status background={"#15994C"} /> ;
                                case "PENDING":
                                return <Status background={"#f7b343"} />;
                                case "ERROR":
                                return <Status background={"#F44336"} />;
                                case "REJECTED":
                                return <Status background={"#F44336"} />;
                            }
                            })()}
                        </TitleList>
                    
                    </Row>
                    ) : (
                        <>
                        {this.state.filter.value === "ALL" ? (
                            <Row 
                                justify={'flex-start'} 
                                margin={'5px 0px 10px 0px'} 
                                widthAuto padding={'15px'} 
                                border={'1px solid #F2F2F2'} 
                                background={'#FBFCFD'}
                                onClick={() => this.props.onOpenDetailItem(propietario)}
                                key={i}
                            >
                                <TitleList width={"30%"} bold={true}>{propietario.client_name} </TitleList>
                                <TitleList bold={true} margin={'0px 15px 0px 5px'}>{propietario.neighborhood_name} </TitleList>
                                <TitleList bold={true} margin={'0px 50px 0px 0px'}>{propietario.property_code} </TitleList>
                                <TitleList bold={true} margin={'0px 50px 0px 0px'}>{formatter.format(propietario.amount)} </TitleList>
                                <TitleList>
                            {(() => {
                            switch(propietario.status_code) {
                                case "COMPLETED":
                                return  <Status background={"#15994C"} /> ;
                                case "PENDING":
                                return <Status background={"#f7b343"} />;
                                case "ERROR":
                                return <Status background={"#F44336"} />;
                                case "REJECTED":
                                return <Status background={"#F44336"} />;
                                
                            }
                            })()}
                        </TitleList>
                            </Row>
                        ) : (<></>)}
                    </>
                    )}
                </>
            ))}            
        </DataWrapper> 
        )
    }


}


const mapStateToProps = ({
    expensas
  }) => ({
    expensas
  })
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators({  fetchgetFileDetailNeighbourhood }, dispatch)
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    compose(
    withWidth()
    )(ExpensasDetail)
  )
    