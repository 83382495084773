import * as actionTypes from '../actions/actionTypes'
import { updateObject } from 'shared/utility'

const initialState = {

  loadingVendorConsultas: false,
  vendorConsultasStart: false,
  vendorConsultasSuccess: false,
  vendorConsultasFail: false,
  vendorConsultas: [],

  loadingVendorConsultasEstados: false,
  vendorConsultasEstadosStart: false,
  vendorConsultasEstadosSuccess: false,
  vendorConsultasEstadosFail: false,
  vendorConsultasEstados: [],

  loadingPostChangeState: false,
  postChangeStateStart: false,
  postChangeStateFail: false,
  postChangeStateSuccess: false,
  postChangeState: [],

  loadingPostAssingUser: false,
  postAssingUserStart: false,
  postAssingUserSuccess: false,
  postAssingUserFail: false,
  postAssingUser: [],

  loadingNewTicket: false,
  newTicketStart: false,
  newTicketSuccess: false,
  newTicketFail: false,
  newTicket: [],

  loadingEditTicket: false,
  editTicketStart: false,
  editTicketSuccess: false,
  editTicketFail: false,
  editTicket: [],


  loadingproductType: false,
  productTypeStart: false,
  productTypeSuccess: false,
  productTypeFail: false,
  productType: [],


  loadinguserGestorTickets: false,
  userGestorTicketsStart: false,
  userGestorTicketsSuccess: false,
  userGestorTicketsFail: false,
  userGestorTickets: [],


  loadingentrepeneur: false,
  entrepeneurStart: false,
  entrepeneurSuccess: false,
  entrepeneurFail: false,
  entrepeneur: [],


  loadingGetTicketByID: false,
  GetTicketByIDStart: false,
  GetTicketByIDSuccess: false,
  GetTicketByIDFail: false,
  GetTicketByID: [],

  loadingpostTicketHistory: false,
  postTicketHistoryStart: false,
  postTicketHistorySuccess: false,
  postTicketHistoryFail: false,
  postTicketHistory: [],

  loadingGetTicketHistoryByID: false,
  GetTicketHistoryByIDStart: false,
  GetTicketHistoryByIDSuccess: false,
  GetTicketHistoryByIDFail: false,
  GetTicketHistoryByID: [],
  

}


///////////////////////////////////

const postTicketHistoryByIdStart = state => {
  return updateObject(state, {
    loadingpostTicketHistory: true,
    postTicketHistoryStart: true,
    postTicketHistorySuccess: false,
    postTicketHistoryFail: false,
    postTicketHistory: [],

  })
}

const postTicketHistoryByIdSuccess = (state, action) => {
  return updateObject(state, {
    loadingpostTicketHistory: false,
    postTicketHistoryStart: false,
    postTicketHistorySuccess: true,
    postTicketHistoryFail: false,
    postTicketHistory: action.data,
  })
}

const postTicketHistoryByIdFail = (state, error) => {
  return updateObject(state, {

    loadingpostTicketHistory: false,
    postTicketHistoryStart: false,
    postTicketHistorySuccess: false,
    postTicketHistoryFail: error,
    postTicketHistory: [],
  })
}







///////////////////////////////////

const fetchTicketHistoryByIdStart = state => {
  return updateObject(state, {
    loadingGetTicketHistoryByID: true,
    GetTicketHistoryByIDStart: true,
    GetTicketHistoryByIDSuccess: false,
    GetTicketHistoryByIDFail: false,
    GetTicketHistoryByID: [],

  })
}

const fetchTicketHistoryByIdSuccess = (state, action) => {
  return updateObject(state, {
    loadingGetTicketHistoryByID: false,
    GetTicketHistoryByIDStart: false,
    GetTicketHistoryByIDSuccess: true,
    GetTicketHistoryByIDFail: false,
    GetTicketHistoryByID: action.data,
  })
}

const fetchTicketHistoryByIdFail = (state, error) => {
  return updateObject(state, {

    loadingGetTicketHistoryByID: false,
    GetTicketHistoryByIDStart: false,
    GetTicketHistoryByIDSuccess: false,
    GetTicketHistoryByIDFail: error,
    GetTicketHistoryByID: [],
  })
}



///////////////////////////////////

const fetchTicketByIdStart = state => {
  return updateObject(state, {
    loadingGetTicketByID: true,
    GetTicketByIDStart: true,
    GetTicketByIDSuccess: false,
    GetTicketByIDFail: false,
    GetTicketByID: [],

  })
}

const fetchTicketByIdSuccess = (state, action) => {
  return updateObject(state, {
    loadingGetTicketByID: false,
    GetTicketByIDStart: false,
    GetTicketByIDSuccess: true,
    GetTicketByIDFail: false,
    GetTicketByID: action.data,
  })
}

const fetchTicketByIdFail = (state, error) => {
  return updateObject(state, {

    loadingGetTicketByID: false,
    GetTicketByIDStart: false,
    GetTicketByIDSuccess: false,
    GetTicketByIDFail: error,
    GetTicketByID: [],
  })
}


///////////////////////////////////

const fetchEntrepreneurStart = state => {
  return updateObject(state, {
    loadingentrepeneur: false,
    entrepeneurStart: false,
    entrepeneurSuccess: false,
    entrepeneurFail: false,
    entrepeneur: [],

  })
}

const fetchEntrepreneurSuccess = (state, action) => {
  return updateObject(state, {
    loadingentrepeneur: false,
    entrepeneurStart: false,
    entrepeneurSuccess: false,
    entrepeneurFail: false,
    entrepeneur: action.data,
  })
}

const fetchEntrepreneurFail = (state, error) => {
  return updateObject(state, {

    loadingentrepeneur: false,
    entrepeneurStart: false,
    entrepeneurSuccess: false,
    entrepeneurFail: error,
    entrepeneur: [],
  })
}




///////////////////////////////////

const fetchUsersGestorStart = state => {
  return updateObject(state, {
    loadinguserGestorTickets: true,
    userGestorTicketsStart: true,
    userGestorTicketsSuccess: false,
    userGestorTicketsFail: false,
    userGestorTickets: [],

  })
}

const fetchUsersGestorSuccess = (state, action) => {
  return updateObject(state, {
    loadinguserGestorTickets: false,
    userGestorTicketsStart: false,
    userGestorTicketsSuccess: true,
    userGestorTicketsFail: false,
    userGestorTickets: action.data,
  })
}

const fetchUsersGestorFail = (state, error) => {
  return updateObject(state, {
    loadinguserGestorTickets: false,
    userGestorTicketsStart: false,
    userGestorTicketsSuccess: false,
    userGestorTicketsFail: error,
    userGestorTickets: [],
  })
}








///////////////////////////////////

const fetchProductTypeStart = state => {
  return updateObject(state, {
    loadingproductType: true,
    productTypeStart: true,
    productTypeSuccess: false,
    productTypeFail: false,
    //    productType: [],

  })
}

const fetchProductTypeSuccess = (state, action) => {
  return updateObject(state, {
    loadingproductType: false,
    productTypeStart: false,
    productTypeSuccess: true,
    productTypeFail: false,
    productType: action.data,
  })
}

const fetchProductTypeFail = (state, error) => {
  return updateObject(state, {
    loadingproductType: false,
    productTypeStart: false,
    productTypeSuccess: false,
    productTypeFail: error,
    productType: [],
  })
}

///////////////////////////////////

const fetchVendorConsultasStart = state => {
  return updateObject(state, {
    loadingVendorConsultas: true,
    vendorConsultasStart: true,
    vendorConsultasSuccess: false,
    vendorConsultasFail: false,

  })
}

const fetchVendorConsultasSuccess = (state, action) => {
  return updateObject(state, {
    loadingVendorConsultas: false,
    vendorConsultasStart: false,
    vendorConsultasSuccess: true,
    vendorConsultasFail: false,
    vendorConsultas: action.data,
  })
}

const fetchVendorConsultasFail = (state, error) => {
  return updateObject(state, {
    loadingVendorConsultas: false,
    vendorConsultasStart: false,
    vendorConsultasSuccess: false,
    vendorConsultasFail: error,
  })
}


///////////////////////////////////

const fetchVendorConsultasEstadosStart = state => {
  return updateObject(state, {
    loadingVendorConsultasEstados: true,
    vendorConsultasStartEstados: true,
    vendorConsultasSuccessEstados: false,
    vendorConsultasFailEstados: false,

  })
}

const fetchVendorConsultasEstadosSuccess = (state, action) => {
  return updateObject(state, {
    loadingVendorConsultasEstados: false,
    vendorConsultasStartEstados: false,
    vendorConsultasSuccessEstados: true,
    vendorConsultasFailEstados: false,
    vendorConsultasEstados: action.data,

  })
}

const fetchVendorConsultasEstadosFail = (state, error) => {
  return updateObject(state, {
    loadingVendorConsultasEstados: false,
    vendorConsultasEstadosStart: false,
    vendorConsultasEstadosSuccess: false,
    vendorConsultasEstadosFail: error,
  })
}







///////////////////////////////////

const postAssingUserStart = state => {
  return updateObject(state, {
    loadingPostAssingUser: true,
    postAssingUserStart: true,
    postAssingUserSuccess: false,
    postAssingUserFail: false,
  })
}

const postAssingUserSuccess = (state, action) => {
  return updateObject(state, {
    loadingPostAssingUser: false,
    postAssingUserStart: false,
    postAssingUserSuccess: true,
    postAssingUserFail: false,
    postAssingUser: action.data,

  })
}

const postAssingUserFail = (state, error) => {
  return updateObject(state, {
    loadingPostAssingUser: false,
    postAssingUserStart: false,
    postAssingUserSuccess: false,
    postAssingUserFail: error,

  })
}
const postAssingUserReset = (state, error) => {
  return updateObject(state, {
    loadingPostAssingUser: false,
    postAssingUserStart: false,
    postAssingUserSuccess: false,
    postAssingUserFail: false,

  })
}


///////////////////////////////////

const postChangeStateStart = state => {
  return updateObject(state, {
    loadingpostChangeState: true,
    postChangeStateStart: true,
    postChangeStateSuccess: false,
    postChangeStateFail: false,
  })
}

const postChangeStateSuccess = (state, action) => {
  return updateObject(state, {
    loadingpostChangeState: false,
    postChangeStateStart: false,
    postChangeStateSuccess: true,
    postChangeStateFail: false,
    postChangeState: action.data,

  })
}

const postChangeStateFail = (state, error) => {
  return updateObject(state, {
    loadingpostChangeState: false,
    postChangeStateStart: false,
    postChangeStateSuccess: false,
    postChangeStateFail: error,

  })
}

const postChangeStateReset = (state, error) => {
  return updateObject(state, {
    loadingpostChangeState: false,
    postChangeStateStart: false,
    postChangeStateSuccess: false,
    postChangeStateFail: false,
  })
}




///////////////////////////////////

const newTicketStart = state => {
  return updateObject(state, {
    loadingNewTicket: true,
    newTicketStart: true,
    newTicketSuccess: false,
    newTicketFail: false,
  })
}

const newTicketSuccess = (state, action) => {
  return updateObject(state, {
    loadingNewTicket: false,
    newTicketStart: false,
    newTicketSuccess: true,
    newTicketFail: false,
    newTicket: action.data,

  })
}

const newTicketFail = (state, error) => {
  return updateObject(state, {
    loadingNewTicket: false,
    newTicketStart: false,
    newTicketSuccess: false,
    newTicketFail: error,

  })
}

const newTicketReset = (state, error) => {
  return updateObject(state, {
    loadingNewTicket: false,
    newTicketStart: false,
    newTicketSuccess: false,
    newTicketFail: false,
  })
}



///////////////////////////////////

const editTicketStart = state => {
  return updateObject(state, {
    loadingEditTicket: true,
    editTicketStart: true,
    editTicketSuccess: false,
    editTicketFail: false,
  })
}

const editTicketSuccess = (state, action) => {
  return updateObject(state, {
    loadingEditTicket: false,
    editTicketStart: false,
    editTicketSuccess: true,
    editTicketFail: false,
    editTicket: action.data,

  })
}

const editTicketFail = (state, error) => {
  return updateObject(state, {
    loadingEditTicket: false,
    editTicketStart: false,
    editTicketSuccess: false,
    editTicketFail: error,

  })
}

const editTicketReset = (state, error) => {
  return updateObject(state, {
    loadingEditTicket: false,
    editTicketStart: false,
    editTicketSuccess: false,
    editTicketFail: false,
  })
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GET_VENDOR_CONSULTAS_FAIL:
      return fetchVendorConsultasFail(state, action)
    case actionTypes.FETCH_GET_VENDOR_CONSULTAS_SUCCESS:
      return fetchVendorConsultasSuccess(state, action)
    case actionTypes.FETCH_GET_VENDOR_CONSULTAS_START:
      return fetchVendorConsultasStart(state, action)


    case actionTypes.FETCH_GET_VENDOR_CONSULTAS_ESTADOS_FAIL:
      return fetchVendorConsultasEstadosFail(state, action)
    case actionTypes.FETCH_GET_VENDOR_CONSULTAS_ESTADOS_SUCCESS:
      return fetchVendorConsultasEstadosSuccess(state, action)
    case actionTypes.FETCH_GET_VENDOR_CONSULTAS_ESTADOS_START:
      return fetchVendorConsultasEstadosStart(state, action)


    case actionTypes.POST_CHANGE_STATE_FAIL:
      return postChangeStateFail(state, action)
    case actionTypes.POST_CHANGE_STATE_SUCCESS:
      return postChangeStateSuccess(state, action)
    case actionTypes.POST_CHANGE_STATE_START:
      return postChangeStateStart(state, action)
    case actionTypes.POST_CHANGE_STATE_RESET:
      return postChangeStateReset(state, action)

    case actionTypes.POST_ASSIGN_USER_FAIL:
      return postAssingUserFail(state, action)
    case actionTypes.POST_ASSIGN_USER_SUCCESS:
      return postAssingUserSuccess(state, action)
    case actionTypes.POST_ASSIGN_USER_START:
      return postAssingUserStart(state, action)
    case actionTypes.POST_ASSIGN_USER_RESET:
      return postAssingUserReset(state, action)



    case actionTypes.NEW_TICKET_FAIL:
      return newTicketFail(state, action)
    case actionTypes.NEW_TICKET_SUCCESS:
      return newTicketSuccess(state, action)
    case actionTypes.NEW_TICKET_START:
      return newTicketStart(state, action)
    case actionTypes.NEW_TICKET_RESET:
      return newTicketReset(state, action)



    case actionTypes.EDIT_TICKET_FAIL:
      return editTicketFail(state, action)
    case actionTypes.EDIT_TICKET_SUCCESS:
      return editTicketSuccess(state, action)
    case actionTypes.EDIT_TICKET_START:
      return editTicketStart(state, action)
    case actionTypes.EDIT_TICKET_RESET:
      return editTicketReset(state, action)



    case actionTypes.ENTREPENEUR_FAIL:
      return fetchEntrepreneurFail(state, action)
    case actionTypes.ENTREPENEUR_SUCCESS:
      return fetchEntrepreneurSuccess(state, action)
    case actionTypes.ENTREPENEUR_START:
      return fetchEntrepreneurStart(state, action)

    case actionTypes.USERS_GESTOR_FAIL:
      return fetchUsersGestorFail(state, action)
    case actionTypes.USERS_GESTOR_SUCCESS:
      return fetchUsersGestorSuccess(state, action)
    case actionTypes.USERS_GESTOR_START:
      return fetchUsersGestorStart(state, action)

    case actionTypes.PRODUCT_TYPE_FAIL:
      return fetchProductTypeFail(state, action)
    case actionTypes.PRODUCT_TYPE_SUCCESS:
      return fetchProductTypeSuccess(state, action)
    case actionTypes.PRODUCT_TYPE_START:
      return fetchProductTypeStart(state, action)


    case actionTypes.GET_TICKET_FAIL:
      return fetchTicketByIdFail(state, action)
    case actionTypes.GET_TICKET_SUCCESS:
      return fetchTicketByIdSuccess(state, action)
    case actionTypes.GET_TICKET_START:
      return fetchTicketByIdStart(state, action)



      case actionTypes.GET_TICKET_HIS_FAIL:
        return fetchTicketHistoryByIdFail(state, action)
      case actionTypes.GET_TICKET_HIS_SUCCESS:
        return fetchTicketHistoryByIdSuccess(state, action)
      case actionTypes.GET_TICKET_HIS_START:
        return fetchTicketHistoryByIdStart(state, action)

        case actionTypes.SAVE_TICKET_HIS_FAIL:
          return postTicketHistoryByIdFail(state, action)
        case actionTypes.SAVE_TICKET_HIS_SUCCESS:
          return postTicketHistoryByIdSuccess(state, action)
        case actionTypes.SAVE_TICKET_HIS_START:
          return postTicketHistoryByIdStart(state, action)


    default:
      return state
  }
}

export default reducer
