import React, { Component } from 'react'
import Modal from 'components/Modal'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import PageLoader from '../../../components/PageLoader'
import TextArea from 'components/TextArea';
import Moment from 'react-moment'

import Input from 'components/Input'
import mapHistory from 'selectors/mapConsultasVendorHistory'
import ModalMessage from 'components/ModalMessage'
import { getFormattedDateHour } from 'utils/dateParser'


import {
  FormContainer,
  Row,
  RowLeft,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ErrorMessage,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  InputWrapperMargin,
  ContenedorHistorial
} from './styled'

import { connect } from 'react-redux'
import CrossIcon from 'assets/icons/cross.png'





class ShowTicket extends Component {
  state = {}

  componentDidMount() {
    //this.props.fetchRoles()
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors, resetForm }) => (

    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row error={errors.user || errors.type}>
        <InputWrapper width={48}>
          <InputLabel><strong>{'Contacto'}</strong></InputLabel>
          <InputLabel>{'Nombre: '}{values.name}</InputLabel>
          <InputLabel>{'Apellido: '}{values.surname}</InputLabel>
          <InputLabel>{'Teléfono: '}{values.tel}</InputLabel>
          <InputLabel>{'Email: '}{values.email}</InputLabel>
          <InputLabel>{'Asunto: '}{values.subject}</InputLabel>
          <InputLabel>{'Mensaje: '}{values.message}</InputLabel>
          <br />
          <InputLabel><strong>{'Info'}</strong></InputLabel>
          <InputLabel>{'Barrio: '}{values.entrepreneurshipid.label}</InputLabel>
          <InputLabel>{'Tipo Producto: '}{values.productType ? values.productType.label : 'N/D'}</InputLabel>
          <InputLabel>{'Usuario Asignado: '}{values.userAssigned ? values.userAssigned.label : 'N/D'}</InputLabel>
          <InputLabel>{'Estado: '}{values.status.label}</InputLabel>
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Historial:'}</InputLabel>
          <Row>
            <InputWrapper width={100}>
              <InputLabel>{'Ingrese un comentario para agregar al ticket actual:'}</InputLabel>
              <TextArea
                name={'history'}
                placeholder={'Comentario'}
                value={values.history}
                onChange={({ target: { value } }) => setFieldValue('history', value)}
                minHeight={'100'}
                minWidth={'0'}
                maxHeight={'100'}
                minWidth={'0'}></TextArea>
              {errors.history && <ErrorLabel>{errors.history}</ErrorLabel>}
              <ButtonWrapper>
                {this.props.gestorConsultas.loadingpostTicketHistory ?
                  <PageLoader />
                  :
                  <StyledButton type="Submit">Agregar</StyledButton>
                }

              </ButtonWrapper>
            </InputWrapper>
          </Row>
          <hr />
          {

            this.props.gestorConsultas.GetTicketHistoryByID.length > 0 ?
              <ContenedorHistorial>
                {mapHistory(this.props.gestorConsultas.GetTicketHistoryByID).map((e) => (
                  <>
                    <InputLabel>{getFormattedDateHour(e.date)} - {e.comment}</InputLabel>
                  </>
                ))
                }
              </ContenedorHistorial>
              :

              <PageLoader />

          }

        </InputWrapper>
      </Row>



      {this.props.editTicketSuccess ?
        <ModalMessage success={true} onClose={() => this.props.onClose()}> Ticket editado correctamente.</ModalMessage>
        :
        null
      }

      {this.props.editTicketFail ?
        <ModalMessage success={false} onClose={() => this.props.editTicketReset()}> Error modificando el ticket.</ModalMessage>
        :
        null
      }
    </FormContainer>
  )


  handleSubmit = async (data, { setSubmitting, setErrors, setStatus, resetForm }) => {

    let body = {
      'ticketId': this.props.ticketSelected.id,
      'comment': data.history,
    }
    await this.props.saveHistory(body)
    await this.props.fetchHistory(this.props.ticketSelected.id)

    resetForm(
      {
        name: this.props.ticketSelected.vendor.name,
        surname: this.props.ticketSelected.vendor.surname,
        tel: this.props.ticketSelected.vendor.phonenumber,
        email: this.props.ticketSelected.vendor.email,
        status: { value: this.props.ticketSelected.state.id, label: this.props.ticketSelected.state.name },
        subject: this.props.ticketSelected.subject,
        message: this.props.ticketSelected.message,
        userAssigned: this.props.ticketSelected.user ? { value: this.props.ticketSelected.user.id, label: this.props.ticketSelected.user.fullname } : null,
        productType: this.props.ticketSelected.producttype ? { value: this.props.ticketSelected.producttype.id, label: this.props.ticketSelected.producttype.name } : null,
        entrepreneurshipid: { value: this.props.ticketSelected.entrepreneurship.id, label: this.props.ticketSelected.entrepreneurship.name },
        history: '',
      }
    )
  }

  getSignUpSchema = () =>
    Yup.object().shape({
      history: Yup.string().required()
    })


  getInitialValues = () => ({
    name: this.props.ticketSelected.vendor.name,
    surname: this.props.ticketSelected.vendor.surname,
    tel: this.props.ticketSelected.vendor.phonenumber,
    email: this.props.ticketSelected.vendor.email,
    status: { value: this.props.ticketSelected.state.id, label: this.props.ticketSelected.state.name },
    subject: this.props.ticketSelected.subject,
    message: this.props.ticketSelected.message,
    userAssigned: this.props.ticketSelected.user ? { value: this.props.ticketSelected.user.id, label: this.props.ticketSelected.user.fullname } : null,
    productType: this.props.ticketSelected.producttype ? { value: this.props.ticketSelected.producttype.id, label: this.props.ticketSelected.producttype.name } : null,
    entrepreneurshipid: { value: this.props.ticketSelected.entrepreneurship.id, label: this.props.ticketSelected.entrepreneurship.name },
  })


  render() {


    return (
      <Modal>
        {this.props.ticketSelected.length == 0 ?
          <PageLoader />
          :
          <Formik
            initialValues={this.getInitialValues()}
            validateOnChange={false}
            validationSchema={this.getSignUpSchema()}
            onSubmit={this.handleSubmit}
            render={e => this.getFormContent(e)}
          />
        }
      </Modal>
    )
  }
}

const mapStateToProps = ({
  userInfo, gestorConsultas
}) => ({
  userInfo,
  gestorConsultas,
  loadingEditTicket: gestorConsultas.loadingEditTicket,
  editTicketSuccess: gestorConsultas.editTicketSuccess,
  editTicketFail: gestorConsultas.editTicketFail,
  editTicketStart: gestorConsultas.editTicketStart,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {

    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowTicket)
