import React from 'react'
import Page from 'components/Page'
import Section from 'components/Section'
import EmptyLoader from 'components/PageLoader'
import { Wrapper, Container, Row, Circle, ColumnItem, StyledButton, ErrorLabel, ContainerImg, Icon, PageWrapper, ContainerSearchBar, SearchWrapper } from './styled'
import MaterialTable from 'material-table'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageLoader from '../../../components/PageLoader'
import { fetchNewsById, fetchNews, fetchNewsByName, editNews, editNewsReset, fetchDeleteNews, fetchDeleteNewsReset, createNewNews, createNewNewsReset, fetchDetailNewsReset } from 'store/actions/fundacion.actions';
import SearchBar from 'components/SearchBar'
import eyeIcon from 'assets/icons/eyeIcon.svg'
import editIcon from 'assets/icons/editIcon.svg'
import Delete from 'assets/icons/deletetrash.svg'
import DeleteModal from '../components/modalDelete'
import NewsModal from '../components/newsModal'
import ModalMessage from 'components/ModalMessage'

class News extends React.Component {

  state = {
    filter: '',
    isFiltered: false,
    transferSelected: [],
    error: '',
    newResultModal: false,
    tabEnabled: 0,
    addNews: false,
    showDetail: false,
    editNews: false,
    deleteModal: false
  }

  componentDidMount() {
    this.props.fetchNews()
  }

  isContentAvailable = () => (this.props.fetchNewsSucces === true && this.props.loadingNews === false) ? true : false


  toggleAddModal = () => {
    this.setState({ addNews: !this.state.addNews }, () => {
      if (!this.state.addNews) {
        this.props.createNewNewsReset();
        this.fetchNews()
      }
    });
  }

  toggleEditModal = () => {
    this.setState({ editNews: !this.state.editNews }, () => {
      if (!this.state.editNews) {
        this.fetchNews()
      }
    });
  }

  toggleDetailModal = () => {
    this.setState({ showDetail: !this.state.showDetail }, () => {
      if (!this.state.showDetail) {
        this.fetchNews()
      }
    });
  }

  toggleDeleteModal = () => {
    this.props.fetchDeleteNewsReset();
    this.setState({ deleteModal: !this.state.deleteModal });
  }

  handleEditNews = (idNews) => {
    this.props.editNewsReset();
    this.props.fetchDetailNewsReset();
    this.props.fetchNewsById(idNews);
    this.setState({ idToEdit: idNews }, this.toggleEditModal())
  }

  handleDetailNews = (idNews) => {
    console.log('handleDetailNews', idNews);
    this.props.fetchDetailNewsReset();
    this.props.fetchNewsById(idNews);
    this.setState({ idToDetail: idNews }, this.toggleDetailModal(idNews))
  }

  handleDeleteNews = (idNews) => {
    this.setState({ idToDelete: idNews }, this.toggleDeleteModal())
  }

  fetchNews = () => {
    console.log('this.state.isFiltered', this.state.isFiltered);
    if (this.state.isFiltered) {
      this.props.fetchNewsByName(this.state.filter);
      return;
    }

    this.props.fetchNews();
  }


  async DeleteNews() {
    this.props.fetchDeleteNews(this.state.idToDelete)
  }

  createNewsHandler = async (data) => {
    let formattedData = {
      title: data.title,
      shortDescription: data.shortDescription,
      description: data.description,
      author: data.author,
      tags: data.tags,
      pictureBase64: data.imageUrl ? data.imageUrl.base64Value : null,
      imageExtension: data.imageUrl ? data.imageUrl.extension : null,
      order: data.order
    };

    await this.props.createNewNews(formattedData);
  }

  editNewsHandler = async (data) => {
    let formattedData = {
      id: data.id,
      title: data.title,
      shortDescription: data.shortDescription,
      description: data.description,
      author: data.author,
      tags: data.tags,
      pictureBase64: data.imageUrl ? data.imageUrl.base64Value : null,
      imageExtension: data.imageUrl ? data.imageUrl.extension : null,
      order: data.order
    };

    await this.props.editNews(formattedData);
  }

  searchBarKeyPressHandler = e => {
    if (e.keyCode === 13) {
      if (e.target.value !== '') {
        this.setState({ isFiltered: true });
        this.props.fetchNewsByName(e.target.value)
      } else {
        this.props.fetchNews();
      }
    }
  }

  searchBarInputClearHandler = () => {
    this.setState({ filter: '', isFiltered: false });
    this.props.fetchNews();
  }

  searchBarInputOnChange = value => {
    this.setState({ filter: value })
  }


  renderPageContent = () => {
    return (
      <SearchWrapper>
        <ContainerSearchBar>
          <SearchBar
            placeholder={'Buscar por título'}
            style={'width: \'70%\' '}
            value={this.state.filter}
            onChange={this.searchBarInputOnChange}
            onClear={this.searchBarInputClearHandler}
            noBorder={true}
            onKeyDown={this.searchBarKeyPressHandler}
            autofocus
          />
        </ContainerSearchBar>
        <Container>
          <MaterialTable
            columns={[
              { title: 'Titulo', field: 'title', },
              { title: 'Descripción corta', field: 'shortDescription', },
              { title: 'Autor', field: 'author', },
              { title: 'Orden', field: 'order', },
              { title: 'Fecha de creación', field: 'creationDate', },
              {
                title: 'Acciones', field: 'publication', render: rowData =>
                  <ContainerImg>
                    <Icon src={eyeIcon} onClick={() => this.handleDetailNews(rowData.id)} />
                    <Icon src={editIcon} onClick={() => this.handleEditNews(rowData.id)} />
                    <Icon src={Delete} onClick={() => this.handleDeleteNews(rowData.id)} />
                  </ContainerImg>
              }
            ]}
            data={this.props.news}

            localization={{
              header: {
                actions: 'Acciones',
              },
              body: { emptyDataSourceMessage: 'No hay datos para mostrar' }
            }}
            options={{
              toolbar: false,
              paging: false,
              height: '300px',
              width: "70vw",
              selection: false,
              actionsColumnIndex: -1,
              headerStyle: {
                zIndex: 0
              }
            }} //Esconde titulo y search y pagination 
          />
        </Container>
      </SearchWrapper>
    )
  }

  renderModalMessageSuccess = (message, callback) => (<ModalMessage success={true} onClose={() => callback()}>
    {message}
  </ModalMessage>);

  renderModalMessageFail = (message, callback) => (<ModalMessage success={false} onClose={() => callback()}>
    {message}
  </ModalMessage>);

  render() {
    //ADD MODAL
    let addModal = null;
    if (this.props.createNewNewsSuccess) {
      addModal = this.renderModalMessageSuccess('Se ha creado la novedad correctamente.', this.toggleAddModal);
    }
    else if (this.props.createNewNewsFail) {
      editModal = this.renderModalMessageFail('Hubo un error al intentar crear la novedad.', this.toggleAddModal);
    }
    else if (this.state.addNews) {
      addModal = <NewsModal tittle="Agregar Novedad"
        onClose={() => this.toggleAddModal()}
        onSubmit={this.createNewsHandler}
        isSubmittingNews={this.props.loadingCreateNewNews} />;
    }

    //EDIT MODAL
    let editModal = null;
    if (this.props.editNewsSuccess) {
      editModal = this.renderModalMessageSuccess('Se ha editado la novedad correctamente.', this.toggleEditModal);
    }
    else if (this.props.editNewsFail) {
      editModal = this.renderModalMessageFail('Hubo un error al intentar editar la novedad.', this.toggleEditModal);
    }
    else if (this.state.editNews) {
      editModal = <NewsModal tittle="Editar Novedad"
        isReadOnly={false}
        onSubmit={this.editNewsHandler}
        loading={this.props.loadingDetailNews}
        onClose={() => { this.toggleEditModal() }}
        isSubmittingNews={this.props.loadingEditNews}
        detailNews={this.props.detailNews} />;
    }

    //DETAIL MODAL
    let detailModal = null;
    if (this.state.showDetail) {
      detailModal = <NewsModal tittle="Detalle"
        isReadOnly={true}
        onClose={() => { this.toggleDetailModal() }}
        loading={this.props.loadingDetailNews}
        detailNews={this.props.detailNews} />;
    }

    let deleteModal = null;
    if (this.state.deleteModal) {
      deleteModal = <DeleteModal success={this.props.successDeleteNews}
        isNews={true}
        loadingDelete={this.props.loadingDeleteNews}
        onClose={() => this.toggleDeleteModal()}
        delete={() => this.DeleteNews()} />;
    }

    console.log('loadingEditNews', this.props.loadingEditNews);
    return (
      <>
        {!this.state.addNews && !this.state.editNews && !this.state.showDetail ?
          <Page>
            <PageWrapper>
              <Section title={'Listado de novedades'}
                onBack={() => this.props.onClose()}
                btnContent={'Agregar novedad'}
                onBtnClick={() => this.toggleAddModal()}>
                <Wrapper>
                  {this.isContentAvailable() ? this.renderPageContent() : <EmptyLoader />}
                </Wrapper>
              </Section>
            </PageWrapper>
          </Page>
          : null
        }
        {addModal}
        {editModal}
        {detailModal}
        {deleteModal}
      </>
    )
  }
}

const mapStateToProps = ({
  fundacion
}) => ({
  loadingNews: fundacion.loadingNews,
  news: fundacion.news,
  detailNews: fundacion.detailNews,
  loadingEditNews: fundacion.loadingEditNews,
  editNewsSuccess: fundacion.editNewsSuccess,
  editNewsFail: fundacion.editNewsFail,
  loadingDetailNews: fundacion.loadingDetailNews,
  fetchNewsSucces: fundacion.fetchNewsSuccess,
  successDeleteNews: fundacion.fetchDeleteNewsSuccess,
  loadingDeleteNews: fundacion.loadingDeleteNews,
  loadingCreateNewNews: fundacion.loadingCreateNewNews,
  createNewNewsSuccess: fundacion.createNewNewsSuccess,
  createNewNewsFail: fundacion.createNewNewsFail,
  submitErrorNews: fundacion.createNewNewsFail
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchNews,
      fetchNewsById,
      fetchNewsByName,
      fetchDetailNewsReset,
      fetchDeleteNewsReset,
      fetchDeleteNews,
      createNewNews,
      createNewNewsReset,
      editNews,
      editNewsReset,
      fetchDetailNewsReset
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(News)