import React, { Component } from 'react'
import compose from 'recompose/compose'
import Page from 'components/Page'
import Section from 'components/Section'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MaterialTable from 'material-table'
import {
    fetchGetProducts, 
} from 'store/actions/clasificados.actions'
import { Container,Row,Column,Title,PedidoNumber,ColumnMain,Total,ContainerTable,Box,Text,HeaderPrice,HeaderCantidad,HeaderProducts,Name,Price,Subtitle,Quantity} from "./styled"
import mapProduct from '../../../../selectors/mapProduct'



class DetailPedido extends Component {
    state = {
       loading: false,
    }



    render() {
        console.log("props",this.props)
        console.log("length", Object.keys(this.props.detailProduct).length)
        
        return (
            <Page>
                <Section title={'Detalle del Pedido'} onBack={() => this.props.onClose()}>
                    <Container>
                        <ColumnMain>
                            <Column>
                                <Title bold={true}>Resumen del pedido</Title>
                                <PedidoNumber>
                                    <Total>Pedido N°</Total>
                                    <Total>{"#"+this.props.order.orderid}</Total>
                                </PedidoNumber>
                            </Column> 
                            <Row>
                                <ContainerTable>
                                    <HeaderProducts>Producto</HeaderProducts>
                                    <HeaderCantidad>Cantidad</HeaderCantidad>
                                    <HeaderPrice>Precio</HeaderPrice>

                                    <Name>{this.props.order.title}</Name>
                                    <Quantity>{this.props.order.quantity}</Quantity>
                                    <Price>{this.props.order.price}</Price>
                                </ContainerTable>
                            </Row>
                            {/* <Row justify="space-evenly">
                                <Total bold={true}>Subtotal</Total>
                                <Total >$10.000</Total>
                            </Row>
                            <Row justify="space-evenly">
                                <Total bold={true}>Envio</Total>
                                <Total >$300</Total>
                            </Row> */}

                            <Row justify="space-evenly" background="rgba(24, 115, 243, 0.3)">
                                <Total  color="#1873F3" bold={true}>Total del pedido</Total>
                                <Total color="#1873F3" bold={true}>{this.props.order.price}</Total>
                            </Row>

                        </ColumnMain>
                        <ColumnMain>
                            <Title>Método de pago</Title>
                            <Box>
                                <Text>{this.props.order.paymentmethodname}</Text>
                                <Text>{this.props.order.price}</Text>
                            </Box>
                            {/* <Title>Método y dirección de envío</Title>
                            <Box>
                                <Text>
                                    <Text>Método de envío:</Text>
                                    <Text></Text>
                                </Text>
                                <Text>{this.props.order.price}</Text>
                            </Box> */}
                        </ColumnMain>
                    </Container>
                </Section>
            </Page>
        )
    }
}
const mapStateToProps = ({
    clasificados: { detailProduct },
}) => ({
    detailProduct, 
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchGetProducts }, dispatch)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(DetailPedido)
)