import styled from 'styled-components';
import mediaQueries from 'config/media-queries';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 560px;
  padding: 2% 5%;
`
export const Text = styled.span`
  font-size: 18px;
  color: #000;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: ${({ margin }) => margin ? margin : 'inherit'};
`
export const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 20px;
`
export const ButtonWrapper = styled.div`
  margin-top: 2%;
  display: flex;
  justify-content: flex-end;
`
export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  border: 1px solid ${({ theme }) => theme.colors.blueRibbon};
  font-size: 18px;
  font-weight: 500;
  padding: 13px 30px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  &:disabled {
    color: gainsboro;
    border-color: gainsboro;
  }
`
export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3%;
  flex: ${props => props.flex ? props.flex : 1};
  align-items: ${props => props.alignItems ? props.alignItems : 'start'};
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'start'};
  &:last-child {
    margin-right: 0%;
  }
`
export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
  margin-bottom:  ${({ margin }) => margin || '20px'};
  display: ${({ display }) => display || 'block'};
  flex-direction: ${({ flexdirection }) => flexdirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'start'};
  .react-datepicker-wrapper {
    width: 100%
    .react-datepicker__input-container {
      width: 100%;
    }
  }
`
export const InputLabel = styled.label`
  display: ${({ display }) => display || 'block'};
  font-family: ${({ theme }) => theme.fonts.roboto};
  margin: ${({ margin }) => margin || 0};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  padding-bottom: 10px;
  align-items: ${({ alignItems }) => alignItems || 'normal'};
`
export const ErrorLabel = styled.label`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 16px;
  font-weight: 500;
`
export const SucessLabel = styled.label`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: #85ec0d;
  font-size: 16px;
  font-weight: 500;
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  ${mediaQueries.mobile`
    flex-direction: column;
  `}
`
export const Loading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

export const StyledTabs = styled.div``

export const Breakline = styled.div`
  border: solid 0.5px #e9e9e9;
  width: 100%;
  margin: 3% 0;
`
export const AccessEntry = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width:  ${({ width }) => width ? width : "auto"};
  align-self:  ${({ width }) => width ? "center" : null};
  margin: 2%;
`
export const BigButton = styled.div`
  flex: 1;
  height: 100px;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px #e1e1e1;
  border-top: 2.5px solid #1873f3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  &:nth-child(1) {
    margin-right: 20px;
  }
`
export const PngImage = styled.img`
  padding: 0px 15px;
`
