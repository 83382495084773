import styled from 'styled-components'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {
    Grid,
    Button,
    Paper,
    Typography
} from '@material-ui/core'
import img from "assets/images/ticketBorderOK.PNG"
import imgError from "assets/images/ticketBorderError.PNG"

export const ProgressContainer = styled.div`
    flex-grow: 1;
`

export const GridButtonBack = styled(Grid)`
    margin-top: ${({ theme }) => theme.spacing.unit3};
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    max-width: 1000px;
    border-radius: 10px;
    display: flex;
`

export const ButtonBack = styled.button`
    padding: 0px !important;
    height: 35px !important;
    width: 35px !important;
    margin: ${({ theme }) => theme.spacing.unit2  } !important;
    background-color: ${({ theme }) => theme.colors.white } !important;

`
export const Container = styled.div`
    display:flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.blueRibbon };
    width: 50%;
    height: 600px;
    border-radius: 10px;
`
export const ContainerError = styled.div`
    display:flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.pomegranate };
    width: 50%;
    height: 600px;
    border-radius: 10px;
`

export const Row = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-bottom: solid 2px ${({ theme }) => theme.colors.greyBorder };
    width: 100%;
    margin: ${props => (props.margin ? props.margin : '0px')};
`


export const TitleCodigo = styled.label`
    text-align: center;
    font-size: ${props => (props.size ? props.size : '14px')};
    font-weight: ${props => (props.weight ? props.weight : '400')};
    margin:${props => (props.margin ? props.margin : '2% 0%')}; 
    letter-spacing: ${props => (props.spacing ? props.spacing : '')};
`

export const CheckContainer = styled.div`
    width: 70%;
    margin: 3% 15%;    
    align-items: center;
    color: white;
    font-size: 24px;
    text-align: center;
    margin: 6% 15%;
`
export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.blueRibbon };
    background-color: ${({ theme }) => theme.colors.white };
`

export const TyTitle = styled(Typography)`
    width: 90%;
    max-width: 1000px;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 30px;
    font-weight: bold !important;
    margin-top: 20px !important;
    margin-bottom: ${({ theme }) => theme.spacing.unit3 }!important;
`

export const PaperStyled = styled(Paper)`
    border-radius: 10px !important;
    border-style: solid;
    border-width: 0.5px;
    border-color: ${({ theme }) => theme.colors.ghost };
   
    box-shadow: none !important;
    margin-bottom:  ${({ theme }) => theme.spacing.unit2 };
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
`

export const gridItemForm = styled(Grid)`
    flex-direction: column;
    display: flex;
    padding-left: ${({ theme }) => theme.spacing.unit3 };
    padding-right: ${({ theme }) => theme.spacing.unit3 };
    min-height: 500px;

`

export const LabelContainer = styled.label`
    width: 80%;
    color: ${({ theme }) => theme.colors.warmGrey };
    font-size: 20px;
    margin-top: 10px;
    margin-top: ${({ theme }) => theme.spacing.unit2 };
`
export const SelectWrapper = styled.div`
    width: 100%;
    margin: 20px 0px 20px 0px;
`
export const RadioBtnContainer = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.unit2 };
    margin-bottom: ${({ theme }) => theme.spacing.unit2 };
    display:flex;
    flex-direction: row;
    justify-content: flex-start;  

`

export const StyledButton = styled.button`
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.black};
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 65px;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    margin: 6% 0px 0px 20%;
`


export const NewDestinatarioLabel = styled.label`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.blueRibbon};;
    width: 100%;
    margin: 10px 140px 10px 0px;
    text-decoration: underline;
`

export const TitleOperation = styled.label`
    font-size: ${props => (props.size ? props.size : '15px')};
    font-weight: ${props => (props.weight ? props.weight : 'normal')};

`


export const DestinatarioDetailContainer = styled.div`
    background-image: url(${img});   
    background-position: bottom;
    display:flex;
    flex-direction: column;
    width: 60%;
    margin: 0% 17%;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 3% 3%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;    
`

export const DestinatarioDetailContainerError = styled.div`
    background-image: url(${imgError});   
    background-position: bottom;
    display:flex;
    flex-direction: column;
    width: 60%;
    margin: 3% 17%;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 3% 3%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
   
`
export const DestinatarioDetailItemContainer = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: ${props => (props.justify ? props.justify  : 'space-between')};
    width: 100%;
    margin: 2% 0%;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')};
`
export const DestinatarioDetailItem = styled.div`
    font-size: 15px;
    font-weight: ${props => (props.bold ? 'bold' : 'normal')};
    color: ${props => (props.color ? props.color  : 'black')};
`
export const AvatarItem = styled.div`
    flex-grow: 1;
    width: 100%;
    min-height: 60px;
    max-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const AvatarItemError = styled.div`
    flex-grow: 1;
    width: 100%;
    min-height: 60px;
    max-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Label = styled.label`
    padding: 5px;
    font-weight: 500;
    font-size: 20px;
`

