import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross.png'
import CircularProgress from '@material-ui/core/CircularProgress'
import { submitActivity,editActivity,deleteActivity } from 'store/actions/activities.actions'
import Input from 'components/Input'
import DetailTitle from 'components/DetailTitle'
import Select from 'react-select'
import DeleteConfirmation from 'components/DeleteConfirmation'

import {
  FormContainer,
  Row,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  StyledLabel,
  Separator,
  SelectContainer,
  ActionLabel,
  Column
} from './styled'

class NewActivity extends React.Component {
  state={
    selectedSpace: ""
  }
  
  componentDidMount(){
    var formattedNeighbourhoods = this.props.neighbourhoods.map(item => {
      var neighbourhood = {
        value:  item.name,
        label: item.name,
        guid: item.guid
      }
      return neighbourhood
    })
    this.setState({
      neighbourhoods: formattedNeighbourhoods,
    })
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }
  escFunction=(event)=>{
    if(event.keyCode === 27) {
     this.props.onClose(false)
    }
  }

  isEditMode = () => this.props.activityToEdit

  formattedNeighbourhoods = () => {
    var formattedNeighbourhoods = this.props.neighbourhoods.map(item => {
      var neighbourhood = {
        value:  item.name,
        label: item.name,
        guid: item.guid
      }
      return neighbourhood
    })

    var selectedNeighbourhoodsPrevious = [];
    this.props.activityToEdit.neighbourhoodList.forEach(neighbourhood =>{
      formattedNeighbourhoods.find((formatted,i) => 
      formatted.guid === neighbourhood ? (
        selectedNeighbourhoodsPrevious.push(formatted)
      ) : (
        null
      )
      )  
    }) 
    return selectedNeighbourhoodsPrevious
  }

  getInitialValues = () => ({
    description: this.isEditMode() ? (this.props.activityToEdit.description) : ("") ,
    SelectNeighbourhoods: this.isEditMode() ? (this.formattedNeighbourhoods()) : ("") ,
  })

  getSignUpSchema = () =>
    Yup.object().shape({
      description: Yup.string().required('El campo nombre es requerido'),
      SelectNeighbourhoods: Yup.string().required('El campo barrios es requerido')
    })

    
  toggleDeleteConfirmationClose = () => {
    this.setState({ deleteConfirmation: !this.state.deleteConfirmation })
  }

   

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row>
        <InputWrapper>
          <DetailTitle>{'Nueva Actividad'}</DetailTitle>
          <Separator />
          <Input
            name={'description'}
            label={'Nombre'}
            placeholder={'Nombre de la actividad'}
            value={values.description}
            error={errors.description}
            onChange={({ target: value }) => setFieldValue('description', value.value)}
            margin="normal"
            type="text"
            autoFocus 
          />
          {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper>
          <DetailTitle>{'Seleccionar barrios donde estara disponible la actividad'}</DetailTitle>
          <Separator />
          <SelectContainer>
            <Select
              isMulti
              value={values.SelectNeighbourhoods}
              onChange={(value) => setFieldValue("SelectNeighbourhoods", value)}
              options={this.state.neighbourhoods}
              placeholder="Lista de barrios"                  
            />
          </SelectContainer>
          {errors.SelectNeighbourhoods && <ErrorLabel>{errors.SelectNeighbourhoods}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <ButtonWrapper>
        <StyledButton type={'submit'} >
          { this.checkButtonContent() }
        </StyledButton>
      </ButtonWrapper>
      {this.isEditMode() && (
        <ButtonWrapper>
          <ActionLabel
            onClick={this.toggleDeleteConfirmationClose}
            disabled={false}
            error
          >
            {'Eliminar actividad'}
          </ActionLabel>
        </ButtonWrapper> )}
        
    </FormContainer>
  )

  
  handleActivityDelete = async () => {
    await this.props.deleteActivity(this.props.activityToEdit.id)
    if (!this.props.activities.errorDelete) this.props.onClose(true)
  }

  checkData = (data) => {
    if (!this.isEditMode()) {
      this.handleSubmitCreation(data)
    } else {
      this.handleSubmitEdit(data)
    }
  }
  
  checkButtonContent = () =>
    this.props.activities.isSubmitting ? (
      <CircularProgress size={18} />
    ) : this.isEditMode() ? (
      'Actualizar'
    ) : (
      'Crear'
    )

  handleSubmitCreation = async data => {
    const NeighbourhoodList = data.SelectNeighbourhoods.map(neighbourhoods => neighbourhoods.guid)
    const formattedData = {
      description: data.description,
      neighbourhoodList: NeighbourhoodList,
    }
    await this.props.submitActivity(formattedData)
    if (!this.props.activities.error) this.props.onClose(true)
  }

  handleSubmitEdit = async data => {
    const NeighbourhoodList = data.SelectNeighbourhoods.map(neighbourhoods => neighbourhoods.guid)
    const formattedData = {
      description: data.description,
      neighbourhoodList: NeighbourhoodList,
      id: this.props.activityToEdit.id
    }
    await this.props.editActivity(formattedData)
    if (!this.props.activities.error) this.props.onClose(true)
  }

  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.checkData}
          render={e => this.getFormContent(e)}
        />
         {this.state.deleteConfirmation && (
          <DeleteConfirmation
            title={'¿Eliminar actividad?'}
            onBtnClose={this.toggleDeleteConfirmationClose}
            btnContent={'Eliminar'}
            onBtnClick={this.handleActivityDelete}
            onError={this.props.activities.errorDelete}
          />
        )}
      </Modal>
    )
  }
}

const mapStateToProps = ({ activities }) => ({
  activities,
  userInfo: { neighbourhoods }}) => ({
    activities,
    neighbourhoods
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitActivity,editActivity,deleteActivity
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewActivity)
