import React from 'react'
import { DataContainer, DataWrapper, Row, Column,Title, DataLabel, Separator,Pencil } from './styled'

const DestinatarioDetail = ({ guest }) => {
  return guest ? (
    <DataContainer>
      <DataWrapper>
        <Row justify={'space-between'}>
          <Title>{'Detalle del destinatario'}</Title><Pencil/>
        </Row>
        <Row align={'flex-start'} >
          <Column>
            <Row justify={'space-between !important'} width={'75%'} margin={'0px 0px 0px 75px'} borderStyle={'solid'} borderWidth={'thin'}  borderColor={'rgba(205, 205, 211, 0.45)'}>
              <DataLabel bold={'700'} color>{'Nombre y Apellido:'}</DataLabel>
              <DataLabel>{'Ignacio Baratelli'}</DataLabel>
            </Row>
            <Row justify={'space-between !important'} width={'75%'} margin={'0px 0px 0px 75px'} borderStyle={'solid'} borderWidth={'thin'}  borderColor={'rgba(205, 205, 211, 0.45)'}>
              <DataLabel bold={'700'} color>{'Email:'}</DataLabel>
              <DataLabel>{'IBaratelli@miiii.app'}</DataLabel>
            </Row>
            <Row justify={'space-between !important'} width={'75%'} margin={'0px 0px 0px 75px'} borderStyle={'solid'} borderWidth={'thin'}  borderColor={'rgba(205, 205, 211, 0.45)'}>
              <DataLabel bold={'700'} color>{'Tipo de destinatario:'}</DataLabel>
              <DataLabel>{'Proveedor'}</DataLabel>
            </Row>
            <Row justify={'space-between !important'} width={'75%'} margin={'0px 0px 0px 75px'} borderStyle={'solid'} borderWidth={'thin'}  borderColor={'rgba(205, 205, 211, 0.45)'}>
              <DataLabel bold={'700'} color>{'Rubro:'}</DataLabel>
              <DataLabel>{'Carpintero'}</DataLabel>
            </Row>
            <Row justify={'space-between !important'} width={'75%'} margin={'0px 0px 0px 75px'} borderStyle={'solid'} borderWidth={'thin'}  borderColor={'rgba(205, 205, 211, 0.45)'}>
              <DataLabel bold={'700'} color>{'Razon social:'}</DataLabel>
              <DataLabel>{'JardinerosUnidos'}</DataLabel>
            </Row>
            <Row justify={'space-between !important'} width={'75%'} margin={'0px 0px 0px 75px'} borderStyle={'solid'} borderWidth={'thin'}  borderColor={'rgba(205, 205, 211, 0.45)'}>
              <DataLabel bold={'700'} color>{'Rubro:'}</DataLabel>
              <DataLabel>{'Carpintero'}</DataLabel>
            </Row>
            <Row justify={'space-between !important'} width={'75%'} margin={'0px 0px 0px 75px'} borderStyle={'solid'} borderWidth={'thin'}  borderColor={'rgba(205, 205, 211, 0.45)'}>
              <DataLabel bold={'700'} color>{'CBU:'}</DataLabel>
              <DataLabel>{'1478523697412589637412'}</DataLabel>
            </Row>
            <Row justify={'space-between !important'} width={'75%'} margin={'0px 0px 0px 75px'} borderStyle={'solid'} borderWidth={'thin'}  borderColor={'rgba(205, 205, 211, 0.45)'}>
              <DataLabel bold={'700'} color>{'CUIL:'}</DataLabel>
              <DataLabel>{'20408300828'}</DataLabel>
            </Row>
            
          </Column>
          
        </Row>
        <Separator />
      </DataWrapper>
    </DataContainer>
  ) : (
    <DataContainer>
      <DataWrapper>
        <label>{'Seleccione un destinatario'}</label>
      </DataWrapper>
    </DataContainer>
  )
}

export default DestinatarioDetail
