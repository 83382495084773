import { createSelector } from 'reselect'

const mapProprietaryAccess = createSelector(
  proprietaryAcessList => proprietaryAcessList,
  proprietaryAcessList =>
    proprietaryAcessList.map(
      ({ name, surname, dni, phone_number, property_code, properties_guid }, i) => ({
        value: dni,
        label: `${name} ${surname}`,
        dni,
        phone_number,
        property_code,
        properties_guid,
      })
    )
)

export default mapProprietaryAccess
