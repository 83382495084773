import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    min-height: 60px;
    max-height: 100px;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
`

export const AvatarItem = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.colors.white};
  max-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Label = styled.div`
  padding: 5px;
  font-weight: 600;
`
export const GroupContainer = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`
  
export const CheckedElement = styled.img`
  position: absolute;
  z-index: 99;
  top: 0px;
  right: 15%;
  border: 2px solid ${({ theme }) => theme.colors.white};
  width: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`

