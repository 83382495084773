import { createSelector } from 'reselect'
import { getFormattedDate } from 'utils/dateParser'

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'ARS',
});

const mapDespachados = createSelector(
  despachados => despachados,
  despachados => 
  despachados.map(
      ({ buyerfullname, id,title,price,creationdate,shopitemimagesurls,quantity,currency,orderstatename,orderid,paymentmethodname }, i) => ({
        value: id,
        title: title,
        currency: currency,
        price: formatter.format(price),
        quantity: quantity,
        orderstatename: orderstatename,
        orderid: orderid,
        creationdate: getFormattedDate(creationdate),
        images: shopitemimagesurls,
        buyerfullname: buyerfullname,
        paymentmethodname: paymentmethodname
      })
    )
)

export default mapDespachados
