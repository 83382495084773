import styled from 'styled-components'

export const SearchWrapper = styled.div`
display: flex;
flex-direction: column;
border: 1px solid ${({ theme }) => theme.colors.alto};
margin-right: 1px;
border-radius: 10px;
`

export const ListWrapper = styled.div`
  width: 35%;
  min-height: 560px;
  background-color: ${({ theme }) => theme.colors.ghost};
  padding-right: 13px;
  max-height: 600px;
  overflow-y: scroll;
  border-bottom-left-radius: 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.ghost};
`

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  align-items: normal;

  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 560px;
`

export const ContentWrapper = styled.div`
  display: flex;
`
