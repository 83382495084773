import React, { Component } from 'react'
import Modal from 'components/Modal'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import CrossIcon from 'assets/icons/cross-blue.png'
import Input from 'components/Input'
import FileInput from 'components/FileInput'
import CircularProgress from '@material-ui/core/CircularProgress'
import Loader from 'components/PageLoader'

import userMaleImg from 'assets/images/user_male.png'
import userFemaleImg from 'assets/images/user_female.png'

import {
  FormContainer,
  TitleLabel,
  SelectWrapper,
  ButtonWrapper,
  StyledButton,
  UserImg,
  CrossImage,
  Row,
  RowLeft,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ErrorMessage,
  InputWrapperMargin
} from './styled'

import { connect } from 'react-redux'
import mapRoles from "selectors/mapRoles"
import mapPropietaries from "selectors/mapPropietaries"
import { fetchRoles, fetchgetMenus, fetchgetPersonByNeighbourhood, fetchRefreshMenusForRole } from "store/actions/profile.actions"
import { fetchEditUser, fetchEditUserFail, fetchEditUserSuccess } from "store/actions/users.actions"

const genders = [{ label: "hombre", value: "0" }, { label: "mujer", value: "1" }, { label: "otro", value: "3" }]

class ProprietaryEdit extends Component {
  state = {
    customRoles: [],
  }

  componentDidMount() {
    this.props.fetchRoles()
    this.props.fetchgetMenus()
    this.props.fetchgetPersonByNeighbourhood()
  }

  componentWillReceiveProps() {
    this.props.profile.menus.map(menu => {
      if (menu.code.indexOf("_BO") !== -1) {
        this.setState(prevState => ({
          allMenus: {
            ...prevState.allMenus,
            [menu.code]: 0
          }
        }))
      }
    })
  }

  handleSelectRoles = value => {
    this.setState({ selectedRoles: value })
    if (value.length !== 0) {
      value[0].menus.map(menu => {
        if (menu.code.indexOf("_BO") !== -1) {
          this.setState(prevState => ({
            customRoles: {
              ...prevState.customRoles,
              [menu.id]: 1
            }
          }))
        }
      })
    } else {
      this.props.profile.menus.map(menu => {
        if (menu.code.indexOf("_BO") !== -1) {
          this.setState(prevState => ({
            customRoles: {
              ...prevState.customRoles,
              [menu.id]: 0
            }
          }))
        }
      })
    }
  }

  handleSelectRoles = (setFieldValue, option) => {
    const value = [option]
    setFieldValue('rol', value)
    this.setState({ selectedRoles: value })
    if (value.length !== 0) {
      value[0].menus.map(menu => {
        if (menu.code.indexOf("_BO") !== -1) {
          this.setState(prevState => ({
            customRoles: {
              ...prevState.customRoles,
              [menu.id]: 1
            }
          }))
        }
      })
    } else {
      this.props.profile.menus.map(menu => {
        if (menu.code.indexOf("_BO") !== -1) {
          this.setState(prevState => ({
            customRoles: {
              ...prevState.customRoles,
              [menu.id]: 0
            }
          }))
        }
      })
    }
  }

  getNeighbourhoodOptions = () => {
    return this.props.neighbourhoods.map(n => ({ label: n.name, value: n.guid }))
  }

  handleSubmit = async data => {

    const neighbourhood = data.neighbourhood.map(n => n.value)
    const gender = Object.values(data.gender)
    const rolformatted = data.rol.map(n => n.value)
    var base64result = null;
    var UserData;

    if (data.profile_picture == null) {

      UserData = {
        personid: data.id,
        name: data.name,
        family_name: data.surname,
        gender: data.gender.value,
        dni: data.dni,
        username: data.username,
        password: data.password,
        email: data.email,
        phone_number: `+${data.phonenumber}`,
        roles: rolformatted,
        neighbourhoods: neighbourhood
      }

    } else {
      base64result = data.user_picture.base64Value;
      UserData = {
        name: data.name,
        family_name: data.surname,
        gender: data.gender.value,
        dni: data.dni,
        username: data.username,
        password: data.password,
        email: data.email,
        phone_number: `+${data.phonenumber}`,
        roles: rolformatted,
        neighbourhoods: neighbourhood,
        profile_picture: base64result
      }
    }

    this.props.fetchEditUser(UserData)
    this.props.onClose()

  }


  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <TitleLabel> Edición {this.props.userToEdit.title ? " de " + this.props.userToEdit.title : ""}
        </TitleLabel>
        <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper width={48}>
          <InputLabel>{'Nombre'}</InputLabel>
          <Input
            value={values.name}
            onChange={({ target: { value } }) => setFieldValue('name', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.name ? true : false}
          />
          {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Apellido'}</InputLabel>
          <Input
            value={values.surname}
            onChange={({ target: { value } }) => setFieldValue('surname', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.surname ? true : false}
          />
          {errors.surname && <ErrorLabel>{errors.surname}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper width={48}>
          <InputLabel>{'DNI'}</InputLabel>
          <Input
            value={values.dni}
            onChange={({ target: { value } }) => setFieldValue('dni', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.dni ? true : false}
          />
          {errors.dni && <ErrorLabel>{errors.dni}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Genero de su DNI'}</InputLabel>
          <Select
            value={values.gender}
            onChange={value => setFieldValue('gender', value)}
            options={genders}
            placeholder="Seleccionar genero"
            defaultValue={values.gender}
            error={errors.gender ? true : false}
          />
          {errors.gender && <ErrorLabel>{errors.gender}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper width={48}>
          <InputLabel>{'Permiso para ver barrios'}</InputLabel>
          <Select
            value={values.neighbourhood}
            onChange={value => setFieldValue('neighbourhood', value)}
            options={this.getNeighbourhoodOptions()}
            placeholder="Buscar..."
            error={errors.neighbourhood ? true : false}
            isMulti
          />
          {errors.neighbourhood && <ErrorLabel>{errors.neighbourhood}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Teléfono'}</InputLabel>
          <Input
            value={values.phonenumber}
            onChange={({ target: { value } }) => setFieldValue('phonenumber', value)}
            placeholder={'54 911 ********'}
            type={'text'}
            rows={1}
            error={errors.phonenumber ? true : false}
          />
          {errors.phonenumber && <ErrorLabel>{errors.phonenumber}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={48}>
          <InputLabel>{'Email'}</InputLabel>
          <Input
            value={values.email}
            onChange={({ target: { value } }) => setFieldValue('email', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.email ? true : false}
          />
          {errors.email && <ErrorLabel>{errors.email}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Tipo de rol'}</InputLabel>
          <Select
            value={values.rol}
            onChange={option => this.handleSelectRoles(setFieldValue, option)}
            options={this.props.roles}
            isLoading={this.props.profile.loadingRoles}
            placeholder="Buscar..."
            error={errors.rol ? true : false}
          />
          {errors.rol && <ErrorLabel>{errors.rol}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={48}>
          <InputLabel>{'Nombre de usuario'}</InputLabel>
          <Input
            value={values.username}
            onChange={({ target: { value } }) => setFieldValue('username', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.username ? true : false}
          />
          <Input
            value={values.id}
            onChange={({ target: { value } }) => setFieldValue('id', value)}
            type={'hidden'}
            rows={1}
          />
          {errors.username && <ErrorLabel>{errors.username}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Contraseña'}</InputLabel>
          <Input
            value={values.password}
            onChange={({ target: { value } }) => setFieldValue('password', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.password ? true : false}
          />
          {errors.password && <ErrorLabel>{errors.password}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={33}>
          <InputLabel>{'Foto del usuario'}</InputLabel>
          <UserImg src={values.picture_url} />
          <FileInput onChange={file => setFieldValue('user_picture', file)} />
          {errors.user_picture &&
            <ErrorLabel>{errors.user_picture}</ErrorLabel>
          }
        </InputWrapper>
      </Row>
      <Row>
        <ErrorLabel>{this.props.users.fetchEditUserFail}</ErrorLabel>
      </Row>
      <ButtonWrapper>
        {this.props.errorNewProprietary ? <ErrorMessage>Hubo un Problema</ErrorMessage> : ''}
        <StyledButton type="Submit">
          {this.props.loadingNewProprietary ? <CircularProgress size={18} /> : 'Guardar'}
        </StyledButton>
      </ButtonWrapper>
    </FormContainer>
  )

  getEditionSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, 'El nombre debe tener un mínimo de 3(tres) caracteres')
        .required()
      ,
      surname: Yup.string()
        .min(2, 'El apellido debe tener un mínimo de 2(dos) caracteres')
        .required(),
      dni: Yup.string()
        .matches(/^\+?[1-9]\d{6,14}$/, 'DNI invalido')
        .min(8, 'El DNI debe tener un mínimo de 8(ocho) caracteres')
        .max(9, 'El DNI debe tener un máximo de 9(nueve) caracteres')
        .required(),
      gender: Yup.string()
        .required(),
      email: Yup.string()
        .email('El Email es inválido')
        .required(),
      phonenumber: Yup.string()
        .matches(/^\+?[1-9]\d{6,14}$/, 'Teléfono invalido')
        .length(13, 'El teléfono debe tener 13(trece) caracteres')
        .required(),
      username: Yup.string()
        .required(),
      neighbourhood: Yup.string().required(),
      rol: Yup.string()
        .required()
    })

  getInitialValues = () => ({
    rol: this.props.userToEdit.personroles.map(n => ({ label: n.name, value: n.roleid })),
    name: this.props.userToEdit.name,
    surname: this.props.userToEdit.surname,
    dni: this.props.userToEdit.dni,
    gender: this.props.userToEdit.gender,
    email: this.props.userToEdit.email,
    phonenumber: this.props.userToEdit.phonenumber,
    username: this.props.userToEdit.username,
    neighbourhood: this.props.userToEdit.neighbourhoods.map(n => ({ label: n.name, value: n.neighbourhoodguid })),
  })

  render() {
    if (this.props.users.fetchEditUserSuccess === true) {
      return (<><Loader customHeight={'50px'} /></>)
    } else {
      return (
        <Modal>
          <Formik
            initialValues={this.getInitialValues()}
            validateOnChange={true}
            validationSchema={this.getEditionSchema()}
            onSubmit={this.handleSubmit}
            render={e => this.getFormContent(e)}
          />
        </Modal>
      )
    }
  }




}

const mapStateToProps = ({
  profile,
  users,
  userInfo: { neighbourhoods },
}) => ({
  profile,
  roles: mapRoles(profile.roles),
  propietaries: mapPropietaries(profile.persons),
  users,
  neighbourhoods
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchRoles, fetchgetMenus, fetchgetPersonByNeighbourhood, fetchRefreshMenusForRole, fetchEditUser,
    fetchEditUserFail,
    fetchEditUserSuccess
  }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProprietaryEdit)
