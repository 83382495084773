import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross-blue.png'
import FileInput from 'components/FileInput'
import PageLoader from 'components/PageLoader'

import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
} from './styled'

class UploadExpensas extends React.Component {
  state={
    errorFile: false,
    loadingFile: false
  }

  componentWillUnmount(){
    this.setState({loadingFile: false,errorFile: false})
  }

  getInitialValues = () => ({
  })

  getSignUpSchema = () =>
    Yup.object().shape({
        file: Yup.string().required('El campo nombre es requerido')
    })

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row> <InputLabel>{'Subir Expensas'}</InputLabel> </Row>
      <Row>
        <InputWrapper width={30}>
            <FileInput onChange={file => setFieldValue('file', file)} pdf={true}/>
            {errors.headerImage && <ErrorLabel>{errors.headerImage}</ErrorLabel>}
        </InputWrapper>
      </Row>
      {this.props.error || this.state.errorFile ? (
        <ButtonWrapper>
          <ErrorLabel>Error al cargar el archivo,verifique si es el correcto</ErrorLabel>
        </ButtonWrapper>
      ): (<> </>)}
      {this.props.loading   ? 
        (
          <ButtonWrapper>
            <PageLoader customHeight={"50"}/>
          </ButtonWrapper>
        ) : 
        (
          <ButtonWrapper>
            <StyledButton type={'submit'}>SUBIR</StyledButton>
          </ButtonWrapper>
        )}
    </FormContainer>
  )

  handleSubmit = async data => {
    this.setState({loadingFile: true})
    try{
      let fileinBase64 = btoa(data.file)
      await this.props.onChange(fileinBase64)
      if (!this.props.error) this.props.onOpenResult(true)
      if (!this.props.error) this.props.onClose(true)
      if (!this.props.error) this.props.openPeriod(0)
    }catch(e){
      this.setState({errorFile: true})
    }
  }

  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

export default UploadExpensas