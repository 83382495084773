import styled from 'styled-components'

export const FileInputWrapper = styled.div`
  width:  ${(props) => props.width ? props.width : '80px'};
  padding:  ${(props) => props.padding ? props.padding : ''};
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  grid-area: ${(props) => props.gridArea ? props.gridArea : ''};
`

export const StyledFileInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 200px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
`

export const ImageAttachText = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
`

// export const AttachedContainer = styled.div`
//   width:100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   border-radius: 10px;
//   background-color: ${({ theme }) => theme.colors.transparent};
//   pointer-events: none;
// `
export const AttachedContainer = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.transparent};
`
export const CheckedElement = styled.img`
  position: absolute;
  top: -12px;
  right: -12px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  width: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  pointer-events: none;
  z-index: 99;
`

export const DocumentImg = styled.img`
  max-width: 100%;
  max-height:200px;
  border-radius: 8px;
  pointer-events: none;
  z-index: 1;
`
export const RemoveElement = styled.img`
  position: absolute;
  bottom: -12px;
  right: -12px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  width: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  z-index: 10001;
`