import React,{ useRef, useState } from 'react'
import { Avatar } from '@material-ui/core'
import { CardContainer, CardTitle,  TitleCard, CardMoney,SubtitleCard,LabelCVU,StyledLabel,ContainerLabel,ContainerErrorLabel} from './styled'
import copyClipboard from "assets/icons/copyClipboard.png"
import doubleCheck from "assets/icons/doubleCheck.png"

const Card = ({ amount,cvu,neighbourhoodSelected,error }) => {
    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);

    function copyToClipboard(e) {
        var textField = document.createElement('textarea')
        textField.innerText = cvu
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        setCopySuccess(true);
      };
      
    return (
        <CardContainer>
            <CardTitle>
                <Avatar
                    style={{
                        width: '20px',
                        height: '20px',
                        padding: '10px',
                        background: 'white'
                        }}
                    >
                    <h3 style={{color: '#1873F3',fontWeight: 'bold'}}>{ neighbourhoodSelected.value.charAt(0).toUpperCase()+"/"}</h3>
                </Avatar>
                <label style={{color: 'white',margin: '4%'}}>{neighbourhoodSelected.value}</label>
            </CardTitle>
            <CardMoney>
                {cvu ? (
                    <>
                        <TitleCard> {amount} </TitleCard>
                        <SubtitleCard >Saldo disponible</SubtitleCard>
                        <ContainerLabel>
                            <StyledLabel bold={true} >CVU: </StyledLabel>
                            <LabelCVU ref={textAreaRef}  >
                                {cvu}                       
                            </LabelCVU>
                            {copySuccess ? (
                                <img src={doubleCheck} onClick={copyToClipboard} style={{cursor: "pointer",width: "20px", marginLeft: "2%"}}/>
                            ) : (
                                <img src={copyClipboard} onClick={copyToClipboard} style={{cursor: "pointer",width: "20px", marginLeft: "2%"}}/>
                            )}
                        </ContainerLabel>
                    </>
                ): (
                    <>
                        <ContainerErrorLabel>
                            <StyledLabel bold={true} >Error: </StyledLabel>
                            <LabelCVU ref={textAreaRef}>Este barrio no tiene CVU</LabelCVU>
                        </ContainerErrorLabel>
                    </>
                )}
            </CardMoney>
        </CardContainer>
    )
}

export default Card
