import styled from 'styled-components'

export const Container = styled.div`
    padding: 1%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    transition: all 0.2s ease;
    box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
    border-radius: 10px;
    background-color: ${({ blue,theme }) => blue ? theme.colors.blueRibbon : theme.colors.white};
`

export const Header = styled.div`
    width: 100%;
    height: 150px;
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-weight: 500;
    font-size: 20px;
    border-radius: 10px;
`

export const Body = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-weight: 500;
    font-size: 20px;
`

export const Title = styled.div`
    font-size: 22px;
    font-weight: 500;
    color: ${({ blue,theme }) => blue ? theme.colors.white : theme.colors.blueRibbon};
    align-self: center;
    margin-top: 30px;
`
export const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: ${({ blue,theme }) => blue ? theme.colors.white : theme.colors.blueRibbon};
    align-self: center;
`
export const RowItem = styled.div`
    width: 100%;
    padding: 3% 0%;
    color: black;
    font-weight: 400;
    display: flex;
    justify-content: ${({ justify }) => justify};
`

export const StyledButton = styled.button`
    background-color: ${({ theme,invert }) => invert ? theme.colors.white  : theme.colors.blueRibbon};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme,invert }) => invert ? theme.colors.blueRibbon  : theme.colors.white};
    font-size: 15px;
    font-weight: 500;
    width: 45%;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    margin: 2%;
    padding: 2%;
    border: none;

    &:hover{
        border: ${({ theme }) => "solid 0.5px "+theme.colors.blueRibbon };
        transition: all 0.3s ease;
    }
`
export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
`

export const CheckIcon = styled.img`
    width: 80px;
    color: white;
`

export const CloseIcon = styled.img`
    width: 20px;
    color: white;
    align-self: flex-end;
    cursor: pointer;
`