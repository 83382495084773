import React, { Component } from 'react'
import { InputStyled , Container,DestinatarioDetailItem,LabelChecked, LabelContainer, SelectWrapper ,StyledButton, DestinatarioDetailContainer, DestinatarioDetailItemContainer,IconBack,TitleContainer,ErrorLabel} from './styled';
import Select from 'react-select'
import ImagesGroup from '../../ImagesGroup/index'
import CheckBox from 'components/CheckBox'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Conceptos from "constants/conceptosTransferencia"
import {plusBusinessDays} from 'utils/dateParser'
  

const options = [
    { value: '0', label: 'Inmediata' },
    { value: '7', label: '7 dias habiles' },
    { value: '10', label: '10 dias habiles' },
    { value: '20', label: '20 dias habiles' }
  ];

export default class SecondStep extends Component {
    state = {
        transferTotal: false,
        amount: '0'
    }

    
  handleFormSubmit = formData => {
    let diasHabiles = plusBusinessDays(formData.date.value)
    let formDataParsed = formData
    formDataParsed.date = diasHabiles
    this.props.updateTransaction(formDataParsed)
    this.props.NextStep()
  };

  handleOptionChange = changeEvent => {
    this.setState({
      selectedOption: changeEvent.target.value
    });
  };


   
getInitialValues = () => ({
  amount:'',
  concepto: "PRE"
})

getSignUpSchema = () =>
  Yup.object().shape({
    amount: Yup.string().required('El campo es requerido'),
    date: Yup.string().required('El campo es requerido'),
    concepto: Yup.string().required('El campo es requerido'),
  })

  handleCheckBoxChange = setFieldValue => {  
    this.setState({
      transferTotal: !this.state.transferTotal
    });
    setFieldValue('amount', this.props.totalBalance )
  };


getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
  <Container onSubmit={handleSubmit}>
    <TitleContainer onClick={()=> this.props.PreviousStep()}><IconBack/>{'Paso anterior'}</TitleContainer>
    <LabelContainer>Datos del destinatario</LabelContainer>
    <DestinatarioDetailContainer>
      <DestinatarioDetailItemContainer>
          <DestinatarioDetailItem>Nombre </DestinatarioDetailItem>
          <DestinatarioDetailItem bold={true} >   {this.props.transactionProgress.DestinationBankAccount.HolderName}
          </DestinatarioDetailItem>
      </DestinatarioDetailItemContainer>
      <DestinatarioDetailItemContainer>
          <DestinatarioDetailItem> Alias</DestinatarioDetailItem>
          <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.DestinationBankAccount.Alias}</DestinatarioDetailItem>
      </DestinatarioDetailItemContainer>
      <DestinatarioDetailItemContainer>
          <DestinatarioDetailItem> Banco</DestinatarioDetailItem>
          <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.DestinationBankAccount.BankName}</DestinatarioDetailItem>
      </DestinatarioDetailItemContainer>
      <DestinatarioDetailItemContainer>
          <DestinatarioDetailItem> CBU/CVU </DestinatarioDetailItem>
          <DestinatarioDetailItem bold={true} >{this.props.transactionProgress.DestinationBankAccount.Cvbu}</DestinatarioDetailItem>
      </DestinatarioDetailItemContainer>
    </DestinatarioDetailContainer>
    
    <LabelContainer>Concepto</LabelContainer>
    <ImagesGroup margin={{marginTop: 10, marginBottom: 10 }} cuentas={Conceptos} onChange={(e)  => setFieldValue('concepto', e.codigo)} />
        {errors.concepto && <ErrorLabel>{errors.concepto}</ErrorLabel>}
    <LabelContainer>Importe a Transferir</LabelContainer>
    <InputStyled
      prefix="$"
      value={values.amount} 
      onChangeEvent={e => setFieldValue('amount', e.target.value.slice(1,15))} 
      thousandSeparator={','} 
      decimalSeparator={'.'}
    />
    {errors.amount && <ErrorLabel>{errors.amount}</ErrorLabel>}
    <LabelChecked checked={this.state.transferTotal}>
      <CheckBox
        type="radio"
        name="rdBtn"
        value="externo"
        checked={this.state.transferTotal}
        onChange={()=> this.handleCheckBoxChange(setFieldValue)}
      />
      Transferir total de ARS {this.props.totalBalance}
    </LabelChecked>
    <LabelContainer>Plazo de acreditacion</LabelContainer>
    <SelectWrapper> 
        <Select
          value={values.date}
          onChange={e => setFieldValue('date', e )}
          options={options}
          placeholder="Selecciona un plazo"
        /> 
    </SelectWrapper>
    {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
    
    <StyledButton type="submit" >Transferir</StyledButton>
  </Container>
)

    render() {
        return (
          <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleFormSubmit}
          render={e => this.getFormContent(e)}
        />
        )
    }
}
  