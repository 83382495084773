import React from 'react'
import { ContentWrapper,WrapperItems,RowItem,Description, Label, Container,CloseIcon, Row, TableContainer,Circle,ColumnItem,LoaderWrapper,ErrorLabel } from './styled'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cross from 'assets/icons/cross.png'
import Modal from 'components/Modal'
import PageLoader from 'components/PageLoader';
import {getExtendedDate} from 'utils/dateParser'

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ARS',
  });
  
class ModalDetailTransfer extends React.Component {
    state = {
        transferSelected: [],
        error: '',
        newResultModal: false,
    }

    isValidate = (value) => value == null ? 'No obtenido' : value

    render() {
        var billetera = this.props.billetera
        return (
            <Modal>
                <Container>
                <CloseIcon src={cross} onClick={this.props.onClose}/>
                {billetera.TransactionData !== false && billetera.loadingTransactionData === false ? (
                    <WrapperItems> 
                        <RowItem title={true}>
                            <Label bold={true}> Resultado : </Label> 
                        </RowItem>
                        <RowItem>
                            <Description> Destinatario: </Description> 
                            <Description bold={true}>{this.isValidate(billetera.TransactionData.destination_account_client)} </Description> 
                        </RowItem>
                        <RowItem>
                            <Description> Monto: </Description> 
                            <Description bold={true}>{formatter.format(this.isValidate(billetera.TransactionData.amount_net))} </Description> 
                        </RowItem>
                        <RowItem>
                            <Description> Cuenta de origen: </Description> 
                            <Description bold={true}>
                                {this.isValidate(billetera.TransactionData.source_account_client)} 
                            </Description> 
                        </RowItem>
                        <RowItem>
                            <Description>Pagado a traves de: </Description> 
                            <Description bold={true}>
                                { billetera.TransactionData.payments.length > 0 ? billetera.TransactionData.payments[0].transaction_payment_method : "No obtenido"} 
                            </Description> 
                        </RowItem>
                        <RowItem>
                            <Description>Fecha y hora: </Description> 
                            <Description bold={true}>
                                {getExtendedDate(this.isValidate(billetera.TransactionData.date))} 
                            </Description> 
                        </RowItem>
                        <RowItem>
                            <Description>Cod operacion: </Description> 
                            <Description bold={true}>
                                {this.isValidate(billetera.TransactionData.id)} 
                            </Description> 
                        </RowItem>
                    </WrapperItems>
                ) : (
                        <LoaderWrapper >
                            <PageLoader customHeight={'400'} />
                        </LoaderWrapper>
                ) }
                </Container>
            </Modal>
        )
      }
}

const mapStateToProps  = state => {
    return {
        billetera: state.billetera
    }
  }
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
      },
      dispatch
    )
  
  export default connect(
      mapStateToProps,
      mapDispatchToProps
  )(ModalDetailTransfer)

