import styled from 'styled-components'
import mediaQueries from 'config/media-queries'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  ${mediaQueries.tablet`
    padding-top: 10px;
  `}
`

export const SectionTitle = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 28px;
  font-weight: 700;
  margin: 15px 0;
`

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
`

export const LinkText = styled.a`
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.blueRibbon};
  text-decoration: underline;
  cursor: pointer;
  flex-grow: 0;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 5;
`

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 15px;
  font-weight: 500;
  padding: 0.8% 0.5% 0.8% 0.5%;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  text-align: center;
  flex-grow: 1;
  line-height: 25px;
  max-width: 22%;
  margin-left: 10px;
`

export const BackButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:100px;
  margin-right: 25px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 0px 3px 8px -2px rgba(0,0,0,0.6);
  -moz-box-shadow: 0px 3px 8px -2px  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0px 3px 8px -2px rgba(0,0,0,0.6);
  -o-box-shadow: 0px 3px 8px -2px  rgba(0,0,0,0.6);

  ${mediaQueries.tablet`
        margin: 20px 0;
  `}
`

export const StyledImg = styled.img`
  width: 20px;
`
