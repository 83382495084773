import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross-blue.png'
import Input from 'components/Input'
import DatePickerStyled from 'components/DatePickerStyled'
import { registerLocale } from 'react-datepicker';
import { addMonths, subMonths } from 'date-fns'
import { getAlternativeFormattedDate } from 'utils/dateParser'
import { fetchGenerateExpensas } from 'store/actions/expensas.actions'
import PageLoader from 'components/PageLoader';
import Select from 'react-select';
import { fetchLots, fetchNotifications } from 'store/actions/acceso.actions';
import { RadioGroup, FormControlLabel, StyledRadio } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import mapLots from 'selectors/mapLots';
import mapNeighbourhoods from 'selectors/mapNeighbourhoods';
import es from 'date-fns/locale/es';

import {
  FormContainer,
  Row, FormSection, Text,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  LabelFecha,
  ExpensasList,
  ListItem, SelectContainer, InputWrapper2Col
} from './styled'

const motivosDummyData = [
  { label: "Hora limite de salida", value: "0" },
  { label: "Evacuacion", value: "1" },
  { label: "lalala lalala", value: "3" }
]

const mensajesDummyData = [
  { label: "Se cierran todos los accesos, unica salida la principal.", value: "0" },
  { label: "Evacuacion", value: "1" },
  { label: "lalala lalala", value: "3" }
]

registerLocale('es', es)

class nuevaNotificacion extends React.Component {

  state = {
    user: JSON.parse(localStorage.getItem('user')),
    filter: '',
    newExpensa: false,
    isDetailItemOpen: false,
    radioBtnCanal: 'email'
  }
  componentDidMount() {
    this.props.fetchLots();
  }

  getInitialValues = () => ({
    currentDate: '',
  })

  handleSubmit = async data => {

    const notificationData = {
      message: data.message,
      channel: data.chanel,
      destination: data.destination,
      subject: data.subject,
      neighbourhood: data.neighbourhoods
    }
    
    await this.props.fetchNotifications(notificationData)
  }

  handleRadioChange = (setFieldValue, values) => {
    setFieldValue('channel', values.currentTarget.value);
    this.setState({
      radioBtnCanal: values.currentTarget.value
    })
  }

  handleChange = (setFieldValue, values) => {
    setFieldValue('message', values.message);
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row>
        <InputLabel>
          {'Nueva Notificación'}
        </InputLabel>
      </Row>
      <Row >
          <InputWrapper>
            <InputLabel>{'Para'}</InputLabel>
            <SelectContainer>
              <Select
                isMulti={true}
                name={'destination'}
                autosize={false}
                value={values.destination}
                onChange={values => setFieldValue('destination', values)}
                placeholder="Sel. Lotes"
                options={!this.props.loading ? this.props.mappedLots : [{ value: null, label: "Loading..." }]}
              />
            </SelectContainer>
            {errors.destination && <ErrorLabel>{errors.destination}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>{'Motivo'}</InputLabel>
            <SelectContainer>
              <Select
                name={'subject'}
                value={values.subject}
                onChange={values => setFieldValue('subject', values)}
                options={motivosDummyData}
              ></Select>
            </SelectContainer>
            {errors.subject && <ErrorLabel>{errors.subject}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>{'Barrio'}</InputLabel>
            <SelectContainer>
              <Select
                name={'neighbourhoods'}
                value={values.neighbourhoods}
                options={!this.props.loading ? this.props.mappedBarrios : [{ value: null, label: "Loading..." }]}
                placeholder="Sel. Barrio"
                onChange={values => setFieldValue('neighbourhoods', values)}
              />
            </SelectContainer>
            {errors.neighbourhoods && <ErrorLabel>{errors.neighbourhoods}</ErrorLabel>}
          </InputWrapper>
      </Row>
      <Row>
        <InputWrapper2Col>
          <InputLabel>{'Mensaje'}</InputLabel>
          <SelectContainer>
              <Select
                name={'message'}
                value={values.message}
                options={mensajesDummyData}
                placeholder="Sel. Mensaje"
                onChange={values => setFieldValue('message', values)}
              />
            </SelectContainer>
          {errors.message && <ErrorLabel>{errors.message}</ErrorLabel>}
        </InputWrapper2Col>
      </Row>
      <Row>
        <InputWrapper2Col>
          <InputLabel>{'Canales'}</InputLabel>
          <RadioGroup row name="channel" value={values.channel} onChange={values => this.handleRadioChange(setFieldValue, values)}>
            <FormControlLabel value="email" control={<Radio />} label="Email" />
            <FormControlLabel value="push" control={<Radio />} label="Push" />
            <FormControlLabel value="sms" control={<Radio />} label="SMS" />
            <FormControlLabel value="all" control={<Radio />} label="Todos" />
            />
        </RadioGroup>
          {errors.channel && <ErrorLabel>{errors.channel}</ErrorLabel>}
        </InputWrapper2Col>
      </Row>
      <ButtonWrapper>
        <StyledButton type={'submit'}>Enviar Notificación</StyledButton>
      </ButtonWrapper>
    </FormContainer>
  )

  getNuevaNotifSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        channel: Yup.string().required('Ingrese un canal de envio'),
        destination: Yup.string().required('Seleccione un destinatario'),
        subject: Yup.string().required('Seleccione un motivo'),
        neighbourhoods: Yup.string().required('Seleccione un Barrio'),
        message: Yup.string().required('Complete un mensaje para enviar.')

      })
    )

  render() {
    return (
      <Modal>
        <Formik
          validateOnChange={false}
          validationSchema={this.getNuevaNotifSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

const mapStateToProps = ({
  accesos, userInfo
}) => ({
  mappedLots: mapLots(accesos.lots),
  loading: accesos.loadingLots,
  mappedBarrios: mapNeighbourhoods(userInfo.neighbourhoods)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLots, fetchNotifications
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(nuevaNotificacion)
