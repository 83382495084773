import styled from 'styled-components'

export const FormContainer = styled.form`
  width: 75%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;    
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
  box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'space-between'};
  margin-bottom: ${({ error }) => (error ? 0 : 20)}px;
`

export const InputWrapper = styled.div`
    width:  33%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`

export const DivRadio = styled.div`
  display: contents;
`

export const InputWrapper2Col = styled.div`
    width:  50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`

export const InputLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  width:  18%;
  padding-bottom: 10px;
  padding-right: 20px;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.royalBlue};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
`
export const ButtonWrapper = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
`
export const ResetIcon = styled.img`
  width: 30px;
  margin-right: 20px;
  cursor: pointer;
`

export const SelectContainer = styled.div`
  width: 38vh;
`
export const Text = styled.span`
  font-size: 18px;
  color: #000;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: ${({ margin }) => margin ? margin : 'inherit'};
`
export const FormSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${props => props.alignItems ? props.alignItems : 'start'};
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'start'};  border-radius: 4px;
  width: 100%;
  padding: 2%;
  box-sizing: border-box;
  &:last-child {
  margin-right: 0%;
  }
`
export const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 20px;
`
export const LabelFecha = styled.label`
  font-size: 15px;
  display: flex;
  align-items: center;
    
`

export const DocumentImg = styled.img`
  width: 100%;
  border-radius: 8px;
`

export const FileInputWrapper = styled.div`
   width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const StyledFileInput = styled.input`
  width: 150px;
  height: 150px;
  font-size: 200px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
  
`

export const ImageAttachText = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 14px;
  text-decoration: underline;
  margin-left: 20px;
`

export const AttachedContainer = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.transparent};
  
  ${FileInputWrapper} &:hover{
    cursor: pointer;
  }
`

export const CheckedElement = styled.img`
  position: absolute;
  top: -12px;
  right: -12px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  width: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`

export const ExpensasList = styled.ul`
`


export const ListItem = styled.li`
`


