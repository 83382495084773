import React from 'react'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Container, Row, Title, Subtitle, Content } from './styled'
import { Avatar } from '@material-ui/core'

export const ScrollWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.white};

  &::-webkit-scrollbar {
    display: none;
  }
`

export const InfiniteLoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 0;
`

export const NoContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.blueRibbon};
  font-size: 20px;
  padding: 20px 0;
`

const MonthList = ({
  elements,
  onClick,
  year,
  selectedPos,
  fetchMoreContent,
  hasMore,
  noMoreContentMsg,
}) => (
  <div>
    <InfiniteScroll
        scrollableTarget={'scrollContainer'}
        dataLength={elements.length}
        next={fetchMoreContent}
        hasMore={hasMore}
        width="25%"
        loader={
            <InfiniteLoaderWrapper>
                <CircularProgress size={30} />
            </InfiniteLoaderWrapper>
        }
        endMessage={
            <NoContentContainer style={{ textAlign: 'center' }}>{noMoreContentMsg}</NoContentContainer>
        }
    >
        <ScrollWrapper>
            {elements.map((e, i) => (
                <Container onClick={()=> onClick(i)} selected={e.selected} key={i}>
                  <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <Avatar  src={e.avatar} style={{ width: '35px', height: '35px' }} ></Avatar>
                    <Row>
                      { 
                        (() => {
                          switch(e.month) {
                              case 1:
                              return   <Title>Expensas Enero {year} </Title>;
                              case 2:
                              return   <Title>Expensas Febrero {year} </Title>;
                              case 3:
                              return   <Title>Expensas Marzo {year} </Title>;
                              case 4:
                              return   <Title>Expensas Abril {year} </Title>;
                              case 5:
                              return   <Title>Expensas Mayo {year} </Title>;
                              case 6:
                              return   <Title>Expensas Junio {year} </Title>;
                              case 7:
                              return   <Title>Expensas Julio {year} </Title>;
                              case 8:
                              return   <Title>Expensas Agosto {year} </Title>;
                              case 9:
                              return   <Title>Expensas Septiembre {year} </Title>;
                              case 10:
                              return   <Title>Expensas Octubre {year} </Title>;
                              case 11:
                              return   <Title>Expensas Noviembre {year} </Title>;
                              case 12:
                              return   <Title>Expensas Diciembre {year} </Title>;
                              
                              default:
                              return null;
                          }
                          })()
                      }
                    </Row>
                  </div>
              </Container>
            ))}
        </ScrollWrapper>
    </InfiniteScroll>
  </div>
)

export default MonthList

