import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { convertFileInBase64 } from 'utils/fileManager'
import * as Yup from 'yup'
import { Formik } from 'formik'
import CrossIcon from 'assets/icons/cross-blue.png'
import FileIcon from 'assets/icons/file.jpg'
import FileInput from 'components/FileInput'
import { importProprietary } from 'store/actions/padron.actions'
import PageLoader from 'components/PageLoader'
import { Base64 } from 'js-base64';
import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
} from './styled'
import Modal from 'components/Modal'
import {readFile} from "utils/fileManager"

class UploadFile extends React.Component {
  state = {
    url: this.props.value,
    errorFile: false,
    loadingFile: false,
    errorMessage: ''
  }

  getInitialValues = () => ({
    importFile: ''
  })

  getSignUpSchema = () =>
    Yup.object().shape({
      importFile: Yup.string().required('Campo requerido')
    })

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) this.setState({ url: this.props.value })
  }

  hasAttachedContent = () => this.state.url

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row> <InputLabel>{'Carga masiva de productos (.xlsx)'}</InputLabel> </Row>
      <Row>
        <InputWrapper width={30}>
            <FileInput image={FileIcon} name="importFile" value={values.importFile} onChange={file => setFieldValue('importFile', file)} useBase64={true} />
            {errors.importFile && <ErrorLabel>{errors.importFile}</ErrorLabel>}
        </InputWrapper>
      </Row>
      {this.props.error || this.state.errorFile ? (
        <ButtonWrapper>
          <ErrorLabel>Error al cargar el archivo: {this.state.errorMessage}</ErrorLabel>
        </ButtonWrapper>
      ): (<> </>)}
      {this.props.loading   ? 
        (
          <ButtonWrapper>
            <PageLoader customHeight={"50"}/>
          </ButtonWrapper>
        ) : 
        (
          <ButtonWrapper>
            <StyledButton type={'submit'}>SUBIR</StyledButton>
          </ButtonWrapper>
        )
      }
    </FormContainer>
    )

    
  handleSubmit = async data => {
    this.setState({loadingFile: true})
    try{
      console.log('Inicia post');
      await this.props.onChange(data.importFile);
      console.log('error:', this.props.error);
      if (!this.props.error){
        this.props.onOpenResult(true);
        this.props.onClose(true);
      }
      this.setState({errorMessage: this.props.errorMessage})
    }catch(e){
      alert('error', e);
      this.setState({errorFile: true})
      this.setState({errorMessage: this.props.errorMessage})
    }
  }

  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

export default UploadFile

