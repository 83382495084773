import styled from 'styled-components'

export const PageWrapper = styled.div`
width: 100%;
min-height: 100%;
align-items: normal;
&:before {
  animation: portal-profile-before 0.6s ease-out forwards 1;
  animation-delay: 0.2s;
}
&:after {
  animation: portal-profile-after 0.4s ease-out forwards 1;
  animation-delay: 0.4s;
}
`
export const Wrapper = styled.div`
  min-height: 560px;
  border-radius: 10px;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
`

export const MainWrapper = styled.div`
  width: 40%;
  min-height: 560px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 15px;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.ghost};
  flex-grow: 1;
  order: 1;
  align-items: center;
`

export const ListMovementsWrapper = styled.div`
width: 60%;
min-height: 560px;
background-color: ${({ theme }) => theme.colors.white};
padding-right: 13px;
overflow-y: scroll;
border-bottom-left-radius: 10px;
flex-grow: 2;
order: 1;
`

export const HeaderMovementsWrapper = styled.div`
min-height: 20px;
padding-right: 13px;
padding-left: 13px;
background-color: ${({ theme }) => theme.colors.white};
max-height: 100px;
display: flex;
justify-content: space-around;
flex-direction: row;
flex-wrap: nowrap;
`

export const TitleWrapper = styled.div`
flex-grow: 2;
order: 1;
`
export const SelectWrapper = styled.div`
  z-index: 100;
  flex-grow: 1;
  order: 2;
  width: 45%;
  margin: 10px 0px 0px 0px;
`

export const FormControlstyled = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  text-align: start;
`
export const Refresh = styled.img`
  width: 30px;
  order: 3;
  margin: 0% 4%;
  
:hover {
  cursor: pointer;
}
`