import { createSelector } from 'reselect'


/**
 * address: null
brochureurl: "https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Brochures/Ficha+digital+-+Barrio+Acacias.pdf"
code: "ACACIAS"
description: "> Lotes de 660 m2 promedio
↵> Salida navegable al Lago Central
↵> Club con pileta y canchas de tenis
↵> Ubicación noreste cercano a la zona comercial"
entrepreneurshipparent: null
entrepreneurshipparentid: 1
externalcode: "110"
id: 2
latitude: 0
longitude: 0
name: "Acacias"
pictureurl: "https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Images/A4_PUERTOS_06_Acacias.jpg"
postalcode: null
 */
const mapEntrepeneur = createSelector(
  entrepeneurList => entrepeneurList,
  entrepeneurList =>
    entrepeneurList.map(
      ({ id, name }, i) => ({
        value: id,
        label: name
      })
    )
)

export default mapEntrepeneur
