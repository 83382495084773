import styled, { css } from 'styled-components'
import PencilIcon from '@material-ui/icons/Edit'

export const DataContainer = styled.div`
  width: 65%;
  padding: 20px 25px;
  border-bottom-right-radius: 10px;
`

export const DataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.colors.mercury};
    border-radius: 5px;
`

export const Pencil = styled(PencilIcon)`
    background: ${({ theme }) => theme.colors.blueRibbon};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 15px;
    padding: 4px;
    height: 18px !important;
    width: 18px !important;
`


export const Row = styled.div`
  width: ${({ width }) => (width || '100%')};
  margin:  ${({ margin }) => (margin || '0px')};
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'center'};
  border-bottom-style: ${({ borderStyle }) => borderStyle || 'none'};
  border-bottom-width: ${({ borderWidth}) => borderWidth || 'none'};
  border-bottom-color: ${({ borderColor }) => borderColor || 'none'};
`

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
`

export const Title = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 22px;
  font-weight: 700;
  margin: 15px 0;
`

export const DataLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ color, theme }) => (color ? theme.colors.black : theme.colors.midGray)};
  font-size: 18px;
  font-weight: ${({ bold })  => bold || '300'};

  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin: 15px 0;
      margin-right: 5px;
    `}
`

export const DataContent = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.midGray};
  font-size: 18px;
  font-weight: 500;
  ${({ noMargin }) =>
    !noMargin
      ? css`
          margin: 15px 0;
        `
      : css`
          margin-top: 15px;
        `}
`

export const GuestState = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};
`

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
  margin-top: ${({ noMargin }) => (noMargin ? 15 : 40)}px;
  margin-bottom: ${({ noMargin }) => (noMargin ? 15 : 10)}px;
`
