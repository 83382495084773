import styled from 'styled-components'

export const FormContainer = styled.form`
  width: 50%;
  height: 20h;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 100px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
`

export const Modal = styled.div`
    position: fixed;
    top: 32%;
    left: 0;
    width: 65%;
    height: 30%;
    background-color: rgba(255,255,255,0.75);
    z-index: 9999;
    left: 18%;
    padding-right: 20px;
    padding-left: 20px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${({ error }) => (error ? 0 : 15)}px;
`

export const RowLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: ${({ error }) => (error ? 0 : 15)}px;
`

export const InputWrapper = styled.div`
  width: ${({ width }) => width || 100}%;
`

export const InputWrapperMargin = styled.div`
  width: ${({ width }) => width || 100}%;
  margin-right: 25px;
`

export const InputLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 18px;
  padding: 10px 0;
`

export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 12px 60px;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
`

export const ErrorMessage = styled.label`
  margin-right: auto;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.red};
`

export const SelectWrapper = styled.div`
  min-height: 50px;
  width: 280px;
`
export const TitleLabel = styled.div`
  font-size: 25px;
  margin-bottom: 10px;
`