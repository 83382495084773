import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import RegistrosEInformes from './registroseinformes'


class Gestor extends Component {
  render() {
    return (
      <RegistrosEInformes></RegistrosEInformes>
    )
  }
}



const mapStateToProps = ({
  accesos,
}) => ({
  accesos
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(compose(withWidth())(Gestor))
