import React from 'react'
import NoFile from 'assets/icons/empty-document.png'
import ReplaceFile from 'assets/icons/replace-document.png'
import CheckedImg from 'assets/icons/checked.png'
import inputTypes from 'constants/inputTypes'
import {
  AttachedContainer,
  CheckedElement,
  DocumentImg,
  FileInputWrapper,
  StyledFileInput
} from './styled'

class FileInputRaw extends React.Component {
  state = {
    url: "",
    loading: false,
  }

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) {
      if (this.props.value == null) this.setState({url: ""})
      else this.setState({ url: this.props.image })
    }
  }

  hasAttachedContent = () => this.state.url

  fileSelectedHandler = e => {
    this.props.onChange(e.target.files[0])
    this.setState({text: (e.target.files[0] || {}).name});
  }

  render() {
    return (
      <FileInputWrapper>
        {this.props.onChange && (
          <StyledFileInput type={inputTypes.FILE} onChange={this.fileSelectedHandler} />
        )}
        <AttachedContainer>
          {this.state.text ? <CheckedElement src={CheckedImg} /> : ""}
          <DocumentImg src={this.state.url || (this.props.isReplace && ReplaceFile) || NoFile} />
        </AttachedContainer>
      </FileInputWrapper>
    )
  }
}

export default FileInputRaw
