import { createSelector } from 'reselect'

const mapLots = createSelector(
  lotsList => lotsList,
  lotsList =>
    lotsList.map(
      (e, i) => ({
        label: e.code,
        value: e.guid,
        owners: e.owner_names, 
        phone_number: e.owner_phones,
      })
    )
)

export default mapLots
