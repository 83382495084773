import styled from 'styled-components'

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  align-items: normal;
  
  &::-webkit-scrollbar { width: 0 !important }

  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`
export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  align-items: normal;
  
  &::-webkit-scrollbar { width: 0 !important }

  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`
export const InputStyled = styled.div`
  width: 100%;
  min-height: 100%;
  align-items: normal;
  
  &::-webkit-scrollbar { width: 0 !important }

  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 560px;
`

export const AccessEntry = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width:  ${({ width }) => width ? width : "auto"};
  align-self:  ${({ width }) => width ? "center" : null};
  margin: 2%;
`

export const BigButton = styled.div`
  flex: 1 1 40%;
  height: 100px;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px #e1e1e1;
  border-top: 2.5px solid #1873f3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
`
export const Text = styled.span`
  font-weight: 500;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`

export const PngImage = styled.img`
  padding: 0px 15px;
`