import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross-blue.png'
import DatePickerStyled from 'components/DatePickerStyled'
import CheckBox from 'components/CheckBox'
import { registerLocale } from 'react-datepicker';
import { addMonths, subMonths } from 'date-fns'
import { getFormattedDateYear } from 'utils/dateParser'
import { fetchGenerateAutomaticExpensas, fetchGenerateExpensas, fetchGenerateAutomaticExpensasReset } from 'store/actions/expensas.actions'
import PageLoader from 'components/PageLoader';
import es from 'date-fns/locale/es';

import {
  FormContainer,
  Row,
  InputLabel,
  InputWrapper,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  LabelFecha,
  ExpensasList,
  ListItem,
} from './styled'

registerLocale('es', es)

class GenerateAutomaticExpense extends React.Component {
  state = {
    user: JSON.parse(localStorage.getItem('user')),
    filter: '',
    newExpensa: false,
    isDetailItemOpen: false,
    check: false,
    body: {
      'PaymentMethod': 'APP_MB',
      'DateFrom': '',
    }
  }

  getInitialValues = () => ({

    Date: '',
  })

  handleCheck() {
    console.log("click check")
    this.setState({ check: !this.state.check })
  }
  getSignUpSchema = () =>
    Yup.object().shape({
      Date: Yup.string().required('El campo mes actual es requerido'),
    })

  handleClosePopUp() {
    this.props.fetchGenerateAutomaticExpensasReset();
    this.props.onClose(false);
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.handleClosePopUp()} src={CrossIcon} />
      <Row> <InputLabel>{'Generar expensas adheridas al debito'}</InputLabel> </Row>
      <Row margin={"25"}>
        <InputWrapper width={28} margin={'0px 5px'}>
          <LabelFecha>{'Mes a cobrar'}</LabelFecha>
          <DatePickerStyled
            selected={values.Date}
            startDate={values.startDate}
            endDate={values.endDate}
            selectsStart
            onChange={Date => setFieldValue('Date', Date)}
            minDate={0}
            placeholderText="Fecha"
            dateFormat="MMMM"
            showMonthYearPicker
            locale="es"
          />
          {errors.Date && <ErrorLabel>{errors.Date}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row >
        <LabelFecha size={"12px"}>Descargar archivo para procesarlo de manera manual en la web del banco.</LabelFecha>
        <input
          onChange={()=> this.handleCheck()}
          checked={this.state.check}
          type="checkbox"
          id="check"
        />
      </Row>
      <Row>
        <LabelFecha>** Se generaran expensas para todos los barrios **</LabelFecha>
          <a href={window.URL.createObjectURL(new Blob([this.props.expensas.expensas.file_result], { type: 'text/plain' }))} download="expensas.txt" id="download" hidden></a>
       </Row>
      {
        this.props.expensas.errorGenerateExpensasMessage ? (
          <Row><ErrorLabel>{this.props.expensas.errorGenerateExpensasMessage}</ErrorLabel></Row>
        ) : (<></>)
      }
      {
        this.props.expensas.expensas.neighbourhood_list ?
          <ExpensasList>
            {this.props.expensas.expensas.neighbourhood_list.map((e, i) => {
              return (<ListItem>{e.neighbourhood_name}: {e.neighbourhood_result}</ListItem>);
            })}
          </ExpensasList> :
          (
          <>
            {this.props.expensas.loadingGenerateExpensas || this.props.expensas.loadingGenerateAutomaticExpensas ? 
              (
                <ButtonWrapper>
                <PageLoader customHeight="80px" />
              </ButtonWrapper>)
               : 
              ( <ButtonWrapper>
                  <StyledButton type={'submit'} >Crear</StyledButton>
                </ButtonWrapper>)
              }
          </>)
      }
      
    </FormContainer>
  )

  componentDidUpdate() {
    if (this.props.expensas.descargaFile ) {
      if(this.state.check === true){this.downloadBlob(); } 
    }     
  }
  componentDidUpdate() {
    if (!this.props.expensas.loadingGenerateExpensas && this.state.check === true && this.props.expensas.descargaFile) {
      this.downloadBlob()
    }
  }


  componentDidMount(){
    this.props.fetchGenerateAutomaticExpensasReset();
  }
  

  handleSubmit = data => {
    let newBody = this.state.body;
    newBody.DateFrom = getFormattedDateYear(data.Date);
    if (this.state.check === true) {
      this.props.fetchGenerateExpensas(newBody)
    } else {
      this.props.fetchGenerateAutomaticExpensas(newBody)
    }
  }

  downloadBlob() {
    if(this.props.expensas.expensas.file_result.length > 0 ){
      var binaryData = [];
      binaryData.push(this.props.expensas.expensas.file_result[0].fileText);
      

      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(new Blob(binaryData, {type: "text/plain"}))
      a.href = url;
      a.download = "expensas generadas";
      a.click();
      setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
    }
  }

  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

const mapStateToProps = ({
  userInfo: { neighbourhoods, username },
  expensas: expensas,
}) => ({
  neighbourhoods,
  username,
  expensas
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGenerateAutomaticExpensas,
      fetchGenerateAutomaticExpensasReset,
      fetchGenerateExpensas
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateAutomaticExpense)
