import { createSelector } from 'reselect'
var moment = require('moment');
const mapMovies = createSelector(
  movies => movies,
  movies =>
    movies.map(movie => ({
      id:movie.id,
      title: movie.title,
      description: movie.description,
      imageUrl: movie.image_url,
      movieUrl: movie.movie_url,
      order:movie.order,
      creationDate:moment(movie.creation_date).format('YYYY-MM-DD'),
      isPublic: movie.is_public,
      isPublicGrid: movie.is_public ? 'Si' : 'No'
    }))
)

export default mapMovies
