import { createSelector } from 'reselect'

const mapUsersTickets = createSelector(
  users => users,
  users =>
    users.map(user => ({
      label: user.fullname,
      value: user.id
    }))
)

export default mapUsersTickets