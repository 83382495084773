import React from 'react'
import NoFile from 'assets/icons/empty-document.png'
import CheckedImg from 'assets/icons/checked.png'
import CancelImg from 'assets/icons/cancel.png'
import inputTypes from 'constants/inputTypes'
import { readImages } from 'utils/fileManager'
import {
  AttachedContainer,
  CheckedElement,
  DocumentImg,
  FileInputWrapper,
  StyledFileInput,
  RemoveElement
} from './styled'

class FileImageInput extends React.Component {
  state = {
    url: this.props.value
  }

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) this.setState({ url: this.props.value })
  }

  hasAttachedContent = () => this.state.url

  fileSelectedHandler = async event => {
    const attachFile = await readImages(event)
    if (this.props.onChange) this.props.onChange(attachFile)
    this.setState({ ...attachFile })
  }

  removeFile = () => {
    this.props.removeFile(this.state.url);
    this.setState({ url: null});
  }

  render() {
    const { onChange, gridArea,width,padding } = this.props;
    return (
      <FileInputWrapper width={width} padding={padding} gridArea={gridArea ? gridArea : ''}>
        {onChange && (
          !this.props.disabled?<StyledFileInput  accept="image/x-png,image/gif,image/jpeg" type={inputTypes.FILE} onChange={this.fileSelectedHandler} />:null
        )}
        <AttachedContainer>
          {this.hasAttachedContent() && <CheckedElement src={CheckedImg} />}
          <DocumentImg src={this.hasAttachedContent() || NoFile} />
          {
            this.hasAttachedContent() && this.props.canRemoveElement && 
            <RemoveElement src={CancelImg} onClick={this.removeFile}/>
          }
        </AttachedContainer>
      </FileInputWrapper>
    )
  }
}

export default FileImageInput
