import todo from 'assets/icons/creditCard.svg'
import transfer from 'assets/icons/transfer.svg'
import multas from 'assets/icons/noun-bill-1712977.svg'
import otros from 'assets/icons/noun-more-1002227.svg'
import qr from 'assets/icons/qr-code.svg';

const cuentas = [
    { value: 'todo',label: "Todos", src: todo },
    { value: 'Debito Directo Expensa', label: "Deb. directo", src: multas  },
    { value: 'Transferencia bancaria',label: "Transf. Banc",  src: transfer  },
    { value: 'Pago QR',label: "QR",  src: qr  },
    { value: 'REVERSO - ',label: "Reversos",  src: otros },
  ];

export default cuentas;