import * as actionTypes from './actionTypes'
import {
  getRoles,
  getMenus,
  getPersonByNeighbourhood,
  fetchRefreshMenusForRoleAPI
} from 'apiConstants'
import getApi from 'shared/api'
import fetchData from 'shared/fetchData'

//------------------Request Roles ----------------------------------
export const fetchRolesSuccess  = roles => {
    return {
      type: actionTypes.FETCH_GET_ROLES_SUCCESS,
      roles: roles
    }
  }
  
  export const fetchRolesFail = error => {
    return {
      type: actionTypes.FETCH_GET_ROLES_FAIL,
      error: error
    }
  }
  
  export const fetchRolesStart = () => {
    return {
      type: actionTypes.FETCH_GET_ROLES_START
    }
  }
  
  export const fetchRoles = () => {
    return async (dispatch, getState) => {
      dispatch(fetchRolesStart())
      const {
        userInfo: {
          selectedNeighbourhood: { guid }
        }
      } = getState()
      const api = await getApi(guid)
      try {
        const response = await api.get(getRoles)
        dispatch(
          fetchRolesSuccess(response.data)
        )
      } catch (error) {
        dispatch(fetchRolesFail(error.response.data.message))
      }
    }
  }

//--------------- Get Menus ------------------------
export const fetchgetMenusSuccess = menus => {
  return {
      type: actionTypes.FETCH_GET_MENUS_SUCCESS,
      menus: menus
  }
}

export const fetchgetMenusFail = error => {
  return {
      type: actionTypes.FETCH_GET_MENUS_FAIL,
      error: error
  }
}

export const fetchgetMenusStart = () => {
  return {
      type: actionTypes.FETCH_GET_MENUS_START
  }
}

export const fetchgetMenus = (guid,lote,year) => {
  return async (dispatch, getState) => {
    dispatch(fetchgetMenusStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const response = await api.get(getMenus)
      dispatch(
        fetchgetMenusSuccess(response.data)
      )
    } catch (error) {
      dispatch(fetchgetMenusFail(error.response.data.message))
    }
  }
}

//--------------- Get Menus ------------------------
export const fetchgetPersonByNeighbourhoodSuccess = persons => {
  return {
      type: actionTypes.FETCH_GET_PERSON_SUCCESS,
      persons: persons
  }
}

export const fetchgetPersonByNeighbourhoodFail = error => {
  return {
      type: actionTypes.FETCH_GET_PERSON_FAIL,
      error: error
  }
}

export const fetchgetPersonByNeighbourhoodStart = () => {
  return {
      type: actionTypes.FETCH_GET_PERSON_START
  }
}

export const fetchgetPersonByNeighbourhood = (guid,lote,year) => {
  return async (dispatch, getState) => {
    dispatch(fetchgetPersonByNeighbourhoodStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const response = await api.get(getPersonByNeighbourhood)

      dispatch(
        fetchgetPersonByNeighbourhoodSuccess(response.data)
      )
    } catch (error) {
      dispatch(fetchgetPersonByNeighbourhoodFail(error))
    }
  }
}


//--------------- Get Menus ------------------------
export const fetchRefreshMenusForRoleSuccess = success => {
  return {
      type: actionTypes.FETCH_REFRESH_MENUS_FOR_ROLE_SUCCESS,
      success: success
  }
}

export const fetchRefreshMenusForRoleFail = error => {
  return {
      type: actionTypes.FETCH_REFRESH_MENUS_FOR_ROLE_FAIL,
      error: error
  }
}

export const fetchRefreshMenusForRoleStart = () => {
  return {
      type: actionTypes.FETCH_REFRESH_MENUS_FOR_ROLE_START
  }
}

export const fetchRefreshMenusForRole = (body) => {
  return async (dispatch, getState) => {
    dispatch(fetchRefreshMenusForRoleStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const response = await api.post(fetchRefreshMenusForRoleAPI,body)
      dispatch(
        fetchRefreshMenusForRoleSuccess(response.data)
      )
    } catch (error) {
      dispatch(fetchRefreshMenusForRoleFail(error.response.data.message))
    }
  }
}