import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross.png'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from 'components/Input'
import FileImageInput from 'components/FileImageInput'
import FileInputRaw from 'components/FileInputRaw';
import FileInputGroup from 'components/FileInputGroup'
import { httpsOnly } from 'constants/regularExpresions.js'
import PageLoader from 'components/PageLoader';
import TextArea from 'components/TextArea';
import FileIcon from 'assets/icons/file.jpg'
import { Progress } from 'react-sweet-progress';
import 'react-sweet-progress/lib/style.css';
import CheckBox from 'components/CheckboxFormik'

import {
  FormContainer,
  Row,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  Title,
  FlexWrapper,
  ProgressBarWrapper,
  RadioBtnContainer,
  LabelCheckbox,
  LabelChecked
} from './styled'

class StoryModal extends React.Component {
  state = {
    isPublic: this.props.detailStory ? this.props.detailStory.isPublic : false
  }

  getInitialValues = () => ({
    id: this.props.detailStory ? this.props.detailStory.id : null,
    name: this.props.detailStory ? this.props.detailStory.name : '',
    description: this.props.detailStory ? this.props.detailStory.description : '',
    imageUrl: this.props.detailStory ? this.props.detailStory.imageUrl : '',
    storyUrl: this.props.detailStory ? this.props.detailStory.storyUrl : '',
    storyRaw: null,
    order: this.props.detailStory ? this.props.detailStory.order : 1,
    isPublic: this.props.detailStory ? this.props.detailStory.isPublic : false
  })

  getSignUpSchema = () =>
  this.props.detailStory ?
  Yup.lazy(values =>
    Yup.object().shape({
      name: Yup.string().required('Ingrese un título'),
      description: Yup.object().nullable(),
      order: Yup.number().moreThan(0,'El orden debe ser un número mayor a 0').required('Ingrese un orden de prioridad'),
      storyUrl: Yup.string().required('Ingrese la URL del video'),
      imageUrl: Yup.string().required('Seleccione una imagen'),
      storyRaw: Yup.mixed().test(
        'format-validation',
        'Debe ser un archivo de formato MP4 o MKV',
        value => {
          if (value == null)
            return true;
          var re = /(?:\.([^.]+))?$/;
          var ext = re.exec(value.name)[1];
          if (ext != null){
            return (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv'); 
          }
          return false;
        }
      )
    })
  )
  : Yup.lazy(values =>
    Yup.object().shape({
      name: Yup.string().required('Ingrese un título'),
      description: Yup.object().nullable(),
      order: Yup.number().moreThan(0,'El orden debe ser un número mayor a 0').required('Ingrese un orden de prioridad'),
      imageUrl: Yup.string().required('Seleccione una imagen'),
      storyUrl: Yup.string(),
      storyRaw: Yup.mixed().required('Seleccione un archivo').test(
        'format-validation',
        'Debe ser un archivo de formato MP4 o MKV',
        value => {
          if (value == null)
            return false;
          var re = /(?:\.([^.]+))?$/;
          var ext = re.exec(value.name)[1];
          if (ext != null){
            return (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv'); 
          }
          return false;
        }
      )
    })
  );

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => {
    return (
      <FormContainer onSubmit={handleSubmit} >
        <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
        <FlexWrapper>
          <Title>{this.props.tittle}</Title>
        </FlexWrapper>
        <FlexWrapper>
          <FlexWrapper width={70}>
            <InputWrapper width={70}>
              <InputLabel>{'Nombre'}</InputLabel>
              <Input
                name={'nameInput'}
                label={'nameInput'}
                placeholder={'Ingrese un título...'}
                value={values.name}
                error={errors.name}
                onChange={({ target: value }) => setFieldValue('name', value.value)}
                margin="normal"
                disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
              />
              {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper width={30}>
              <InputLabel>{'Orden'}</InputLabel>
              <Input
                type="number"
                name={'orderInput'}
                label={'orderInput'}
                placeholder={'Ingrese el orden de prioridad...'}
                value={values.order}
                error={errors.order}
                onChange={({ target: value }) => setFieldValue('order', value.value)}
                margin="normal"
                disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
              />
              {errors.order && <ErrorLabel>{errors.order}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper>
              <InputLabel>{'Descripción'}</InputLabel>
              <TextArea 
                minWidth="0"
                maxWidth="1024"
                minHeight="110"
                maxHeight="110"
                name={'descriptionInput'}
                label={'descriptionInput'}
                placeholder={'Ingrese una descripción...'}
                value={values.description}
                error={errors.description}
                onChange={({ target: value }) => setFieldValue('description', value.value)}
                margin="normal"
                disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
              ></TextArea>
              {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
            </InputWrapper>
          </FlexWrapper>
          <FlexWrapper width={30}>
            <InputWrapper>
              <InputLabel>{'Imagen'}</InputLabel>
              <FileImageInput width="100%"
                disabled={this.props.isReadOnly ? this.props.isReadOnly : false} 
                value={values.imageUrl} 
                onChange={(file) => setFieldValue('imageUrl', file)} />
                {errors.imageUrl && <ErrorLabel textAlign="center">{errors.imageUrl}</ErrorLabel>}
            </InputWrapper>
       
          </FlexWrapper>
          <RadioBtnContainer width={30} >
            <LabelChecked checked={this.state.isPublic}>
            <Field
              component={CheckBox}
              name="isPublic"
              id="isPublic"
            />
            <LabelCheckbox>
            Es publico

            </LabelCheckbox>
          </LabelChecked>
        </RadioBtnContainer>
        </FlexWrapper>
        <FlexWrapper align="flex-end" hidden={!this.props.detailStory}>
          <InputWrapper>
            <InputLabel>{'Url video actual'}</InputLabel>
            <Input
              name={'storyUrlInput'}
              label={'storyUrlInput'}
              value={values.storyUrl}
              error={errors.storyUrl}
              onChange={({ target: value }) => setFieldValue('storyUrl', value.value)}
              margin="normal"
              disabled={true}
            />
          </InputWrapper>
        </FlexWrapper>
        <FlexWrapper align="flex-end" hidden={this.props.isReadOnly}>
          <InputWrapper width={30}>
            <InputLabel>{this.props.detailStory ? 'Reemplazar video' : 'Video'}</InputLabel>
            <FileInputRaw name="storyRaw" value={values.storyRaw} image={FileIcon} onChange={file => setFieldValue('storyRaw', file)} isReplace={this.props.detailMovie}/>
            {errors.storyRaw && <ErrorLabel>{errors.storyRaw}</ErrorLabel>}
            {errors.storyUrl && <ErrorLabel>{errors.storyUrl}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper width={70}>
            <ProgressBarWrapper opacity={this.props.uploadProgress > 0 ? 1 : 0}>
              <Progress percent={this.props.uploadProgress} />
            </ProgressBarWrapper>
          </InputWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <ButtonWrapper>
            <StyledButton type={'submit'} disabled={this.props.isSubmittingStory} hidden={this.props.isReadOnly}>
              {this.props.isSubmittingStory ? (
                <CircularProgress size={18} />
              ) : (
                  'Publicar'
                )}
            </StyledButton>
          </ButtonWrapper>
        </FlexWrapper>
      </FormContainer>
    );
  }

  render() {
    if (this.props.loading) {
      return (<PageLoader />);
    }

    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.props.onSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}


export default StoryModal;