import React from 'react'
import { Wrapper, Container, Row, Circle, ColumnItem, StyledButton, ErrorLabel, SelectWrapper, SelectWrapperDatePicker, StyledLabel, ContentWrapper, InputWrapper, InputLabel, FormContainer } from './styled'
import MaterialTable from 'material-table'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Checkbox } from '@material-ui/core';

import { fetchActivities } from 'store/actions/activities.actions'
import { fetchReporteReservas } from 'store/actions/reportes.actions'
import { fetchSpaces } from 'store/actions/spaces.actions'
import Select from 'react-select'
import mapSpaces from 'selectors/mapSpaces'
import mapBookingReport from 'selectors/mapBookingReport'
import PageLoader from 'components/PageLoader'
import DatePickerStyled from 'components/DatePickerStyled'
import { setSelectedResource, resetSelectedResource } from 'store/actions/selectedResource'
import weekDays from 'constants/weekDays'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Formik } from 'formik'
import * as Yup from 'yup'


class ReporteReserva extends React.Component {

  state = {
    activitiesdata: undefined,
    selectedSpace: null,
    selectedActivity: null,
    startDate: new Date(),
    endDate: new Date(),
    spaceEnabled: false
  }


  componentDidMount() {
    this.fetchContent()
  }

  fetchContent = () => {
    this.props.fetchActivities()
  }

  getInitialValues = () => ({
    activity: '',
    space: '',
    startDate: new Date(),
    endDate: new Date()
  })

  handleActivitiesSelection = (setFieldValue, values) => {
    setFieldValue('activity', values);
    if (values.value == '') {
      // setFieldValue('space', this.state.types[0]);
      this.setState({ spaceEnabled: false });
    }
    else {
      this.props.fetchSpaces(values.value.id);
      this.setState({ spaceEnabled: true });
    }
  }

  handleExportReport = (data) => {

    const formattedBody = {
      selectedSpace: data.space.value.id,
      selectedActivity: data.activity.value.id,
      startDate: data.startDate,
      endDate: data.endDate

    }
    this.props.fetchReporteReservas(formattedBody)

  }
  generateEmptyTableValues = () => {
    const tableValues = Object.keys(weekDays).map(key => ({
      day_of_week: parseInt(key),
      active_times: []
    }))
    this.setState({ tableValues })
  }

  getValidationSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        activity: Yup.string().required('Ingrese un lote'),
        space: Yup.string().required('Ingrese una categoría'),
        startDate: Yup.string().required('Ingrese una fecha'),
        endDate: Yup.string().required('Ingrese una fecha')
      })
    )

  isLoading = () => this.props.activities.isFetching

  getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <Row>
        <InputWrapper margin={'20px 15px'}>
          <InputLabel>{'Actividad'}</InputLabel>
          <Select
            name={'activity'}
            value={values.activity}
            isSearchable={false}
            // onChange={values => setFieldValue('activity', values)}
            error={errors.activity}

            // value={this.state.selectedActivity}
            onChange={values => this.handleActivitiesSelection(setFieldValue, values)}
            options={this.props.formattedActivities}
            placeholder={this.props.activities.isFetching ? "Cargando " : "Seleccione una actividad..."}
            isLoading={this.props.activities.isFetching}
            isDisabled={this.props.activities.isFetching}
            autoFocus
          />
          {errors.activity && <ErrorLabel>{errors.activity}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper margin={'20px 15px'}>
          <InputLabel>{'Espacio'}</InputLabel>
          <Select
            name={'space'}
            value={values.space}
            isSearchable={false}
            onChange={values => setFieldValue('space', values)}
            error={errors.space}

            // value={this.state.selectedSpace}
            // onChange={this.handleSpaceSelection}
            options={this.props.formattedSpaces}
            placeholder={this.props.spaces.isFetching ? "Cargando" : "Seleccione un espacio..."}
            isLoading={this.props.spaces.isFetching}
            isDisabled={this.props.spaces.isFetching || !this.state.spaceEnabled}
            autoFocus
          />
          {errors.space && <ErrorLabel>{errors.space}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row align="flex-end">
        <InputWrapper margin='20px 15px'>
          <InputLabel>{'Desde'}</InputLabel>
          <DatePickerStyled
            selected={values.startDate}
            startDate={values.startDate}
            endDate={values.endDate}
            onChange={startDate => setFieldValue('startDate', startDate)}

            // selected={this.state.startDate}
            // startDate={this.state.startDate}
            // endDate={this.state.endDate}
            selectsStart
            showTimeSelect
            dateFormat="HH:mm dd/MM/yyy"
            // onChange={this.handleStartDateChange}
            // minDate={new Date()}
            // maxDate={addMonths(new Date(), 12)}
            placeholderText="Fecha desde"
            width="100%"
            padding="8px 1%"
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ['bottom'] // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false // turn off since needs preventOverflow to be enabled
              }
            }}
          />
          {errors.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper margin={'20px 15px'}>
          <InputLabel>{'Hasta'}</InputLabel>
          <DatePickerStyled
            selected={values.endDate}
            startDate={values.startDate}
            endDate={values.endDate}
            onChange={endDate => setFieldValue('endDate', endDate)}

            // selected={this.state.endDate}
            // onChange={this.handleEndDateChange}
            minDate={this.state.startDate || null}
            // startDate={this.state.startDate}
            // endDate={this.state.endDate}
            selectsEnd
            showTimeSelect
            dateFormat="HH:mm dd/MM/yyy"
            // minDate={new Date()}
            // maxDate={addMonths(new Date(), 12)}
            placeholderText="Fecha hasta"
            width="100%"
            padding="8px 1%"
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ['bottom'] // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false // turn off since needs preventOverflow to be enabled
              }
            }}
          />
          {errors.endDate && <ErrorLabel>{errors.endDate}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper margin={'20px 15px'}>
          {/* <ResetIcon onClick={() => resetForm()} src={TrashIcon} /> */}
          <StyledButton type={'submit'}>
            {this.props.isSubmittingEvent || this.props.isSubmittingArticle ? (
              <CircularProgress size={18} />
            ) : (
                'Filtrar'
              )}
          </StyledButton>
        </InputWrapper>
      </Row>
    </FormContainer>
  );

  render() {
    return (
      <div>

        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getValidationSchema()}
          onSubmit={this.handleExportReport}
          render={e => this.getFormContent(e)}
        />

        {this.props.loadingReporteReserva ? <PageLoader /> :
          <MaterialTable
            columns={[
              { title: 'Usuario', field: 'host_username', },
              { title: 'Fecha', field: 'Date', },
              { title: 'Desde', field: 'from', },
              { title: 'Hasta', field: 'to', },
              { title: 'Precio', field: 'price', },
              { title: 'Actividad', field: 'Activity', },
              { title: 'Espacio', field: 'Space', },
            ]}
            data={this.props.reporteReservas}
            onSelectionChange={(rows) => this.onMovementClick(rows)}
            title=""
            options={{
              toolbar: true,
              paging: true,
              selection: false,
              exportButton: true,
              exportAllData: true,
              exportFileName: 'ReporteReservas.' + (new Date().toISOString().split('.')[0])
            }}
            localization={{
              toolbar: {
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportName: 'Exportar a CSV'
              },
              emptyDataSourceMessage: 'No hay datos para mostrar'
            }}
          />
        }
      </div>
    )
  }

}

const mapStateToProps = ({
  spaces, activities, reportes
}) => ({
  reportes,
  spaces,
  activities,
  formattedActivities: ((activities == null || activities.values == null) ? [] : mapSpaces(activities.values)),
  formattedSpaces: ((spaces == null || spaces.values == null) ? [] : mapSpaces(spaces.values)),
  reporteReservas: ((reportes.reporteReservas == null || reportes.reporteReservas == null) ? [] : mapBookingReport(reportes.reporteReservas)),
  loadingReporteReserva: reportes.loadingReporteReserva,

})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSpaces,
      setSelectedResource,
      resetSelectedResource,
      fetchActivities,
      fetchReporteReservas
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReporteReserva)