import expensas from 'assets/icons/group-3.svg'

const conceptos = [
    { id: '1',codigo: 'PRE', label: 'Prestamo', value: 'Prestamo',src: expensas },
    { id: '1',codigo: 'CUO', label: 'Cuota', value: 'Cuota',src: expensas },
    { id: '1',codigo: 'EXP', label: 'Expensas', value: 'Expensas',src: expensas },
    { id: '1',codigo: 'FAC', label: 'Factura', value: 'Factura',src: expensas },
    { id: '1',codigo: 'SEG', label: 'Seguro', value: 'Seguro',src: expensas },
    { id: '1',codigo: 'HON', label: 'Honorarios', value: 'Honorarios',src: expensas },
    { id: '1',codigo: 'VAR', label: 'Varios', value: 'Varios ',src: expensas }
    ];

export default conceptos