import { createActions } from 'redux-actions'
import getApi from 'shared/api'
import { activitiesAPI, } from 'apiConstants'

const {
  fetchActivitiesStart,
  fetchActivitiesSuccess,
  fetchActivitiesError,
  submitActivityStart,
  submitActivitySuccess,
  submitActivityError,
  editActivityStart,
  editActivitySuccess,
  editActivityError,
  deleteActivityStart,
  deleteActivitySuccess,
  deleteActivityError
} = createActions({
  FETCH_ACTIVITIES_START: () => {},
  FETCH_ACTIVITIES_SUCCESS: data => ({ data }),
  FETCH_ACTIVITIES_ERROR: error => ({ error }),
  SUBMIT_ACTIVITY_START: () => {},
  SUBMIT_ACTIVITY_SUCCESS: data => ({ data }),
  SUBMIT_ACTIVITY_ERROR: error => ({ error }),
  EDIT_ACTIVITY_START: () => {},
  EDIT_ACTIVITY_SUCCESS: data => ({ data }),
  EDIT_ACTIVITY_ERROR:  error => ({ error }),
  DELETE_ACTIVITY_START: () => {},
  DELETE_ACTIVITY_SUCCESS: data => ({ data }),
  DELETE_ACTIVITY_ERROR: error => ({ error })
})

const fetchActivities = () => {
  return async (dispatch, getState) => {
    dispatch(fetchActivitiesStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    try {
      const response = await api.get(activitiesAPI)
      dispatch(fetchActivitiesSuccess({ values: response.data }))
    } catch (error) {
      dispatch(fetchActivitiesError(error))
    }
  }
}

const submitActivity = newActivity => {
  return async (dispatch, getState) => {
    dispatch(submitActivityStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    try {
      const response = await api.post(activitiesAPI, newActivity)
      dispatch(submitActivitySuccess(response.data))
    } catch (error) {
      dispatch(submitActivityError(error))
    }
  }
}


const editActivity = editedActivity => {
  return async (dispatch, getState) => {
    dispatch(editActivityStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    try {
      const response = await api.put(activitiesAPI, editedActivity)
      dispatch(editActivitySuccess(response.data))
    } catch (error) {
      dispatch(editActivityError(error))
    }
  }
}


const deleteActivity = id => {
  return async (dispatch, getState) => {
    dispatch(deleteActivityStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid, true)
    try {
      const response = await api.delete(`${activitiesAPI}/${id}`)
      dispatch(deleteActivitySuccess(response.data))
    } catch (error) {
      dispatch(deleteActivityError(error))
    }
  }
}



export {
  fetchActivities,
  fetchActivitiesStart,
  fetchActivitiesSuccess,
  fetchActivitiesError,
  submitActivityStart,
  submitActivitySuccess,
  submitActivityError,
  submitActivity,
  editActivityStart,
  editActivitySuccess,
  editActivityError,
  editActivity,
  deleteActivityStart,
  deleteActivitySuccess,
  deleteActivityError,
  deleteActivity
}
