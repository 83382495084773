import React, { Component } from 'react'
import {  Container, AvatarItem, TitleQr,  Row,FormContainer,DestinatarioDetailContainer,DestinatarioDetailItemContainer,DestinatarioDetailItem,StyledButton,IconBack,CheckContainer,TyTitle, GridButtonBack, ButtonBack} from './styled';
import LoaderDialog from 'components/loaders/loaderDialog'
import tic from 'assets/icons/tic.svg'
import { Avatar } from '@material-ui/core'
import {getFormattedDate} from 'utils/dateParser'

export default class FourStep extends Component {
    state = {
        selectedOption: true
    }
    
  handleFormSubmit = formSubmitEvent => {
    formSubmitEvent.preventDefault();
  };

  handleOptionChange = changeEvent => {
     this.setState(prevState => ({
      selectedOption:  !prevState.selectedOption
    }))  
  };  

    render() {
        return (
            <Container>
                <GridButtonBack item >
                    <ButtonBack onClick={this.props.onClose} variant="fab">
                        <IconBack  />
                    </ButtonBack>
                    <TyTitle >Nuevo QR de pago</TyTitle>
                </GridButtonBack>
                <FormContainer onSubmit={this.handleFormSubmit}>
                    <CheckContainer>  
                        <DestinatarioDetailItemContainer>
                            <AvatarItem>
                                <Avatar
                                    style={{
                                        width: '25px',
                                        height: '25px',
                                        padding: '20px',
                                        borderColor: '#e5e5e5',
                                        borderStyle: 'solid',
                                        borderWidth: '10px',
                                        backgroundColor: '#1873f3'
                                    }}
                                >
                                    <img src={tic} alt="todo" />
                                </Avatar>
                                <TitleQr size={'18px'}>{'Ya se le envío una notificacíon a '+this.props.transaction.title+' para que realice el pago'}</TitleQr>
                            </AvatarItem>
                        </DestinatarioDetailItemContainer>
                    </CheckContainer>
                    <DestinatarioDetailContainer>
                        {this.props.QR ? 
                            (<Row> 
                                <img height={'100px'} width={'100px'} src={'data:image/png;base64,'+this.props.QR} />
                                <TitleQr>Codigo de operacion</TitleQr>
                                <TitleQr spacing={'5px'} size={'18px'} weight={'600'}>"12345678"</TitleQr>
                            </Row>
                            ):(
                            <Row> 
                                <LoaderDialog/>
                            </Row>
                            )}
                        <Row>
                            <DestinatarioDetailItemContainer>
                                <DestinatarioDetailItem> Destinatario: </DestinatarioDetailItem>
                                <DestinatarioDetailItem bold={true} > {this.props.transaction.title} </DestinatarioDetailItem>
                            </DestinatarioDetailItemContainer> 
                            <DestinatarioDetailItemContainer>
                                <DestinatarioDetailItem> Monto: </DestinatarioDetailItem>
                                <DestinatarioDetailItem bold={true} > {this.props.transaction.amount} </DestinatarioDetailItem>
                            </DestinatarioDetailItemContainer>
                            <DestinatarioDetailItemContainer>
                                <DestinatarioDetailItem> Fecha y hora: </DestinatarioDetailItem>
                                <DestinatarioDetailItem bold={true} > {getFormattedDate(this.props.transaction.date)} </DestinatarioDetailItem>
                            </DestinatarioDetailItemContainer>
                            <DestinatarioDetailItemContainer>
                                <DestinatarioDetailItem> Cuenta de origen: </DestinatarioDetailItem>
                                <DestinatarioDetailItem bold={true} > {this.props.transaction.origin} </DestinatarioDetailItem>
                            </DestinatarioDetailItemContainer>
                        </Row>
                    </DestinatarioDetailContainer>
                    <StyledButton onClick={() => this.props.onClose()} >Volver a la Billetera</StyledButton>
            </FormContainer>
        </Container>
        )
    }
}