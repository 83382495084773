import { createSelector } from 'reselect'
var moment = require('moment');

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapReportTickets = createSelector(  
  tickets => tickets,
  tickets => 
  (tickets.map(ticket => ({
    categoryid : ticket.categoryid,
    categoryname : ticket.categoryname,
    typeid : ticket.typeid,
    stateid : ticket.stateid,
    assignedto : ticket.assignedto,
    neighbourhoodguid : ticket.neighbourhoodguid,
    createdby : ticket.createdby,
    createdbyname : ticket.createdbyname,
    closingcomment : ticket.closingcomment,
    title : ticket.title,
    openingdate : moment(ticket.openingdate).format('YYYY-MM-DD'),
    closingdate : ticket.stateid == 3 ? moment(ticket.closingdate).format('YYYY-MM-DD') : '',
    createdbydni : ticket.createdbydni,
    lot : ticket.lot,
    active : ticket.active,
    activedescription : ticket.active ? 'Si' : 'No',
    typedescription : ticket.typedescription,
    statedescription : ticket.statedescription
  })) )
)
export default mapReportTickets
