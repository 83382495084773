import { createSelector } from 'reselect'

const mapProprietary = createSelector(
  proprietaryList => proprietaryList,
  proprietaryList =>
    proprietaryList.map(
      ({ id, name, surname,dni, username, property_code, phone_number, email,neighbourhoods_name, picture_url,cuil }, i) => ({
        pos: i,
        id,
        title: `${name} ${surname}`,
        subtitle: `Lote: ${property_code}`,
        extraInfo: username,
        avatar: picture_url,
        withAvatar: true,
        username,
        name,
        surname,
        neighbourhoods_name,
        property_code,
        dni,
        phone_number,
        email,
        picture_url,
        cuil
      })
    )
)

export default mapProprietary
