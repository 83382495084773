import { Formik } from 'formik'
import React from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Page from 'components/Page'
import Section from 'components/Section'
import StyledTabs from 'components/StyledTabs'
import SearchBar from 'components/SearchBar'
import ResourceList from 'components/ResourceList'
import EmptyLoader from 'components/PageLoader'
import styled from 'styled-components'
import compose from 'recompose/compose'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import themeStyles from './tickets.theme.style'
import mapCategoriesByRole from 'selectors/mapCategoriesByRole'
import mapTicket from 'selectors/mapTicket'
import { Avatar, Grid } from '@material-ui/core'
import StyledInput from 'components/Input'
import {getDayHourDate} from 'utils/dateParser'
import ModalChangeState from './ModalChangeState'
import ModalChangeCategory from './ModalChangeCategory'
import { 
  fetchTickets,
  pendingTicket,
  changeCategoryTicket, 
  fetchTicketMessages, 
  fetchCategoriesByRole, 
  sendTicketMessage, 
  closeTicket, 
  setSelectedTicket, 
  searchTickets,
  resetSelectedTicket,
  fetchAllCategories
} from 'store/actions/tickets.actions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 600px;
`
const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 600px;
`
const ListWrapper = styled.div`
  width: 35%;
  background-color: ${({ theme }) => theme.colors.ghost};
  padding-right: 13px;
  max-height: 600px;
  overflow-y: scroll;
  border-bottom-left-radius: 10px;
  border-right: 1px solid ${({ theme }) => theme.colors.ghost};
`
const PageWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  align-items: normal;
  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`
const ContainerSelects = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`
const SelectStyled = styled(Select)`
  width: 50%;
  margin: 0px 10px;
`
const ContainerSearchBar = styled.div`
  width: 100%;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.alto};
`
const SearchWrapper = styled.div`
  max-height: auto;
  display: flex;
  flex-direction: column;
  border: none;
  margin-right: 1px;
  border-radius: 10px;
`
const DataContainer = styled.div`
  width: 65%;
  padding: 20px 25px;
  border-bottom-right-radius: 10px;
`
const Row = styled.div`
  width: ${({ widthAuto }) => (widthAuto ? 'auto' : '100%')};
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'center'};
`
const Name = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 400;
  margin: 2% 0%;
`
const HourText = styled.div`
 font-size: 12px;
 color: ${({ theme }) => theme.colors.blueRibbon};
 text-align: right;
`
const TextContainer = styled.div`
  width: ${({ sentbyuser }) => sentbyuser ? "300px" : "370px" };
  height: auto;
  padding: 1% 2%;
  border-radius: 5px;
  background-color: ${({ sentbyuser }) => sentbyuser ? "#ddeafd" : "#ececec" }; 
  margin-left: ${({ sentbyuser }) => sentbyuser ? "" : "42%" };   
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`
const ContainerMessage = styled.div`
  height: 440px;
  overflow: overlay;
`
const ImageMessage = styled.img`
  height: 150px;
  width: 60%;
  align-self: ${({ sentbyuser }) => sentbyuser ? "flex-start" : "flex-end" };
`
const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 15px;
  font-weight: 500;
  padding: 0.8% 5% 0.8% 5%;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  text-align: center;
  flex-grow: 1;
  line-height: 25px;
  max-width: 32%;
`

class Tickets extends React.Component {
  state = {
    categoriesList:[],
    filter:'',
    msjData: '',
    ticket: {},
    categoryId: { value:0, label:'Todas'},
    sendText:'',
    tabSelected: 0,
    isModalState: false,
    isModalCategory: false,
  }
  
  getInitialValues = () => ({
    filter:''
  })

  componentDidMount = () => {
    if (this.props.tickets.ticketsList.length === 0) {
      this.fetchContent()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.tickets.ticketsList.length === 0 && prevState.filter.length !== 0 && this.state.filter.length === 0) {
      this.fetchContent()
    }
  }

  fetchContent = reset => {
    this.props.fetchTickets(0)
    this.props.fetchCategoriesByRole()
    this.props.fetchAllCategories()
  }

  isLoadingContent = () => false

  handleTicketClick = ticket =>{
    this.props.setSelectedTicket(ticket)
    this.setState({ ticket: ticket})
    this.setState({ sendText: '' })
    this.props.fetchTicketMessages(ticket.id)
  } 

  handleCategory = (e) => {
    this.props.fetchTickets(e.value)
    this.setState({selectValue: e})
  }
  handleInputOnChange = (value)=>{ 
    this.setState({ filter: value })
    this.props.searchTickets(value)
  }
  
  handleInputClear = (e)=>{ 
    this.setState({ filter: '' })
  }

  toggleModalState=(stateToChange)=>{ 
    this.setState({ isModalState: stateToChange })
  }
  
  toggleModalCategory=()=>{ 
    this.setState({ isModalCategory: !this.state.isModalCategory })
  }
  
  handleKeyPress = (e)=>{ 
    if (e.keyCode === 13 && e.target.value !== '') {
      var filterList = this.props.ticketsList.filter(item=>{ return item.dni == this.state.filter || item.name.includes(this.state.filter) || item.lote.includes(this.state.filter)})
    }
  }

  handleSend = ()=>{ 
    const formattedBody={
      ticket_id: this.state.ticket.id,
      text: this.state.sendText
    }
    this.props.sendTicketMessage(formattedBody)
  }
  
  handleClose = (MessageClose)=>{ 
    this.props.closeTicket(this.state.ticket.id,MessageClose)
  }

  handleMessageOnChange = (e)=>{ 
    this.setState({ sendText: e.target.value })
  }
  
  handleTabChange = (event, value) => {
    this.setState({ tabSelected: value })
  }

  handlePending = (MessageClose) => {
    const formattedBody = {
      state_id: 2,
      changestate_comment: MessageClose
    }
    this.props.pendingTicket(this.state.ticket.id,formattedBody)
  }

  handleTransfer = (categoryTransfer) => {
    const formattedBody = {
      category_id : categoryTransfer,
    }
    this.props.changeCategoryTicket(this.state.ticket.id,formattedBody)
  }

  renderMessagesContent = () => (
    <div>
      <Row justify={'space-between'} align={'center'}>
        <Grid xs={1}>
          <Avatar style={{ background: '#1873f3', width: '30px', height: '30px', padding: '10px' }} />
        </Grid>
        <Grid xs={10}>
          <Row justify={'space-between'}>
              <label>{this.state.ticket.name}</label>
          </Row>
          <Row> 
            <label>{this.state.ticket.detail}</label>
          </Row>
          <Row> 
            <label>Titulo: {this.state.ticket.titleMessage}</label>
          </Row>
        </Grid>
      </Row>
      <ContainerMessage>
      { this.props.messagesRaw.map((e) => (
        <>
          {e.sentbyuser ? (
            <TextContainer sentbyuser={true}>
              <>
                {e.picture_url ? (
                  <ImageMessage sentbyuser={true} src={e.picture_url}/>
                ) : (<></>)}
                <Name>{e.text}</Name>
              </>
              <HourText>{getDayHourDate(e.date)}</HourText>
            </TextContainer>            
            ) : (
            <TextContainer sentbyuser={false}>
              <>
              {e.picture_url ? (
                <ImageMessage sentbyuser={false} src={e.picture_url}/>
              ) : (<></>)}
              <Name>{e.text}</Name>
              </>
              <HourText>{getDayHourDate(e.date)}</HourText>
          </TextContainer>
          )
          }
        </>
    ))}
    </ContainerMessage>
    {
      this.props.userInfo.menusAuth.map(menu=>{ 
        if(menu.code === "TICKETS_ADMIN_BO"){
          return(
        <>
          <Row justify={'space-between'} align={'center'} style={{width:'65%',margin: "4% 1%"}}>
            <StyledButton onClick={this.toggleModalCategory}>{'Transferir'}</StyledButton>
            {this.state.tabSelected === 1 ? "" : <StyledButton onClick={()=> this.toggleModalState("pending")}>{'Pendiente'}</StyledButton>}   {this.state.tabSelected === 2 ? "" : <StyledButton onClick={()=> this.toggleModalState("close")} >{'Terminado'}</StyledButton>} 
          </Row>
          <Row justify={'space-between'} align={'center'}>
            <StyledInput value={this.state.sendText} onChange={(e)=>this.handleMessageOnChange(e) } style={{width:'80%'}}></StyledInput>
            <StyledButton onClick={this.handleSend} >{'Enviar'}</StyledButton>
          </Row>
        </>)
        }
      })
  }
  </div>
  )

  renderPageContent = ({ handleSubmit, values, setFieldValue, errors }) => (
   
        <ContentWrapper>
          <ListWrapper id={'scrollContainer'} className={classNames('portal-hide-scrollbars')}>
            <ResourceList
              elements={this.props.ticketsList[this.state.tabSelected]}
              onClick={this.handleTicketClick}
              selectedPos={this.props.selectedTicket ? this.props.selectedTicket.pos : null}
              noMoreContentMsg={'No hay mas tickets disponibles'}
            />
            {console.log("xxx", this.props.ticketsList)}
          </ListWrapper>
          <DataContainer>
          {this.props.messagesRaw.length > 0 ? this.renderMessagesContent():''}
          </DataContainer>
        </ContentWrapper>
  )

  render() {
    return (
      <Page>
        <PageWrapper>
          <Section title={'Bandeja de tickets'}>
            <Wrapper>
              <StyledTabs
                value={this.state.tabSelected}
                tabs={[
                  { label: 'Nuevos', value: this.props.ticketsList[0].length,id : 0 },
                  { label: 'Pendiente', value: this.props.ticketsList[1].length,id : 1 },
                  { label: 'Resueltos', value: this.props.ticketsList[2].length,id : 2 }
                ]}
                handleTabChange={this.handleTabChange}
              />
            <SearchWrapper>
              <ContainerSearchBar>
                <SearchBar
                  placeholder={'Busca por nombre, DNI o numero de lote'}
                  style={'width: \'70%\' '}
                  value={this.state.filter}
                  onChange={(e)=>  this.handleInputOnChange(e) }
                  onClear={this.handleInputClear}
                  onKeyDown={this.handleKeyPress}
                  noBorder={true}
                  autofocus
                />
                <ContainerSelects>
                  <SelectStyled
                    value={this.state.selectValue}
                    onChange={this.handleCategory}
                    placeholder={'Todas las categorias'}
                    options={this.props.categoriesList}
                  />
                </ContainerSelects>
              </ContainerSearchBar>
              { this.state.isModalState === "close" ? 
                  <ModalChangeState 
                    onBtnClick={this.handleClose}
                    onLoading={this.props.tickets.loadingFetchTickets}
                    onError={this.props.tickets.closeTicketFail}
                    onSuccess={this.props.tickets.closeTicketSuccess}
                    onBtnClose={this.toggleModalState}
                  /> 
                : this.state.isModalState === "pending" ? 
                  <ModalChangeState 
                    onBtnClick={this.handlePending}
                    onLoading={this.props.tickets.loadingPendingTicket}
                    onError={this.props.tickets.pendingTicketFail}
                    onSuccess={this.props.tickets.pendingTicketSuccess}
                    onBtnClose={this.toggleModalState}
                  /> : ""
              }
              {this.state.isModalCategory && 
                <ModalChangeCategory 
                  onBtnClick={this.handleTransfer}
                  onLoading={this.props.tickets.loadingPendingTicket}
                  onError={this.props.tickets.changeCategoryTicketFail}
                  onSuccess={this.props.tickets.changeCategoryTicketSuccess}
                  onBtnClose={this.toggleModalCategory}
                  actualCategory={this.state.ticket.categoryid}
                  categoryList={this.props.allCategoriesList}
                /> 
              }
              {this.props.loading ? (
                <EmptyLoader />
              ) : (
                  <Formik
                    initialValues={this.getInitialValues()}
                    render={e =>this.renderPageContent(e)}
                  />
              )}
              </SearchWrapper>
            </Wrapper>
          </Section>
        </PageWrapper>
      </Page>
    )
  }
}

const mapStateToProps = ({
  tickets,
  userInfo,
  tickets:{
    ticketsList,
    categoriesList,
    ticketMessagesList,
    allCategoriesList
  },
  selectedTicket
}) => ({
  tickets, 
  categoriesRaw: categoriesList,
  categoriesList: mapCategoriesByRole(categoriesList),
  allCategoriesList: mapCategoriesByRole(allCategoriesList),
  ticketsList: mapTicket(ticketsList),
  messagesRaw: ticketMessagesList.length > 0 ? ticketMessagesList.reverse() : "",
  selectedTicket,
  loading: tickets.loadingFetchTickets,
  userRoles: userInfo.menusAuth,
  userInfo,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTickets,
      fetchTicketMessages,
      fetchCategoriesByRole,
      fetchAllCategories,
      sendTicketMessage,
      setSelectedTicket,
      resetSelectedTicket,
      closeTicket,
      pendingTicket,
      changeCategoryTicket,
      searchTickets,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth(),
    withStyles(themeStyles, { withTheme: true })
  )(Tickets)
)
