import { createSelector } from 'reselect'

const mapHistorial = createSelector(
  historialList => historialList,
  historialList =>
  historialList.map((val, i) => ({
      pos: i,
      date: val.creationdate,
      comment: val.detail,
    }))
)
export default mapHistorial
