import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  loadingproducts: false,
  fetchGetProductsFail: false,
  fetchGetProductsSuccess: false,
  products: [],

  loadingDetailProduct: false,
  fetchDetailProductStart: false,
  fetchDetailProductSuccess: false,
  fetchDetailProductFail: false,
  detailProduct: [],

  loadingDeleteProduct: false,
  fetchDeleteProductStart: false,
  fetchDeleteProductSuccess: false,
  fetchDeleteProductFail: false,

  loadingAddProduct: false,
  fetchAddProductStart: false,
  fetchAddProductSuccess: false,
  fetchAddProductFail: false,


  loadingCategories: false,
  fetchCategoriesStart: false,
  fetchCategoriesSuccess: false,
  fetchCategoriesFail: false,
  categorias: [],

  loadingPayment: false,
  fetchPaymentStart: false,
  fetchPaymentSuccess: false,
  fetchPaymentFail: false,
  payment: [],

  loadingItemConditions: false,
  fetchItemConditionsStart: false,
  fetchItemConditionsSuccess: false,
  fetchItemConditionsFail: false,
  itemConditions: [],


  loadingShippingMethods: false,
  fetchShippingMethodsStart: false,
  fetchShippingMethodsSuccess: false,
  fetchShippingMethodsFail: false,
  shipping: [],


  loadingEditProduct: false,
  fetchEditProductStart: false,
  fetchEditProductSuccess: false,
  fetchEditProductFail: false,

  loadingDespachos: false,
  fetchDespachosStart: false,
  fetchDespachosFail: false,
  despachos: [],

  //conversasiones
  loadingConversations: false,
  fetchConversationsStart: false,
  fetchConversationsFail: false,
  fetchConversationsSuccess: false,
  conversations: [],

  //sendMsg
  loadingSendMsg: false,
  sendMsgStart: false,
  sendMsgFail: false,
  sendMsgSuccess: false,
  messageSent: [],

  //getMsg
  loadingGetMsg: false,
  getMsgStart: false,
  getMsgFail: false,
  getMsgSuccess: false,
  messages: [],

  //startConver
  loadingNewConversation: false,
  newConversationStart: false,
  newConversationFail: false,
  newConversationSuccess: false,
  newConversation: [],

  loadingVentas: false,
  fetchVentasStart: false,
  fetchVentasFail: false,
  fetchVentasSuccess: false,
  ventas: [],

  loadingGetReadyToDeliver: false,
  fetchGetReadyToDeliverStart: false,
  fetchGetReadyToDeliverFail: false,
  fetchGetReadyToDeliverSuccess: false,
  orderReadyToDeliver: [],

  deleteProduct: [],

  orderQr: [],
  fetchOrderQRFail: false,
  fetchOrderQRStart: false,
  fetchOrderQRSuccess: false,
  fetchOrderQRReset: false,
  loadingOrderQR: false,

  loadingUploadProductsFile: false,
  errorUploadProductsFile: false,
  errorMessage: '',
  resultUploadProductsFile: [],

  resultDisclaimerMetodoPago: false
}

const fetchGetProductsStart = state => {
  return updateObject(state, {
    loadingProducts: true,
    fetchGetProductsSuccess: false,
    fetchGetProductsFail: false,
    deleteProduct: [],
    detailProduct: {},
  })
}

const fetchGetProductsSuccess = (state, action) => updateObject(state, {
  loadingProducts: false,
  fetchGetProductsSuccess: true,
  fetchGetProductsFail: false,
  products: action.products
})

const fetchGetProductsFail = (state, action) => {
  return updateObject(state, {
    loadingProducts: false,
    fetchGetProductsSuccess: false,
    fetchGetProductsFail: action.error
  })
}

const fetchDetailProductStart = state => {
  return updateObject(state, {
    loadingDetailProduct: true,
    fetchDetailProductSuccess: false,
    fetchDetailProductFail: false
  })
}

const fetchDetailProductSuccess = (state, action) =>
  updateObject(state, {
    loadingDetailProduct: false,
    fetchDetailProductSuccess: true,
    fetchDetailProductFail: false,
    detailProduct: action.productDetail
  })

const fetchDetailProductFail = (state, action) => {
  return updateObject(state, {
    loadingDeleteProduct: false,
    fetchDetailProductSuccess: false,
    fetchDetailProductFail: action.error
  })
}


/// fetchs delete

const fetchDeleteProductStart = state => {
  return updateObject(state, {
    loadingDeleteProduct: true,
    fetchDeleteProductSuccess: false,
    fetchDeleteProductFail: false,
    deleteProduct: [],
    detailProduct: [],
  })
}

const fetchDeleteProductSuccess = (state, action) =>
  updateObject(state, {
    loadingDeleteProduct: false,
    fetchDeleteProductSuccess: true,
    fetchDeleteProductFail: false,
    deleteProduct: action.productDelete
  })


const fetchDeleteProductReset = (state, action) =>
  updateObject(state, {
    loadingDeleteProduct: false,
    fetchDeleteProductSuccess: false,
    fetchDeleteProductFail: false,
  })

const fetchDeleteProductFail = (state, action) => {
  return updateObject(state, {
    loadingDeleteProduct: false,
    fetchDeleteProductSuccess: false,
    fetchDeleteProductFail: action.error
  })
}




/// fetchs add

const fetchAddProductStart = state => {
  return updateObject(state, {
    loadingAddProduct: true,
    fetchAddProductSuccess: false,
    fetchAddProductFail: false,
    AddProduct: [],
    detailProduct: [],
  })
}

const fetchAddProductSuccess = (state, action) =>
  updateObject(state, {
    loadingAddProduct: false,
    fetchAddProductSuccess: true,
    fetchAddProductFail: false,
    AddProduct: action.product
  })


const fetchAddProductReset = (state, action) =>
  updateObject(state, {
    loadingAddProduct: false,
    fetchAddProductSuccess: false,
    fetchAddProductFail: false,
  })

const fetchAddProductFail = (state, action) => {
  return updateObject(state, {
    loadingAddProduct: false,
    fetchAddProductSuccess: false,
    fetchAddProductFail: true,
    error: action.error
  })
}




/// fetchs add

const fetchEditProductStart = state => {
  return updateObject(state, {
    loadingEditProduct: true, fetchEditProductSuccess: false,
    fetchEditProductFail: false
  })
}

const fetchEditProductSuccess = (state, action) =>
  updateObject(state, {
    loadingEditProduct: false, fetchEditProductSuccess: true,
    fetchEditProductFail: false, EditProduct: action.productDelete
  })

const fetchEditProductReset = (state, action) =>
  updateObject(state, {
    loadingEditProduct: false, fetchEditProductSuccess: false, fetchEditProductFail: false,
  })

const fetchEditProductFail = (state, action) => {
  return updateObject(state, {
    loadingEditProduct: false,
    fetchEditProductSuccess: false,
    fetchEditProductFail: action.error
  })
}


/// fetchs categorias
const fetchCategoriesStart = state => {
  return updateObject(state, {
    loadingCategories: true,
    fetchCategoriesSuccess: false,
    fetchCategoriesFail: false,
  })
}

const fetchCategoriesSuccess = (state, action) =>
  updateObject(state, {
    loadingCategories: false,
    fetchCategoriesSuccess: true,
    fetchCategoriesFail: false,
    categorias: action.categorias.values
  })

const fetchCategoriesReset = (state, action) =>
  updateObject(state, {
    loadingCategories: false, fetchCategoriesSuccess: false, fetchCategoriesFail: false,
  })

const fetchCategoriesFail = (state, action) => {
  return updateObject(state, {
    loadingCategories: false,
    fetchCategoriesSuccess: false,
    fetchCategoriesFail: action.error
  })
}




/// fetchs metodos pago

const fetchPaymentStart = state => {
  return updateObject(state, {
    loadingPayment: true,
    fetchPaymentSuccess: false,
    fetchPaymentFail: false
  })
}

const fetchPaymentSuccess = (state, action) =>
  updateObject(state, {
    loadingPayment: false, fetchPaymentSuccess: true,
    fetchPaymentFail: false,
    payment: action.payment
  })

const fetchPaymentReset = (state, action) =>
  updateObject(state, {
    loadingPayment: false, fetchPaymentSuccess: false, fetchPaymentFail: false,
  })

const fetchPaymentFail = (state, action) => {
  return updateObject(state, {
    loadingPayment: false,
    fetchPaymentSuccess: false,
    fetchPaymentFail: action.error
  })
}



/// fetchs ebnvios shipp

const fetchShippingMethodsStart = state => {
  return updateObject(state, {
    loadingShippingMethods: true, fetchShippingMethodsSuccess: false,
    fetchShippingMethodsFail: false,
  })
}

const fetchShippingMethodsSuccess = (state, action) =>
  updateObject(state, {
    loadingShippingMethods: false, fetchShippingMethodsSuccess: true,
    fetchShippingMethodsFail: false,
    shipping: action.shipping.values
  })

const fetchShippingMethodsReset = (state, action) =>
  updateObject(state, {
    loadingShippingMethods: false, fetchShippingMethodsSuccess: false, fetchShippingMethodsFail: false,
  })

const fetchShippingMethodsFail = (state, action) => {
  return updateObject(state, {
    loadingShippingMethods: false,
    fetchShippingMethodsSuccess: false,
    fetchShippingMethodsFail: action.error
  })
}


/// fetchs condiciones uso

const fetchItemConditionsStart = state => {
  return updateObject(state, {
    loadingItemConditions: true, fetchItemConditionsSuccess: false,
    fetchItemConditionsFail: false
  })
}

const fetchItemConditionsSuccess = (state, action) =>
  updateObject(state, {
    loadingItemConditions: false, fetchItemConditionsSuccess: true,
    fetchItemConditionsFail: false,
    itemConditions: action.itemConditions
  })

const fetchItemConditionsReset = (state, action) =>
  updateObject(state, {
    loadingItemConditions: false, fetchItemConditionsSuccess: false, fetchItemConditionsFail: false,
  })

const fetchItemConditionsFail = (state, action) => {
  return updateObject(state, {
    loadingItemConditions: false,
    fetchItemConditionsSuccess: false,
    fetchItemConditionsFail: action.error
  })
}

/// fetchs DEPACHOS

const fetchDespachosStart = state => {
  return updateObject(state, {
    loadingDespachos: true, fetchDespachosSuccess: false,
    fetchDespachosFail: false
  })
}

const fetchDespachosSuccess = (state, action) =>
  updateObject(state, {
    loadingDespachos: false, fetchDespachosSuccess: true,
    fetchDespachosFail: false,
    despachos: action.despachos
  })

const fetchDespachosReset = (state, action) =>
  updateObject(state, {
    loadingDespachos: false, fetchDespachosSuccess: false, fetchDespachosFail: false,
  })

const fetchDespachosFail = (state, action) => {
  return updateObject(state, {
    loadingDespachos: false,
    fetchDespachosSuccess: false,
    fetchDespachosFail: action.error
  })
}

/// fetchs Ventas

const fetchVentasStart = state => {
  return updateObject(state, {
    loadingVentas: true,
    fetchVentasSuccess: false,
    fetchVentasFail: false
  })
}

const fetchVentasSuccess = (state, action) =>
  updateObject(state, {
    loadingVentas: false,
    fetchVentasSuccess: true,
    fetchVentasFail: false,
    ventas: action.ventas
  })

const fetchVentasReset = (state, action) =>
  updateObject(state, {
    loadingVentas: false,
    fetchVentasSuccess: false,
    fetchVentasFail: false,
  })

const fetchVentasFail = (state, action) => {
  return updateObject(state, {
    loadingVentas: false,
    fetchVentasSuccess: false,
    fetchVentasFail: action.error
  })
}

/// fetchs Ventas

const fetchOrderQRStart = state => {
  return updateObject(state, {
    loadingOrderQR: true,
    fetchOrderQRSuccess: false,
    fetchOrderQRFail: false
  })
}

const fetchOrderQRSuccess = (state, action) =>
  updateObject(state, {
    loadingOrderQR: false,
    fetchOrderQRSuccess: true,
    fetchOrderQRFail: false,
    orderQr: action.orderQr
  })

const fetchOrderQRReset = (state, action) =>
  updateObject(state, {
    loadingOrderQR: false,
    fetchOrderQRSuccess: false,
    fetchOrderQRFail: false,
  })

const fetchOrderQRFail = (state, action) => {
  return updateObject(state, {
    loadingOrderQR: false,
    fetchOrderQRSuccess: false,
    fetchOrderQRFail: action.error
  })
}


/// fetchs Ready to deliver

const fetchGetReadyToDeliverStart = state => {
  return updateObject(state, {
    loadingGetReadyToDeliver: true,
    fetchGetReadyToDeliverSuccess: false,
    fetchGetReadyToDeliverFail: false
  })
}

const fetchGetReadyToDeliverSuccess = (state, action) =>
  updateObject(state, {
    loadingGetReadyToDeliver: false,
    fetchGetReadyToDeliverSuccess: true,
    fetchGetReadyToDeliverFail: false,
    orderReadyToDeliver: action.orderReadyToDeliver
  })

const fetchGetReadyToDeliverReset = (state, action) =>
  updateObject(state, {
    loadingGetReadyToDeliver: false,
    fetchGetReadyToDeliverSuccess: false,
    fetchGetReadyToDeliverFail: false,
  })

const fetchGetReadyToDeliverFail = (state, action) => {
  return updateObject(state, {
    loadingGetReadyToDeliver: false,
    fetchGetReadyToDeliverSuccess: false,
    fetchGetReadyToDeliverFail: action.error
  })
}


/// fetchs condiciones uso

const fetchConversationsStart = state => {
  return updateObject(state, {
    loadingConversations: true,
    fetchConversationsSuccess: false,
    fetchConversationsFail: false,
    fetchConversationsStart: true
  })
}

const fetchConversationsSuccess = (state, action) =>
  updateObject(state, {
    loadingConversations: false,
    fetchConversationsSuccess: true,
    fetchConversationsFail: false,
    fetchConversationsStart: false,
    conversations: action.data
  })

const fetchConversationsReset = (state, action) =>
  updateObject(state, {
    loadingConversations: false,
    fetchConversationsSuccess: false,
    fetchConversationsStart: false,
    fetchConversationsFail: false,
  })

const fetchConversationsFail = (state, action) => {
  return updateObject(state, {
    loadingConversations: false,
    fetchConversationsSuccess: false,
    fetchConversationsStart: false,
    fetchConversationsFail: action.error

  })
}

///////////////////////---

const newConversationStart = (state, action) =>
  updateObject(state, {
    loadingNewConversation: true,
    newConversationSuccess: false,
    newConversationStart: true,
    newConversationFail: false,
  })

const newConversationSuccess = (state, action) =>
  updateObject(state, {
    loadingNewConversation: false,
    newConversationSuccess: true,
    newConversationFail: false,
    newConversationStart: false,
    newConversation: action
  })

const newConversationFail = (state, action) => {
  return updateObject(state, {
    loadingNewConversation: false,
    newConversationSuccess: false,
    newConversationStart: false,
    sendMsgStart: false,
    newConversationFail: action.error
  })
}

const newConversationReset = (state, action) =>
  updateObject(state, {
    //startConver
    loadingNewConversation: false,
    newConversationStart: false,
    newConversationFail: false,
    newConversationSuccess: false,
    newConversation: [],
  })

///////////////////////---

const sendMsgStart = (state, action) =>
  updateObject(state, {
    loadingSendMsg: true,
    messages: { data: null },
    sendMsgSuccess: false,
    sendMsgFail: false,
    sendMsgStart: true,
  })

const sendMsgSuccess = (state, action) =>
  updateObject(state, {
    loadingSendMsg: false,
    sendMsgSuccess: true,
    sendMsgFail: false,
    messageSent: action,
    sendMsgStart: false,
  })

const sendMsgFail = (state, action) => {
  return updateObject(state, {
    loadingSendMsg: false,
    sendMsgSuccess: false,
    sendMsgFail: action.error,
    sendMsgStart: false,
  })
}

const sendMsgReset = (state, action) =>
  updateObject(state, {
    loadingSendMsg: false,
    sendMsgSuccess: false,
    sendMsgFail: false,
    sendMsgStart: false,
  })

///////////////////////---

const getMsgStart = (state, action) =>
  updateObject(state, {
    loadingGetMsg: true,
    getMsgSuccess: false,
    getMsgFail: false,
    getMsgStart: true,
    messages: { data: null }
  })

const getMsgSuccess = (state, action) =>
  updateObject(state, {
    loadingGetMsg: false,
    sendMsgSuccess: true,
    getMsgSuccess: true,
    getMsgFail: false,
    getMsgStart: false,
    messages: action
  })

const getMsgFail = (state, action) => {
  return updateObject(state, {
    loadingGetMsg: false,
    getMsgSuccess: false,
    getMsgStart: false,
    getMsgFail: action.error
  })
}

const getMsgReset = (state) =>
  updateObject(state, {
    loadingGetMsg: false,
    sendMsgSuccess: false,
    getMsgFail: false,
    getMsgStart: false,
  })

//---------------  Post File Upload  ------------------------
const fetchUploadProductsFileSuccess = (state, action) => {
  return updateObject(state, {
    resultUploadProductsFile: action.data,
    loadingUploadProductsFile: false,
    errorUploadProductsFile: false,
  })
}

const fetchUploadProductsFileFail = (state, action) => {
  return updateObject(state, { loadingUploadProductsFile: false, errorUploadProductsFile: true, errorMessage: action.error })
}

const fetchUploadProductsFileStart = state => {
  return updateObject(state, { resultUploadProductsFile: [], loadingUploadProductsFile: true, errorUploadProductsFile: false })
}

const fetchUploadProductsFileReset = state => {
  return updateObject(state, {
    resultUploadProductsFile: [],
    loadingUploadProductsFile: false,
    errorUploadProductsFile: false,
  })
}


//---------------  Disclaimer metodo de pago   ------------------------
const fetchDisclaimerMetodoPagoSuccess = (state, action) => {
  return updateObject(state, {
    resultDisclaimerMetodoPago: action.data,
    loadingDisclaimerMetodoPago: false,
    errorDisclaimerMetodoPago: false,
  })
}

const fetchDisclaimerMetodoPagoFail = state => {
  return updateObject(state, { loadingDisclaimerMetodoPago: false, errorDisclaimerMetodoPago: true })
}

const fetchDisclaimerMetodoPagoStart = state => {
  return updateObject(state, { resultDisclaimerMetodoPago: [], loadingDisclaimerMetodoPago: true, errorDisclaimerMetodoPago: false })
}

const fetchDisclaimerMetodoPagoReset = state => {
  return updateObject(state, {
    resultDisclaimerMetodoPago: [],
    loadingDisclaimerMetodoPago: false,
    errorDisclaimerMetodoPago: false,
  })
}



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GET_PRODUCTS_SUCCESS:
      return fetchGetProductsSuccess(state, action)
    case actionTypes.FETCH_GET_PRODUCTS_FAIL:
      return fetchGetProductsFail(state, action)
    case actionTypes.FETCH_GET_PRODUCTS_START:
      return fetchGetProductsStart(state, action)
    case actionTypes.FETCH_DETAIL_PRODUCT_SUCCESS:
      return fetchDetailProductSuccess(state, action)
    case actionTypes.FETCH_DETAIL_PRODUCT_FAIL:
      return fetchDetailProductFail(state, action)
    case actionTypes.FETCH_DETAIL_PRODUCT_START:
      return fetchDetailProductStart(state, action)
    case actionTypes.FETCH_DELETE_PRODUCT_SUCCESS:
      return fetchDeleteProductSuccess(state, action)
    case actionTypes.FETCH_DELETE_PRODUCT_FAIL:
      return fetchDeleteProductFail(state, action)
    case actionTypes.FETCH_DELETE_PRODUCT_START:
      return fetchDeleteProductStart(state, action)
    case actionTypes.FETCH_DELETE_PRODUCT_RESET:
      return fetchDeleteProductReset(state, action)
    case actionTypes.FETCH_EDIT_PRODUCT_SUCCESS:
      return fetchEditProductSuccess(state, action)
    case actionTypes.FETCH_EDIT_PRODUCT_FAIL:
      return fetchEditProductFail(state, action)
    case actionTypes.FETCH_EDIT_PRODUCT_START:
      return fetchEditProductStart(state, action)
    case actionTypes.FETCH_EDIT_PRODUCT_RESET:
      return fetchEditProductReset(state, action)
    case actionTypes.FETCH_GET_CATEGORIES_SUCCESS:
      return fetchCategoriesSuccess(state, action)
    case actionTypes.FETCH_GET_CATEGORIES_FAIL:
      return fetchCategoriesFail(state, action)
    case actionTypes.FETCH_GET_CATEGORIES_START:
      return fetchCategoriesStart(state, action)
    case actionTypes.FETCH_GET_CATEGORIES_RESET:
      return fetchCategoriesReset(state, action)
    case actionTypes.FETCH_GET_PAYMENT_SUCCESS:
      return fetchPaymentSuccess(state, action)
    case actionTypes.FETCH_GET_PAYMENT_FAIL:
      return fetchPaymentFail(state, action)
    case actionTypes.FETCH_GET_PAYMENT_START:
      return fetchPaymentStart(state, action)
    case actionTypes.FETCH_GET_PAYMENT_RESET:
      return fetchPaymentReset(state, action)
    case actionTypes.FETCH_GET_SHIPPING_SUCCESS:
      return fetchShippingMethodsSuccess(state, action)
    case actionTypes.FETCH_GET_SHIPPING_FAIL:
      return fetchShippingMethodsFail(state, action)
    case actionTypes.FETCH_GET_SHIPPING_START:
      return fetchShippingMethodsStart(state, action)
    case actionTypes.FETCH_GET_SHIPPING_RESET:
      return fetchShippingMethodsReset(state, action)
    case actionTypes.FETCH_GET_CONDITIONS_SUCCESS:
      return fetchItemConditionsSuccess(state, action)
    case actionTypes.FETCH_GET_CONDITIONS_FAIL:
      return fetchItemConditionsFail(state, action)
    case actionTypes.FETCH_GET_CONDITIONS_START:
      return fetchItemConditionsStart(state, action)
    case actionTypes.FETCH_GET_CONDITIONS_RESET:
      return fetchItemConditionsReset(state, action)
    case actionTypes.FETCH_ADD_PRODUCT_SUCCESS:
      return fetchAddProductSuccess(state, action)
    case actionTypes.FETCH_ADD_PRODUCT_FAIL:
      return fetchAddProductFail(state, action)
    case actionTypes.FETCH_ADD_PRODUCT_START:
      return fetchAddProductStart(state, action)
    case actionTypes.FETCH_ADD_PRODUCT_RESET:
      return fetchAddProductReset(state, action)
    case actionTypes.FETCH_DESPACHOS_SUCCESS:
      return fetchDespachosSuccess(state, action)
    case actionTypes.FETCH_DESPACHOS_FAIL:
      return fetchDespachosFail(state, action)
    case actionTypes.FETCH_DESPACHOS_START:
      return fetchDespachosStart(state, action)
    case actionTypes.FETCH_DESPACHOS_RESET:
      return fetchDespachosReset(state, action)
    case actionTypes.FETCH_MSG_SUCCESS:
      return fetchConversationsSuccess(state, action)
    case actionTypes.FETCH_MSG_FAIL:
      return fetchConversationsFail(state, action)
    case actionTypes.FETCH_MSG_START:
      return fetchConversationsStart(state, action)
    case actionTypes.FETCH_MSG_RESET:
      return fetchConversationsReset(state, action)
    case actionTypes.SEND_MSG_SUCCESS:
      return sendMsgSuccess(state, action)
    case actionTypes.SEND_MSG_FAIL:
      return sendMsgFail(state, action)
    case actionTypes.SEND_MSG_START:
      return sendMsgStart(state, action)
    case actionTypes.SEND_MSG_RESET:
      return sendMsgReset(state, action)
    case actionTypes.NEW_CONVER_SUCCESS:
      return newConversationSuccess(state, action)
    case actionTypes.NEW_CONVER_FAIL:
      return newConversationFail(state, action)
    case actionTypes.NEW_CONVER_START:
      return newConversationStart(state, action)
    case actionTypes.NEW_CONVER_RESET:
      return newConversationReset(state, action)
    case actionTypes.GET_MSG_SUCCESS:
      return getMsgSuccess(state, action)
    case actionTypes.GET_MSG_FAIL:
      return getMsgFail(state, action)
    case actionTypes.GET_MSG_START:
      return getMsgStart(state, action)
    case actionTypes.GET_MSG_RESET:
      return getMsgReset(state, action)
    case actionTypes.FETCH_VENTAS_SUCCESS:
      return fetchVentasSuccess(state, action)
    case actionTypes.FETCH_VENTAS_FAIL:
      return fetchVentasFail(state, action)
    case actionTypes.FETCH_VENTAS_START:
      return fetchVentasStart(state, action)
    case actionTypes.FETCH_VENTAS_RESET:
      return fetchVentasReset(state, action)
    case actionTypes.FETCH_READY_TO_DELIVER_SUCCESS:
      return fetchGetReadyToDeliverSuccess(state, action)
    case actionTypes.FETCH_READY_TO_DELIVER_FAIL:
      return fetchGetReadyToDeliverFail(state, action)
    case actionTypes.FETCH_READY_TO_DELIVER_START:
      return fetchGetReadyToDeliverStart(state, action)
    case actionTypes.FETCH_READY_TO_DELIVER_RESET:
      return fetchGetReadyToDeliverReset(state, action)
    case actionTypes.FETCH_GET_QR_ORDER_SUCCESS:
      return fetchOrderQRSuccess(state, action)
    case actionTypes.FETCH_GET_QR_ORDER_FAIL:
      return fetchOrderQRFail(state, action)
    case actionTypes.FETCH_GET_QR_ORDER_START:
      return fetchOrderQRStart(state, action)
    case actionTypes.FETCH_GET_QR_ORDER_RESET:
      return fetchOrderQRReset(state, action)
    case actionTypes.FETCH_UPLOAD_PRODUCTS_FILE_SUCCESS:
      return fetchUploadProductsFileSuccess(state, action)
    case actionTypes.FETCH_UPLOAD_PRODUCTS_FILE_FAIL:
      return fetchUploadProductsFileFail(state, action)
    case actionTypes.FETCH_UPLOAD_PRODUCTS_FILE_START:
      return fetchUploadProductsFileStart(state, action)
    case actionTypes.FETCH_UPLOAD_PRODUCTS_FILE_RESET:
      return fetchUploadProductsFileReset(state, action)

    case actionTypes.DISCLAIMER_METODO_PAGO_SUCCESS:
      return fetchDisclaimerMetodoPagoSuccess(state, action)
    case actionTypes.DISCLAIMER_METODO_PAGO_FAIL:
      return fetchDisclaimerMetodoPagoFail(state, action)
    case actionTypes.DISCLAIMER_METODO_PAGO_START:
      return fetchDisclaimerMetodoPagoStart(state, action)
    case actionTypes.DISCLAIMER_METODO_PAGO_RESET:
      return fetchDisclaimerMetodoPagoReset(state, action)

    default:
      return state
  }
}
export default reducer
