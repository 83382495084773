import React from 'react';
import Webcam from 'react-webcam';
import Modal from 'components/Modal';
import CameraIcon from '../../assets/images/group-11.svg'
import { 
  ContainerWebcam,
  Header,
  Title,
  WebCapture,
  ShowCaptured,
  CloseIcon,
  Footer,
  RedoButton,
  CaptureButton,
  ContinueButton
} from './styled'

class WebcamModal extends React.Component {
  state = { imageData: null }

  setRef = (webcam) => {
    this.webcam = webcam;
  }

  captureImage = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({
      imageData: imageSrc
    })
  };

  redoImage = (e) => {
    e.persist();
    this.setState({
      imageData:null
    })
  }

  render() {
    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: 'user'
    };

    return (
      <Modal traslucid={true}>
          <ContainerWebcam>          
              <Header>
                <CloseIcon src={require('../../assets/icons/cross-blue.png')} onClick={this.props.onClose}/>
              </Header>
              <Title>Foto del dorso del DNI</Title>
              <WebCapture>
                { 
                  !this.state.imageData ? 
                  <Webcam
                    audio={false}
                    height={660}
                    ref={this.setRef}
                    screenshotFormat="image/jpeg"
                    width={570}
                    videoConstraints={videoConstraints}
                  />  
                : 
                  <ShowCaptured>
                    <img src={this.state.imageData} />
                  </ShowCaptured>
                }
              </WebCapture>
              <Footer>
                <RedoButton type="button" onClick={this.redoImage}>Volver a sacar</RedoButton>
                <CaptureButton  type="button" disabled={this.state.imageData} onClick={this.captureImage} >
                  <img src={CameraIcon} />
                </CaptureButton>
                <ContinueButton type="button" 
                  disabled={!this.state.imageData} 
                  onClick={() => this.props.retrieveImage(this.state.imageData)}>
                    Continuar
                  </ContinueButton>
              </Footer>
          </ContainerWebcam>  
      </Modal>
    )
  }
}

export default WebcamModal;