import styled, { css } from 'styled-components'
import Select from 'react-select'

export const PageWrapper = styled.div`
   overflow: hidden;
  width: 100%;
  min-height: 100%;
  align-items: normal;

  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`
export const ErrorLabel = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  padding-top: 10px;
  font-weight: 500;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const SearchWrapper = styled.div`
    max-height: auto;
    display: flex;
    flex-direction: column;
    border: none;
    margin-right: 1px;
    border-radius: 10px;
`

export const ContentWrapper = styled.div`
    max-height: 550px;
    min-height: 350px;
    display: flex;
    margin-right: 1px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.alto};
`

export const ListWrapper = styled.div`
    width: 30%;
    padding-right: 13px;
    height: auto;
    overflow-y: scroll;
    border-bottom-left-radius: 10px;
    border-right: 2px solid ${({ theme }) => theme.colors.ghost};
`

export const ContainerSelects = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`

export const ContainerSearchBar = styled.div`
    width: 100%;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.alto};
`


export const SelectStyled = styled(Select)`
    width: 50%;
    margin: 0px 10px;
`

export const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blueRibbon};
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 15px;
  font-weight: 500;
  padding: 0.8% 0.5% 0.8% 0.5%;
  border-radius: 25px;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  text-align: center;
  flex-grow: 1;
  line-height: 25px;
  max-width: 22%;

`


