import React, { Component } from 'react'
import Page from 'components/Page'
import Section from 'components/Section'
import Select from 'react-select';
import styled, { css } from 'styled-components'
import FileImageInput from 'components/FileImageInput';
import plus from 'assets/icons/plus.svg';
import { PageWrapper, Wrapper, ButtonContinuar, Row, Title, WrapperForm, StyledButton } from "../styled"
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import ProgressBar from 'components/ProgressBar';

import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import CheckBox from 'components/CheckboxFormik';
import Loader from 'components/PageLoader'
import Modal from 'components/ModalMessage'


import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    fetchGetPayment, fetchGetShippingMethods, fetchAddProduct, fetchEditProduct
} from 'store/actions/clasificados.actions'

import compose from 'recompose/compose'



const LoaderImg = styled.img`  
    width: 80px;
`


const ErrorLabel = styled.div`  
    width: "100%"
    color: ${({ theme }) => theme.colors.red};
    font-size: 14px;
    padding-top: 10px;
    font-weight: 500;
`

const GridContainerImagenes = styled.div`  
    display: grid;
      width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 10px;
    grid-template-areas: "main main one two" "main main three four";

    
    @media (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`


const GridContainerDataMapped = styled.div`  
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    margin-bottom: 15px;

    @media (max-width: 650px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`

const GridContainerLoader = styled.div`  
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    margin-bottom: 15px;
    justify-items: center;
    @media (max-width: 650px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`

const pagos = [{ label: 'Mercado Pago', value: '0' },
{ label: 'Efectivo', value: '1' },
{ label: 'Transferencia', value: '2' }];


const envios = [{ label: 'Mercado Envios', value: '0' },
{ label: 'Retiro en local', value: '1' },];


class Paso3 extends Component {
    state = {
        step: 1,
        loading: false,
        formData: [
            { validated: false },
            { validated: false },
            { validated: false },
        ],
        imagesArray: []
    }

    fieldImages = (image, setFieldValue) => {
        let imagesArray = this.state.imagesArray.concat(image.base64Value)
        this.setState({ imagesArray }, setFieldValue('images', imagesArray))
    }
    
    clearImage = () => {
        this.setState({ dniImage: null})
    }

    removeFile = (url, setFieldValue, file) => {
        if(!url.includes('amazonaws'))
        {
            let base64Value = url.substring(url.indexOf(',') + 1);
            let imagesArray = this.state.imagesArray.filter(x => x !== base64Value);
            this.setState({ imagesArray }, setFieldValue('images', imagesArray))
        }
        else{
            let imagesArray = file.filter(x => x !== url);
            this.setState({ imagesArray }, setFieldValue('file', imagesArray))
        }
    }

    getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
        <form onSubmit={handleSubmit} key='2'>
            {/* {console.log("values del paso 3",values)}
            {console.log("values.file[0]",values.file[0])}
            {console.log("state array",this.state.imagesArray) } */}
            <Wrapper>
                <ProgressBar percentage={'100'} />
                <br />
                {this.props.clasificados.loadingEditProduct ?
                    <GridContainerLoader>
                        <Loader customHeight={'150px'} />
                    </GridContainerLoader>
                    :
                    <>
                        <Title>Seleccione las fotos del producto</Title>
                        <GridContainerImagenes>
                            <FileImageInput
                                onChange={file => this.fieldImages(file, setFieldValue)}
                                value={values.file[0] ? values.file[0] : "" }
                                removeFile={url => this.removeFile(url, setFieldValue, values.file)}
                                clearImage={this.clearImage}
                                width="95%"
                                padding="5%"
                                gridArea="main"
                                icon={plus}
                                dashed={true}
                                canRemoveElement={true}

                            />
                            <FileImageInput
                                onChange={file => this.fieldImages(file, setFieldValue)}
                                removeFile={url => this.removeFile(url, setFieldValue, values.file)}
                                value={values.file[1] ? values.file[1] : "" }
                                gridArea="one"
                                icon={plus}
                                dashed={true}
                                canRemoveElement={true}
                            />
                            <FileImageInput
                                onChange={file => this.fieldImages(file, setFieldValue)}
                                value={values.file[2] ? values.file[2] : "" }
                                removeFile={url => this.removeFile(url, setFieldValue, values.file)}
                                gridArea="two"
                                icon={plus}
                                dashed={true}
                                canRemoveElement={true}
                            />
                            <FileImageInput
                                onChange={file => this.fieldImages(file, setFieldValue)}
                                value={values.file[3] ? values.file[3] : "" }
                                removeFile={url => this.removeFile(url, setFieldValue, values.file)}
                                gridArea="three"
                                icon={plus}
                                dashed={true}
                                canRemoveElement={true}
                            />
                            <FileImageInput
                                onChange={file => this.fieldImages(file, setFieldValue)}
                                value={values.file[4] ? values.file[4] : "" }
                                removeFile={url => this.removeFile(url, setFieldValue, values.file)}
                                gridArea="four"
                                icon={plus}
                                dashed={true}
                                canRemoveElement={true}
                            />
                        </GridContainerImagenes>
                        <>
                            <Title>Selecciona que medios de pagos ofreces</Title>
                            <GridContainerDataMapped>
                                {this.props.clasificados.payment.values.length > 0 ?
                                    <Select
                                        name={'acceptedpaymentmethodlist'}
                                        label={'acceptedpaymentmethodlist'}
                                        autosize={false}
                                        isMulti={true}
                                        isSearchable={true}
                                        value={values.acceptedpaymentmethodlist}
                                        onChange={value => setFieldValue('acceptedpaymentmethodlist', value)}
                                        placeholder="Medios de Pago"
                                        options={
                                            this.props.clasificados.payment.values.map(n =>
                                                ({ label: n.name, value: n.code }))}
                                    />
                                    :
                                    <GridContainerLoader>
                                        <Loader customHeight={'50px'} />
                                    </GridContainerLoader>
                                }

                                {errors.acceptedpaymentmethodlist && <ErrorLabel>{errors.acceptedpaymentmethodlist}</ErrorLabel>}
                            </GridContainerDataMapped>

                            <Title>Selecciona que metodos de envío ofreces</Title>
                            <GridContainerDataMapped>
                                {this.props.clasificados.shipping.length > 0 ?
                                    <Select
                                        name={'deliverymethods'}
                                        label={'deliverymethods'}
                                        autosize={false}
                                        isMulti={true}
                                        isSearchable={true}
                                        value={values.deliverymethods}
                                        onChange={value => setFieldValue('deliverymethods', value)}
                                        placeholder="Metodos de envios"
                                        options={
                                            this.props.clasificados.shipping.map(n =>
                                                ({ label: n.name, value: n.id }))}
                                    />
                                    :
                                    <GridContainerLoader>
                                        <Loader customHeight={'50px'} />
                                    </GridContainerLoader>
                                }
                                {errors.deliverymethods && <ErrorLabel>{errors.deliverymethods}</ErrorLabel>}
                            </GridContainerDataMapped>
                        </>

                        {this.props.clasificados.fetchEditProductSuccess ?
                            <StyledButton type={'button'} onClick={() => this.props.backtoClasif()} >Cerrar</StyledButton>
                            :
                            <StyledButton type={'submit'} >Finalizar</StyledButton>
                        }

                    </>
                }

                {this.props.clasificados.fetchEditProductSuccess || this.props.clasificados.fetchEditProductFail ?
                    <Modal success={this.props.clasificados.fetchEditProductSuccess} onClose={() => this.props.backtoClasif()} >
                        {
                            this.props.clasificados.fetchEditProductFail ?
                                <h3>Hubo un error, intentar nuevamente.</h3>
                                :

                                <>Producto creado correctamente</>
                        }
                        {
                            !this.props.clasificados.fetchEditProductFail ?
                                this.timer = setTimeout(() => {
                                    this.props.backtoClasif()
                                }, 2000)
                                :
                                null
                        }
                    </Modal>
                    :
                    null
                }
            </Wrapper>
        </form>
    )


    toggleModalAdd = () => {
        this.setState({
            apiReady: !this.state.apiReady,
        });
    }

    handleSubmit = (values) => {
        const { index, getFormData } = this.props;
        getFormData(values, index);
        console.log("submit paso 3,",values)
        this.props.handleEditProduct()
    }

    getInitialValues = () => ({
        file: this.props.producto.values.shopitemimagesurls.map(n => n),
        deliverymethods: this.props.producto.values.deliverymethods.map(n => ({ label: n.name, value: n.id })),
        acceptedpaymentmethodlist: this.props.producto.values.acceptedpaymentmethodlist.map(n => ({ label: n.paymentmethodname, value: n.paymentmethodcode })),
    })
    componentDidMount() {
        this.props.fetchGetShippingMethods();
        this.props.fetchGetPayment();
    }
    getSignUpSchema = () =>
        Yup.object().shape({
            deliverymethods: Yup.string()
                .required(),
            acceptedpaymentmethodlist: Yup.string()
                .required(),
        })

    render() {
        return (
            <Formik
                initialValues={this.getInitialValues()}
                validateOnChange={false}
                validationSchema={this.getSignUpSchema()}
                onSubmit={this.handleSubmit}
                render={e => this.getFormContent(e)}
            />
        )
    }
}

const mapStateToProps = ({
    clasificados
}) => ({
    clasificados,
    fetchEditProductSuccess: clasificados.fetchEditProductSuccess,
    fetchEditProductFail: clasificados.fetchEditProductFail,
    loadingEditProduct: clasificados.loadingEditProduct
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchAddProduct, fetchEditProduct, fetchGetPayment, fetchGetShippingMethods }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(Paso3)
)