import React, { Component } from 'react';
import NewGuestFirstStep from './FirstStep';
import NewGuestSecondStep from './SecondStep';
import NewGuestThirdStep from './ThidStep';
import NewGuestFourthStep from './FourthStep';
import { base64Formatter } from '../../../utils/base64formatter';
import { checkIfURL } from '../../../utils/checkIfURL';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchGetGuest, fetchCheckIn, fetchPostInvite, clearAccessStore, fetchPostInviteUpdate } from 'store/actions/acceso.actions';
import compose from 'recompose/compose';
import withWidth from '@material-ui/core/withWidth';
import CircularProgress from '@material-ui/core/CircularProgress'
import AddUser from 'assets/images/add-user.png'
import ScanQRPng from 'assets/images/qr-code.png'
import StyledTabs from 'components/StyledTabs'
import { Text, Wrapper, ButtonWrapper, StyledButton, SucessLabel, ErrorLabel, Loading, Title, AccessEntry, BigButton, PngImage} from './styled'

class NuevoInvitado extends Component {
  state = { 
    tabSelected: null,
    activeIndex: 0,
    guestInviteId: null,
    formData: [
      {validated: false},
      {validated: false},
      {validated: false},
      {validated: false}
    ]
  }
 
  changeIndex = (index, next) => {
    const { activeIndex } = this.state
    let newIndex;
    if(next) {
      newIndex = activeIndex;
      newIndex++; 
    } else {
      newIndex = activeIndex === index ? -1 : index
    }
    this.setState({ activeIndex: newIndex })
  }

  getGuestInviteId = (id) => {
    this.setState({guestInviteId: id});
  }

  getFormData = (data, index) => {
    const currentState = [...this.state.formData];
    currentState[index] = {validated: true, ...data};
    this.setState({
      formData: currentState
    });
    this.changeIndex(index, true);
  }

  validateAllForms = () => {
    return [...this.state.formData].every(e => e.validated === true );
  }

  handleCheckIn = () => {
    const { formData } = this.state;
    const propietario = formData[0];
    if(!this.props.editMode) { propietario.propietario_lote =  propietario.propietario_lote.value};
    const invitado = formData[1];
    const licencia = formData[2];
    const seguro = formData[3];
  
    const formattedData = {
      'functional_unit_id': propietario.propietario_lote, 
      'guests': [{
        'name': `${invitado.invitado_nombre} ${invitado.invitado_apellido}`,
        'telephone': '0',
        'dni': invitado.invitado_dni_numero,
        'avatar': base64Formatter(invitado.invitado_dni_escaneado),
      }],
      'vehicle': {
        'vehicle_license_plate': seguro.auto_patente,
        'vehicle_colour': seguro.auto_color,
        'vehicle_model': seguro.auto_modelo.value,
        'vehicle_brand': seguro.auto_marca.value,
        'vehicle_passenger_count': 1,
        'vehicle_model_name': seguro.auto_modelo.label,
        'vehicle_brand_name': seguro.auto_marca.label
      },
      'vehicle_insurance' : {
        'expire_date': seguro.seguro_vencimiento,
        'file': base64Formatter(seguro.seguro_img),
        'extension': 'JPEG',
      },
      'date_from': new Date(),
      'date_to': new Date(),
      'meeting_point_latitude': 0,
      'meeting_point_longitude': 0,
    };
    //console.log(formattedData)
    this.props.fetchPostInvite(formattedData)
  }

  updateCheckIn = () => {
    const { formData, guestInviteId } = this.state;
    let id = this.props.createdInvitationId;
    if(this.props.editMode) { id = guestInviteId}
    let vehicle = {};
    let guest = {};
    let vehicle_insurance = {};
    const invitado = formData[1];
    const seguro = formData[3]; 
  
    // Guest
    if(this.props.editMode) {
      guest = {
        'isFrontPhoto': true,
        'expire_date': invitado.invitado_dni_vencimiento,
        'file': base64Formatter(invitado.invitado_dni_escaneado),
        'extension': 'JPEG'
      };
      if(checkIfURL(invitado.invitado_dni_escaneado)) {
        delete guest.file;
      }
    } else {
      guest = {
        'isFrontPhoto': true,
        'expire_date': invitado.invitado_dni_vencimiento,
        'file': base64Formatter(invitado.invitado_dni_escaneado),
        'extension': 'JPEG'
      };
    }

    // Insurance
    if(this.props.editMode) {
      vehicle_insurance = {
        'expire_date': seguro.seguro_vencimiento,
        'file': base64Formatter(seguro.seguro_img),
        'extension': 'JPEG',
      };
      if(checkIfURL(seguro.seguro_img)) {
        delete vehicle_insurance.file;
      }
    } else {
      vehicle_insurance = {
        'expire_date': seguro.seguro_vencimiento,
        'file': base64Formatter(seguro.seguro_img),
        'extension': 'JPEG',
      };
    }
    // Car
    vehicle = {
      'vehicle_license_plate': seguro.auto_patente,
      'vehicle_colour': seguro.auto_color,
      'vehicle_model': seguro.auto_modelo.value,
      'vehicle_brand': seguro.auto_marca.value,
      'vehicle_passenger_count': 1,
      'vehicle_model_name': seguro.auto_modelo.label,
      'vehicle_brand_name': seguro.auto_marca.label
    };
    this.props.fetchPostInviteUpdate(guest, id,'dni');
    this.props.fetchPostInviteUpdate(vehicle, id,'car');
    this.props.fetchPostInviteUpdate(vehicle_insurance, id,'insurance');

    if(this.props.editMode) {
      this.props.onSuccess();
    }
  }
  
  componentDidUpdate(prevProps) {
    if(!this.props.loading) {
      if (prevProps.accesos.postInviteSuccess === false && this.props.accesos.postInviteSuccess === true && this.props.createdInvitationId) {
        this.updateCheckIn();
        this.props.onSuccess();
        this.props.toggleSuccesModal(
          <p>La invitacion fue creada exitosamente!</p>
        )
      }
    }
  }

  checkStatusCheckIn = async(props) => {
    const checkInSucces = await props.checkInSucces;
    const errorPostInvite = await props.errorPostInvite;
    if(checkInSucces) {
      props.onSuccesCheckIn()
    } else if(errorPostInvite) {
      props.onFailCheckIn()
    }
  }

  componentWillUnmount() {
    this.props.clearAccessStore()
  }

  handleTabChange = (event, value) => {
    this.setState({ tabSelected: value })
  }

  render() {
    return (
      <Wrapper>
      { !this.props.showSteps ? 
      <AccessEntry>
        <BigButton onClick={() => this.props.toggleScanQRModal()}>
          <PngImage src={ScanQRPng}/>
          <Text>
            Escanear Código QR
          </Text>
        </BigButton>
        <BigButton onClick={() => this.props.toggleSteps()}>
          <PngImage src={AddUser}/>
          <Text>
            Registrar Nuevo Ingreso
          </Text>
        </BigButton>
      </AccessEntry>
        :
        <>
        <StyledTabs style={{'marginBottom':'20px'}} 
          value={this.state.tabSelected}
          tabs={[
            { label: 'Propietario', value:-1 , id: 0},
            { label: 'Invitados', value:-1 , id: 1},
            { label: 'Proveedores', value:-1 , id: 2},
            { label: 'Empleados', value:-1 , id: 3},
          ]}
          handleTabChange={this.handleTabChange}
        />
        { this.state.tabSelected == 0 ? 
        <>
        <Title style={{'marginTop':'20px'}}>Modulo en desarrollo.</Title>
        </>
        : 
        <></>
        }
        { this.state.tabSelected == 1 ? 
        <>
        <Title style={{'marginTop':'20px'}}>Completa los datos del Invitado.</Title>  
        <NewGuestFirstStep title={'Datos del propietario'} active={this.state.activeIndex === 0} index={0} formDataValidation={this.state.formData[0].validated} changeIndex={this.changeIndex.bind(this)} getFormData={this.getFormData} editMode={this.props.editMode} editInitialValues={this.props.accesos.getGuestInvitation} getGuestInviteId={this.getGuestInviteId} />
        <NewGuestSecondStep title={'Datos del invitado'}  active={this.state.activeIndex === 1}  index={1} formDataValidation={this.state.formData[1].validated} changeIndex={this.changeIndex.bind(this)} getFormData={this.getFormData} editMode={this.props.editMode} editInitialValues={this.props.accesos.getGuestInvitation} />
        <NewGuestThirdStep title={'Datos de la licencia de conducir'} active={this.state.activeIndex === 2}  index={2} formDataValidation={this.state.formData[2].validated} changeIndex={this.changeIndex.bind(this)} getFormData={this.getFormData} editMode={this.props.editMode} editInitialValues={this.props.accesos.getGuestInvitation} />
        <NewGuestFourthStep title={'Datos del seguro de vehículo'}  active={this.state.activeIndex === 3}  index={3} formDataValidation={this.state.formData[3].validated} changeIndex={this.changeIndex.bind(this)} getFormData={this.getFormData} editMode={this.props.editMode} editInitialValues={this.props.accesos.getGuestInvitation} />
        <ButtonWrapper style={{'flexDirection':'column',}}>
          <StyledButton disabled={!this.validateAllForms() || this.props.loading || this.state.createdSuccessfully} onClick={!this.props.editMode ? this.handleCheckIn : this.updateCheckIn}>{'Completar'}</StyledButton> { this.props.error ? <ErrorLabel>{this.props.error}</ErrorLabel> : null} { this.state.createdSuccessfully ? <SucessLabel>Creado exitosamente</SucessLabel> : null} { this.props.loading ? <Loading style={{width: '100%'}}><CircularProgress color={'primary'} size={30} /></Loading> : null}
        </ButtonWrapper>
        </>
        : 
        <></>
        }
        { this.state.tabSelected == 2 ? 
        <>
        <Title style={{'marginTop':'20px'}}>Modulo en desarrollo.</Title>
        </>
        : 
        <></>
        }
        { this.state.tabSelected == 3 ? 
        <>
        <Title style={{'marginTop':'20px'}}>Modulo en desarrollo.</Title>
        </>
        : 
        <></>
        }
        </>
        }
        </Wrapper>
    )
  }
}

const mapStateToProps = ({
  accesos,
}) => ({
  loading: accesos.loadingPostInvite,
  accesos: accesos,
  createdInvitationId: accesos.createdInvitationId,
  postInviteSuccess: accesos.postInviteSuccess,
  error: accesos.errorPostInvite,
  checkInSucces: accesos.checkInSuccess,
  checkInFail: accesos.checkInFail
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGetGuest, fetchCheckIn, fetchPostInvite, clearAccessStore, fetchPostInviteUpdate
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(withWidth())(NuevoInvitado)
  )