import React from 'react'
import { Wrapper, Container, Row, Circle, PageWrapper, ColumnItem, ErrorLabel, LinkTable, Status } from './styled'
import Page from 'components/Page'
import Section from 'components/Section'
import MaterialTable from 'material-table'
import Select from 'react-select';
import { Checkbox } from '@material-ui/core';
import { fetchUsers } from 'store/actions/users.actions'

import StyledTabs from 'components/StyledTabs'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    postAssingUser, fetchVendorConsultas, fetchVendorConsultasEstados,
    postChangeState, newTicket, editTicket, fetchEntrepreneur,
    fetchUsersGestor, fetchProductType, newTicketReset, editTicketReset, fetchTicketById,
    postTicketHistoryById, fetchTicketHistoryById
} from '../../../store/actions/gestorConsultas.actions'

import { Formik } from 'formik'
import * as Yup from 'yup'
import mapConsultasVendor from "selectors/mapConsultasVendor"
import mapConsultasEstados from "selectors/mapConsultasEstados"

import mapEntrepeneur from 'selectors/mapEntrepeneur'
import mapUserGestor from 'selectors/mapUserGestor'
import mapProductType from 'selectors/mapProductType'
import DatePickerStyled from 'components/DatePickerStyled'

import PageLoader from '../../../components/PageLoader'

import ModalEstados from '../modalEstados'
import ModalUsuarios from '../modalUsuarios'
import CreateTicket from '../CreateTicket'
import EditTicket from '../EditTicket'
import ShowTicket from '../ShowTicket'

import AddUser from 'assets/images/add-user.png'
import ArrowForward from '@material-ui/icons/ArrowForward';

import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';

import {
    InputWrapper, InputLabel, FormContainer, StyledButton, ContenedorTablaBotones,

} from './styled2'

//const defaultValue = { label: 'Todos', value: -1 };

class RegistrosEInformes extends React.Component {

    state = {
        modalEstados: false,
        modalUsuarios: false,
        ticketsSelecteds: null,

        newTicket: false,
        editTicket: false,
        showTicket: false,

        filtros: null,
        selectedItemForEdit: null,


        desdeDate: null,
        hastaDate: null,
    }

    componentDidMount() {

        this.props.fetchVendorConsultasEstados()
        this.obtenerConsultas()
        this.props.fetchEntrepreneur()
        this.props.fetchUsersGestor()
        this.props.fetchProductType()

    }

    obtenerConsultas() {
        var hoy = new Date();
        var unaSemanaAtras = new Date();
        hoy.setDate(hoy.getDate() - 15);

        this.setState({ desdeDate: body })
        this.setState({ hastaDate: unaSemanaAtras })

        const body = {
            "datefrom": hoy,
            "dateto": unaSemanaAtras,
            "searchtext": "",
            "pagesize": 100,
            "pagenumber": 0
        }
        if (typeof this.setState === "function") {
            this.setState({ filtros: body })

        }

        this.props.fetchVendorConsultas(body)
    }


    toggleSelectState(data) {
        this.setState({
            modalEstados: !this.state.modalEstados,
            ticketsSelecteds: data
        })
    }

    toggleSelectUser(data) {
        this.setState({
            modalUsuarios: !this.state.modalUsuarios,
            ticketsSelecteds: data
        })
    }

    toggleNewTicket = () => {
        this.setState({ newTicket: !this.state.newTicket })
        if (this.state.newTicket == false) {
            this.obtenerConsultas()
        }
    }

    toggleEditTicket = () => {
        this.setState({ editTicket: !this.state.editTicket })
    }
    toggleShowTicket = () => {
        this.setState({ showTicket: !this.state.showTicket })
    }

    handleEditTicket(id) {
        this.props.fetchTicketById(id)
        this.toggleEditTicket()
    }

    handleShowTicket(id) {
        this.props.fetchTicketById(id)
        this.props.fetchTicketHistoryById(id)
        this.toggleShowTicket()
    }


    handleSubmit = data => {

        const body = {
            "datefrom": data.startDate ? data.startDate : this.state.filtros.startDate,
            "dateto": data.endDate ? data.endDate : this.state.filtros.endDate,
            "ticketstateid": data.status ? data.status.value : this.state.filtros.ticketstateid ? this.state.filtros.ticketstateid.value : 0,
            "userid": data.user_asignado ? data.user_asignado.value : this.state.filtros.userid ? this.state.filtros.userid.value : 0,
            "pagesize": 120,
            "pagenumber": 0,
        }

        if (typeof this.setState === "function") {
            this.setState({ filtros: body })

        }
        this.props.fetchVendorConsultas(body)
    }

    getInitialValues = () => ({
        user_asignado: '',
        status: '',
        startDate: this.state.desdeDate,
        endDate: this.state.hastaDate
    })

    getValidationSchema = () =>
        Yup.lazy(values =>
            Yup.object().shape({
                startDate: Yup.required('Ingrese una fecha'),
                endDate: Yup.required('Ingrese una fecha')
            })
        )

    getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
        <FormContainer onSubmit={handleSubmit}>
            <Row>
                <InputWrapper margin={'20px 15px'}>
                    <InputLabel>{'Estado'}</InputLabel>
                    <Select
                        name={'status'}
                        value={values.status}
                        isSearchable={false}
                        onChange={values => setFieldValue('status', values)}
                        error={errors.status}
                        options={this.props.consultasEstados}
                        placeholder="Estado"
                        isDisabled={this.props.gestorConsultas.loadingVendorConsultasEstados}
                        isLoading={this.props.gestorConsultas.loadingVendorConsultasEstados}
                        autoFocus
                    />
                    {errors.property && <ErrorLabel>{errors.property}</ErrorLabel>}
                </InputWrapper>
                <InputWrapper margin={'20px 15px'}>
                    <InputLabel>{'Usuario'}</InputLabel>
                    <Select
                        name={'user_asignado'}
                        value={values.user_asignado}
                        isSearchable={false}
                        onChange={values => setFieldValue('user_asignado', values)}
                        error={errors.user_asignado}
                        options={this.props.usergestores}
                        placeholder="Usuario"
                        isDisabled={this.props.gestorConsultas.loadinguserGestorTickets}
                        isLoading={this.props.gestorConsultas.loadinguserGestorTickets}
                        autoFocus
                    />
                    {errors.category && <ErrorLabel>{errors.category}</ErrorLabel>}
                </InputWrapper>

                <InputWrapper margin='20px 15px'>
                    <InputLabel>{'Desde'}</InputLabel>
                    <DatePickerStyled
                        selected={values.startDate}
                        startDate={values.startDate}
                        endDate={values.endDate}
                        selectsStart

                        dateFormat="dd/MM/yyyy"
                        onChange={startDate => setFieldValue('startDate', startDate)}
                        // minDate={new Date()}
                        // maxDate={addMonths(new Date(), 12)}
                        placeholderText="Fecha desde"
                        width="100%"
                        padding="8px 1%"
                        popperPlacement="bottom"
                        popperModifiers={{
                            flip: {
                                behavior: ['bottom'] // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                            }
                        }}
                    />
                    {errors.startDate && <ErrorLabel>{errors.startDate}</ErrorLabel>}
                </InputWrapper>
                <InputWrapper margin={'20px 15px'}>
                    <InputLabel>{'Hasta'}</InputLabel>
                    <DatePickerStyled
                        selected={values.endDate}
                        startDate={values.startDate}
                        endDate={values.endDate}
                        selectsEnd

                        dateFormat="dd/MM/yyyy"
                        onChange={startDate => setFieldValue('endDate', startDate)}
                        minDate={values.startDate || null}
                        // minDate={new Date()}
                        // maxDate={addMonths(new Date(), 12)}
                        placeholderText="Fecha hasta"
                        width="100%"
                        padding="8px 1%"
                        popperPlacement="bottom"
                        popperModifiers={{
                            flip: {
                                behavior: ['bottom'] // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                            }
                        }}
                    />
                    {errors.endDate && <ErrorLabel>{errors.endDate}</ErrorLabel>}
                </InputWrapper>
                <InputWrapper margin={'20px 15px'}>
                    <StyledButton type={'submit'}>
                        Filtrar
                    </StyledButton>
                </InputWrapper>
            </Row>
        </FormContainer>
    );


    render() {
        return (
            <Page>
                <PageWrapper>
                    <Section
                        title={'Gestor de consultas'}
                        onBtnClick={() => this.toggleNewTicket()}
                        btnContent={'Agregar Consulta'}>
                        <Wrapper>
                            <Row>
                                <Wrapper>
                                    <Formik
                                        initialValues={this.getInitialValues()}
                                        validateOnChange={false}
                                        validationSchema={this.getValidationSchema()}
                                        onSubmit={this.handleSubmit}
                                        render={e => this.getFormContent(e)}
                                    />
                                </Wrapper>
                            </Row>
                            {/*       
                                   <Row>
                                <Wrapper>
                                <StyledTabs
                                value={this.state.tabEnabled}
                                tabs={[
                                { label: 'Todas', value: -1, id: 0 },
                                { label: 'Pendientes', value: 1, id: 1 }
                                ]}
                                handleTabChange={this.handleTabChange}
                                />
                                </Wrapper>
                                </Row> */}

                            {
                                this.state.modalUsuarios ?
                                    <ModalUsuarios
                                        postChangeState={this.props.postAssingUser}
                                        userList={this.props.usergestores}
                                        ticketSeleccionados={this.state.ticketsSelecteds}
                                        onClose={() => this.toggleSelectUser()}
                                        filtrosConsultas={this.state.filtros}
                                    ></ModalUsuarios>
                                    :
                                    null
                            }

                            {
                                this.state.modalEstados ?
                                    <ModalEstados
                                        postChangeState={this.props.postChangeState}
                                        estadosList={this.props.consultasEstados}
                                        ticketSeleccionados={this.state.ticketsSelecteds}
                                        onClose={() => this.toggleSelectState()}
                                        filtrosConsultas={this.state.filtros}
                                    ></ModalEstados>
                                    :
                                    null
                            }
                            {
                                this.state.newTicket ?
                                    <CreateTicket
                                        newTicket={this.props.newTicket}
                                        newTicketReset={this.props.newTicketReset}
                                        estadosList={this.props.consultasEstados}
                                        productTypesList={this.props.productType}
                                        entrepeneurList={this.props.entrepeneur}
                                        usersList={this.props.usergestores}


                                        filtrosConsultas={this.state.filtros}
                                        onClose={() => this.toggleNewTicket()}
                                    ></CreateTicket>
                                    :
                                    null
                            }
                            {
                                this.state.editTicket ?
                                    <EditTicket
                                        editTicket={this.props.editTicket}
                                        editTicketReset={this.props.editTicketReset}
                                        estadosList={this.props.consultasEstados}
                                        productTypesList={this.props.productType}
                                        entrepeneurList={this.props.entrepeneur}
                                        usersList={this.props.usergestores}

                                        ticketSelected={this.props.gestorConsultas.GetTicketByID}

                                        filtrosConsultas={this.state.filtros}
                                        onClose={() => this.toggleEditTicket()}
                                    ></EditTicket>
                                    :
                                    null
                            }

                            {
                                this.state.showTicket ?
                                    <ShowTicket

                                        fetchHistory={this.props.fetchTicketHistoryById}
                                        saveHistory={this.props.postTicketHistoryById}

                                        ticketSelected={this.props.gestorConsultas.GetTicketByID}
                                        onClose={() => this.toggleShowTicket()}
                                    ></ShowTicket>
                                    :
                                    null
                            }

                            <Row>
                                <Container>
                                    {this.props.gestorConsultas.vendorConsultasSuccess ?
                                        <>
                                            <MaterialTable
                                                columns={[
                                                    { title: 'Fecha', field: 'date', },
                                                    {
                                                        title: 'Estado', field: 'status', render: rowData => {
                                                            
                                                            switch (rowData.status) {
                                                                case "FINALIZADO":
                                                                    return <Status title={rowData.statusName} background={"#15994C"} />;
                                                                case "EN_PROCESO":
                                                                    return <Status title={rowData.statusName} background={"#f7b343"} />;
                                                                case "PENDIENTE":
                                                                    return <Status title={rowData.statusName} background={"#F44336"} />;

                                                                default:
                                                                    return null;
                                                            }
                                                            
                                        }
                                    },
                                                    {title: 'Nombre', field: 'nya', },
                                                    {title: 'Asunto', field: 'subject', },
                                                    {title: 'Mensaje', field: 'message', render: rowData => <>{rowData.message.substring(0, 22) + '...'}</> },
                                                    {title: 'Email', field: 'email', },
                                                    {title: 'Asignado', field: 'assing.fullname', },
                                                    {
                                                title: 'Accion', field: 'publication', render: rowData =>
                                                            <ContenedorTablaBotones>
                                                <LinkTable title={'Editar'} ><EditTwoToneIcon title={'Editar'} fontSize={'35px'} onClick={() => this.handleEditTicket(rowData.id)}></EditTwoToneIcon></LinkTable>
                                                <LinkTable title={'Visualizar'} ><VisibilityTwoToneIcon title={'Visualizar'} fontSize={'35px'} onClick={() => this.handleShowTicket(rowData.id)}></VisibilityTwoToneIcon></LinkTable>
                                            </ContenedorTablaBotones>
                                            }
                                        ]}

                                                data={this.props.consultas ? this.props.consultas : null}

                                            title="Consultas recibidas"
                                                options={{
                                                toolbar: true,
                                                paging: true,
                                                selection: true,
                                                // exportButton: true
                                            }}
                                            actions={[
                                                {
                                                    tooltip: 'Cambiar de Estado',
                                                    icon: () => (<ArrowForward />),
                                                    onClick: (evt, data) => this.toggleSelectState(data)
                                                },
                                                {
                                                    tooltip: 'Asignar a Usuario',

                                                    icon: () => (<img src={AddUser} style={{ width: '20px' }} />),
                                                    onClick: (evt, data) => this.toggleSelectUser(data)
                                                }
                                            ]}
                                            localization={{
                                                toolbar: {
                                                    exportTitle: 'Exportar',
                                                    exportAriaLabel: 'Exportar',
                                                    exportName: 'Exportar a CSV',
                                                    searchTooltip: 'Buscar',
                                                    searchPlaceholder: 'Buscar',
                                                    nRowsSelected: '{0} tickets seleccionados'
                                                },
                                                body: {
                                                    emptyDataSourceMessage: 'No hay registros.'
                                                },
                                                pagination: {
                                                    labelRowsSelect: 'Registros',
                                                    labelDisplayedRows: '{count} de {from}-{to}',
                                                    firstTooltip: 'Primera página',
                                                    previousTooltip: 'Anterior',
                                                    nextTooltip: 'Siguiente',
                                                    lastTooltip: 'Última'
                                                }

                                            }}
                                            />
                                        </>
                                        :
                                        <PageLoader />
                                    }
                                </Container>
                            </Row>
                        </Wrapper>
                    </Section>
                </PageWrapper>
            </Page>
        )
    }

}

//         res.data
const mapStateToProps = ({
    gestorConsultas, userInfo
}) => ({
    gestorConsultas,
    userInfo,
    consultasRaw: gestorConsultas.vendorConsultas,
    consultas: mapConsultasVendor(gestorConsultas.vendorConsultas),
    //consultasEstados: gestorConsultas.vendorConsultasEstados  ? mapConsultasEstados(gestorConsultas.vendorConsultasEstados).unshift({ label: 'Todos', value: -1 }) : null,
    consultasEstados: mapConsultasEstados(gestorConsultas.vendorConsultasEstados),
    usergestores: mapUserGestor(gestorConsultas.userGestorTickets),
    //usergestoresPlusTodos: mapUserGestorFiltros(gestorConsultas.userGestorTickets),
    entrepeneur: mapEntrepeneur(gestorConsultas.entrepeneur),
    productType: mapProductType(gestorConsultas.productType)
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchVendorConsultas,
            fetchVendorConsultasEstados,
            postChangeState,
            postAssingUser,
            fetchUsers,
            newTicket,
            editTicket,
            newTicketReset,
            editTicketReset,
            fetchEntrepreneur,
            fetchUsersGestor,
            fetchProductType, fetchTicketById,
            postTicketHistoryById,
            fetchTicketHistoryById
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegistrosEInformes)