import { createSelector } from 'reselect'
import { getFormattedDate } from 'utils/dateParser'

const mapFilteredProducts = createSelector(
  FilteredProducts => FilteredProducts,
  FilteredProducts =>
    FilteredProducts.map(
      (
        {
            creationdate,
            sellerusername,
            currency,
            productname,
            price,
            categoryname,
            images,
            id
        },
        i
      ) => ({
        date: getFormattedDate(creationdate),
        user: sellerusername,
        amount: price,
        productname,
        category: categoryname,
        images,
        idProduct: id
      })
    )
)

export default mapFilteredProducts
