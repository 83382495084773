import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Page from 'components/Page'
import Section from 'components/Section'
import EmptyLoader from 'components/PageLoader'
import EmptyWrapper from 'components/PageEmpty'
import ActivitiesList from './ActivitiesList'
import { fetchActivities } from 'store/actions/activities.actions'
import { setSelectedResource, resetSelectedResource } from 'store/actions/selectedResource'
import QuestionIcon from 'assets/icons/info.png'
import ActivityDetail from './ActivityDetail'
import NewActivity from './NewActivity'
import NewSpace from './NewSpace'
import { Wrapper, ActivitiesContainer, StyledTitle, DetailContainer } from './styled'
import UploadTyC from './UploadTyC'
import DetailModalTyC from './DetailModalTyC'
import {uploadTyC } from 'store/actions/reservas.actions'

class Reservations extends React.Component {
  state = {
    newActivity: false,
    newSpace: false,
    newUploadTyC:false,
    spaceToEdit: undefined,
    spaceData: undefined,
  }

  componentDidMount = () => {
    this.props.resetSelectedResource()
    this.fetchContent()
  }

  fetchContent = () => {
    this.props.fetchActivities()
  }

  isLoading = () => this.props.activities.isFetching

  isContentAvaiable = () => this.props.activities.values && this.props.activities.values.length > 0

  checkPageContent = () =>
    this.isContentAvaiable() ? (
      this.renderPageContent()
    ) : (
        <EmptyWrapper message={'No hay actividades disponibles'} />
      )

  renderPageContent = () => (
    <Wrapper>
      {this.renderActivities()}
      {this.renderDetail()}
    </Wrapper>
  )

  renderActivities = () => (
    <ActivitiesContainer>
      <StyledTitle>{'Tipo de actividades'}</StyledTitle>
      <ActivitiesList
        activities={this.props.activities.values}
        selected={this.props.selectedResource}
        onActivityClick={activity => this.props.setSelectedResource(activity)}
        onEdit={(data)=>this.toggleNewActivityForm(data)}
        uploadTyC={(data)=>this.toggleNewUploadTyC(data)}
      />
    </ActivitiesContainer>
  )

  renderDetail = () => (
    <DetailContainer>
      {this.props.selectedResource ? (
        <ActivityDetail
          onCreate={() => this.toggleNewSpaceForm(undefined)}
          onEdit={this.toggleNewSpaceForm}
        />
      ) : (
        <EmptyWrapper message={'Seleccione una actividad'} icon={QuestionIcon} />
      )}
    </DetailContainer>
  )

  handleFormClose = reset => {
    if (reset) this.fetchContent()
    this.toggleNewActivityForm()
  }
  handleFormUploadTyC = reset => {
    this.toggleNewUploadTyC()
  }

  toggleNewActivityForm  = (activityToEdit) => this.setState({ newActivity: !this.state.newActivity,activityToEdit })

  toggleNewUploadTyC  = (activityToEdit) => this.setState({ newUploadTyC: !this.state.newUploadTyC,activityToEdit })

  handleSpaceClose = reset => {
    if (reset) this.fetchContent()
    this.toggleNewSpaceForm()
  }

  toggleNewSpaceForm = (spaceToEdit, spaceData) =>
    this.setState({ newSpace: !this.state.newSpace, spaceToEdit, spaceData })

    

  render() {

        console.log("ACTIVIDADES",this.props.activities.values);
        console.log("ACTIVITYITOEDIT",this.state.activityToEdit);
    return (
      <Page>
        <Section
          title={'Administrar Reservas'}
          btnContent={'Nueva actividad'}          
          onBtnClick={() => this.toggleNewActivityForm(undefined)}
        >
          {this.isLoading() ? <EmptyLoader /> : this.checkPageContent()}
        </Section>
        {this.state.newActivity && <NewActivity onClose={this.handleFormClose} activityToEdit={this.state.activityToEdit} />}
        {this.state.newUploadTyC ?
            <DetailModalTyC
              onClose={this.toggleNewUploadTyC}
              onChange={(file) => this.props.uploadTyC(file)}
              onDeleteTyC={(file) => this.props.deleteTyC(file)}
              fetchActivities={(file) => this.props.fetchActivities(file)}
              error={this.props.reservas.errorUploadTyC}
              errorDeleteTyC={this.props.reservas.errorDeleteTyC}
              activityToEdit= {this.state.activityToEdit}
              successMessage= {this.props.reservas.uploadTyC}
              successDelete= {this.props.reservas.deletedTyC}
            ></DetailModalTyC>
          : <> </> }

        {this.state.newSpace && (
          <NewSpace
            space={this.state.spaceToEdit}
            spaceData={this.state.spaceData}
            onClose={this.handleSpaceClose}
          />
        )}
      </Page>
    )
  }
}

const mapStateToProps = ({ activities, selectedResource,reservas,activityToEdit }) => ({
  activities,
  selectedResource,
  reservas,
  activityToEdit
  
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchActivities,
      setSelectedResource,
      resetSelectedResource,
      uploadTyC
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reservations)
