import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchSpaces } from 'store/actions/spaces.actions'
import Select from 'react-select'
import mapSpaces from 'selectors/mapSpaces'
import { Container, StyledLabel, Row, SelectWrapper, ActionLabel, TableWrapper } from './styled'
import ReservationsTable from 'components/ReservationsTable'
import { fetchActiveTimes } from 'store/actions/activeTimes.actions'
import { getFormattedDateYear } from 'utils/dateParser'
import PageLoader from 'components/PageLoader'
import DatePickerStyled from 'components/DatePickerStyled'
import EditIcon from 'components/EditIcon'
import AddIcon from 'components/AddIcon'
import Input from 'components/Input'

class ActivityDetail extends React.Component {
  state = {
    selectedSpace: null,
    edit: false,
    startDate: new Date()
  };

  componentDidMount = () => {
    this.props.fetchSpaces(this.props.selectedResource.id)
  }

  componentDidUpdate = prevProps => {
    if (this.props.selectedResource.id !== prevProps.selectedResource.id) {      
      this.props.fetchSpaces(this.props.selectedResource.id)
      this.setState({ selectedSpace: null })
    }
  }

  toggleMode = () => this.setState({ edit: !this.state.edit })

  handleSpaceSelection = option => {
    this.props.fetchActiveTimes(option.value.id, getFormattedDateYear(this.state.startDate))
    this.setState({ selectedSpace: option })
  }

  handleChange = date => {
    this.setState({
      startDate: date
    }, () => this.props.fetchActiveTimes(this.state.selectedSpace.value.id, getFormattedDateYear(this.state.startDate)));
  };

  render() {
    return (
      <Container>
        <Row justify={"space-between"}>
          <SelectWrapper flexGrow={"2"}>
            <StyledLabel>{'Espacios'}</StyledLabel>
            <Select
              value={this.state.selectedSpace}
              onChange={this.handleSpaceSelection}
              options={this.props.formattedSpaces}
              placeholder={this.props.spaces.isFetching ? "Cargando " : "Seleccione un espacio..."}
              isLoading={this.props.spaces.isFetching}
              isDisabled={this.props.spaces.isFetching}
              autoFocus
            />
          </SelectWrapper>
          {!this.props.activeTimes.isFetching && this.state.selectedSpace && (
            <>
              <SelectWrapper flexGrow={"2"}>
                <StyledLabel >{'Día de inicio'}</StyledLabel>
                <DatePickerStyled
                  selected={this.state.startDate}
                  onChange={this.handleChange}
                  dateFormat="dd/MM/yyyy"
                  placeholder={"Semana a visualizar"}
                />
              </SelectWrapper>
              <SelectWrapper flexGrow={"1"}>
                <StyledLabel>{'El Max. dias a reservar'}</StyledLabel>
                <Input
                  disabled
                  value={this.state.selectedSpace.value.maxreservationtime}
                  disabled
                />
              </SelectWrapper>
            </>
          )}
          <SelectWrapper flexGrow={"1"} width={this.state.selectedSpace ? "10%" : "5%"}>
            {!this.props.activeTimes.isFetching && this.state.selectedSpace && (
              <EditIcon
                border={true}
                width="30px"
                margin={"0% 0% 35% 0%"}
                onClick={() => this.props.onEdit(this.props.activeTimes.values, this.state.selectedSpace.value)}
              />
            )}
            <AddIcon
              border={true}
              width="30px"
              onClick={this.props.onCreate}
            />
          </SelectWrapper>

        </Row>
        <TableWrapper>
          {this.props.activeTimes.isFetching ? (
            <PageLoader customHeight={400} />
          ) : this.state.selectedSpace ? (
            <ReservationsTable data={this.props.activeTimes.values}
            selectedSpace={this.state.selectedSpace.value.id}
            startDate={this.state.startDate}
            />
          ) : (
                <label>{'Seleccione un espacio'}</label>
              )}
        </TableWrapper>
      </Container>
    )
  }
}

const mapStateToProps = ({ selectedResource, activeTimes, spaces,reservas}) => ({
  selectedResource,
  formattedSpaces: mapSpaces(spaces.values),
  activeTimes,
  spaces,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchSpaces, fetchActiveTimes }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityDetail)
