import React, { Component } from 'react'
import Modal from 'components/Modal'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import CrossIcon from 'assets/icons/cross-blue.png'
import Input from 'components/Input'
import FileInput from 'components/FileInput'
import CircularProgress from '@material-ui/core/CircularProgress'

import userMaleImg from 'assets/images/user_male.png'
import userFemaleImg from 'assets/images/user_female.png'

import {
  FormContainer,
  TitleLabel,
  Container,
  SelectWrapper,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  Row,
  RowLeft,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ErrorMessage,
  InputWrapperMargin
} from './styled'

import { connect } from 'react-redux'
import mapRoles from "selectors/mapRoles"
import mapPropietaries from "selectors/mapPropietaries"
import { fetchRoles, fetchgetMenus, fetchgetPersonByNeighbourhood, fetchRefreshMenusForRole } from "store/actions/profile.actions"
import { deleteUsuarioById, deleteUsuarioFail, deleteUsuarioSuccess, deleteUsuarioStart } from 'store/actions/users.actions'


class RemoveUser extends Component {
  state = {
    customRoles: [],
  }

  componentDidMount() {
    this.props.fetchRoles()
    this.props.fetchgetMenus()
    this.props.fetchgetPersonByNeighbourhood()
  }

  componentWillReceiveProps() {
    this.props.profile.menus.map(menu => {
      if (menu.code.indexOf("_BO") !== -1) {
        this.setState(prevState => ({
          allMenus: {
            ...prevState.allMenus,
            [menu.code]: 0
          }
        }))
      }
    })
  }

  handleSelectRoles = value => {
    this.setState({ selectedRoles: value })
    if (value.length !== 0) {
      value[0].menus.map(menu => {
        if (menu.code.indexOf("_BO") !== -1) {
          this.setState(prevState => ({
            customRoles: {
              ...prevState.customRoles,
              [menu.id]: 1
            }
          }))
        }
      })
    } else {
      this.props.profile.menus.map(menu => {
        if (menu.code.indexOf("_BO") !== -1) {
          this.setState(prevState => ({
            customRoles: {
              ...prevState.customRoles,
              [menu.id]: 0
            }
          }))
        }
      })
    }
  }

  handleSelectRoles = value => {
    this.setState({ selectedRoles: value })
    if (value.length !== 0) {
      value[0].menus.map(menu => {
        if (menu.code.indexOf("_BO") !== -1) {
          this.setState(prevState => ({
            customRoles: {
              ...prevState.customRoles,
              [menu.id]: 1
            }
          }))
        }
      })
    } else {
      this.props.profile.menus.map(menu => {
        if (menu.code.indexOf("_BO") !== -1) {
          this.setState(prevState => ({
            customRoles: {
              ...prevState.customRoles,
              [menu.id]: 0
            }
          }))
        }
      })
    }
  }

  sendRol() {
  }

  getNeighbourhoodOptions = () => {

    console.log('barrios ', this.props.neighbourhoods)
    this.props.neighbourhoods.map(n => ({ label: n.name, value: n.guid }))

  }


  handleSubmit = async (dataId) => {
    console.log('data post delete', dataId)
    await this.props.deleteUsuarioById(dataId)
    this.props.onClose(true)
    this.props.fetchUsers()
  }

  render() {
    return (
      <Modal>
        <FormContainer>
          <Row>
            <TitleLabel> ¿ Esta seguro que desea eliminar al usuario ? </TitleLabel>
            <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
          </Row>
          <Row>
            <TitleLabel>{this.props.userToRemove.title ? this.props.userToRemove.title : ""}</TitleLabel>
          </Row>
          <ButtonWrapper>
            <StyledButton onClick={() => this.handleSubmit(this.props.userToRemove.username)} type='button'>Eliminar</StyledButton>
          </ButtonWrapper>
        </FormContainer>
      </Modal>
    )
  }




}

const mapStateToProps = ({
  profile,
  userInfo,
  userInfo: { neighbourhoods },
}) => ({
  profile,
  roles: mapRoles(profile.roles),
  propietaries: mapPropietaries(profile.persons),
  userInfo,
  neighbourhoods
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchRoles, fetchgetMenus, deleteUsuarioById, fetchgetPersonByNeighbourhood, fetchRefreshMenusForRole }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveUser)
