async function fetchData(guid,url,method,body){
    const tokenId = localStorage.getItem('tokenId')
    let response = { error: '',errorMsg: '', response: ''}
    const DataFetch = await fetch(url,
    {
        method: method, 
        body: JSON.stringify(body),
        headers: {
            Authorization: `Bearer ${tokenId}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-barrio': guid
        }
    }) 
    .then(response => response.json())
    .then((res) => {         
        response.response = res
        return response
    })
    .catch((err)=> {
        response.error = err
        return response
    })
        
}


export default fetchData
