import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross.png'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from 'components/Input'
import FileImageInput from 'components/FileImageInput'
import FileInputGroup from 'components/FileInputGroup'
import { httpsOnly } from 'constants/regularExpresions.js'
import PageLoader from 'components/PageLoader';
import TextArea from 'components/TextArea';
import {
  FormContainer,
  Row,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  Title,
  FlexWrapper
} from './styled'

class NewsModal extends React.Component {

  getInitialValues = () => {
    return ({
      id: this.props.detailNews ? this.props.detailNews.id : null,
      title: this.props.detailNews ? this.props.detailNews.title : '',
      shortDescription: this.props.detailNews ? this.props.detailNews.shortDescription : '',
      description: this.props.detailNews ? this.props.detailNews.description : '',
      imageUrl: this.props.detailNews ? this.props.detailNews.imageUrl : '',
      author: this.props.detailNews ? this.props.detailNews.author : '',
      tags: this.props.detailNews ? this.props.detailNews.tags : '',
      order: this.props.detailNews ? this.props.detailNews.order : 1
    })
  }

  getSignUpSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        title: Yup.string().required('Ingrese un título'),
        shortDescription: Yup.string().required('Ingrese una descripción corta'),
        description: Yup.object().nullable(),
        author: Yup.object().nullable(),
        tags: Yup.object().nullable(),
        imageUrl: Yup.object().nullable(),
        order: Yup.number().moreThan(0,'El orden debe ser un número mayor a 0').required('Ingrese un orden de prioridad'),
      })
    )

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => {
    return (
      <FormContainer onSubmit={handleSubmit} >
        <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
        <FlexWrapper>
          <Title>{this.props.tittle}</Title>
        </FlexWrapper>
        <FlexWrapper>
          <FlexWrapper width={70}>
            <InputWrapper width={70}>
              <InputLabel>{'Título'}</InputLabel>
              <Input
                name={'nameInput'}
                label={'nameInput'}
                placeholder={'Ingrese un título...'}
                value={values.title}
                error={errors.title}
                onChange={({ target: value }) => setFieldValue('title', value.value)}
                margin="normal"
                disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
              />
              {errors.title && <ErrorLabel>{errors.title}</ErrorLabel>}
            </InputWrapper>
          <InputWrapper width={30}>
            <InputLabel>{'Orden'}</InputLabel>
            <Input
              type="number"
              name={'orderInput'}
              label={'orderInput'}
              placeholder={'Ingrese el orden de prioridad...'}
              value={values.order}
              error={errors.order}
              onChange={({ target: value }) => setFieldValue('order', value.value)}
              margin="normal"
              disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
            />
            {errors.order && <ErrorLabel>{errors.order}</ErrorLabel>}
          </InputWrapper>

            <InputWrapper>
              <InputLabel>{'Autor'}</InputLabel>
              <Input
                type="text"
                name={'authorInput'}
                label={'authorInput'}
                placeholder={'Ingrese el autor...'}
                value={values.author}
                error={errors.author}
                onChange={({ target: value }) => setFieldValue('author', value.value)}
                margin="normal"
                disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
              />
              {errors.author && <ErrorLabel>{errors.author}</ErrorLabel>}
            </InputWrapper>

            <InputWrapper>
              <InputLabel>{'Descripción corta'}</InputLabel>
              <Input
                type="text"
                name={'shortDescriptionInput'}
                label={'shortDescriptionInput'}
                placeholder={'Ingrese una descripción corta...'}
                value={values.shortDescription}
                error={errors.shortDescription}
                onChange={({ target: value }) => setFieldValue('shortDescription', value.value)}
                margin="normal"
                disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
              />
              {errors.shortDescription && <ErrorLabel>{errors.shortDescription}</ErrorLabel>}
            </InputWrapper>


          </FlexWrapper>
          <FlexWrapper width={30}>
            <InputWrapper>
              <InputLabel>{'Imagen'}</InputLabel>
              <FileImageInput width="100%"
                disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
                value={values.imageUrl}
                onChange={(file) => setFieldValue('imageUrl', file)} />
              {errors.imageUrl && <ErrorLabel textAlign="center">{errors.imageUrl}</ErrorLabel>}
            </InputWrapper>
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <InputWrapper>
            <InputLabel>{'Descripción'}</InputLabel>
            <TextArea
              minWidth="0"
              maxWidth="1024"
              minHeight="110"
              maxHeight="110"
              name={'descriptionInput'}
              label={'descriptionInput'}
              placeholder={'Ingrese una descripción...'}
              value={values.description}
              error={errors.description}
              onChange={({ target: value }) => setFieldValue('description', value.value)}
              margin="normal"
              disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
            ></TextArea>
            {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper>
            <InputLabel>{'Tags'}</InputLabel>
            <Input
              name={'tagsInput'}
              label={'tagsInput'}
              placeholder={'Ingrese las etiquetas'}
              value={values.tags}
              error={errors.tags}
              onChange={({ target: value }) => setFieldValue('tags', value.value)}
              margin="normal"
              disabled={this.props.isReadOnly ? this.props.isReadOnly : false}
            />
            {errors.tags && <ErrorLabel>{errors.tags}</ErrorLabel>}
          </InputWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <ButtonWrapper>
            <StyledButton type={'submit'} disabled={this.props.isSubmittingNews} hidden={this.props.isReadOnly}>
              {this.props.isSubmittingNews ? (
                <CircularProgress size={18} />
              ) : (
                'Publicar'
              )}
            </StyledButton>
          </ButtonWrapper>
        </FlexWrapper>
      </FormContainer>
    );
  }

  render() {
    if (this.props.loading) {
      return (<PageLoader />);
    }

    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.props.onSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}


export default NewsModal;