import React, { Component } from 'react'
import Page from 'components/Page'
import Section from 'components/Section'
import Select from 'react-select';
import { base64Formatter } from '../../../../utils/base64formatter';
import styled, { css } from 'styled-components'
import ImageUploadPlaceholder from 'components/ImageUploadPlaceholder';
import plus from 'assets/images/registro.png';
import Buttom from 'components/ButtonMaterial';
import { PageWrapper, Wrapper, ButtonContinuar, Row, Title, WrapperForm, StyledButton } from "./styled"
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import ProgressBar from 'components/ProgressBar';
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import PageLoader from 'components/PageLoader';

import Paso1 from './paso1';
import Paso2 from './paso2';
import Paso3 from './paso3';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchGetShippingMethods, fetchAddProduct, fetchEditProduct } from 'store/actions/clasificados.actions'
import { getFullDateTimePlus, getFullDateTime } from 'utils/dateParser'

import compose from 'recompose/compose'

const Algo = styled.div``

const GridContainer = styled.div`  
    display: grid;
    width: 40%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    
    @media (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`


const GridContainerImagenes = styled.div`  
    display: grid;
    width: 40%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    
    @media (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`


const GridContainerDataMapped = styled.div`  
    display: grid;
    width: 40%;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    
    @media (max-width: 650px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 0px;
    }
`

class EditProduct extends Component {
    state = {
        step: 1,
        activeIndex: 0,
        formData: [
            { validated: false },
            { validated: false },
            { validated: false },
        ]
    }

    getStep = () => {
        switch (this.state.step) {
            case 1:
                return (
                    <Paso1
                        formDataValidation={this.state.formData[0].validated}
                        changeIndex={this.changeIndex.bind(this)}
                        getFormData={this.getFormData}
                        index={0}
                        active={this.state.activeIndex === 0}
                        producto={this.props.producto}
                        handleContinuar={() => this.handleContinuar()}
                    />
                )

            case 2:
                return (
                    <Paso2
                        formDataValidation={this.state.formData[1].validated}
                        changeIndex={this.changeIndex.bind(this)}
                        getFormData={this.getFormData}
                        index={1}
                        producto={this.props.producto}
                        active={this.state.activeIndex === 1}
                        handleContinuar={() => this.handleContinuar()}
                    />
                )
            case 3:
                return (
                    <Paso3
                        formDataValidation={this.state.formData[2].validated}
                        changeIndex={this.changeIndex.bind(this)}
                        getFormData={this.getFormData}
                        index={2}
                        producto={this.props.producto}
                        active={this.state.activeIndex === 2}
                        handleEditProduct={() => this.handleEditProduct()}
                        backtoClasif={() => this.props.onClose()}
                    />
                )
        }
    }

    handleContinuar = () => {
        this.setState({ step: this.state.step + 1 });
    }


    handleEditProduct = () => {
        const { formData } = this.state;
        const paso1 = formData[0];
        const paso2 = formData[1];
        const paso3 = formData[2];

        const formEditProduct = {
            'id': this.props.producto.values.id,
            'title': paso1.titulo,
            'shopitemstateid': 1,
            'description': paso1.descripcion,
            'shopitemconditionid': paso2.itemCondition.value,
            'shopitemcategoryid': paso1.categoria.value,
            'quantity': paso2.stock,
            'publicationstartdate': getFullDateTime(),
            'publicationenddate': getFullDateTimePlus(30),
            'keywords':
                [],
            'prices':
                [{
                    'price': paso2.importe,
                    'currency': 'ARS'
                }],

        };

        //acceptedpaymentmethodlist
        formEditProduct.deliverymethods = paso3.deliverymethods.map(deliver => ({
            id: deliver.value
        }))

        //barrios
        formEditProduct.neighbourhoods = this.props.userInfo.neighbourhoods.map(barrio => ({
            neighbourhoodguid: barrio.guid,
            neighbourhoodname: barrio.name
        }))

        //acceptedpaymentmethodlist
        formEditProduct.acceptedpaymentmethodlist = paso3.acceptedpaymentmethodlist.map(pay => ({
            paymentmethodcode: pay.value,
            paymentmethodname: pay.label
        }))


        if (paso3.images !== undefined) {
            formEditProduct.newImages = paso3.images.map(image => ({
                ImageBase64: image,
                extension: this.guessImageMime(image)
            }))
        }

        if(paso3.file !== undefined){
            formEditProduct.images = paso3.file.map(image => (image))
        }

        this.props.fetchEditProduct(formEditProduct)
        this.props.onClose()

    }

    guessImageMime(data) {
        if (data.charAt(0) == '/') {
            return "JPEG";
        } else if (data.charAt(0) == 'R') {
            return "GIF";
        } else if (data.charAt(0) == 'i') {
            return "PNG";
        }
    }

    getFormData = (data, index) => {
        const currentState = [...this.state.formData];
        currentState[index] = { validated: true, ...data };
        this.setState({
            formData: currentState
        });
        this.changeIndex(index, true);
    }

    changeIndex = (index, next) => {
        const { activeIndex } = this.state
        let newIndex;
        if (next) {
            newIndex = activeIndex;
            newIndex++;
        } else {
            newIndex = activeIndex === index ? -1 : index
        }
        this.setState({ activeIndex: newIndex })
    }

    handleVolver = () => {
        this.setState({ step: this.state.step - 1 });
    }

    openWebcamModal = () => {
        this.setState({ cameraModalOpen: !this.state.cameraModalOpen, });
    }

    render() {

        return (
            <Page>
                <PageWrapper>
                    {this.props.producto !== undefined && this.props.producto.values !== undefined ?
                        <Section
                            title={'Editar producto'}
                            onBack={() => this.props.onClose()}>
                            <Algo>
                                <WrapperForm>
                                    {this.getStep()}
                                </WrapperForm>
                            </Algo>
                        </Section>
                        :
                        <>
                            <PageLoader />
                        </>
                    }
                </PageWrapper>
            </Page>
        )
    }
}
const mapStateToProps = ({
    clasificados,
    selectedGuest,
    userInfo
}) => ({
    clasificados,
    fetchAddProductSuccess: clasificados.fetchAddProductSuccess,
    fetchAddProductFail: clasificados.fetchAddProductFail,
    loadingAddProduct: clasificados.loadingAddProduct,
    userInfo

})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ fetchAddProduct, fetchEditProduct, fetchGetShippingMethods }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(EditProduct)
)