import React from 'react'
import styled from 'styled-components'

const InsertDriveFileIcon = props => (
  <svg
    className="prefix__MuiSvgIcon-root-1015 prefix__sc-eIHaNI prefix__bWRgyL"
    viewBox="0 0 18 18"
    aria-hidden="true"
    {...props}
  >
    <path d="M4 1c-.55 0-.99.45-.99 1L3 16c0 .55.44 1 1 1h10c.55 0 1-.45 1-1V6l-5-5H4zm6 5V2l4 4h-4z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
)


export const InsertDriveFileIconStyled = styled(InsertDriveFileIcon)`
  fill-opacity: ${({ translucent }) =>  translucent || '1'};
  border-style: solid;
  border-width: ${({border }) => border ? "0.5px" : "0"};
  border-radius: 8px;
  padding: 1%;
  margin: ${({margin }) => margin ? margin : "none"};
  &:hover{
    fill-opacity: 1;
    transition: all 0.3s ease-in-out, background-position 1ms;
    transform: scale(1.1,1.1);
    fill: ${({ theme }) => theme.colors.blueRibbon};
    cursor: pointer;
    border-color: ${({border, theme }) => border ? theme.colors.blueRibbon : "none"};
  }

`
export default InsertDriveFileIconStyled
