import themes from './themes'
export const configuredTheme = themes[1].theme

export const configuredLayout = {
  currentLayout: 'toolbar'
}

export const menuItems = [
  {
    title: 'Tickets',
    href: '/tickets'
  },
  {
    title: 'Users',
    href: '/users'
  },
  {
    title: 'Feed',
    href: '/feed'
  },
  {
    title: 'Padron',
    href: '/padron'
  },
  {
    title: 'Reservas',
    href: '/reservas'
  },
  {
    title: 'Invitados',
    href: '/invitados'
  },
  {
    title: 'Proveedores',
    href: '/proveedores'
  },
  {
    title: 'Personal de Seguridad',
    href: '/seguridad'
  },
  {
    title: 'Accesos',
    href: '/accesos'
  },
  {
    title: 'Shops',
    href: '/shops'
  },
  {
    title: 'Reportes',
    href: '/reportes'
  },
  {
    title: 'Fundacion',
    href: '/fundacion'
  }
  ,
  {
    title: 'Gestor',
    href: '/gestor'
  }
]
