import React from 'react'
import Page from 'components/Page'
import Section from 'components/Section'
import EmptyLoader from 'components/PageLoader'
import { Wrapper, Container, Row, Circle, ColumnItem, StyledButton, ErrorLabel, ContainerImg, Icon, PageWrapper, ContainerSearchBar, SearchWrapper } from './styled'
import MaterialTable from 'material-table'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageLoader from '../../../components/PageLoader'
import { fetchStory, fetchStories, fetchStoriesByName, editStory, editStoryReset, fetchDeleteStory, fetchDeleteStoryReset, createNewStory, createNewStoryReset, fetchDetailStoryReset } from 'store/actions/fundacion.actions';
import SearchBar from 'components/SearchBar'
import eyeIcon from 'assets/icons/eyeIcon.svg'
import editIcon from 'assets/icons/editIcon.svg'
import Delete from 'assets/icons/deletetrash.svg'
import DeleteModal from '../components/modalDelete'
import StoryModal from '../components/storyModal'
import ModalMessage from 'components/ModalMessage'

class Stories extends React.Component {

  state = {
    filter: '',
    isFiltered: false,
    transferSelected: [],
    error: '',
    newResultModal: false,
    tabEnabled: 0,
    addStory: false,
    showDetail: false,
    editStory: false,
    deleteModal: false
  }

  componentDidMount() {
    this.props.fetchStories()
  }

  isContentAvailable = () => (this.props.fetchStoriesSucces === true && this.props.loadingStories === false) ? true : false


  toggleAddModal = () => {
    this.setState({ addStory: !this.state.addStory }, () => {
      if (!this.state.addStory) {
        this.props.createNewStoryReset();
        this.fetchStories()
      }
    });
  }

  toggleEditModal = () => {
    this.setState({ editStory: !this.state.editStory }, () => {
      if (!this.state.editStory) {
        this.fetchStories()
      }
    });
  }

  toggleDetailModal = () => {
    this.setState({ showDetail: !this.state.showDetail }, () => {
      if (!this.state.showDetail) {
        this.fetchStories()
      }
    });
  }

  toggleDeleteModal = () => {
    this.props.fetchDeleteStoryReset();
    this.setState({ deleteModal: !this.state.deleteModal });
  }

  handleEditStory = (idStory) => {
    this.props.editStoryReset();
    this.props.fetchDetailStoryReset();
    this.props.fetchStory(idStory);
    this.setState({ idToEdit: idStory }, this.toggleEditModal())
  }

  handleDetailStory = (idStory) => {
    this.props.fetchDetailStoryReset();
    this.props.fetchStory(idStory);
    this.setState({ idToDetail: idStory }, this.toggleDetailModal(idStory))
  }

  handleDeleteStory = (idStory) => {
    this.setState({ idToDelete: idStory }, this.toggleDeleteModal())
  }

  fetchStories = () => {
    if (this.state.isFiltered) {
      this.props.fetchStoriesByName(this.state.filter);
      return;
    }

    this.props.fetchStories();
  }


  async DeleteStory() {
    this.props.fetchDeleteStory(this.state.idToDelete)
  }

  createStoryHandler = async (data) => {
    let formattedData = {
      name: data.name,
      description: data.description,
      pictureBase64: data.imageUrl ? data.imageUrl.base64Value : null,
      imageExtension: data.imageUrl ? data.imageUrl.extension : null,
      storyUrl: data.storyUrl,
      order: data.order,
      storyRaw: data.storyRaw,
      isPublic: data.isPublic
    };

    await this.props.createNewStory(formattedData);
  }

  editStoryHandler = async (data) => {
    let formattedData = {
      id: data.id,
      name: data.name,
      description: data.description,
      pictureBase64: data.imageUrl ? data.imageUrl.base64Value : null,
      imageExtension: data.imageUrl ? data.imageUrl.extension : null,
      storyUrl: data.storyUrl,
      order: data.order,
      storyRaw: data.storyRaw,
      isPublic: data.isPublic
    };
    await this.props.editStory(formattedData);
  }

  searchBarKeyPressHandler = e => {
    if (e.keyCode === 13) {
      if (e.target.value !== '') {
        this.setState({ isFiltered: true });
        this.props.fetchStoriesByName(e.target.value)
      } else {
        this.props.fetchStories();
      }
    }
  }

  searchBarInputClearHandler = () => {
    this.setState({ filter: '', isFiltered: false });
    this.props.fetchStories();
  }

  searchBarInputOnChange = value => {
    this.setState({ filter: value })
  }


  renderPageContent = () => {
    return (
      <SearchWrapper>
        <ContainerSearchBar>
          <SearchBar
            placeholder={'Buscar por Nombre'}
            style={'width: \'70%\' '}
            value={this.state.filter}
            onChange={this.searchBarInputOnChange}
            onClear={this.searchBarInputClearHandler}
            noBorder={true}
            onKeyDown={this.searchBarKeyPressHandler}
            autofocus
          />
        </ContainerSearchBar>
        <Container>
          <MaterialTable
            columns={[
              { title: 'Nombre/s', field: 'name', },
              { title: 'Fecha de creación', field: 'creationDate', },
              { title: 'Orden', field: 'order', },
              { title: 'Es publico', field: 'isPublicGrid', },
              {
                title: 'Acciones', field: 'publication', render: rowData =>
                  <ContainerImg>
                    <Icon src={eyeIcon} onClick={() => this.handleDetailStory(rowData.id)} />
                    <Icon src={editIcon} onClick={() => this.handleEditStory(rowData.id)} />
                    <Icon src={Delete} onClick={() => this.handleDeleteStory(rowData.id)} />
                  </ContainerImg>
              }
            ]}
            data={this.props.stories}

            localization={{
              header: {
                actions: 'Acciones',
              },
              body: { emptyDataSourceMessage: 'No hay datos para mostrar' }
            }}
            options={{
              toolbar: false,
              paging: false,
              height: '300px',
              width: '70vw',
              selection: false,
              actionsColumnIndex: -1,
              headerStyle: {
                zIndex: 0
              }
            }} //Esconde titulo y search y pagination 
          />
        </Container>
      </SearchWrapper>
    )
  }

  renderModalMessageSuccess = (message, callback) => (<ModalMessage success={true} onClose={() => callback()}>
    {message}
  </ModalMessage>);

  renderModalMessageFail = (message, callback) => (<ModalMessage success={false} onClose={() => callback()}>
    {message}
  </ModalMessage>);

  render() {
    //ADD MODAL
    let addModal = null;
    if (this.props.createNewStorySuccess) {
      addModal = this.renderModalMessageSuccess('Se ha creado el cuento correctamente.', this.toggleAddModal);
    }
    else if (this.props.createNewStoryFail) {
      editModal = this.renderModalMessageFail('Hubo un error al intentar crear el cuento.', this.toggleAddModal);
    }
    else if (this.state.addStory) {
      addModal = <StoryModal tittle="Agregar Cuento"
        onClose={() => this.toggleAddModal()}
        onSubmit={this.createStoryHandler}
        isSubmittingStory={this.props.loadingCreateNewStory}
        uploadProgress={this.props.uploadStoryProgress} />;
    }

    //EDIT MODAL
    let editModal = null;
    if (this.props.editStorySuccess) {
      editModal = this.renderModalMessageSuccess('Se ha editado el cuento correctamente.', this.toggleEditModal);
    }
    else if (this.props.editStoryFail) {
      editModal = this.renderModalMessageFail('Hubo un error al intentar editar el cuento.', this.toggleEditModal);
    }
    else if (this.state.editStory) {
      editModal = <StoryModal tittle="Editar Cuento"
        isReadOnly={false}
        onSubmit={this.editStoryHandler}
        loading={this.props.loadingDetailStory}
        onClose={() => { this.toggleEditModal() }}
        isSubmittingStory={this.props.loadingEditStory}
        detailStory={this.props.detailStory}
        uploadProgress={this.props.uploadStoryProgress} />;
    }

    //DETAIL MODAL
    let detailModal = null;
    if (this.state.showDetail) {
      detailModal = <StoryModal tittle="Detalle"
        isReadOnly={true}
        onClose={() => { this.toggleDetailModal() }}
        loading={this.props.loadingDetailStory}
        detailStory={this.props.detailStory} />;
    }

    let deleteModal = null;
    if (this.state.deleteModal) {
      deleteModal = <DeleteModal success={this.props.successDeleteStory}
        isStory={false}
        loadingDelete={this.props.loadingDeleteStory}
        onClose={() => this.toggleDeleteModal()}
        delete={() => this.DeleteStory()} />;
    }

    return (
      <>
        {!this.state.addStory && !this.state.editStory && !this.state.showDetail ?
          <Page>
            <PageWrapper>
              <Section title={'Listado de cuentos'}
                onBack={() => this.props.onClose()}
                btnContent={'Agregar cuento'}
                onBtnClick={() => this.toggleAddModal()}>
                <Wrapper>
                  {this.isContentAvailable() ? this.renderPageContent() : <EmptyLoader />}
                </Wrapper>
              </Section>
            </PageWrapper>
          </Page>
          : null
        }
        {addModal}
        {editModal}
        {detailModal}
        {deleteModal}
      </>
    )
  }
}

const mapStateToProps = ({
  fundacion
}) => ({
  loadingStories: fundacion.loadingStories,
  stories: fundacion.stories,
  detailStory: fundacion.detailStory,
  loadingEditStory: fundacion.loadingEditStory,
  editStorySuccess: fundacion.editStorySuccess,
  editStoryFail: fundacion.editStoryFail,
  loadingDetailStory: fundacion.loadingDetailStory,
  fetchStoriesSucces: fundacion.fetchStoriesSuccess,
  successDeleteStory: fundacion.fetchDeleteStorySuccess,
  loadingDeleteStory: fundacion.loadingDeleteStory,
  loadingCreateNewStory: fundacion.loadingCreateNewStory,
  createNewStorySuccess: fundacion.createNewStorySuccess,
  createNewStoryFail: fundacion.createNewStoryFail,
  submitErrorStory: fundacion.createNewStoryFail,
  uploadStoryProgress: fundacion.uploadStoryProgress,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchStories,
      fetchStory,
      fetchStoriesByName,
      fetchDetailStoryReset,
      fetchDeleteStoryReset,
      fetchDeleteStory,
      createNewStory,
      createNewStoryReset,
      editStory,
      editStoryReset
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stories)