import React from 'react'
import {
  SectionTitle,
  Row,
  StyledButton,
  Wrapper,
  CrossImage,
  CrossWrapper,
  SectionError,
  SelectContainer,
  ErrorWrapper
} from './styled'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross.png'
import Select from 'react-select'
import CircularProgress from '@material-ui/core/CircularProgress'


class ModalChangeState extends React.Component {
    state={
        options: [
            {value: "El ticket a sido resuelto exitosamente",label: "El ticket a sido resuelto exitosamente"},
            {value: "Sin respuesta del propietario",label: "Sin respuesta del propietario"},
            {value: "Rechazado por administrador",label: "Rechazado por administrador"},
            {value: "Rechazado por usuario",label: "Rechazado por usuario"},
            {value: "En consulta con área",label: "En consulta con área"},
            {value: "Ticket cerrado por tiempo sin respuesta",label: "Ticket cerrado por tiempo sin respuesta"},
        ],
        optionSelected: ""
    }

    handleOption=(option)=>{
        this.setState({optionSelected: option})
    }

    render(){
        const { title, onBtnClick, btnContent, onBtnClose, onLoading, onError,onSuccess } = this.props
        return(
            <Modal>
            <Wrapper>
                <CrossWrapper>
                <CrossImage onClick={onBtnClose} src={CrossIcon} />
                </CrossWrapper>
                <Row>
                <SectionTitle>{title}</SectionTitle>
                    <SelectContainer>
                        <Select
                            value={this.state.optionSelected}
                            onChange={this.handleOption}
                            options={this.state.options}
                            placeholder="Seleccione una opcion"
                        />
                    </SelectContainer>
                    {onError && <SectionError>{onError}</SectionError>}
                    <StyledButton onClick={()=>this.props.onBtnClick(this.state.optionSelected.label)}>
                        {onLoading ? <CircularProgress size={18} /> : "Enviar"}
                    </StyledButton>
                </Row>
                {onSuccess ? onBtnClose() : ""}
            </Wrapper>
            </Modal>
        )
    }
}

export default ModalChangeState
