import React from 'react'
import Input from 'components/Input'
import InputMask from 'react-input-mask'
import CloseImg from 'assets/icons/cross.png'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { isTimeRangeValid } from 'utils/timeFormatter'
import Select from 'react-select';
import mapRoles from 'selectors/mapRoles'

import {
  DetailContainer,
  FormContainer,
  Title,
  Name,
  CancelButton,
  Row, TitleModal,
  Column,
  SaveButton,
  Close, CrossImage,
  ErrorLabel,
  ButtonWrapper,
  ButtonWrapperBooking,
  StyledLabel,
  SelectWrapper,
  StyledLabelBooking,
  StyledButtonBooking,
  StyledButton, Componentaaaa
} from './styled'
import Modal from 'components/ModalReservas'
import DetailTitle from 'components/DetailTitle'
import CrossIcon from 'assets/icons/cross.png'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { deleteReservationById, generateBooking } from 'store/actions/reservas.actions'
import { fetchActiveTimes } from 'store/actions/activeTimes.actions'
import compose from 'recompose/compose'
import withWidth from '@material-ui/core/withWidth'
import ModalMessage from 'components/ModalMessage'
import { getFormattedDateYear } from 'utils/dateParser'
import CircularProgress from '@material-ui/core/CircularProgress'

const motivosData = [
  { label: 'Mal clima', value: '0' },
  { label: 'Evacuacion', value: '1' },
  { label: 'Falta abonar', value: '3' }
]
class DetailModal extends React.Component {
  state = {
    price: '',
    modalDeleteReserva: false,
    modalGenerateReserva: false,
    NameBooking: null,
    SurnameBooking: null,
    Reason: null,
    Observations: null,
    activeTimeId: null,
    payment: null,
    nameError: '',
    surnameError: ''
  }

  componentDidMount = () => {
  }

  checkData = () => {
    if (!this.hasErrors()) {
      this.handleGenerarReserva()
    }
  }

  hasErrors = () => {
    let hasError = false
    if (this.state.NameBooking === null) {
      this.setState({ nameError: 'Debe ingresar un nombre' })
      hasError = true
    }
    if (this.state.SurnameBooking === null) {
      this.setState({ surnameError: 'Debe ingresar un apellido' })
      hasError = true
    }
    return hasError
  }

  toggleGenerateReserva() {
    this.setState({ modalGenerateReserva: !this.state.modalGenerateReserva })
  }

  toggleDeleteReserva() {
    this.setState({ modalDeleteReserva: !this.state.modalDeleteReserva })
  }

  handleDeleteReserva = async (idReserva) => {
    await this.props.deleteReservationById(idReserva)
    this.props.cerrarModalDetalle()
  }
  handleNameBooking = (data) => {
    this.setState({ NameBooking: data.target.value })
  }
  handleSurnameBooking = (data) => {

    this.setState({ SurnameBooking: data.target.value })
  }
  handleObservations = (data) => {

    this.setState({ Observations: data.target.value })
  }
  handleReason = (data) => {
    this.setState({ Reason: data.target.value })
  }

  handleGenerarReserva = async () => {
    const formattedBody = {
      host_username: this.props.userInfo.username,
      start_date: this.props.date,
      active_time_id: this.props.timeRange.id,
      // attendees: [],
      // payment:[],
      Reason: this.state.Reason,
      Observations: this.state.Observations,
      NameBooking: this.state.NameBooking,
      SurnameBooking: this.state.SurnameBooking,
      IsAdminBooking: true
    }
    await this.props.generateBooking(formattedBody)

    this.props.cerrarModalDetalle()
    this.props.fetchActiveTimes(this.props.selectedSpace, getFormattedDateYear(this.state.startDate));
  }

  renderDetail = () => (
    <>
      <DetailContainer leftPopUp={this.props.leftPopUp} bottomPopUp={this.props.bottomPopUp}>
        <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
        <Title>{'Reservado por:'}</Title>
        <Name marginBottom={'10px'} >{this.props.booking.host_full_name}</Name>
        <Title marginBottom={'10px'}>{'Importe: $'} <Name>{this.props.originalData.price}</Name></Title>
        <Title marginBottom={'10px'}><Name>Desde {this.props.originalData.from} hasta {this.props.originalData.to}</Name></Title>
        {this.props.booking.is_admin_booking ?
          <div>
            <Row>
              <Title>{'A nombre de:'}</Title>
            </Row>
            <Name marginBottom={'10px'} >{this.props.booking.name_booking + " " + this.props.booking.surname_booking}</Name>
            {this.props.booking.observations != null ?
              <div>
                <Row>
                  <Title>{'Observaciones:'}</Title>
                </Row>
                <Name marginBottom={'10px'} >{this.props.booking.observations != null ? this.props.booking.observations : ""}</Name>
              </div> : ""}
            {this.props.booking.reason != null ?
              <div>
                <Row>
                  <Title>{'Motivo:'}</Title>
                </Row>
                <Name marginBottom={'10px'} >{this.props.booking.reason != null ? this.props.booking.reason : ""}</Name>
              </div> : ""}
          </div>
          : null}
      </DetailContainer>
      {this.state.modalDeleteReserva ?

        <Modal top={'25%'} left={'30%'} width={'30vw'} height={'18vh'} display={'grid'}>
          <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
          <TitleModal>{'Esta seguro que quiere bloquear esta reserva?'}</TitleModal>
          <Select
            name={'motivo'}
            autosize={false}
            value={this.state.motivo}
            onChange={values => this.setState({ motivo: values })}
            placeholder="Seleccionar Motivo"
            options={motivosData}
          />
          {this.props.loadingDeleteAPI ?
            <CircularProgress />
            : null
          }
          <ButtonWrapper>
            <StyledButton onClick={() => this.handleDeleteReserva(this.props.originalData.id)} type={'submit'}>Bloquear</StyledButton>
            <StyledButton onClick={() => this.toggleDeleteReserva()} type={'submit'}>Cancelar</StyledButton>
          </ButtonWrapper>
        </Modal>
        :
        null
      }

      {this.props.fecthDeleteReservationIsDone ?
        <ModalMessage
          success={this.props.deletedReservation ? true : false}
          onClose={() => this.toggleDeleteReserva()}>
          {this.props.deletedReservation ? 'Reserva eliminada con exito' : 'Error al eliminar reserva, intente nuevamente.'}
        </ModalMessage>
        :
        <></>
      }


    </>
  )


  renderBooking = (setFieldValue) => (
    <>
      {
        this.props.userInfo.menusAuth.map(menu => {
          if (menu.code === "RESERV_ENCARGADO_BO") {
            return(
            <Modal top={'25%'} left={'30%'} width={'40vw'} height={'40vh'} display={'grid'}>
              <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
              <TitleModal>{'Datos de la reserva'}</TitleModal>
              <FormContainer flexGrow={'3'}>
                <Row>
                  <StyledLabelBooking width="47%">{'Nombre'}
                    <Input
                      value={this.state.NameBooking}
                      error={this.state.nameError}
                      onChange={this.handleNameBooking}
                      disabled={false}
                    />
                    {this.state.nameError && <ErrorLabel>{this.state.nameError}</ErrorLabel>}
                  </StyledLabelBooking>
                  <StyledLabelBooking width="47%">{'Apellido'}
                    <Input
                      value={this.state.SurnameBooking}
                      error={this.state.surnameError}
                      disabled={false}
                      onChange={this.handleSurnameBooking}
                    />
                    {this.state.surnameError && <ErrorLabel>{this.state.surnameError}</ErrorLabel>}
                  </StyledLabelBooking>
                </Row>
                <Row>
                  <StyledLabelBooking>{'Observaciones'}

                    <Input
                      value={this.state.observaciones}
                      disabled={false}
                      onChange={this.handleObservations}
                    />
                  </StyledLabelBooking>
                </Row>
                <Row>
                  <StyledLabelBooking >{'Motivo'}
                    <Input
                      value={this.state.motivo}
                      disabled={false}
                      onChange={this.handleReason}
                    />
                  </StyledLabelBooking>
                </Row>
              </FormContainer>
              <ButtonWrapperBooking>
                <StyledButtonBooking onClick={this.checkData} type={'submit'} >Reservar</StyledButtonBooking>
              </ButtonWrapperBooking>
            </Modal>
            )
          }
        })
        
      }
    </>
  )


  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <DetailContainer leftPopUp={this.props.leftPopUp} bottomPopUp={this.props.bottomPopUp}>
      <FormContainer ref={el => this.myFormRef = el} onSubmit={handleSubmit}>
        <Row>
          <Title>{'Rango inicio:'}</Title>
          <Close src={CloseImg} onClick={this.props.onClose} />
        </Row>
        <Column>
          <InputMask
            mask="99:99"
            value={values.from}
            placeholder={'Ingrese HH:MM'}
            onChange={({ target: { value } }) => setFieldValue('from', value)}
            maskChar={null}
            disabled={true}
          >
            {inputProps => <Input {...inputProps} disabled={true} />}
          </InputMask>
          {errors.from && <ErrorLabel>{errors.from}</ErrorLabel>}
        </Column>
        <Row>
          <Title>{'Rango finalizacion:'}</Title>
        </Row>
        <Column>
          <InputMask
            mask="99:99"
            value={values.to}
            placeholder={'Ingrese HH:MM'}
            onChange={({ target: { value } }) => setFieldValue('to', value)}
            maskChar={null}
            disabled={this.props.originalData}
          >
            {inputProps => <Input {...inputProps} disabled={this.props.originalData} />}
          </InputMask>
          {errors.to && <ErrorLabel>{errors.to}</ErrorLabel>}
        </Column>

        <Row>
          <Title>{'Valor de la reserva:'}</Title>
        </Row>
        <Column>
          <Input
            ref={el => (this.inputRef = el)}
            value={values.price}
            disabled={this.props.isBooked}
            onChange={({ target: { value } }) => setFieldValue('price', value)}
            placeholder={'Precio'}
            type="number"
          />
          {errors.price && <ErrorLabel>{errors.price}</ErrorLabel>}
        </Column>
        {!this.props.isBooked && (
          <Row>
            <CancelButton type="button" onClick={this.deleteRange}>
              {this.props.originalData ? 'Eliminar turno' : 'Cancelar'}
            </CancelButton>
            <SaveButton type="submit">
              {this.props.originalData ? 'Actualizar' : 'Guardar'}
            </SaveButton>
          </Row>
        )}
        {this.props.isBooked && (
          <ErrorLabel>
            {'No puedes editar un rango que se encuentra reservado actualmente'}
          </ErrorLabel>
        )}
      </FormContainer>
    </DetailContainer>
  )


  handleSubmit = formData => {

    if (!this.props.originalData) {
      this.initializeRange(formData)
    } else if (this.props.originalData) {
      this.updateRange(formData)
    }
  }



  initializeRange = formData => {
    let rangeInitialized = {
      from: this.props.timeRange.from,
      to: formData.to,
      is_booked: false,
      is_cancelled: false,
      price: formData.price
    }
    this.props.onRangeCreation(rangeInitialized)
  }

  updateRange = formData => {
    let rangeUpdated = { ...this.props.originalData }
    rangeUpdated.price = formData.price
    this.props.onRangeChange(rangeUpdated)
  }

  toggleDeleteValidation = () => this.setState({ deleteValidation: !this.state.deleteValidation })

  renderDeleteValidation = () => (
    <Modal>
      <DetailTitle>{'Esta seguro que quiere eliminar'}</DetailTitle>
      <ButtonWrapper>
        <StyledButton onClick={this.toggleDeleteValidation} type={'submit'}>
          Cancelar
        </StyledButton>
        <StyledButton onClick={this.deleteRange} type={'submit'}>
          Borrar
        </StyledButton>
      </ButtonWrapper>
    </Modal>
  )

  deleteRange = () =>
    !this.props.originalData
      ? this.props.onClose()
      : this.props.onRangeDelete(this.props.originalData)

  render() {
    if (this.props.edit) {
      const rangeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0,3][0]$/
      const onlyNumberRegex = /^[0-9]*$/

      const SignUpSchema = Yup.object().shape({
        from: Yup.string()
          .matches(rangeRegex, 'Rango horario invalido')
          .required('Ingrese un rango horario'),
        to: Yup.string()
          .matches(rangeRegex, 'Rango horario invalido')
          .test(
            'to-validation',
            'el rango debe ser mayor a el inicio',
            value => value > this.props.timeRange.from
          )
          .test('range-validation', 'El rango ingresado se superpone', value => {
            let isDateValid = true
            if (!this.props.originalData) {
              const timeRange = {
                from: this.props.timeRange.from,
                to: value
              }
              isDateValid = isTimeRangeValid(timeRange, this.props.dayPos, this.props.data)
            }
            return isDateValid
          })
          .required('Ingrese un rango horario'),
        price: Yup.string()
          .matches(onlyNumberRegex, 'Precio invalido')
          .required('Ingrese un precio')
      })


      return (
        <Formik
          initialValues={{
            from: this.props.originalData
              ? this.props.originalData.from
              : this.props.timeRange.from,
            to: this.props.originalData ? this.props.originalData.to : '',
            price: this.props.price || '0'
          }}
          validateOnChange={false}
          validationSchema={SignUpSchema}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      )
    }
    if (this.state.deleteValidation) {
      return this.renderDeleteValidation()
    }
    if (this.props.booking) {
      return this.renderDetail()
    }
    else {
      // this.props.userInfo.menusAuth.map(menu=>{ 
      //   if(menu.code === "TICKETS_ADMIN_BO"){
      return this.renderBooking()
      //   }
      // })
    }
    return null
  }
}


const mapStateToProps = ({ reservas, userInfo, profile }) => {
  return {
    userInfo,
    loadingDeleteAPI: reservas.loadingDeleteReservation,
    deletedReservation: reservas.deletedReservation,
    fecthDeleteReservationIsDone: reservas.fecthDeleteReservationIsDone,
    // roles: mapRoles(profile.roles),
    registeredBooking: (reservas != null && reservas.registeredBooking != null) ? reservas.registeredBooking : []
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteReservationById,
      generateBooking,
      fetchActiveTimes,
      getFormattedDateYear
    }
    ,
    dispatch
  )


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth()
  )(DetailModal)
)
