import React from 'react'
import {
  Wrapper,
  Container,
  Row,
  Circle,
  ColumnItem,
  StyledButton,
  ErrorLabel,
  SelectWrapper,
  TitleWrapper,
  InputWrapper,
  InputLabel,
  FormContainer,
  ButtonWrapper,
} from './styled'
import MaterialTable from 'material-table'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageLoader from '../../../components/PageLoader'
import { fetchAllCategories, fetchTypesByCategory } from 'store/actions/tickets.actions';
import { fetchLotsByNeighbourhood } from 'store/actions/lotes.actions';
import { fetchReporteTickets } from 'store/actions/reportes.actions';
import Select from 'react-select';
import DatePickerStyled from 'components/DatePickerStyled'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Formik } from 'formik'
import * as Yup from 'yup'
import mapCategoriesByRole from 'selectors/mapCategoriesByRole'
import mapLots from 'selectors/mapLots';
import mapReportTickets from 'selectors/mapReportTickets';

class ReporteTickets extends React.Component {

    state = {
        transferSelected: [],
        error: '',
        newResultModal: false,
        tabEnabled: 0,

        properties: [],
        categories: [],
        types: [],

        typesEnabled: false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const orderByLabel = (a,b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (b.label > a.label) {
            return -1;
        }
        return 0;
      }

      var newCategories = nextProps.categories.slice().sort((a, b) => orderByLabel(a,b));
      var newTypes = nextProps.types.slice().sort((a, b) => orderByLabel(a,b));;
      var newProperties = nextProps.properties.slice().sort((a, b) => orderByLabel(a,b));;

      newCategories.unshift({value: '',label: 'Todas'})
      newTypes.unshift({value: '',label: 'Todos'})
      newProperties.unshift({value: '',label: 'Todas'})

      return {
        categories: newCategories,
        types: newTypes,
        properties: newProperties
      };
     }

    getInitialValues = () => ({
      property: this.state.properties[0],
      category: this.state.categories[0],
      type: this.state.types[0],
      startDate: new Date(),
      endDate: new Date()
    })

    componentDidMount() {
      this.props.fetchLotsByNeighbourhood();
      this.props.fetchAllCategories();
    }

    onChangeCategory (setFieldValue, values) {
      setFieldValue('category', values);
      if (values.value == ''){
        setFieldValue('type', this.state.types[0]);
        this.setState({typesEnabled: false});
      }
      else{
        this.props.fetchTypesByCategory(values.value);
        this.setState({typesEnabled: true});
      }
    }

    handleSubmit = data => {

      const fetchData = {
        property: data.property.value == '' ? '' : data.property.label,
        category: data.category.value,
        type: data.type.value,
        startDate: data.startDate,
        endDate: data.endDate
      }
      
      this.props.fetchReporteTickets(fetchData)
    }

    getValidationSchema = () =>
      Yup.lazy(values =>
        Yup.object().shape({
          property: Yup.string().required('Ingrese un lote'),
          category: Yup.string().required('Ingrese una categoría'),
          type: Yup.string().required('Ingrese un tipo'),
          startDate: Yup.string().required('Ingrese una fecha'),
          endDate: Yup.string().required('Ingrese una fecha')
        })
    )

    getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => (
      <FormContainer onSubmit={handleSubmit}>
        <Row>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Lote'}</InputLabel>
            <Select
              name={'property'}
              value={values.property}
              isSearchable={false}
              onChange={values => setFieldValue('property', values)}
              error={errors.property}
              options={this.state.properties}
              placeholder="Seleccione un lote..."
              isDisabled={this.props.loadingLots}
              isLoading={this.props.loadingLots}
              autoFocus
            />
            {errors.property && <ErrorLabel>{errors.property}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Categoría'}</InputLabel>
            <Select
              name={'category'}
              value={values.category}
              isSearchable={false}
              onChange={values => this.onChangeCategory(setFieldValue, values)}
              error={errors.category}
              options={this.state.categories}
              placeholder="Seleccione una categoría..."
              isDisabled={this.props.loadingCategories}
              isLoading={this.props.loadingCategories}
              autoFocus
            />
            {errors.category && <ErrorLabel>{errors.category}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Tipo'}</InputLabel>
            <Select
              name={'type'}
              value={values.type}
              isSearchable={false}
              onChange={values => setFieldValue('type', values)}
              error={errors.type}
              options={this.state.types}
              placeholder="Seleccione un tipo..."
              isDisabled={this.props.loadingTypes || !this.state.typesEnabled}
              isLoading={this.props.loadingTypes}
              autoFocus
            />
            {errors.type && <ErrorLabel>{errors.type}</ErrorLabel>}
          </InputWrapper>
        </Row>
        <Row align="flex-end">
          <InputWrapper margin='20px 15px'>
            <InputLabel>{'Desde'}</InputLabel>
            <DatePickerStyled
              selected={values.startDate}
              startDate={values.startDate}
              endDate={values.endDate}
              selectsStart
              showTimeSelect
              dateFormat="HH:mm dd/MM/yyy"
              onChange={startDate => setFieldValue('startDate', startDate)}
              // minDate={new Date()}
              // maxDate={addMonths(new Date(), 12)}
              placeholderText="Fecha desde"
              width="100%"
              padding="8px 1%"
              popperPlacement="bottom"
              popperModifiers={{
                  flip: {
                      behavior: ['bottom'] // don't allow it to flip to be above
                  },
                  preventOverflow: {
                      enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                  },
                  hide: {
                      enabled: false // turn off since needs preventOverflow to be enabled
                  }
              }}
            />
            {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            <InputLabel>{'Hasta'}</InputLabel>
            <DatePickerStyled
              selected={values.endDate}
              startDate={values.startDate}
              endDate={values.endDate}
              selectsEnd
              showTimeSelect
              dateFormat="HH:mm dd/MM/yyy"
              onChange={startDate => setFieldValue('endDate', startDate)}
              minDate={values.startDate || null}
              // minDate={new Date()}
              // maxDate={addMonths(new Date(), 12)}
              placeholderText="Fecha hasta"
              width="100%"
              padding="8px 1%"
              popperPlacement="bottom"
              popperModifiers={{
                  flip: {
                      behavior: ['bottom'] // don't allow it to flip to be above
                  },
                  preventOverflow: {
                      enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                  },
                  hide: {
                      enabled: false // turn off since needs preventOverflow to be enabled
                  }
              }}
            />
            {errors.date && <ErrorLabel>{errors.date}</ErrorLabel>}
          </InputWrapper>
          <InputWrapper margin={'20px 15px'}>
            {/* <ResetIcon onClick={() => resetForm()} src={TrashIcon} /> */}
            <StyledButton type={'submit'}>
              {this.props.isSubmittingEvent || this.props.isSubmittingArticle ? (
                <CircularProgress size={18} />
              ) : (
                'Filtrar'
              )}
            </StyledButton>
          </InputWrapper>
        </Row>
      </FormContainer>
    );

    forceRowWidth(width, data){
      return (<div style={{minWidth: width}}>  {data}  </div>)
    }

    render() {

      return (
          <>
              <Row>
                  <Container>
                    
                    <Formik
                      initialValues={this.getInitialValues()}
                      validateOnChange={false}
                      validationSchema={this.getValidationSchema()}
                      onSubmit={this.handleSubmit}
                      render={e => this.getFormContent(e)}
                    />
                      {this.props.loadingReporteTickets ? <PageLoader /> :
                          <MaterialTable
                              columns={[
                                  { title: 'Título', field: 'title', render: rowData => this.forceRowWidth('250px', rowData['title']) },
                                  { title: 'Lote', field: 'lot', render: rowData => this.forceRowWidth('60px', rowData['lot']) },
                                  { title: 'Categoría', field: 'categoryname', },
                                  { title: 'Tipo', field: 'typedescription', },
                                  { title: 'Creado por', field: 'createdbyname', },
                                  { title: 'Dni creador', field: 'createdbydni', render: rowData => this.forceRowWidth('60px', rowData['createdbydni']) },
                                  { title: 'Asignado a', field: 'assignedto', },
                                  { title: 'Abierto en', field: 'openingdate', render: rowData => this.forceRowWidth('100px', rowData['openingdate']) },
                                  { title: 'Cerrado en', field: 'closingdate', render: rowData => this.forceRowWidth('110px', rowData['closingdate']) },
                                  { title: 'Estado', field: 'statedescription', },
                                  { title: 'Comentario', field: 'closingcomment', },
                                  // { title: 'Activo', field: 'activedescription', }
                              ]}
                              data={this.props.tickets}
                              // onSelectionChange={(rows) => this.onMovementClick(rows)}
                              title=""
                              options={{
                                  toolbar: true,
                                  paging: true,
                                  selection: false,
                                  exportButton: true,
                                  exportAllData: true,
                                  exportFileName: 'ReporteTickets.'+(new Date().toISOString().split('.')[0])
                              }}
                              localization={{
                                toolbar:{
                                  exportTitle: 'Exportar',
                                  exportAriaLabel: 'Exportar',
                                  exportName: 'Exportar a CSV'
                                },
                                emptyDataSourceMessage: 'No hay datos para mostrar'
                              }}
                          />
                      }
                  </Container>
              </Row>
          </>
      )
    }

}

const mapStateToProps = ({
    reportes, tickets, lotes
}) => ({
    categories: mapCategoriesByRole(tickets.categoriesList),
    types: mapCategoriesByRole(tickets.typesList),
    properties: mapLots(lotes.lotsList),
    loadingReporteTickets: reportes.loadingReporteTickets,
    tickets: mapReportTickets(reportes.tickets),
    loadingCategories: tickets.loadingCategories,
    loadingTypes: tickets.loadingTypes,
    loadingLots: lotes.loadingLots
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          fetchLotsByNeighbourhood,
          fetchAllCategories,
          fetchTypesByCategory,
          fetchReporteTickets
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReporteTickets)