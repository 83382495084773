import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
    expensas: "",
    loadingGenerateExpensas: false,
    errorGenerateExpensas: false,
    errorGenerateExpensasMessage: false,
    descargaFile: false,


    loadingUploadFileExpense: false,
    errorUploadFileExpense: false,

    loadingHomeExpensas: false,
    errorHomeExpensas: false,

    loadingExpensesReport: false,
    errorExpensesReport: false,
    errorExpensesReportMessage: '',

    expensasCliente: [],
    errorexpensasClienteMessage: false,
    errorexpensasCliente: false,
    loadingexpensasCliente: false,

    fileDetailClient: "",
    errorfileDetailClient: false,
    errorfileDetailClientMessage: false,
    loadingfileDetailClient: false,
  }
  
//--------------- Generate file expensas for the bank ------------------------
const fetchGenerateExpensasSuccess = (state, action) => { 
    return updateObject(state, {
        expensas: action.expensas,
        loadingGenerateExpensas: false,
        errorGenerateExpensas: false,
        errorGenerateExpensasMessage: false,
        descargaFile: true,
    })
  }
  
  const fetchGenerateExpensasFail = (state, action) => {
    return updateObject(state, { 
      loadingGenerateExpensas: false,
      errorGenerateExpensas: true,
      errorGenerateExpensasMessage: action.error,
      expensas: "",
      descargaFile: false,
      })
  }
  
  const fetchGenerateExpensasStart = state => {
    return updateObject(state, { loadingGenerateExpensas: true,
      descargaFile: true, errorGenerateExpensas: false, errorGenerateExpensasMessage: false })
  }

//--------------- Generate file expensas for the bank ------------------------
const fetchGenerateAutomaticExpensasSuccess = (state, action) => {
    return updateObject(state, {
        expensas: action.expensas,
        loadingGenerateAutomaticExpensas: false,
        errorGenerateAutomaticExpensas: false,
        errorGenerateAutomaticExpensasMessage: false,
        descargaFile: false,
    })
  }
  
  const fetchGenerateAutomaticExpensasFail = (state, action) => {
    return updateObject(state, { 
      loadingGenerateAutomaticExpensas: false,
      errorGenerateAutomaticExpensas: true,
      errorGenerateAutomaticExpensasMessage: action.error,
      expensas: "",
      descargaFile: false,
      })
  }
  
  const fetchGenerateAutomaticExpensasStart = state => {
    
    return updateObject(state, {  descargaFile: false, loadingGenerateAutomaticExpensas: true, errorGenerateAutomaticExpensas: false, errorGenerateExpensasAutomaticMessage: false })
  }

  const fetchGenerateAutomaticExpensasReset = state => {
    console.log('reseteando --- fetchGenerateAutomaticExpensasReset')
    return updateObject(state, { loadingGenerateAutomaticExpensas: false,expensas:"",  descargaFile: false, })
  }
  

//--------------- Get the data for the Home of Expensas ------------------------
const fetchgetHomeExpensasSuccess = (state, action) => {
  return updateObject(state, {
      homeExpensas: action.homeExpensas,
      loadingHomeExpensas: false,
      errorHomeExpensas: false,
      errorGenerateExpensasMessage: false 
  })
}

const fetchgetHomeExpensasFail = state => {
  return updateObject(state, { loadingHomeExpensas: false, errorHomeExpensas: true })
}

const fetchgetHomeExpensasStart = state => {
  return updateObject(state, { loadingHomeExpensas: true, errorHomeExpensas: false })
}

//--------------- Get the expensas por cliente------------------------
const fetchgetExpensasPorClienteSuccess = (state, action) => {
  return updateObject(state, {
    expensasCliente: action.expensasCliente,
    loadingexpensasCliente: false,
    errorexpensasCliente: false,
    errorexpensasClienteMessage: false
  })
}

const fetchgetExpensasPorClienteFail = (state, action) => {
  return updateObject(state, { 
    loadingexpensasCliente: false,
    errorexpensasCliente: true,
    errorexpensasClienteMessage: action.error
  })
}

const fetchgetExpensasPorClienteStart = state => {
  return updateObject(state, { loadingexpensasCliente: true })
}

//--------------- Get the File detail client ------------------------
const fetchgetFileDetailClientSuccess = (state, action) => {
  return updateObject(state, {
    fileDetailClient: action.fileDetailClient,
    loadingfileDetailClient: false,
    errorfileDetailClient: false,
  })
}

const fetchgetFileDetailClientFail = (state, action) => {
  return updateObject(state, { 
    loadingfileDetailClient: false,
    errorfileDetailClient: true,
    errorfileDetailClientMessage: action.errorfileDetailClient 
    })
}

const fetchgetFileDetailClientStart = state => {
  return updateObject(state, { loadingfileDetailClient: true, errorfileDetailClient: false })
}

//--------------- Get the File detail Neighbourhood ------------------------
const fetchgetFileDetailNeighbourhoodSuccess = (state, action) => {
  return updateObject(state, {
    fileDetailNeighbourhood: action.fileDetailNeighbourhood,
    loadingfileDetailNeighbourhood: false,
    errorfileDetailNeighbourhood: false,
  })
}

const fetchgetFileDetailNeighbourhoodFail = state => {
  return updateObject(state, { loadingfileDetailNeighbourhood: false, errorfileDetailNeighbourhood: true })
}

const fetchgetFileDetailNeighbourhoodStart = state => {
  return updateObject(state, { loadingfileDetailNeighbourhood: true, errorfileDetailNeighbourhood: false })
}

//------------------- Expense Report ---------------------
const fetchExpensesReportSuccess = state => {
  return updateObject(state, {
    loadingExpensesReport: false,
    errorExpensesReport: false,
    errorExpensesReportMessage: ''
  })
}

const fetchExpensesReportFail = (state, action) => {
  return updateObject(state, {
    loadingExpensesReport: false,
    errorExpensesReport: true,
    errorExpensesReportMessage: action.error
   })
}

const fetchExpensesReportStart = state => {
  return updateObject(state, { 
    loadingExpensesReport: true,
    errorExpensesReport: false,
    errorExpensesReportMessage: ''
   })
}

//---------------  Post File Upload  ------------------------
const fetchpostUploadFileSuccess = (state, action) => {
  return updateObject(state, {
    uploadFileRequest: action.uploadFileRequest,
    loadingUploadFileExpense: false,
    errorUploadFileExpense: false,
  })
}

const fetchpostUploadFileFail = state => {
  return updateObject(state, { loadingUploadFileExpense: false, errorUploadFileExpense: true })
}

const fetchpostUploadFileStart = state => {
  return updateObject(state, { loadingUploadFileExpense: true, errorUploadFileExpense: false })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_GENERATE_EXPENSAS_SUCCESS:
        return fetchGenerateExpensasSuccess(state, action)
      case actionTypes.FETCH_GENERATE_EXPENSAS_FAIL:
        return fetchGenerateExpensasFail(state, action)
      case actionTypes.FETCH_GENERATE_EXPENSAS_START:
        return fetchGenerateExpensasStart(state, action) 
      case actionTypes.FETCH_AUTOMATIC_GENERATE_EXPENSAS_SUCCESS:
        return fetchGenerateAutomaticExpensasSuccess(state, action)
      case actionTypes.FETCH_AUTOMATIC_GENERATE_EXPENSAS_FAIL:
        return fetchGenerateAutomaticExpensasFail(state, action)
      case actionTypes.FETCH_AUTOMATIC_GENERATE_EXPENSAS_START:
        return fetchGenerateAutomaticExpensasStart(state, action)
        case actionTypes.FETCH_AUTOMATIC_GENERATE_EXPENSAS_RESET:
          return fetchGenerateAutomaticExpensasReset(state, action)
      case actionTypes.FETCH_GET_HOME_EXPENSAS_SUCCESS:
        return fetchgetHomeExpensasSuccess(state, action)
      case actionTypes.FETCH_GET_HOME_EXPENSAS_FAIL:
        return fetchgetHomeExpensasFail(state, action)
      case actionTypes.FETCH_GET_HOME_EXPENSAS_START:
        return fetchgetHomeExpensasStart(state, action)
      case actionTypes.FETCH_GET_EXPENSAS_CLIENTE_SUCCESS:
        return fetchgetExpensasPorClienteSuccess(state, action)
      case actionTypes.FETCH_GET_EXPENSAS_CLIENTE_FAIL:
        return fetchgetExpensasPorClienteFail(state, action)
      case actionTypes.FETCH_GET_EXPENSAS_CLIENTE_START:
        return fetchgetExpensasPorClienteStart(state, action)
      case actionTypes.FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS:
        return fetchgetFileDetailClientSuccess(state, action)
      case actionTypes.FETCH_GET_FILE_DETAIL_CLIENT_FAIL:
        return fetchgetFileDetailClientFail(state, action)
      case actionTypes.FETCH_GET_FILE_DETAIL_CLIENT_START:
      return fetchgetFileDetailClientStart(state, action)
      case actionTypes.FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_SUCCESS:
        return fetchgetFileDetailNeighbourhoodSuccess(state, action)
      case actionTypes.FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_FAIL:
        return fetchgetFileDetailNeighbourhoodFail(state, action)
      case actionTypes.FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_START:
        return fetchgetFileDetailNeighbourhoodStart(state, action)
        
      case actionTypes.FETCH_POST_UPLOAD_FILE_SUCCESS:
        return fetchpostUploadFileSuccess(state, action)
      case actionTypes.FETCH_POST_UPLOAD_FILE_FAIL:
        return fetchpostUploadFileFail(state, action)
      case actionTypes.FETCH_POST_UPLOAD_FILE_START:
        return fetchpostUploadFileStart(state, action)

      case actionTypes.FETCH_EXPENSES_REPORT_SUCCESS:
        return fetchExpensesReportSuccess(state, action)
      case actionTypes.FETCH_EXPENSES_REPORT_FAIL:
        return fetchExpensesReportFail(state, action)
      case actionTypes.FETCH_EXPENSES_REPORT_START:
        return fetchExpensesReportStart(state, action)


      default:
        return state
    }
  }
  
  export default reducer