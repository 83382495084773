import styled, { keyframes }from 'styled-components';
import Select from 'react-select'
import ArrowBack from '@material-ui/icons/ArrowBackIos'

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;



const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  listitem: {
    marginBottom: '5px'
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  },
  background: {
    background: theme.palette.secondary.main,
    overflow: 'hidden',
    color: theme.palette.text.primary,
    marginBottom: '20px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  tabLabel: {
    maxWidth: '100%',
    textTransform: 'capitalize',
    color: 'black'
  },
  toggleContainer: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  test: {
    transform: 'translate(0,16px) scale(1)'
  },
  divContainerHeadLine: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    margin: '20px 0'
  },
  tyTitle: {
    fontSize: '25px',
    fontWeight: 'bold'
  },
  divContainerHeadLineButtons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  divContainerIconSearch: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '20px'
  },
  iconSearch: {
    fontSize: '35px',
    color: theme.palette.common.darkgrey
  },
  buttonHeadLine: {
    background: theme.palette.common.blue,
    borderRadius: '10px',
    height: '50px',
    width: '200px',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  divContainerNav: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex'
  },
  labelTab: {
    display: 'block',
    color: 'black',
    padding: '15px 0',
    fontSize: '16px'
  },
  /*ajusta el tamaño de las tab*/
  tabRoot: {
    minWidth: '10px'
  },
  /*modifica el color de la tab seleccionada */
  colorTab: {
    backgroundColor: theme.palette.common.blue
  },
  formContainerSelects: {
    textAlign: 'right',
    marginTop: '0px'
  },
  formControlMsjType: {
    width: '35%'
  },
  formControlLot: {
    width: '15%',
    marginLeft: '2%'
  },
  formControlRead: {
    width: '20%',
    marginLeft: '2%'
  },
  divWrapperSpinner: {
    marginLeft: '45%',
    marginTop: '15%'
  }
})


export const PageWrapper = styled.div`
  width: 95%;
  margin-left: 2.5%;
  min-height: 100%;
  align-items: normal;

  &:before {
    animation: portal-profile-before 0.6s ease-out forwards 1;
    animation-delay: 0.2s;
  }
  &:after {
    animation: portal-profile-after 0.4s ease-out forwards 1;
    animation-delay: 0.4s;
  }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`
export const ButtonPublicaction = styled.div`  
  color:${({ theme }) => theme.colors.blueRibbon}; 
  font-weight: 700;
  text-decoration: underline solid;
  font-size: 15px;
  cursor: pointer;
`
export const ContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 5%;
  margin-top: 10px;
  cursor: pointer;
`
export const BtnAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 92%;
  margin-top: 10px;
  cursor: pointer;
`
export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.dark };
    width: 50px;

    ${ContainerIcon}:hover & {
      color: ${({theme})=> theme.colors.blueRibbon};
      transition:0.3s ease;  
      cursor: pointer;
  }
`

export const SearchWrapper = styled.div`
    max-height: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.alto};
    margin-right: 1px;
    border-radius: 10px;
`

export const ContentWrapper = styled.div`
  display: flex;
  max-width: 70vw
`

export const ListWrapper = styled.div`
    width: 35%;
    padding-right: 13px;
    height: auto;
    overflow-y: scroll;
    border-bottom-left-radius: 10px;
    border-right: 2px solid ${({ theme }) => theme.colors.ghost};
`

export const ContainerSelects = styled.div`
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`

export const ContainerSearchBar = styled.div`
    width: 100%;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.alto};
`


export const SelectStyled = styled(Select)`
    width: 70%;
    margin: 0px 10px;
`
export const ImgContainer = styled.div`
  display: flex;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.ghost};
  border-radius: 5px;
  padding: 10px;
`


export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: ${({ margin }) => margin ? "5vw":""};
`

export const MovementContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 15px 0px 15px 30px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.alabaster : theme.colors.transparent};
  border-top: 1px solid
    ${({ theme, selected }) => (selected ? theme.colors.mercury : theme.colors.ghost)};
  border-bottom: 1px solid
    ${({ theme, selected }) => (selected ? theme.colors.mercury : theme.colors.ghost)};
  transition: background 0.15s ease;
  cursor: pointer;
  

  &:hover {
    background-color: ${({ theme }) => theme.colors.alabaster};
    border-top: 2px solid ${({ theme }) => theme.colors.mercury};
    border-bottom: 2px solid ${({ theme }) => theme.colors.mercury};
  }
`

export const Charge = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.oceanGreen};
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
`
export const Pay = styled.label`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.pomegranate};
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
`

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ size }) => size || "16px" };
  font-weight: 500;
  text-align: initial;
  cursor: pointer;
`
export const StyledButton = styled.button`
    background-color: ${({ color,theme }) => ( color ? theme.colors.redOpaced : theme.colors.greenTransparent )};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ color, theme }) => ( color ? theme.colors.pomegranate  : theme.colors.oceanGreen )  };      
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 65px;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    margin: 0px;
`
export const CompradorLabel = styled.div`
  color: #1873F3;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer
`
export const DisclaimerLabel = styled.div`
  display: inline-block;
  background-color: #1873F3;
  border-radius: 4px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 10vh; 
  margin: 0px 0px 10px 0px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 15px; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  visibility:  ${props => props.out ? "hidden" : "visible"};
  transition: ${props => props.out ? "visibility 0s 2s, opacity 2s linear" : "opacity 2s linear"};

}
`
export const ContainerImg = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  margin-right: 10px;
  justify-content: space-around;
`
export const Icon = styled.img`
  width: ${({ width }) => width || '25px'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || 'auto'};
  cursor: pointer;
`
export const Row = styled.div`
  position: fixed;
  bottom: 0;
  width: 86vw;
  background-color: white;
  height: 4vw;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
`

export const Label = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ black,theme }) => (black ? theme.colors.black : theme.colors.midGray)};
    font-size: ${({ bold }) => (bold ? '18px' : '15px')};
    align-self: center;
    font-weight:${({ bold }) => (bold ? 600 : 400)};
    margin: 0px 10px;
`
export const Price = styled.label`
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ amount,theme }) => (amount < 0 ? theme.colors.pomegranate : theme.colors.oceanGreen)};
    font-size: "18px";
    align-self: center;
    font-weight: 600;
`

export const Date = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.roboto};
  color: ${({ theme }) => theme.colors.midGray};
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 5px;
  cursor: pointer;
  margin-right: 15px;
`

export default styles


