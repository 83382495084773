import styled from 'styled-components'
import {
    Grid,
    Button,
    Paper,
    Typography
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'

export const ProgressContainer = styled.div`
    flex-grow: 1;
`

export const FormContainer = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
`

export const GridButtonBack = styled(Grid)`
    margin-top: ${({ theme }) => theme.spacing.unit3};
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    max-width: 1000px;
    border-radius: 10px;
    display: flex;
`

export const ButtonBack = styled.button`
    padding: 0px !important;
    height: 35px !important;
    width: 35px !important;
    margin: ${({ theme }) => theme.spacing.unit2  } !important;
    background-color: ${({ theme }) => theme.colors.white } !important;
`
export const Container = styled.div`
    display:flex;
    flex-direction: column;
    width: 70%;
    overflow: hidden;
    margin-top: ${({ theme }) => theme.spacing.unit } ;
`

export const IconBack = styled(ArrowBack)`
    color: ${({ theme }) => theme.colors.blueRibbon };
    background-color: ${({ theme }) => theme.colors.white };
`

export const TyTitle = styled(Typography)`
    width: 90%;
    max-width: 1000px;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 30px;
    font-weight: bold !important;
    margin-top: 20px !important;
    margin-bottom: ${({ theme }) => theme.spacing.unit3 }!important;
`

export const PaperStyled = styled(Paper)`
    border-radius: 10px !important;
    border-style: solid;
    border-width: 0.5px;
    border-color: ${({ theme }) => theme.colors.ghost };

    box-shadow: none !important;
    margin-bottom:  ${({ theme }) => theme.spacing.unit2 };
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
`

export const gridItemForm = styled(Grid)`
    flex-direction: column;
    display: flex;
    padding-left: ${({ theme }) => theme.spacing.unit3 };
    padding-right: ${({ theme }) => theme.spacing.unit3 };
    min-height: 500px;

`

export const LabelContainer = styled.label`
    width: 80%;
    color: ${({ theme }) => theme.colors.warmGrey };
    font-size: 20px;
    margin-top: 10px;
    margin-top: ${({ theme }) => theme.spacing.unit2 };
`
export const SelectWrapper = styled.div`
    width: 100%;
    margin: 20px 0px 20px 0px;
`
export const RadioBtnContainer = styled.div`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.unit2 };
    margin-bottom: ${({ theme }) => theme.spacing.unit2 };
    display:flex;
    flex-direction: row;
    justify-content: space-around;  

`

export const StyledButton = styled.button`
    background-color: ${({ theme }) => theme.colors.blueRibbon};
    font-family: ${({ theme }) => theme.fonts.roboto};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 65px;
    width: 70%;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    margin: 5% 0px 5% 15%;

    &:hover{
        background-color: ${({ theme }) => theme.colors.royalBlue};
        transition: all 0.3s ease;
    }
`


export const NewDestinatarioLabel = styled.label`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.blueRibbon};;
    width: 100%;
    margin: 10px 140px 10px 0px;
    text-decoration: underline;
`

export const LabelChecked = styled.label`
    font-size: 15px;
    font-weight: ${props => (props.checked ? 'bold' : 'normal')};
`


export const DestinatarioDetailContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 70%;
    margin-top: ${({ theme }) => theme.spacing.unit };
`

export const DestinatarioDetailItemContainer = styled.div`
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`
export const ErrorLabel = styled.div`
    font-size: 15px;
`

export const InputStyled = styled.input`
    width: 90%;
    height: 20px;
    margin-top: 5px;
    margin-bottom: ${({ theme }) => theme.spacing.unit };
    font-size: 15px;
    padding: 10px;
    color: black;
    font-style: italic;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    border-color:  ${({ theme }) => theme.colors.ghost };
`


export const ListWrapper = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.ghost};
    max-height: 200px;
    min-height: auto;
    overflow-y: scroll;
`