import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: ${({ noBorder }) => (noBorder ? 0 : 1)}px solid ${({ theme }) => theme.colors.alto};
`

export const SearchWrapper = styled.div`
    padding: 0.5vw 1vw;
`

export const CloseWrapper = styled.div`
    padding: 12px 25px 6px 4px;
    cursor: pointer;
`

export const InputStyled = styled.input`
   &::-webkit-input-placeholder{
        font-style: italic;
        font-size: 20px;
    }
    border-width: 0px;
    min-height: 25px;
    width: 100%;
    outline:0px !important;
`