import React from 'react'
import NoFile from 'assets/icons/empty-document.png'
import CheckedImg from 'assets/icons/checked.png'
import inputTypes from 'constants/inputTypes'
import { readFileTyC } from 'utils/fileManager'
import {
  AttachedContainer,
  CheckedElement,
  DocumentImg,
  FileInputWrapper,
  StyledFileInput
} from './styled'

class FileInput extends React.Component {
  state = {
    url: "",
    loading: false,
  }

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) this.setState({ url: this.props.image })
  }

  hasAttachedContent = () => this.state.url

  fileSelectedHandler = async e => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      this.props.onChange(text)
      this.setState({text: text})
    };
    reader.readAsText(e.target.files[0])
    return reader
  }

  // Este handler devuelve directamente el base64
  // Funciona con xlsx (btoa falla con este tipo de archivos)
  fileSelectedBase64Handler = async e => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = reader.result.replace(/^data:.+;base64,/, '');
      this.props.onChange(text)
      this.setState({text: text})
    };
    reader.readAsDataURL(e.target.files[0])
    return reader
  }

  fileSelectedTyCHandler = async event => {
    const attachFile = await readFileTyC(event)
    if (this.props.onChange) this.props.onChange(attachFile)
    this.setState({ ...attachFile })
  }

  render() {
    return (
      <FileInputWrapper>
        {this.props.onChange && (
          <StyledFileInput type={inputTypes.FILE} onChange={this.props.isTyC?this.fileSelectedTyCHandler:(this.props.useBase64 ? this.fileSelectedBase64Handler : this.fileSelectedHandler)} />
        )}
        <AttachedContainer>
          {this.state.text ? <CheckedElement src={CheckedImg} /> : ""}
          <DocumentImg src={this.state.url || NoFile} />
        </AttachedContainer>
      </FileInputWrapper>
    )
  }
}

export default FileInput
