
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import asyncComponent from './components/async.component'
import Toolbar from './layouts/layout-toolbar/layout-toolbar.component'
import NoLayout from './layouts/layout-none/layout-none.component'
import Padron from './containers/padron/'
import Login from './containers/authentication/login/login.component'
import AsyncForgot from './containers/authentication/forgot-password/forgot-password.component'
import routeList from './routeList'
// AUTHENTICATION ROUTES
//const AsyncForgot = asyncComponent(() =>
//  import('./containers/authentication/forgot-password/forgot-password.component')
//)

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
)

const ToolbarLayout = props => <Toolbar>{props.children}</Toolbar>

const Routes = props => {
  let activeLayout
  switch (props.layout.currentLayout) {
    case 'toolbar':
      activeLayout = ToolbarLayout
      break
    default:
      activeLayout = ToolbarLayout
  }

  let firstRoute = null;

  let routes = (
    <Switch>
      <AppRoute path="/login" exact component={Login} props={props.childProps} layout={NoLayout} />
      <AppRoute
        path="/forgot-password"
        exact
        component={AsyncForgot}
        props={props.childProps}
        layout={NoLayout}
      />
      <Redirect to="/Login" />
    </Switch>
  )
  // console.log(props)

  if (props.isAuth) {
    const authRoutes = routeList.filter((route) => props.isAuth.menusAuth.some((menu) => menu.name === route.name));

    routes = (
      <Switch>
        {/* <AppRoute
          path="/"
          exact
          component={Padron}
          props={props.childProps}
          layout={activeLayout}
        /> */}
        {authRoutes.map((route, i) => {
          return(
            <AppRoute
              path={route.path}
              exact
              component={route.component}
              props={props.childProps}
              layout={activeLayout}
              key={i}
            />
          )
        })}

        {/* Si no hay ruta disponible, creo la de padron */}
        {authRoutes.length == 0 ?
          (
            <AppRoute
              path={'/padron'}
              exact
              component={Padron}
              props={props.childProps}
              layout={activeLayout}
            />
          ) : null }
        
        <Redirect to={authRoutes.length > 0 ? authRoutes[0].path : '/padron'} />
        
      </Switch>
    )
  }

  

  return routes
}

export default Routes

