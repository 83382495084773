import { createSelector } from 'reselect'
var moment = require('moment');
const mapNews = createSelector(
  news => news,
  news =>
    news.map(noticia => ({
        id: noticia.id,
        neighbourhood: noticia.neighbourhood,
        title: noticia.title,
        shortDescription: noticia.short_description,
        description: noticia.description,
        author: noticia.author,
        tags: noticia.tags,
        order:noticia.order,
        imageUrl: noticia.image_url,
        order:noticia.order,
        creationDate:moment(noticia.creation_date).format('YYYY-MM-DD'),
    }))
)

export default mapNews