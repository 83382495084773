import React from 'react'
import { Wrapper, Container, Row, Circle, ColumnItem, StyledButton, ErrorLabel } from './styled'
import MaterialTable from 'material-table'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageLoader from '../../../components/PageLoader'
import { fetchReporteMascotas } from 'store/actions/reportes.actions';

class ReporteMascotas extends React.Component {

    state = {
        transferSelected: [],
        error: '',
        newResultModal: false,
        tabEnabled: 0
    }

    componentDidMount() {
        this.props.fetchReporteMascotas()
    }

    render() {
        return (
            <>
                <Row>
                    <Container>
                        {this.props.loadingReporteMascotas ? <PageLoader /> :
                            <MaterialTable
                                columns={[
                                    // { title: 'Fecha de empadronamiento', field: 'date', },
                                    { title: 'Nombre/s', field: 'name', },
                                    { title: 'Dueño/a', field: 'owner_name', },
                                    { title: 'Sexo', field: 'gender_description', },
                                    { title: 'Tipo', field: 'animal_type', },
                                    { title: 'Pelaje', field: 'pelage_type', },
                                    { title: 'Edad', field: 'age', },
                                    // { title: 'Peso', field: 'weight', },
                                    { title: 'Tamaño', field: 'size', },
                                    { title: 'Esterilizado', field: 'sterilized_description', }
                                ]}
                                data={this.props.mascotas}
                                onSelectionChange={(rows) => this.onMovementClick(rows)}
                                title=""
                                options={{
                                  toolbar: true,
                                  paging: true,
                                  selection: false,
                                  exportButton: true,
                                  exportAllData: true,
                                  exportFileName: 'ReporteMascotas.'+(new Date().toISOString().split('.')[0])
                                  }}
                                localization={{
                                  toolbar:{
                                    exportTitle: 'Exportar',
                                    exportAriaLabel: 'Exportar',
                                    exportName: 'Exportar a CSV'
                                  },
                                  emptyDataSourceMessage: 'No hay datos para mostrar'
                                }}
                            />
                        }
                    </Container>
                </Row>
            </>
        )
    }

}

const mapStateToProps = ({
    reportes
}) => ({
    loadingReporteMascotas: reportes.loadingReporteMascotas,
    mascotas: reportes.mascotas
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          fetchReporteMascotas
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReporteMascotas)