import * as actionTypes from './actionTypes'
import getApi from 'shared/api'

import {
  fetchReporteMascotasAPI,
  fetchReporteTicketsAPI,
  fetchReporteReservasAPI
} 
from 'apiConstants'

////////////////////// Mascotas //////////////////////

export const fetchReporteMascotasSuccess = mascotas => {
  return {
    type: actionTypes.FETCH_REPORTE_MASCOTAS_SUCCESS,
    mascotas: mascotas
  }
}

export const fetchReporteMascotasFail = error => {
  return {
    type: actionTypes.FETCH_REPORTE_MASCOTAS_FAIL,
    errorReporteMascotas : error
  }
}

export const fetchReporteMascotasStart = () => {
  return {
    type: actionTypes.FETCH_REPORTE_MASCOTAS_START
  }
}

export const fetchReporteMascotas = () => {
  return async (dispatch, getState) => {
    dispatch(fetchReporteMascotasStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)
    return api
    .get(`${fetchReporteMascotasAPI}`)
    .then(res => {
      dispatch(fetchReporteMascotasSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchReporteMascotasFail(error))
    })
  }
}

//////////////////////////////////////////////////////

////////////////////// Tickets //////////////////////

export const fetchReporteTicketsSuccess = tickets => {
  return {
    type: actionTypes.FETCH_REPORTE_TICKETS_SUCCESS,
    tickets: tickets
  }
}

export const fetchReporteTicketsFail = error => {
  return {
    type: actionTypes.FETCH_REPORTE_TICKETS_FAIL,
    errorReporteTickets : error
  }
}

export const fetchReporteTicketsStart = () => {
  return {
    type: actionTypes.FETCH_REPORTE_TICKETS_START
  }
}

export const fetchReporteTickets = (params) => {
  return async (dispatch, getState) => {
    dispatch(fetchReporteTicketsStart())

    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid)
    return api.get(`${fetchReporteTicketsAPI}?`+
        `${params.property != null && params.property != '' ? 'property='+params.property+'&' : ''}`+
        `${params.type != null && params.type != '' ? 'type='+params.type+'&' : ''}`+
        `${params.category != null && params.category != '' ? 'category='+params.category+'&' : ''}`+
        `startTime=${params.startDate.toISOString()}&`+
        `endTime=${params.endDate.toISOString()}`)
    .then(res => {
      dispatch(fetchReporteTicketsSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchReporteTicketsFail(error))
    })
  }
}

/////////// RESERVAS

export const fetchReporteReservasSuccess = reporteReservas => {
  return {
    type: actionTypes.FETCH_BOOKINGREPORT_REQUEST_SUCCESS,
    reporteReservas: reporteReservas
  }
}

export const fetchReporteReservasFail = error => {
  return {
    type: actionTypes.FETCH_BOOKINGREPORT_REQUEST_FAIL,
    errorReporteReserva : error
  }
}

export const fetchReporteReservasStart = () => {
  return {
    type: actionTypes.FETCH_BOOKINGREPORT_REQUEST_START
  }
}

export const fetchReporteReservas = (formattedBody) => {
  return async (dispatch, getState) => {

    dispatch(fetchReporteReservasStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi()
    return api.get(`${fetchReporteReservasAPI}/?spaceid=${formattedBody.selectedSpace}&activityid=${formattedBody.selectedActivity}&stringstartdate=${formattedBody.startDate.toISOString()}&stringenddate=${formattedBody.endDate.toISOString()}`)
    .then(res => {
      dispatch(fetchReporteReservasSuccess(res.data))
    })
    .catch(error => {
      dispatch(fetchReporteReservasFail(error))
    })
  }
}