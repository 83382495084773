import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withWidth from '@material-ui/core/withWidth'
import compose from 'recompose/compose'
import Modal from '../../../components/Modal'
import {InputStyled,Container} from './styled'
import ModalInfo from '../modal-info'
import spinner from 'assets/animated/spinning-arrows.gif'
import {fetchGetGuest,fetchCheckIn} from '../../../store/actions/acceso.actions'

class AccessModal extends React.Component {
  constructor(props){
    super(props);
    this.timeout =  0;
    this.state = {
      isModalInfoOpen: false,
      isUserOnTheBrowser: true,
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
    window.addEventListener('blur', this.softcheckToClose, false);
    
  }

  componentWillUnmount(){
    document.removeEventListener('keydown', this.escFunction, false);
    window.removeEventListener('blur', this.softcheckToClose, false);
  }

  softcheckToClose = () => {
    if(!this.state.isModalInfoOpen && !this.props.loading) {
      this.props.onClose(true)
    }
  }

  escFunction = (event) => {
    if(event.keyCode === 27) {
     this.props.onClose(true)
    }
  }

  doSearch(evt){
    var searchText = evt.target.value; // this is the search text
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.fetchGetGuest(searchText)
      this.toggleModalInfo();
    }, 800);
  }

  toggleModalInfo = () => {
    this.setState({isModalInfoOpen: !this.state.isModalInfoOpen})
  }

  handleCheckIn = () => {
    this.props.fetchCheckIn(this.props.accesos.getGuestInvitation.guest_invite_id)  
  }

  componentDidUpdate(prevProps) {
    if(!this.props.loading) {
      if (prevProps.checkInSucces  === false && this.props.checkInSucces === true) {
        this.props.onSuccess();
        this.props.toggleSuccesModal(
          <p>El check-in fue realizado exitosamente!</p>
        );
      }
      if (prevProps.checkInFail !== this.props.checkInFail) {
        this.props.onSuccess();
        this.props.toggleFailModal(
          <p>El check-in no fue realizado.</p>
        );
      }
      if (prevProps.error === false && this.props.error) {
        this.props.onSuccess();
        this.props.toggleFailModal(
        <p>{this.props.error}</p>
        );
      }
    }
  }
  
  render() {
    return (
      <Modal>
        {this.state.isModalInfoOpen && this.props.accesos.getGuestInvitation.length !== 0 ? (
        <ModalInfo
          accesos={this.props.accesos}
          checkin={this.handleCheckIn}
          error={this.props.error}
          onClose={this.props.onClose}
          editMode={this.props.editMode}
        />
        ) : (
          <Container>
            <InputStyled
              name={'searchInput'}
              label={'searchInput'}
              id="searchInput"
              onChange={evt => this.doSearch(evt)}
              margin="normal"
              type="text"
              autoFocus="autoFocus"
            />
            <img src={spinner}/>    
            {this.props.loading ? "buscando...": ""}             
          </Container>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = ({
  accesos,
}) => ({
  loading: accesos.loadingGuestInvitation,
  accesos: accesos,
  error: accesos.errorGuestInvitation,
  checkInSucces: accesos.checkInSuccess,
  checkInFail: accesos.checkInFail
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGetGuest, fetchCheckIn
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(withWidth())(AccessModal)
  )