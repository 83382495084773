import * as actionTypes from './actionTypes'
import getApi from 'shared/api'

import {
  fetchVendorConsultasAPI,
  fetchVendorConsultasEstadosAPI,
  postVendorChangeStateAPI,
  postVendorAssingUserAPI,
  postTicketAPI,postTicketHistoryAPI,
  fetchProductTypeAPI,
  fetchEntrepreneurAPI,
  fetchUsersGestorAPI, getTicketAPI
}
  from 'apiConstants'

// get consultas

export const fetchVendorConsultasSuccess = data => {
  return { type: actionTypes.FETCH_GET_VENDOR_CONSULTAS_SUCCESS, data: data }
}

export const fetchVendorConsultasFail = error => {
  return { type: actionTypes.FETCH_GET_VENDOR_CONSULTAS_FAIL, error: error }
}

export const fetchVendorConsultasStart = () => {
  return { type: actionTypes.FETCH_GET_VENDOR_CONSULTAS_START }
}

export const fetchVendorConsultas = (body) => {
  return async (dispatch, getState) => {
    dispatch(fetchVendorConsultasStart())

    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid)

    return api
      .post(`${fetchVendorConsultasAPI}`, body)
      .then(res => {
        dispatch(fetchVendorConsultasSuccess(res.data))

      })
      .catch(error => {
        dispatch(fetchVendorConsultasFail(error.data))
      })
  }
}




// get consultas ESTADOS

export const fetchVendorConsultasEstadosSuccess = data => {
  return { type: actionTypes.FETCH_GET_VENDOR_CONSULTAS_ESTADOS_SUCCESS, data: data }
}

export const fetchVendorConsultasEstadosFail = error => {
  return { type: actionTypes.FETCH_GET_VENDOR_CONSULTAS_ESTADOS_FAIL, error: error }
}

export const fetchVendorConsultasEstadosStart = () => {
  return { type: actionTypes.FETCH_GET_VENDOR_CONSULTAS_ESTADOS_START }
}

export const fetchVendorConsultasEstados = (body) => {
  return async (dispatch, getState) => {
    dispatch(fetchVendorConsultasEstadosStart())

    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid)

    return api
      .get(`${fetchVendorConsultasEstadosAPI}`, body)
      .then(res => {

        res.data.unshift({ name: 'Todos', id: -1 })
        dispatch(fetchVendorConsultasEstadosSuccess(res.data))

      })
      .catch(error => {
        dispatch(fetchVendorConsultasEstadosFail(error))
      })
  }
}




export const postChangeStateSuccess = data => {
  return {
    type: actionTypes.POST_CHANGE_STATE_SUCCESS,
    data: data
  }
}


export const postChangeStateFail = error => {
  return {
    type: actionTypes.POST_CHANGE_STATE_FAIL,
    error: error
  }
}
export const postChangeStateReset = error => {
  return {
    type: actionTypes.POST_CHANGE_STATE_RESET,

  }
}

export const postChangeStateStart = () => {
  return {
    type: actionTypes.POST_CHANGE_STATE_START
  }
}

export const postChangeState = (body) => {
  return async (dispatch, getState) => {
    dispatch(postChangeStateStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
      .post(`${postVendorChangeStateAPI}`, body)
      .then(res => {
        dispatch(postChangeStateSuccess(res.data))
      })
      .catch(error => {
        dispatch(postChangeStateFail(error))
      })
  }
}



export const postAssingUserSuccess = data => {
  return {
    type: actionTypes.POST_ASSIGN_USER_SUCCESS,
    data: data
  }
}

export const postAssingUserFail = error => {
  return {
    type: actionTypes.POST_ASSIGN_USER_FAIL,
    error: error
  }
}

export const postAssingUserReset = error => {
  return {
    type: actionTypes.POST_ASSIGN_USER_RESET
  }
}

export const postAssingUserStart = () => {
  return {
    type: actionTypes.POST_ASSIGN_USER_START
  }
}

export const postAssingUser = (body) => {
  return async (dispatch, getState) => {
    dispatch(postAssingUserStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
      .post(`${postVendorAssingUserAPI}`, body)
      .then(res => {
        dispatch(postAssingUserSuccess(res.data))
      })
      .catch(error => {
        dispatch(postAssingUserFail(error))
      })
  }
}





export const newTicketSuccess = data => {
  return {
    type: actionTypes.NEW_TICKET_SUCCESS,
    data: data
  }
}

export const newTicketFail = error => {
  return {
    type: actionTypes.NEW_TICKET_FAIL,
    error: error
  }
}

export const newTicketReset = error => {
  return {
    type: actionTypes.NEW_TICKET_RESET
  }
}

export const newTicketStart = () => {
  return {
    type: actionTypes.NEW_TICKET_START
  }
}

export const newTicket = (body) => {
  return async (dispatch, getState) => {
    dispatch(newTicketStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
      .post(`${postTicketAPI}`, body)
      .then(res => {
        dispatch(newTicketSuccess(res.data))
      })
      .catch(error => {
        dispatch(newTicketFail(error))
      })
  }
}





export const editTicketSuccess = data => {
  return {
    type: actionTypes.EDIT_TICKET_SUCCESS,
    data: data
  }
}

export const editTicketFail = error => {
  return {
    type: actionTypes.EDIT_TICKET_FAIL,
    error: error
  }
}

export const editTicketReset = error => {
  return {
    type: actionTypes.EDIT_TICKET_RESET
  }
}

export const editTicketStart = () => {
  return {
    type: actionTypes.EDIT_TICKET_START
  }
}

export const editTicket = (body) => {
  return async (dispatch, getState) => {
    dispatch(editTicketStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()
    const api = await getApi(guid)

    return api
      .put(`${postTicketAPI}`, body)
      .then(res => {
        dispatch(editTicketSuccess(res.data))
      })
      .catch(error => {
        dispatch(editTicketFail(error))
      })
  }
}







// get USERS

export const fetchProductTypeSuccess = data => { return { type: actionTypes.PRODUCT_TYPE_SUCCESS, data: data } }
export const fetchProductTypeFail = error => { return { type: actionTypes.PRODUCT_TYPE_FAIL, error: error } }
export const fetchProductTypeStart = () => { return { type: actionTypes.PRODUCT_TYPE_START } }
export const fetchProductType = (body) => {
  return async (dispatch, getState) => {
    dispatch(fetchProductTypeStart())
    const { userInfo: { selectedNeighbourhood: { guid } } } = getState()
    const api = await getApi(guid)
    return api.get(`${fetchProductTypeAPI}`, body)
      .then(res => {
        dispatch(fetchProductTypeSuccess(res.data))
      })
      .catch(error => {
        dispatch(fetchProductTypeFail(error.data))
      })
  }
}


export const fetchUsersGestorSuccess = data => { return { type: actionTypes.USERS_GESTOR_SUCCESS, data: data } }
export const fetchUsersGestorFail = error => { return { type: actionTypes.USERS_GESTOR_FAIL, error: error } }
export const fetchUsersGestorStart = () => { return { type: actionTypes.USERS_GESTOR_START } }
export const fetchUsersGestor = (body) => {
  return async (dispatch, getState) => {
    dispatch(fetchUsersGestorStart())
    const { userInfo: { selectedNeighbourhood: { guid } } } = getState()
    const api = await getApi(guid)
    return api.get(`${fetchUsersGestorAPI}`, body)
    
      .then(res => {
        res.data.unshift({ label: 'Todos', value: -1 })
        dispatch(fetchUsersGestorSuccess(res.data))
      })
      .catch(error => {
        dispatch(fetchUsersGestorFail(error.data))
      })
  }
}



export const fetchEntrepreneurSuccess = data => { return { type: actionTypes.ENTREPENEUR_SUCCESS, data: data } }
export const fetchEntrepreneurFail = error => { return { type: actionTypes.ENTREPENEUR_FAIL, error: error } }
export const fetchEntrepreneurStart = () => { return { type: actionTypes.ENTREPENEUR_START } }
export const fetchEntrepreneur = (body) => {
  return async (dispatch, getState) => {
    dispatch(fetchEntrepreneurStart())
    const { userInfo: { selectedNeighbourhood: { guid } } } = getState()
    const api = await getApi(guid)
    return api.get(`${fetchEntrepreneurAPI}`, body)
      .then(res => {
        dispatch(fetchEntrepreneurSuccess(res.data))
      })
      .catch(error => {
        dispatch(fetchEntrepreneurFail(error.data))
      })
  }
}




export const fetchTicketByIdSuccess = data => { return { type: actionTypes.GET_TICKET_SUCCESS, data: data } }
export const fetchTicketByIdFail = error => { return { type: actionTypes.GET_TICKET_FAIL, error: error } }
export const fetchTicketByIdStart = () => { return { type: actionTypes.GET_TICKET_START } }
export const fetchTicketById = (idTicket) => {
  return async (dispatch, getState) => {
    dispatch(fetchTicketByIdStart())
    const { userInfo: { selectedNeighbourhood: { guid } } } = getState()
    const api = await getApi(guid)
    return api.get(`${getTicketAPI}` + idTicket)
      .then(res => {
        dispatch(fetchTicketByIdSuccess(res.data))
      })
      .catch(error => {
        dispatch(fetchTicketByIdFail(error.data))
      })
  }
}



export const fetchTicketHistoryByIdSuccess = data => { return { type: actionTypes.GET_TICKET_HIS_SUCCESS, data: data } }
export const fetchTicketHistoryByIdFail = error => { return { type: actionTypes.GET_TICKET_HIS_FAIL, error: error } }
export const fetchTicketHistoryByIdStart = () => { return { type: actionTypes.GET_TICKET_HIS_START } }
export const fetchTicketHistoryById = (idTicket) => {
  return async (dispatch, getState) => {
    dispatch(fetchTicketHistoryByIdStart())
    const { userInfo: { selectedNeighbourhood: { guid } } } = getState()
    const api = await getApi(guid)
    return api.get(`${getTicketAPI}` + idTicket + '/history')
      .then(res => {
        dispatch(fetchTicketHistoryByIdSuccess(res.data))
      })
      .catch(error => {
        dispatch(fetchTicketHistoryByIdFail(error.data))
      })
  }
}


export const postTicketHistoryByIdSuccess = data => { return { type: actionTypes.SAVE_TICKET_HIS_SUCCESS, data: data } }
export const postTicketHistoryByIdFail = error => { return { type: actionTypes.SAVE_TICKET_HIS_FAIL, error: error } }
export const postTicketHistoryByIdStart = () => { return { type: actionTypes.SAVE_TICKET_HIS_START } }
export const postTicketHistoryById = (data) => {
  return async (dispatch, getState) => {
    dispatch(postTicketHistoryByIdStart())
    const { userInfo: { selectedNeighbourhood: { guid } } } = getState()
    const api = await getApi(guid)
    return api.post(`${postTicketHistoryAPI}`,data)
      .then(res => {
        dispatch(postTicketHistoryByIdSuccess(res.data))
      })
      .catch(error => {
        dispatch(postTicketHistoryByIdFail(error.data))
      })
  }
}


