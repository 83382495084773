import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;

  top: ${(props) => props.top ? props.top : '0px'};  
  left: ${(props) => props.left ? props.left : '0px'};  
  width: ${(props) => props.width ? props.width : '100%'};  
  height: ${(props) => props.height ? props.height : '100%'};  
  display: ${(props) => props.display ? props.display : 'grid'};  

  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9999;
`
