import React from 'react'
import { Wrapper, Container, Row, Circle, ColumnItem, StyledButton, ErrorLabel } from './styled'
import MaterialTable from 'material-table'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageLoader from '../../../components/PageLoader'
import { fetchDonaciones } from 'store/actions/fundacion.actions';

class Donacion extends React.Component {

    state = {
        error: '',
        tabEnabled: 0
    }

    componentDidMount() {
        this.props.fetchDonaciones()
    }

    render() {
        return (
            <>
                <Row>
                    <Container>
                        {this.props.loadingDonaciones ? <PageLoader /> :
                            <MaterialTable
                                columns={[
                                    { title: 'Nombre', field: 'fullName', },
                                    { title: 'DNI', field: 'dni', },
                                    { title: 'Email', field: 'email', },
                                    { title: 'Fecha', field: 'date', },
                                    { title: 'Monto', field: 'amount', }
                                ]}
                                data={this.props.donaciones}
                                onSelectionChange={(rows) => this.onMovementClick(rows)}
                                title=""
                                options={{
                                    toolbar: true,
                                    paging: true,
                                    selection: false,
                                    exportButton: true,
                                    exportAllData: true,
                                    exportFileName: 'Donacion.' + (new Date().toISOString().split('.')[0])
                                }}
                                localization={{
                                    toolbar:{
                                        exportTitle: 'Exportar',
                                        exportAriaLabel: 'Exportar',
                                        exportName: 'Exportar a CSV'
                                    },
                                    body: { emptyDataSourceMessage: 'No hay datos para mostrar' }

                                }}
                            />
                        }
                    </Container>
                </Row>
            </>
        )
    }

}

const mapStateToProps = ({
    fundacion
}) => ({
    loadingDonaciones: fundacion.loadingDonaciones,
     donaciones: fundacion.donaciones
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchDonaciones
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Donacion)