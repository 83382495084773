import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross.png'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from 'components/Input'
import ReservationsTable from 'components/ReservationsTable'
import weekDays from 'constants/weekDays'
import mapSpaces from 'selectors/mapSpaces'
import { submitSpace, editSpace, deleteSpace } from 'store/actions/spaces.actions'
import Select from 'react-select'
import DeleteConfirmation from 'components/DeleteConfirmation'
import {
  Container,
  CrossImage,
  Row,
  SelectContainer,
  Column,
  StyledButton,
  StyledLabel,
  ActionLabel,
  TableWrapper,
  ErrorLabel
} from './styled'

class NewSpace extends React.Component {
  state = {
    name: '',
    dias: this.props.spaceData ? this.props.spaceData.maxreservationtime : "0",
    nameError: '',
    tableValues: undefined,
    remove_ids: [],
    selectedNeighbourhoods: [],
    deleteConfirmation: false,
  }

  componentDidMount = () => {
    var formattedNeighbourhoods = this.props.neighbourhoods.map(item => {
      var neighbourhood = {
        value:  item.name,
        label: item.name,
        guid: item.guid
      }
      return neighbourhood
    })

    var selectedNeighbourhoodsPrevious = [];
    if(this.props.spaceData){
      this.props.spaceData.neighbourhoodlist.forEach(neighbourhood =>{
        formattedNeighbourhoods.find((formatted,i) => 
        formatted.guid === neighbourhood ? (
          selectedNeighbourhoodsPrevious.push(formatted)
        ) : (
          null
        )
        )  
      }) 
    }

    var selectedNeighbourhoodsToBlock = [];
    if(this.props.spaceData){
      this.props.spaceData.spaces_blocked_to.forEach(blocked =>{
        this.props.formattedSpaces.find((formatted,i) => 
        formatted.value.id === blocked ? (
          selectedNeighbourhoodsToBlock.push(formatted)
        ) : (
          null
        )
        )  
      }) 
    }
   
      
    this.setState({
      formattedNeighbourhoods: formattedNeighbourhoods,
      selectedNeighbourhoods: selectedNeighbourhoodsPrevious,
      selectedSpace: selectedNeighbourhoodsToBlock
    })

    

    if (!this.isEditMode()) {
      this.generateEmptyTableValues()
    } else {
      const tableValues = JSON.parse(JSON.stringify([...this.props.space]))
      this.setState({
        tableValues,
        name: this.isEditMode() ? this.props.spaceData.description : ''
      })
    }
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction=(event)=>{
    if(event.keyCode === 27) {
     this.props.onClose(false)
    }
  }
  generateEmptyTableValues = () => {
    const tableValues = Object.keys(weekDays).map(key => ({
      day_of_week: parseInt(key),
      active_times: []
    }))
    this.setState({ tableValues })
  }

  isEditMode = () => this.props.space

  checkButtonContent = () =>
    this.props.spaces.isSubmitting ? (
      <CircularProgress size={18} />
    ) : this.isEditMode() ? (
      'Actualizar'
    ) : (
      'Crear'
    )

  checkData = () => {
    if (!this.hasErrors()) {
      if (!this.isEditMode()) {
        this.handleSpaceCreation()
      } else {
        this.handleSpaceEdit()
      }
    }
  }

  handleSpaceCreation = async () => {
    const filteredValues = this.state.tableValues.reduce(
      (timesArray, { day_of_week, active_times }) => {
        active_times.forEach(({ from, to, price }) =>
          timesArray.push({
            day_of_week,
            activityId:this.props.selectedResource.id,
            from,
            to,
            price
          })
        )
        return timesArray
      },
      []
    )
    const blockedSpaces_id = this.state.selectedSpace ? this.state.selectedSpace.map((item) => { return(item.value.id) }) : ""
    
    const NeighbourhoodSpacesGuid = this.state.selectedNeighbourhoods ? this.state.selectedNeighbourhoods.map((item) => { return(item.guid) }) : ""
        
    await this.props.submitSpace(
      this.props.selectedResource.id,
      this.state.name,
      filteredValues,
      blockedSpaces_id,
      this.state.dias,
      NeighbourhoodSpacesGuid,
    )
    this.checkSpaceSubmitError()
  }

  handleSpaceEdit = async () => {
    const filteredValues = this.state.tableValues.reduce(
      (timesArray, { day_of_week, active_times }) => {
        active_times.forEach(({ id, from, to, price, booking }) => {
          const newActiveTime = {
            day_of_week,
            from,
            to,
            price: parseInt(price)
          }

          if (id) {
            newActiveTime.id = id
          }

          if (booking) {
            newActiveTime.booking = booking
            newActiveTime.is_booked = true
          }

          timesArray.push(newActiveTime)
        })
        return timesArray
      },
      []
    )
    
    const blockedSpaces_id = this.state.selectedSpace ? this.state.selectedSpace.map((item) => { return(item.value.id) }) : ""
    
    const selectedNeighbourhoods = this.state.selectedNeighbourhoods ? this.state.selectedNeighbourhoods.map((item) => { return(item.guid) }) : ""
        
    await this.props.editSpace(
      this.props.spaceData.id,
      filteredValues,
      this.state.remove_ids,
      blockedSpaces_id,
      this.state.dias,
      selectedNeighbourhoods,
      this.props.selectedResource.id,
      this.state.name
    )
    this.checkSpaceSubmitError()
  }

  checkSpaceSubmitError = () => {
    if (!this.props.spaces.error) {
      this.props.onClose(true)
    }
  }

  hasErrors = () => {
    let hasError = false
    if (this.state.name === '') {
      this.setState({ nameError: 'Ingrese un nombre' })
      return (hasError = true)
    } 
    if (this.state.dias === '') {
      this.setState({ diasError: 'Ingrese dias' })
      return (hasError = true)
    } 
    if (this.state.selectedNeighbourhoods === '') {
      this.setState({ neighbourhoodsError: 'Ingrese un barrio' })
      return (hasError = true)
    }
    return hasError
  }
  

  toggleDeleteConfirmationClose = () => {
    this.setState({ deleteConfirmation: !this.state.deleteConfirmation })
  }

  handleSpaceDelele = async () => {
    await this.props.deleteSpace(this.props.spaceData.id)
    this.checkSpaceSubmitError()
  }

  handleSpaceSelection = option => {
    this.setState({ selectedSpace: option })
  }

  
  handleNeighbourhoodsSelection = option => {
    this.setState({ selectedNeighbourhoods: option })
  }
  

  render() {
    return (
      <Modal>
        <Container>
          <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
          <Row >
            <Column width={"15%"}>
              <StyledLabel>{'Nombre'}</StyledLabel>
              <Input
                value={this.state.name}
                error={this.state.nameError}
                onChange={({ target: value }) =>
                  this.setState({ name: value.value, nameError: null })
                }
                placeholder={'Ingrese el nombre del espacio'}
                autoFocus
              />
              {this.state.nameError && <ErrorLabel>{this.state.nameError}</ErrorLabel>}
            </Column>
            <Column width={"30%"}>
              <StyledLabel>{'Seleccionar barrios donde estara disponible la actividad'}</StyledLabel>
              <SelectContainer>
                <Select
                    isMulti
                    value={this.state.selectedNeighbourhoods}
                    onChange={this.handleNeighbourhoodsSelection}
                    options={this.state.formattedNeighbourhoods}
                    placeholder="Lista de barrios"                  
                  />
              </SelectContainer>
              {this.state.neighbourhoodsError && <ErrorLabel>{this.state.neighbourhoodsError}</ErrorLabel>}
            </Column>
            <Column width={"30%"}>
              <StyledLabel>{'Seleccionar espacios a bloquear cuando éste nuevo espacio se reserve'}</StyledLabel>
              <SelectContainer>
                <Select
                  isMulti
                  value={this.state.selectedSpace}
                  onChange={this.handleSpaceSelection}
                  options={this.props.formattedSpaces}
                  placeholder="Lista de espacios"
                  isLoading={this.props.spaces.isFetching}
                  isDisabled={this.props.spaces.isFetching}
                />
              </SelectContainer>
            </Column>
            <Column width={"15%"}>
              <StyledLabel>{'Max. dias a reservar'}</StyledLabel>
              <SelectContainer>
                <Input
                   value={this.state.dias}
                   error={this.state.diasError}
                   onChange={({ target: value }) =>
                     this.setState({ dias: value.value, diasError: null })
                   }
                />
                {this.state.diasError && <ErrorLabel>{this.state.diasError}</ErrorLabel>}
              </SelectContainer>
            </Column> 
          </Row>
          <TableWrapper>
            {this.state.tableValues && (
              <ReservationsTable
                data={this.state.tableValues}
                onChange={tableValues =>
                  this.setState({
                    tableValues
                  })
                }
                onDeleteRange={rangeId =>
                  this.setState({ remove_ids: [...this.state.remove_ids, rangeId] })
                }
                edit
              />
            )}
          </TableWrapper>
          <Row margin={'10px 0px'} justify={this.isEditMode() ? 'space-between' : 'flex-end'} height={"auto"}>
            {this.isEditMode() && (
              <ActionLabel
                onClick={!this.props.spaces.isSubmitting && this.toggleDeleteConfirmationClose}
                disabled={false}
                error
              >
                {'Eliminar espacio'}
              </ActionLabel>
            )}
            <StyledButton type={'submit'} onClick={this.checkData} disabled={this.props.spaces.isSubmitting}>
              {this.checkButtonContent()}
            </StyledButton>
          </Row>
          {this.props.spaces.error && <ErrorLabel>{this.props.spaces.error}</ErrorLabel>}
        </Container>
        {this.state.deleteConfirmation && (
            <DeleteConfirmation
              title={'¿Eliminar espacio?'}
              onBtnClose={this.toggleDeleteConfirmationClose}
              btnContent={'Eliminar'}
              onBtnClick={this.handleSpaceDelele}
              onError={this.props.spaces.error}
            />
          )}
      </Modal>
    )
  }
}

const mapStateToProps = ({ selectedResource, spaces,userInfo: { neighbourhoods } }) => ({
  selectedResource,
  formattedSpaces: mapSpaces(spaces.values),
  spaces,
  neighbourhoods
  })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ submitSpace, editSpace, deleteSpace }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewSpace)
