import { createActions } from 'redux-actions'
import * as actionTypes from './actionTypes'
import {
  getCategoriesByRoleAPI,
  getAllCategoriesTickets,
  getTicketsByCategoryAPI,
  getTicketMessagesAPI,
  getSendMessageAPI,
  getCloseTicketsAPI,
  getPendingTicketsAPI,
  TransferTicketsAPI,
  getSearchedTicketsAPI,
  getTypesByCategoryAPI
} from '../../apiConstants'
import getApi from 'shared/api'

export const fetchTicketsSuccess = tickets => {
  return {
    type: actionTypes.FETCH_TICKETS_SUCCESS,
    tickets: tickets
  }
}

export const fetchTicketsFail = error => {
  return {
    type: actionTypes.FETCH_TICKETS_FAIL,
    error: error
  }
}

export const fetchTicketsStart = () => {
  return {
    type: actionTypes.FETCH_TICKETS_START
  }
}

export const fetchTickets = category => {
  return async (dispatch, getState) => {
    dispatch(fetchTicketsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    try {
      // Fetching content
      const response = await api.get(`${getTicketsByCategoryAPI}${category}`)

      dispatch(
        fetchTicketsSuccess({
          values: response.data,
        })
      )
    } catch (error) {
      dispatch(fetchTicketsFail(error))
    }
  }
}

export const fetchTicketMessagesSuccess = messages => {
  return {
    type: actionTypes.FETCH_TICKET_MESSAGES_SUCCESS,
    messages: messages
  }
}

export const fetchTicketMessagesFail = error => {
  return {
    type: actionTypes.FETCH_TICKET_MESSAGES_FAIL,
    error: error
  }
}

export const fetchTicketMessagesStart = () => {
  return {
    type: actionTypes.FETCH_TICKET_MESSAGES_START
  }
}

export const fetchTicketMessages = ticket => {
  return async (dispatch, getState) => {
    dispatch(fetchTicketMessagesStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    try {
      // Fetching content
      const response = await api.get(`${getTicketMessagesAPI}${ticket}`)

      dispatch(
        fetchTicketMessagesSuccess({
          values: response.data,
        })
      )
    } catch (error) {
      dispatch(fetchTicketMessagesFail(error))
    }
  }
}

export const sendTicketMessageSuccess = messages => {
  return {
    type: actionTypes.FETCH_TICKET_MESSAGES_SUCCESS,
    messages: messages
  }
}

export const sendTicketMessageFail = error => {
  return {
    type: actionTypes.FETCH_TICKET_MESSAGES_FAIL,
    error: error
  }
}

export const sendTicketMessageStart = () => {
  return {
    type: actionTypes.FETCH_TICKET_MESSAGES_START
  }
}

export const sendTicketMessage = body => {
  return async (dispatch, getState) => {
    dispatch(fetchTicketMessagesStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    try {
      // Fetching content
      const response = await api.post(`${getSendMessageAPI}`,body)

      dispatch(
        fetchTicketMessagesSuccess({
          values: response.data,
        })
      )
    } catch (error) {
      dispatch(fetchTicketMessagesFail(error.response.data.message))
    }
  }
}


////

export const closeTicketSuccess = messages => {
  return {
    type: actionTypes.CLOSE_TICKET_SUCCESS,
    messages: messages
  }
}

export const closeTicketFail = error => {
  return {
    type: actionTypes.CLOSE_TICKET_FAIL,
    error: error
  }
}

export const closeTicketStart = () => {
  return {
    type: actionTypes.CLOSE_TICKET_START
  }
}

export const closeTicket = (ticket,message) => {
  return async (dispatch, getState) => {
    dispatch(closeTicketStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    try {
      // Fetching content
      const response = await api.put(`${getCloseTicketsAPI}`,{id:ticket, closing_comment:message})

      dispatch(
        closeTicketSuccess({
          values: response.data,
        },
        dispatch(fetchTickets(0),
        dispatch(setSelectedTicket(""))
        ))
      )
    } catch (error) {
      dispatch(closeTicketFail(error.response.data.message))
    }
  }
}


///

export const pendingTicketSuccess = messages => {
  return {
    type: actionTypes.PENDING_TICKET_SUCCESS,
    messages: messages
  }
}

export const pendingTicketFail = error => {
  return {
    type: actionTypes.PENDING_TICKET_FAIL,
    error: error
  }
}

export const pendingTicketStart = () => {
  return {
    type: actionTypes.PENDING_TICKET_START
  }
}

export const pendingTicket = (id,body) => {
  return async (dispatch, getState) => {
    dispatch(pendingTicketStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    try {
      // Fetching content
      const response = await api.put(`${getPendingTicketsAPI}`+id,body)

      dispatch(
        pendingTicketSuccess({
          values: response.data,
        },
        dispatch(fetchTickets(0),
        dispatch(setSelectedTicket(""))
        ))
      )
    } catch (error) {
      dispatch(pendingTicketFail(error.response.data.message))
    }
  }
}

///

export const changeCategoryTicketSuccess = messages => {
  return {
    type: actionTypes.CHANGE_CATEGORY_TICKET_SUCCESS,
    messages: messages
  }
}

export const changeCategoryTicketFail = error => {
  return {
    type: actionTypes.CHANGE_CATEGORY_TICKET_FAIL,
    error: error
  }
}

export const changeCategoryTicketStart = () => {
  return {
    type: actionTypes.CHANGE_CATEGORY_TICKET_START
  }
}

export const changeCategoryTicket = (id,body) => {
  return async (dispatch, getState) => {
    dispatch(changeCategoryTicketStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)

    try {
      // Fetching content
      const response = await api.put(`${TransferTicketsAPI}`+id,body)

      dispatch(
        changeCategoryTicketSuccess({
          values: response.data,
        },
        dispatch(fetchTickets(0),
        dispatch(setSelectedTicket(""))
        ))
      )
    } catch (error) {
      dispatch(changeCategoryTicketFail(error.response.data.message))
    }
  }
}

//////


export const fetchCategoriesByRoleSuccess = categories => {
  return {
    type: actionTypes.FETCH_CATEGORIES_BY_ROLE_SUCCESS,
    categories: categories
  }
}

export const fetchCategoriesByRoleFail = error => {
  return {
    type: actionTypes.FETCH_CATEGORIES_BY_ROLE_FAIL,
    error: error
  }
}

export const fetchCategoriesByRoleStart = () => {
  return {
    type: actionTypes.FETCH_CATEGORIES_BY_ROLE_START
  }
}

export const fetchCategoriesByRole = () => {
  return async (dispatch, getState) => {
    dispatch(fetchCategoriesByRoleStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)


    //const page = tickets.page
    try {
      // Fetching content
      const response = await api.get(`${getCategoriesByRoleAPI}`)
      response.data.unshift({id: 0,name: 'Todas'})

      dispatch(
        fetchCategoriesByRoleSuccess({
          values: response.data,
        })
      )
    } catch (error) {
      dispatch(fetchCategoriesByRoleFail(error))
    }
  }
}


///

export const searchTicketsSuccess = tickets => {
  return {
    type: actionTypes.SEARCH_TICKETS_SUCCESS,
    tickets: tickets
  }
}

export const searchTicketsFail = error => {
  return {
    type: actionTypes.SEARCH_TICKETS_FAIL,
    error: error
  }
}

export const searchTicketsStart = () => {
  return {
    type: actionTypes.SEARCH_TICKETS_START
  }
}

export const searchTickets = textSearched => {
  return async (dispatch, getState) => {
    dispatch(searchTicketsStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid,true)
    const urlformatted = encodeURI(textSearched)
    try {
      // Fetching content
      const response = await api.get(`${getSearchedTicketsAPI}`+urlformatted)

      dispatch(
        searchTicketsSuccess({
          values: response.data,
        })
      )
    } catch (error) {
      dispatch(searchTicketsFail(error.response.data.message))
    }
  }
}

///

export const fetchAllCategoriesSuccess = categories => {
  return {
    type: actionTypes.FETCH_TICKET_CATEGORIES_SUCCESS,
    categories: categories
  }
}

export const fetchAllCategoriesFail = error => {
  return {
    type: actionTypes.FETCH_TICKET_CATEGORIES_FAIL,
    error: error
  }
}

export const fetchAllCategoriesStart = () => {
  return {
    type: actionTypes.FETCH_TICKET_CATEGORIES_START
  }
}

export const fetchAllCategories = () => {
  return async (dispatch, getState) => {
    dispatch(fetchAllCategoriesStart())
    const {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid,true)
    try {
      // Fetching content
      const response = await api.get(`${getAllCategoriesTickets}`)

      dispatch(
        fetchAllCategoriesSuccess({
          values: response.data,
        })
      )
    } catch (error) {
      dispatch(fetchAllCategoriesFail(error))
    }
  }
}

///

export const fetchTypesByCategorySuccess = types => {
  return {
    type: actionTypes.FETCH_TICKET_TYPES_BY_CATEGORY_SUCCESS,
    types: types
  }
}

export const fetchTypesByCategoryFail = error => {
  return {
    type: actionTypes.FETCH_TICKET_TYPES_BY_CATEGORY_FAIL,
    error: error
  }
}

export const fetchTypesByCategoryStart = () => {
  return {
    type: actionTypes.FETCH_TICKET_TYPES_BY_CATEGORY_START
  }
}

export const fetchTypesByCategory = (categoryId) => {
  return async (dispatch, getState) => {
    dispatch(fetchTypesByCategoryStart())
    let {
      userInfo: {
        selectedNeighbourhood: { guid }
      }
    } = getState()

    const api = await getApi(guid, true)


    //const page = tickets.page
    try {
      // Fetching content
      const response = await api.get(`${getTypesByCategoryAPI}`+categoryId)

      dispatch(
        fetchTypesByCategorySuccess({
          values: response.data,
        })
      )
    } catch (error) {
      dispatch(fetchTypesByCategoryFail(error))
    }
  }
}

const { resetTicket, selectTicket } = createActions({
  RESET_TICKET: () => {""},
  SELECT_TICKET: data => ({ data })
})

const resetSelectedTicket = () => dispatch => dispatch(resetTicket())

const setSelectedTicket = ticket => dispatch => dispatch(selectTicket({ ticket }))

export { setSelectedTicket, selectTicket, resetSelectedTicket, resetTicket }
