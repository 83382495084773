import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  actividadesList: [],
  spacesList: [],
  loadingSpaces: false,
  loadingActividades: false,
  createdActivity: false,
  loadingNewActivity: false,
  errorNewActivity: false,
  createdSpace: false,
  loadingNewSpace: false,
  errorNewSpace: false,
  exceptionsList: false,
  loadingExeptions: false,
  createdException: false,
  loadingNewException: false,
  errorNewException: false,
  fetchedBookings: [],
  loadingBookings: false,

  dataDeleteReserva: [],
  loadingDeleteReservation: false,
  deletedReservation: false,
  fecthDeleteReservationIsDone: false,

  page: 0,
  hasMoreBookings: true,
  bookingsTake: 10,

  registeredBooking:[],
  generatedBooking: false,
  loadingNewBooking: false,
  errorNewBooking: false,

  loadingUploadTyC: false,
  errorUploadTyC: false,
  uploadTyC: [],

  loadingDeleteTyC: false,
  errorDeleteTyC: false,
  deletedTyC: [],

  loadingUploadTyC: false,
  errorUploadTyC: false,
  uploadTyC: []

}

const fetchActividadesSuccess = (state, action) => {
  return updateObject(state, {
    actividadesList: action.actividades,
    loadingActividades: false
  })
}

const fetchActividadesFail = state => {
  return updateObject(state, { loadingActividades: false })
}

const fetchActividadesStart = state => {
  return updateObject(state, { loadingActividades: true })
}
//-----------------------------------------------------------------
const createActivitySuccess = (state, action) => {
  const newActivity = updateObject(action.newActivity)
  return updateObject(state, {
    loadingNewActivity: false,
    createdActivity: true,
    actividadesList: state.actividadesList.concat(newActivity),
    errorNewActivity: false
  })
}

const createActivityFail = state => {
  return updateObject(state, {
    loadingNewActivity: false,
    errorNewActivity: true
  })
}

const createActivityStart = state => {
  return updateObject(state, { loadingNewActivity: true, errorNewActivity: false })
}

//-----------------------------------------------------------------
const fetchSpacesSuccess = (state, action) => {
  return updateObject(state, {
    spacesList: action.spacesList,
    loadingSpaces: false
  })
}

const fetchSpacesFail = state => {
  return updateObject(state, { loadingSpaces: false })
}

const fetchSpacesStart = state => {
  return updateObject(state, { loadingSpaces: true })
}
//------------------------------------------------------

const createSpaceSuccess = state => {
  return updateObject(state, {
    loadingNewSpace: false,
    createdSpace: true,
    errorNewSpace: false
  })
}

const createSpaceFail = state => {
  return updateObject(state, { loadingNewSpace: false, errorNewSpace: true })
}

const createSpaceStart = state => {
  return updateObject(state, { loadingNewSpace: true, errorNewSpace: false })
}
//------------------------------------------------------

const createExceptionsSuccess = state => {
  return updateObject(state, {
    loadingNewException: false,
    createdException: true,
    errorNewException: false
  })
}

const createExceptionsFail = state => {
  return updateObject(state, {
    loadingNewException: false,
    errorNewException: true
  })
}

const createExceptionsStart = state => {
  return updateObject(state, { loadingNewException: true, errorNewException: false })
}

//----------------------------------------------------------
const fetchBookingsRequestSuccess = (state, action) => {
  return updateObject(state, {
    fetchedBookings: action.fetchedBookings.values,
    loadingBookings: false,
    hasMoreBookings: action.fetchedBookings.hasMoreBookings,
    bookingsTake: action.fetchedBookings.bookingsTake
  })
}

const fetchBookingsRequestFail = state => {
  return updateObject(state, { loadingBookings: false })
}

const fetchBookingsRequestStart = state => {
  return updateObject(state, { loadingBookings: true })
}



// reducer reservas

const fetchDeleteReservationSuccess = (state, action) => {
  return updateObject(state, {
    dataDeleteReserva: action,
    loadingDeleteReservation: false,
    deletedReservation: true,
    fecthDeleteReservationIsDone: true,
  })
}

const fetchDeleteReservationFail = state => {
  return updateObject(state, { 
    loadingDeleteReservation: false,
     deletedReservation: false, 
     fecthDeleteReservationIsDone: true,
    })
}

const fetchDeleteReservationStart = state => {
  return updateObject(state, { loadingDeleteReservation: true,fecthDeleteReservationIsDone: false, })
}
//----------------------Generate Booking-----------------------

const generateBookingSuccess = (state,action) => {
  return updateObject(state, {
    loadingNewBooking: false,
    generatedBooking: true,
    errorNewBooking: false,
    registeredBooking:action.registeredBooking
  })
}

const generateBookingFail = state => {
  return updateObject(state, {
    loadingNewBooking: false,
    errorNewBooking: true
  })
}

const generateBookingStart= state => {
  return updateObject(state, { loadingNewBooking: true, errorNewBooking: false })
}


//--------------------UPLOAD TyC-------------------------------

const uploadTyCSuccess = (state, action) => {
  return updateObject(state, {
    uploadTyC: action.data,
    loadingUploadTyC: false,
    errorUploadTyC: false,
  })
}

const uploadTyCFail = state => {
  return updateObject(state, { loadingUploadTyC: false, errorUploadTyC: true })
}

const uploadTyCStart = state => {
  return updateObject(state, { uploadTyC: [], loadingUploadTyC: true, errorUploadTyC: false })
}


//--------------------DELETE TyC-------------------------------

const deleteTyCSuccess = (state, action) => {
  return updateObject(state, {
    deletedTyC: action.data,
    loadingDeleteTyC: false,
    errorDeleteTyC: false,
  })
}

const deleteTyCFail = state => {
  return updateObject(state, { loadingDeleteTyC: false, errorDeleteTyC: true })
}

const deleteTyCStart = state => {
  return updateObject(state, { deleteTyCStart: [], loadingDeleteTyC: true, errorDeleteTyC: false })
}

//-------------------------------------------------------------
const reducer = (state = initialState, action) => {
  switch (action.type) {

    // reservasdelete

    case actionTypes.FETCH_DELETE_RESERVA_SUCCESS:
      return fetchDeleteReservationSuccess(state, action)
    case actionTypes.FETCH_DELETE_RESERVA_FAIL:
      return fetchDeleteReservationFail(state, action)
    case actionTypes.FETCH_DELETE_RESERVA_START:
      return fetchDeleteReservationStart(state, action)


    case actionTypes.FETCH_ACTIVIDADES_SUCCESS:
      return fetchActividadesSuccess(state, action)
    case actionTypes.FETCH_ACTIVIDADES_FAIL:
      return fetchActividadesFail(state, action)
    case actionTypes.FETCH_ACTIVIDADES_START:
      return fetchActividadesStart(state, action)

    case actionTypes.CREATE_ACTIVIDAD_SUCCESS:
      return createActivitySuccess(state, action)
    case actionTypes.CREATE_ACTIVIDAD_FAIL:
      return createActivityFail(state, action)
    case actionTypes.CREATE_ACTIVIDAD_START:
      return createActivityStart(state, action)

    case actionTypes.FETCH_SPACES_SUCCESS:
      return fetchSpacesSuccess(state, action)
    case actionTypes.FETCH_SPACES_FAIL:
      return fetchSpacesFail(state, action)
    case actionTypes.FETCH_SPACES_START:
      return fetchSpacesStart(state, action)

    case actionTypes.CREATE_SPACE_SUCCESS:
      return createSpaceSuccess(state, action)
    case actionTypes.CREATE_SPACE_FAIL:
      return createSpaceFail(state, action)
    case actionTypes.CREATE_SPACE_START:
      return createSpaceStart(state, action)

    case actionTypes.CREATE_EXCEPTIONS_SUCCESS:
      return createExceptionsSuccess(state, action)
    case actionTypes.CREATE_EXCEPTIONS_FAIL:
      return createExceptionsFail(state, action)
    case actionTypes.CREATE_EXCEPTIONS_START:
      return createExceptionsStart(state, action)

    case actionTypes.FETCH_BOOKING_REQUESTS_SUCCESS:
      return fetchBookingsRequestSuccess(state, action)
    case actionTypes.FETCH_BOOKING_REQUESTS_FAIL:
      return fetchBookingsRequestFail(state, action)
    case actionTypes.FETCH_BOOKING_REQUESTS_START:
      return fetchBookingsRequestStart(state, action)
 
    case actionTypes.GENERATE_BOOKING_SUCCESS:
      return generateBookingSuccess(state, action)
    case actionTypes.GENERATE_BOOKING_FAIL:
      return generateBookingFail(state, action)
    case actionTypes.GENERATE_BOOKING_START:
      return generateBookingStart(state, action)

    case actionTypes.UPLOAD_TYC_SUCCESS:
      return uploadTyCSuccess(state, action)
    case actionTypes.UPLOAD_TYC_FAIL:
      return uploadTyCFail(state, action)
    case actionTypes.UPLOAD_TYC_START:
      return uploadTyCStart(state, action)

    case actionTypes.DELETE_TYC_SUCCESS:
      return deleteTyCSuccess(state, action)
    case actionTypes.DELETE_TYC_FAIL:
      return deleteTyCFail(state, action)
    case actionTypes.DELETE_TYC_START:
      return deleteTyCStart(state, action)

    default:
      return state
  }
}

export default reducer
