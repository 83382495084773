import { createSelector } from 'reselect'
import { getFormattedDate } from 'utils/dateParser'

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'ARS',
});

const mapProduct = createSelector(
  product => product,
  product => 
  product.map(
      ({ id,title,description,shopitemconditionid,shopitemcategoryid,quantity,prices,creationdate,shopitemimagesurls,shopitemconditionname,shopitemcategoryname,neighbourhoodlist,acceptedpaymentmethodlist  }, i) => ({
        id,
        title,
        description,
        shopitemconditionid,
        shopitemcategoryid,
        quantity,
        prices,
        creationdate: getFormattedDate(creationdate),
        shopitemimagesurls,
        shopitemconditionname,
        shopitemcategoryname,
        neighbourhoodlist,
        acceptedpaymentmethodlist,
      })
    )
)

export default mapProduct