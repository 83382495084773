import React, { Component } from 'react'
import Page from 'components/Page'
import Section from 'components/Section'
import EmptyLoader from 'components/PageLoader'
import Registro from 'assets/images/contract.png'

import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import ReporteReserva from '../reportes/reportereserva'
import ReporteMascotas from '../reportes/reportemascotas'
import ReporteTickets from '../reportes/reportetickets'

import { Wrapper, PageWrapper, AccessEntry, BigButton, Text, PngImage } from './styled'


class Reportes extends Component {
  state = {

    reporteReservaForm: false,
    reporteMascotasForm:false,
    reporteTicketsForm:false
  }

  renderPageContent = () => (
    <>
      <AccessEntry>
        {this.props.userRoles.some(el => el.code === 'RESERV_BO') &&
          (<BigButton onClick={this.toggleReporteReserva}>
            <PngImage src={Registro} />
            <Text>
              Reservas
            </Text>
          </BigButton>)
        }
        {this.props.userRoles.some(el => el.code === 'PEOPLE_BO') &&
          (<BigButton onClick={this.toggleReporteMascota}>
            <PngImage src={Registro} />
            <Text>
              Mascotas
            </Text>
          </BigButton>)
        }
        {this.props.userRoles.some(el => el.code === 'TICKETS_ADMIN_BO' || el.code === 'TICKETS_BO') &&
          (<BigButton onClick={this.toggleReporteTickets}>
            <PngImage src={Registro} />
            <Text>
              Tickets
            </Text>
          </BigButton>)
        }
      </AccessEntry>
    </>
  )

  toggleReporteReserva  = () => {
    this.setState({
      reporteReservaForm: !this.state.reporteReservaForm
    })
  }
  
  toggleReporteMascota  = () => {
    this.setState({
      reporteMascotasForm: !this.state.reporteMascotasForm
    })
  }

  toggleReporteTickets  = () => {
    this.setState({
      reporteTicketsForm: !this.state.reporteTicketsForm
    })
  }

  isLoadingContent = () => this.props.loading;

  componentDidUpdate(prevProps) {
    if (this.props.loadingPuts && !prevProps.loadingPuts) {
      this.togglePutsModalDetails();
    }
  }

  render() {
    const { reporteReservaForm,reporteMascotasForm, reporteTicketsForm } = this.state;
    
    if (reporteReservaForm) {
      return (
        <Page>
        <PageWrapper>
          <Section 
              title={'Reporte de Reservas'} 
              onBack={this.toggleReporteReserva} 
              > 
          </Section>
          <ReporteReserva></ReporteReserva>
        </PageWrapper>
        </Page>
      )
    }
    else if (reporteMascotasForm) {
      return (
        <Page>
        <PageWrapper>
          <Section 
              title={'Reporte de Mascotas'} 
              onBack={this.toggleReporteMascota} 
              // onBtnClick={() => this.toggleModalNuevaNotificacion()}
              // btnContent={'Exportar Listado'}
              > 
          </Section>
          <ReporteMascotas></ReporteMascotas>
        </PageWrapper>
        </Page>
      )
    }
    else if (reporteTicketsForm) {
      return (
        <Page>
        <PageWrapper>
          <Section 
              title={'Reporte de Tickets'} 
              onBack={this.toggleReporteTickets} 
              // onBtnClick={() => this.toggleModalNuevaNotificacion()}
              // btnContent={'Exportar Listado'}
              > 
          </Section>
          <ReporteTickets></ReporteTickets>
        </PageWrapper>
        </Page>
      )
    }
    else {
      return (
        <Page>
          {
            <PageWrapper>
              <Section title={'Reportes'}>
                <Wrapper>
                  {this.isLoadingContent() ? <EmptyLoader /> : this.renderPageContent()}
                </Wrapper>
              </Section>
            </PageWrapper>   
          }
        </Page>
      )
    }
  }
}


const mapStateToProps = ({
  reportes,
  userInfo
}) => ({
  reportes,
  userRoles: userInfo.menusAuth
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(compose(withWidth())(Reportes))
