import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {withWidth} from '@material-ui/core'
import { bindActionCreators } from 'redux'
import MaterialTable from 'material-table'
import { Avatar } from '@material-ui/core'
import img from 'assets/avatars/male/34.jpg'
import Select from 'react-select'
import Section from 'components/Section'
import {Header, ContentWrapper, Label, LabelContainer, SelectContainer, TableContainer, Status } from './styled'
import { fetchgetExpensasPorCliente,fetchgetFileDetailClient } from 'store/actions/expensas.actions'
import EmptyWrapper from 'components/PageEmpty'
import EmptyLoader from 'components/PageLoader'
import mapExpensasClient from 'selectors/mapExpensasClient'
import { getFormattedDate } from 'utils/dateParser'

const years = [
  { value: '2021', label: '2021' },
  { value: '2020', label: '2020' },
  { value: '2019', label: '2019' },
];

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'ARS',
  });

class DetailItem extends Component {
  state = {
    selectedOption: '',
    expensaSelected: "",
    periodDownload: "",
    yearFilter:  { value: '2021', label: '2021' },
    listExpensas: []
  }

  componentWillMount = () => {
      this.handleFetch();       
  }

  componentDidUpdate(prevProps, prevState) {
      if (prevState.yearFilter !== this.state.yearFilter || prevState.selectedNeighbourhood !== this.state.selectedNeighbourhood) {
          this.handleFetch();
      }
  }

  handleFetch(){
      this.props.fetchgetExpensasPorCliente(this.props.propietary.client_id,this.state.yearFilter.label)  
  }

  handleSelectYear = value => this.setState({ yearFilter: value })

  renderPageContent = () => (
    <>
      <Header>
        <Avatar
          src={this.props.expensasFormatted ? this.props.expensasFormatted[0].client_picture_url : img}
          style={{
            width: '45px',
            height: '45px'
          }}
        />
        <LabelContainer margin={'5px'} width={'25%'}>
          <Label black bold>Propietario: </Label>
          <Label >{this.props.propietary.client_name}</Label>
        </LabelContainer>
        <LabelContainer>
          <Label black bold>Usuario: </Label>
          <Label >{this.props.expensas.expensasCliente[0].client_user_name}</Label>
        </LabelContainer>
        <LabelContainer >
          <Label black bold>Lote: </Label>
          <Label >{this.props.expensas.expensasCliente[0].property_code}</Label>
        </LabelContainer>
        <SelectContainer >
          <Select
            value={this.state.yearFilter}
            onChange={this.handleSelectYear}
            options={years}
            placeholder={"2019"}
          />
        </SelectContainer>
      </Header>
      <TableContainer>
        <MaterialTable
          columns={[
            { title: 'Periodo', field: 'month' },
            { title: 'Monto', field: 'amount', type: 'currency', render: rowData =>  <label>{formatter.format(rowData.amount)}</label> },
            { title: 'Barrio', field: 'neighborhood_name' },
            { title: 'Fecha de Pago', field: 'payment_date', render: rowData =>  <label>{rowData.payment_date ? getFormattedDate(rowData.payment_date) : ""}</label> }, 
            { title: 'Banco Débito', field: 'debit_account_bank_name' },
            { title: 'Cod. Recibo', field: 'external_payment_code' },
            { title: 'Vista previa', field: 'imageUrl', render: rowData =>  <img src={rowData.imageUrl} style={{width: 30, marginLeft: 30, cursor: 'pointer'}} onClick={() => this.fecthFileDetail(rowData) } />   },
            { title: 'Estado', type: 'text', render: rowData =>  <Status status={rowData.status} rechazo={rowData.motivo_rechazo}>{rowData.status} </Status> },
            { title: 'Motivo Rechazo', field: 'motivo_rechazo' }
          ]}
          data={this.props.expensasFormatted}
          options={{
            toolbar: false,
            paging: false,
            headerStyle: {
                zIndex: 0
              }
          }} //Esconde titulo y search y pagination 
        />
        <a 
         href={'data:application/octet-stream;base64,' + this.props.expensas.fileDetailClient}
         download={this.state.periodDownload+".pdf"} 
         id="download" 
         hidden
        ></a>
      </TableContainer>
  </>
  )

  fecthFileDetail = async period => {
    this.setState({periodDownload: period.month+"-"+period.property_code})
    var response = await this.props.fetchgetFileDetailClient(period.id)

    var data = await response


   if(this.props.expensas.fileDetailClient){
      this.setState({dataResponse: this.props.expensas.fileDetailClient },
      this.downloadBlob())
   }

  }

  
  downloadBlob(){
    document.getElementById('download').click();
  }


  isLoadingContent = () =>  {
      if(this.props.expensas.loadingexpensasCliente){
        return true
      }else{        
       return false
      }
  }

  render() { 
    return (
      <Section 
        title={'Detalle Propietario'} 
        onBack={this.props.onClose} 
      >
        <ContentWrapper  >
            {this.isLoadingContent() ? (<EmptyLoader /> ) : (
                this.props.expensas.expensasCliente.length === 0 ? (
                  <EmptyWrapper message={'No hay expensas disponibles'} /> ) : (
                    this.renderPageContent()
                ) 
              )}
        </ContentWrapper>
    </Section>
    )
  }
}

const mapStateToProps = ({
  expensas
}) => ({
  expensas,
  expensasFormatted: mapExpensasClient(expensas),
})


const mapDispatchToProps = dispatch =>
  bindActionCreators({  fetchgetExpensasPorCliente,fetchgetFileDetailClient }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  withWidth()
  )(DetailItem)
)
  