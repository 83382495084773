import React from 'react'
import AnimationWrapper from 'components/AnimationWrapper'
import { Container, Wrapper, Items } from './styled'
import check from 'assets/icons/checked-border-round.png'
import cross from 'assets/icons/cross-border-round.png'

class Modal extends React.Component {
  state = { showing: true }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ showing: false });
      this.props.onClose()
    }, 3000);
  }

  render() {
    const { children, success } = this.props
    return (
      <Wrapper>
        {
          this.state.showing ?
            (
              <AnimationWrapper>
                <Container success={success}>
                  <Items>
                    <img src={success ? check : cross} onClick={this.props.onClose} style={{ marginRight: '10px' }} />
                    {children}
                  </Items>
                </Container>
              </AnimationWrapper>
            )
            :
            (
              <></>
            )
        }
      </Wrapper>
    )
  }
}

export default Modal
