import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {withWidth} from '@material-ui/core'
import axios from 'axios'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import { bindActionCreators } from 'redux'
import { fetchTransactionChargeWithCard } from 'store/actions/billetera.actions'
import PaperLayout from 'components/PaperLayout'
import FirstStep from './FirstStep/index'
import SecondStep from './SecondStep/index'
import ThirdStep from './ThirdStep/index'

class GenerateChargeWithCard extends Component {
  state = {
    completed: false,
    step: {
      name: 'Creation',
      number: 1,
      percentage: 10,
      },
    GetCodeQRImage: 'data:image/png;base64,'+this.props.GetCodeQRImage,
    transaction: {
      'description': 'description'
    }
  }

  createTransaction = (transaction) => {
    this.setState(prevState => ({
      transaction: {
        ...prevState.transaction,
        amount: transaction.amount,
        conceptId: 1,
        destinationBankAccount:
        {
          Id: this.props.billetera.AccountDebitInfo.Id
        }
      }
    })) 
  }
  updateTransaction = (transaction) => {
    this.setState(prevState => ({
      transaction: {
        ...prevState.transaction,
        sourcePayment: 
        {
          paymentType: "TC",
          cardId: 0,
          cardNumber: Number(transaction.cardNumber),
          cardExpirationDateMonth: Number(transaction.cardExpirationDateMonth),
          cardExpirationDateYear: Number(transaction.cardExpirationDateYear),
          cardSecurityCode: transaction.cardSecurityCode,
          cardHolderName: transaction.name,
          saveCard: false
        }
      }
    }), () => {
      this.props.fetchTransactionChargeWithCard(this.state.transaction)
     }) 
  }

  NextStep = () => {
    this.setState(prevState => ({
      step: {
        ...prevState.step,
        number:  prevState.step.number + 1,
        percentage: prevState.step.percentage + 90
      }
    }))
  }

  render() {
    return ( 
      <>
      {this.state.completed === false ? ( 
      <PaperLayout title={'Nuevo cobro con tarjeta'} onClose={() => this.props.onClose()} Step={this.state.step} >
        {(() => {
          switch(this.state.step.number) {
            case 1:
              return <FirstStep NextStep={() => {this.NextStep()}} createTransaction={this.createTransaction} />;
            case 2:
              return <SecondStep NextStep={() => {this.NextStep()}} onClose={() => {this.props.onClose()}} updateTransaction={this.updateTransaction}/> 
            case 3:
              return this.setState({completed: true})
            default:
              return null;
          }
        })()}
      </PaperLayout >)
      : (
          <ThirdStep onClose={() => {this.props.onClose()}} billetera={this.props.billetera}/> 
        )}   
    </>
    )
  }
}

const mapStateToProps  = state => {
  return {
    billetera: state.billetera,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTransactionChargeWithCard,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withWidth()
  )(withErrorHandler(GenerateChargeWithCard, axios))
)
