import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Formik,Field } from 'formik'
import * as Yup from 'yup'
import Modal from 'components/Modal'
import CrossIcon from 'assets/icons/cross.png'
import TrashIcon from 'assets/icons/trash-blue.png'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import DatePickerStyled from 'components/DatePickerStyled'
import Select from 'react-select'
import { addMonths } from 'date-fns'
import FileImageInput from 'components/FileImageInput'
import FileInputGroup from 'components/FileInputGroup'
import { httpsOnly } from 'constants/regularExpresions.js'
import CheckBox from 'components/CheckboxFormik'
import { createNewEvent, createNewArticle, editEvent, editArticle } from 'store/actions/feed.actions'
import {
  FormContainer,
  Row,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CancelButton,
  Container,
  LabelChecked,
  CrossImage,
  Barrio,
  ResetIcon
} from '../NewPublication/styled'
import { parseToISO } from 'utils/iso8601Parser'

class PopUp extends React.Component {
  state={
    sendsnotification: false
  }

  render() {
    console.log(this.state)
    console.log(this.props)

    return (
      <Modal>
      <Container>
       <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
        <InputLabel>Esta seguro que quiere publicar en el/los barrios {this.props.neighbourhoods.map((barrio)=> <Barrio>{barrio.label}</Barrio>)}</InputLabel>
        <InputLabel>Esta publicación enviara PUSH/SMS/LLAMADOS a los usuarios</InputLabel>
       <ButtonWrapper> 
            <CancelButton onClick={() => this.props.onClose(false)}>
               Cancelar
            </CancelButton>
            <StyledButton onClick={() => this.props.onHandleSubmit()} >
                {this.props.isSubmittingEvent || this.props.isSubmittingArticle ? (
                <CircularProgress size={18} />
                ) : (
                'Publicar'
                )}
            </StyledButton>
        </ButtonWrapper>
        <ErrorLabel>
            {this.props.errorNew ? this.props.errorNew : "" }
            {this.props.submitErrorArticle ? this.props.submitErrorArticle : "" }
        </ErrorLabel>
        </Container>
      </Modal>
    )
  }
}

const mapStateToProps = ({ userInfo: { neighbourhoods, selectedNeighbourhood }, feed }) => ({
  isSubmittingEvent: feed.loadingNewEvent,
  submitErrorEvent: feed.errorNewEvent,
  isSubmittingArticle: feed.loadingNewArticle,
  submitErrorArticle: feed.errorNewArticle,
  errorNew: feed.errorNewEventMessage || feed.errorNewArticleMessage,
  selectedNeighbourhood,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createNewEvent, createNewArticle, editEvent, editArticle }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopUp)
