import styled from 'styled-components'

export const Container = styled.div`
    background-color: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
    width: 20vw;
    height: 15vh;
    margin: 1vh 0vh;
    cursor: pointer;

    border-radius:${({ active }) => ( active ? "0 0 4px 4px"  : "" )};
    border-bottom: ${({ active }) => ( active ? "3px solid #1873F3"  : "" )};   
`
export const Title = styled.div`
    color: #000000;
    font-size: 25px;
    line-height: 24px;
    text-align: center;
    margin-top: 4vw;
`
export const Subtitle = styled.div`
    color: #9B9A9B;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
`
export const ContainerImg = styled.div`
    background-color: #FFFFFF;
    border-radius: 37.5px;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
    width: 4vw;
    height: 4vw;
    margin: auto;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 4vw;
    margin-left: 8vw;
`
export const Img = styled.img`;
`