import React, { Component } from 'react'
import Modal from 'components/Modal'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import mapRoles from "selectors/mapRoles"
import Input from 'components/Input'
import { fetchRoles } from "store/actions/profile.actions"
import FileInput from 'components/FileImageInput'

import {
  FormContainer,
  Row,
  RowLeft,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ErrorMessage,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  InputWrapperMargin
} from './styled'

import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import CrossIcon from 'assets/icons/cross.png'
import userMaleImg from 'assets/images/user_male.png'
import userFemaleImg from 'assets/images/user_female.png'
import { fetchNewUser } from 'store/actions/users.actions'

const genders = [{ label: "hombre", value: "0" },{ label: "mujer", value: "1" },{ label: "otro", value: "3" }]

class ProprietaryCreator extends Component {
  state = {}

  componentDidMount() {
    this.props.fetchRoles()
  }

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <CrossImage onClick={() => this.props.onClose(false)} src={CrossIcon} />
      <Row error={errors.user || errors.type}>
        <InputWrapper width={48}>
          <InputLabel>{'Nombre'}</InputLabel>
          <Input
            value={values.name}
            onChange={({ target: { value } }) => setFieldValue('name', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.name ? true : false}
          />
          {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Apellido'}</InputLabel>
          <Input
            value={values.surname}
            onChange={({ target: { value } }) => setFieldValue('surname', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.surname ? true : false}
          />
          {errors.surname && <ErrorLabel>{errors.surname}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper width={48}>
          <InputLabel>{'DNI'}</InputLabel>
          <Input
            value={values.dni}
            onChange={({ target: { value } }) => setFieldValue('dni', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.dni ? true : false}
          />
          {errors.dni && <ErrorLabel>{errors.dni}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Genero de su DNI'}</InputLabel>
          <Select
            value={values.gender}
            onChange={value => setFieldValue('gender', value)}
            options={genders}
            placeholder="Seleccionar genero"
            error={errors.gender ? true : false}
          />
          {errors.gender && <ErrorLabel>{errors.gender}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row error={errors.user || errors.type}>
        <InputWrapper width={48}>
          <InputLabel>{'Permiso para ver barrios'}</InputLabel>
          <Select
            value={values.neighbourhood}
            onChange={value => setFieldValue('neighbourhood', value)}
            options={this.getNeighbourhoodOptions()}
            placeholder="Buscar..."
            error={errors.neighbourhood ? true : false}
            isMulti
          />
          {errors.neighbourhood && <ErrorLabel>{errors.neighbourhood}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Teléfono'}</InputLabel>
          <Input
            value={values.phone}
            onChange={({ target: { value } }) => setFieldValue('phone', value)}
            placeholder={'54 911 ********'}
            type={'text'}
            rows={1}
            error={errors.phone ? true : false}
          />
          {errors.phone && <ErrorLabel>{errors.phone}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={48}>
          <InputLabel>{'Email'}</InputLabel>
          <Input
            value={values.email}
            onChange={({ target: { value } }) => setFieldValue('email', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.email ? true : false}
          />
          {errors.email && <ErrorLabel>{errors.email}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Tipo de rol'}</InputLabel>
          <Select
            value={values.rol}
            onChange={option => this.handleSelectRoles(setFieldValue, option)}
            options={this.props.roles}
            isLoading={this.props.profile.loadingRoles}
            placeholder="Buscar..."
            error={errors.rol ? true : false}
          />
          {errors.rol && <ErrorLabel>{errors.rol}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={48}>
          <InputLabel>{'Nombre de usuario'}</InputLabel>
          <Input
            value={values.username}
            onChange={({ target: { value } }) => setFieldValue('username', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.username ? true : false}
          />
          {errors.username && <ErrorLabel>{errors.username}</ErrorLabel>}
        </InputWrapper>
        <InputWrapper width={48}>
          <InputLabel>{'Contraseña'}</InputLabel>
          <Input
            value={values.password}
            onChange={({ target: { value } }) => setFieldValue('password', value)}
            placeholder={''}
            type={'text'}
            rows={1}
            error={errors.password ? true : false}
          />
          {errors.password && <ErrorLabel>{errors.password}</ErrorLabel>}
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper width={33}>
          <InputLabel>{'Foto del usuario'}</InputLabel>
          <FileInput onChange={file => setFieldValue('user_picture', file)} />
          {errors.user_picture &&
            <ErrorLabel>{errors.user_picture}</ErrorLabel>
          }
        </InputWrapper>
      </Row>
      <Row>
      <ErrorLabel>{this.props.mensajeErrorNewUser}</ErrorLabel>
      </Row>
      <ButtonWrapper>
        {this.props.errorNewProprietary ? <ErrorMessage>Hubo un Problema</ErrorMessage> : ''}
        <StyledButton type="Submit">
          {this.props.loadingNewProprietary ? <CircularProgress size={18} /> : 'Crear propietario'}
        </StyledButton>
      </ButtonWrapper>
    </FormContainer>
  )


  openWebcamModal = () => {
    this.setState({
      cameraModalOpen: !this.state.cameraModalOpen,
    });
  }

  
  handleSubmit = async data => {
    const neighbourhood = data.neighbourhood.map(n => n.value)
    const gender = Object.values(data.gender)
    const rolformatted = data.rol.map(n => n.id)
    const image2base64 = require('image-to-base64');

    var base64result = null;
    let url = null;
    if (data.profile_picture == null) {
      if (gender[1] === 0) {
        // masculinno
        url = userMaleImg;
      } else if (gender[1] === 1) {
        //feminino
        url = userFemaleImg;
      } else {
        // otros
        url = userMaleImg;
      }
      // base64result =
      await image2base64(url).then(
        (response) => {
          base64result = response
        }
      ).catch(
        (error) => {
          base64result = null
        }
      )
    } else {
      base64result = data.user_picture.base64Value;
    }

    const UserData = {
      name: data.name,
      family_name: data.surname,
      gender: gender[1],
      dni: data.dni,
      username: data.username,
      password: data.password,
      email: data.email,
      phone_number: `+${data.phone}`,
      roles: rolformatted,
      neighbourhoods: neighbourhood,
      profile_picture: base64result
    }

    await this.props.fetchNewUser(UserData)
    
  }

  getSignUpSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .min(3, 'El nombre debe tener un mínimo de 3(tres) caracteres')
        .required()
      ,
      surname: Yup.string()
        .min(2, 'El apellido debe tener un mínimo de 2(dos) caracteres')
        .required(),
      dni: Yup.string()
        .matches(/^\+?[1-9]\d{6,14}$/, 'DNI invalido')
        .min(8, 'El DNI debe tener un mínimo de 8(ocho) caracteres')
        .max(9, 'El DNI debe tener un máximo de 9(nueve) caracteres')
        .required(),
      gender: Yup.string()
        .required(),
      email: Yup.string()
        .email('El Email es inválido')
        .required(),
      phone: Yup.string()
        .matches(/^\+?[1-9]\d{6,14}$/, 'Teléfono invalido')
        .length(13, 'El teléfono debe tener 13(trece) caracteres')
        .required(),
      password: Yup.string()
        .required(),
      username: Yup.string()
        .required(),
      neighbourhood: Yup.string(),
      rol: Yup.string()
        .required()
    })

  getNeighbourhoodOptions = () =>
    this.props.neighbourhoods.map(n => ({ label: n.name, value: n.guid }))

  handleSelectRoles = (setFieldValue, option) => {
    const value = [option]
    setFieldValue('rol', value)
    this.setState({ selectedRoles: value })
    if (value.length !== 0) {
      value[0].menus.map(menu => {
        if (menu.code.indexOf("_BO") !== -1) {
          this.setState(prevState => ({
            customRoles: {
              ...prevState.customRoles,
              [menu.id]: 1
            }
          }))
        }
      })
    } else {
      this.props.profile.menus.map(menu => {
        if (menu.code.indexOf("_BO") !== -1) {
          this.setState(prevState => ({
            customRoles: {
              ...prevState.customRoles,
              [menu.id]: 0
            }
          }))
        }
      })
    }
  }

  getInitialValues = () => ({
    neighbourhood: ''
  })


  render() {
    return (
      <Modal>
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleSubmit}
          render={e => this.getFormContent(e)}
        />
      </Modal>
    )
  }
}

const mapStateToProps = ({
  userInfo: { neighbourhoods, selectedNeighbourhood },
  lotes,
  profile,
  users
}) => ({
  roles: mapRoles(profile.roles),
  neighbourhoods,
  profile,
  selectedNeighbourhood,
  lotsList: lotes.lotsList,
  loadingLots: lotes.loadingLots,
  mensajeErrorNewUser: users.fetchNewUserFail
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchNewUser,
      fetchRoles
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProprietaryCreator)
