import styled from 'styled-components'

export const TableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
`

export const Container = styled.div`
  width: 40%;
  justify-content: center;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;    
  padding: 40px 100px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease;
  box-shadow: -17px 19px 19px -14px rgba(0,0,0,0.25);
`

export const Title = styled.div`
    font-size: 20px;
    text-align: center;
`
export const CrossImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
`