import React, { Component } from 'react'
import {  FormContainer, RadioBtnContainer, LabelContainer, SelectWrapper,StyledButton,ErrorLabel, LabelChecked,BarrioLabel,BarrioWrapper } from './styled';
import CheckBox from 'components/CheckBox'
import NewDestinatario from '../../AgendarDestinatario/components/NewDestinatario'
import Input from 'components/Input'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { fetchGetAccount,fetchGetAccountByLote } from 'store/actions/billetera.actions'
import Spinner from 'react-spinner-material'

  const Proveedores = [
    { id: '1',amount: '100', label: 'Proveedor 1',executionDate: "01/01/2019",originClientUserName: "jeror",destinationClientUserName:"thomvilla",externalAccountId: "",type: "InnerTransaction",description:"asd", currency:"ARS"},
    { id: '2',amount: '100', label: 'Proveedor 2',executionDate: "01/01/2019",originClientUserName: "jeror",destinationClientUserName:"thomvilla",externalAccountId: "",type: "InnerTransaction",description:"asd", currency:"ARS"},
    { id: '3',amount: '100', label: 'Proveedor 3',executionDate: "01/01/2019",originClientUserName: "jeror",destinationClientUserName:"thomvilla",externalAccountId: "",type: "InnerTransaction",description:"asd", currency:"ARS"},
    { id: '4',amount: '100', label: 'Proveedor 4',executionDate: "01/01/2019",originClientUserName: "jeror",destinationClientUserName:"thomvilla",externalAccountId: "",type: "InnerTransaction",description:"asd", currency:"ARS"},
  ];

  const Servicios = [
      { id: '1',value: '1', label: 'Servicio 1' },
      { id: '2',value: '2', label: 'Servicio 2' },
      { id: '3',value: '3', label: 'Servicio 3' }
    ];

  const Abonos = [
      { id: '1',value: '1', label: 'Abono 1' },
      { id: '2',value: '2', label: 'Abono 2' },
      { id: '3',value: '3', label: 'Abono 3' }
    ];

  const Otros = [
      { id: '1',value: '1', label: 'Otros 1' },
      { id: '2',value: '2', label: 'Otros 2' },
      { id: '3',value: '3', label: 'Otros 3' }
    ];
    
  const Personal = [
      { id: '1',value: '1', label: 'Personal 1' },
      { id: '2',value: '2', label: 'Personal 2' },
      { id: '3',value: '3', label: 'Personal 3' }
    ];

class FirstStep extends Component {
    state = {
        selectedOption: 'recurrentes',
        isNewDestinatarioOpen: false,
        selectedRecurrente: Proveedores
    }
    
  getInitialValues = () => ({
    cbualias:'',
 })
 
  getSignUpSchema = () =>
    Yup.object().shape({
      cbualias: Yup.string(),
    })

  handleFormSubmit = async data => {
    if(data.cbualias){
      this.props.fetchGetAccount(data.cbualias)
    }else{
      this.props.fetchGetAccountByLote(data.input)
    }
  };
  
  componentDidUpdate(newProps) {
    if (newProps.AccountInfo !== this.props.AccountInfo) {
      this.props.setDestinatario(this.props.AccountInfo[0])
      this.props.NextStep(2)
    }
  }

  handleCheckBoxChange = changeEvent => {  
    this.setState({
      selectedOption: changeEvent.target.value
    });
  };

  handleImagesChange = changeEvent => {
    switch(changeEvent) {
      case 'Proveedores':
        return this.setState({selectedRecurrente: Proveedores})
      case 'Servicios':
        return this.setState({selectedRecurrente: Servicios})
      case 'Abonos':
        return this.setState({selectedRecurrente: Abonos})
      case 'Personal':
        return this.setState({selectedRecurrente: Personal})
      case 'Otros':
        return this.setState({selectedRecurrente: Otros})
      default:
        return null;
    }
  };

  toggleNewDestinatario = () => this.setState({ isNewDestinatarioOpen: !this.state.isNewDestinatarioOpen })

  getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <FormContainer onSubmit={handleSubmit}>
      <LabelContainer>Cuenta origen</LabelContainer>
      <BarrioWrapper>
        <BarrioLabel size={"20px"} >{this.props.neighbourhood.label}</BarrioLabel>
      </BarrioWrapper>
      <LabelContainer>Destinatario</LabelContainer>
        <RadioBtnContainer>
          {/*
          <LabelChecked checked={this.state.selectedOption === 'recurrentes'}>
            <CheckBox
              type="radio"
              name="rdBtn"
              value="recurrentes"
              checked={this.state.selectedOption === 'recurrentes'}
              onChange={this.handleCheckBoxChange}
            />
            Cuentas recurrentes
          </LabelChecked>
           */}
          <LabelChecked checked={this.state.selectedOption === 'lote'}>
            <CheckBox
              type="radio"
              name="rdBtn"
              value="lote"
              checked={this.state.selectedOption === 'lote'}
              onChange={this.handleCheckBoxChange}
            />
            Buscar por lote
          </LabelChecked>
          <LabelChecked checked={this.state.selectedOption === 'externo'}>
            <CheckBox
              type="radio"
              name="rdBtn"
              value="externo"
              checked={this.state.selectedOption === 'externo'}
              onChange={this.handleCheckBoxChange}
            />
           Transferir a externo
          </LabelChecked>
        </RadioBtnContainer>
        {/* AGENDA DESTINATARIO NO ENTRA EN MVP
        {this.state.selectedOption === "recurrentes" ? (
          <>
          <ImagesGroup style={{margin: '0px !important'}} cuentas={cuentas} onChange={option => setFieldValue('concepto', option)}
          onInput={(option) => this.handleImagesChange(option)}
          />          
          <SelectWrapper> 
            <Select
              value={values.select}
              error={errors.select}
              onChange={option => setFieldValue('select', option)}
              options={this.state.selectedRecurrente}
              placeholder="Seleccionar recurente..."
              autoFocus
            />
            {errors.select && <ErrorLabel>{errors.select}</ErrorLabel>}
          </SelectWrapper>
          </>
        ) */}
          {this.state.selectedOption === "externo" ? (
           <SelectWrapper>
              CBU o alias
            <Input
             name={'DestinatarioCbu'}
             label={'DestinatarioCbu'}
             placeholder={'CBU/Alias del beneficiario'}
             value={values.cbualias}
             error={errors.cbualias}
             onChange={({ target: value }) => setFieldValue('cbualias', value.value)}
             margin="normal"
             weight="700"
           />
           {errors.cbualias && <ErrorLabel>{errors.cbualias}</ErrorLabel>}
        </SelectWrapper> 
          )
         : (
          <SelectWrapper>
            <Input
              name={'DestinatarioInput'}
              label={'DestinatarioInput'}
              placeholder={'Buscar por lote...'}
              value={values.input}
              error={errors.input}
              onChange={({ target: value }) => setFieldValue('input', value.value)}
              margin="normal"
              weight="700"
            />  
            {errors.input && <ErrorLabel>{errors.input}</ErrorLabel>}
          </SelectWrapper> 
        )}
        
        {/* AGENDA DE DETINATARIO NO ENTRA EN MVP
         <NewDestinatarioLabel onClick={this.toggleNewDestinatario} >Nuevo Destinatario</NewDestinatarioLabel>
         */}
        {this.props.errorAccountInfoByLoteMessage ||this.props.errorAccountInfoMessage ? (<ErrorLabel>No sé obtuvieron propietarios</ErrorLabel>) : (<></>)}
        {this.props.loadingAccountInfo ||this.props.loadingAccountInfoByLote ? (<StyledButton type="submit" ><Spinner/></StyledButton>): (
        <StyledButton type="submit" >Continuar</StyledButton>)}
        
    </FormContainer>
  )

  render() {
    return ( 
      <>
      {this.state.isNewDestinatarioOpen ? (<NewDestinatario onClose={this.toggleNewDestinatario}/>) : 
      (
        <Formik
          initialValues={this.getInitialValues()}
          validateOnChange={false}
          validationSchema={this.getSignUpSchema()}
          onSubmit={this.handleFormSubmit}
          render={e => this.getFormContent(e)}
        />
      )}
      </>          
    )
  }
}
  

const mapStateToProps = state => {
  return {
    AccountInfo: state.billetera.AccountInfo,
    loadingAccountInfo: state.billetera.loadingAccountInfo,
    loadingAccountInfoByLote: state.billetera.loadingAccountInfoByLote,
    errorAccountInfoByLote: state.billetera.errorAccountInfoByLote,
    errorAccountInfoMessage: state.billetera.errorAccountInfoMessage,
    errorAccountInfoByLoteMessage: state.billetera.errorAccountInfoByLoteMessage,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchGetAccount,
      fetchGetAccountByLote
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
  )(FirstStep)
)
